import { customElement, state } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { when } from "lit/directives/when.js";
import menuSvg from "@/assets/menu.svg";

@customElement("cart-head-mobile")
export class CartHeadMobile extends LitElement {
  @state()
  popup: null | { target: Element } = null;

  openPopup = (target: Element) => {
    this.popup = { target };
  };

  closePopup = () => {
    this.popup = null;
  };

  showMenuSm(event: { detail: { target: Element } }) {
    this.openPopup(event.detail.target);
  }

  renderPopup = () => {
    return html`<menu-sm @close=${this.closePopup}></menu-sm>`;
  };

  render() {
    return html`
      ${when(this.popup, this.renderPopup)}
      <div class="head">
        <sl-icon-button class="menu col-2" src=${menuSvg} @click=${this.showMenuSm}> </sl-icon-button>
        <div class="brand center col-8">
          <fix-brand type="sm"></fix-brand>
        </div>
      </div>
    `;
  }

  static styles = css`
    .head {
      background: #fff;
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 10px;
      border-bottom: 1px #d9d9d9 solid;
    }
    .col-2 {
      width: 16.6%;
    }
    .col-8 {
      width: 66.6%;
    }
    .brand {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
}
