import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import autoSvg from "@/assets/auto.svg";
import doneSvg from "@/assets/done.svg";
import "@/elements/mobile/fix-button";
import { fontBaseNormal, fontBaseStrong } from "@/style/font";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("my-garage")
@localized()
export class MyGrarage extends LitElement {
  @property({ type: Boolean })
  fitsProduct = false;

  @property({ type: String })
  currentCar = null as null | string;

  @property({ reflect: true })
  get mode() {
    if (this.currentCar && this.fitsProduct) {
      return "product";
    } else if (this.currentCar) {
      return "car";
    } else {
      return "selection";
    }
  }

  render() {
    if (this.mode === "selection") {
      return this.button;
    }

    return html`${this.message}${this.button}`;
  }

  get button() {
    return html`
      <fix-button variant="text" size="small">
        <sl-icon slot="prefix" src=${autoSvg}></sl-icon>
        ${t("3ebo2x9ozbl9vr0x.my-garage", "My garage")}
      </fix-button>
    `;
  }

  get message() {
    return html` <div class="message">${this.mode === "product" ? this.productFirsMessage : nothing}${this.currentCarMessage}</div> `;
  }

  get productFirsMessage() {
    return html`<sl-icon src=${doneSvg}></sl-icon>
      <div class="product">This product fits your</div>`;
  }

  get currentCarMessage() {
    return html`<div class="car">${this.currentCar}</div>`;
  }

  static styles = css`
    :host {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: var(--layout-padding-h);
      padding-right: var(--layout-padding-h);
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-top: 1px solid var(--colorsLibrary-Gray-200, #e4e4e7);
      border-bottom: 1px solid var(--colorsLibrary-Gray-200, #e4e4e7);
    }

    :host([mode="selection"]),
    :host([mode="car"]) {
      flex-direction: row;
      gap: 16px;
    }

    :host([mode="product"]) {
      flex-direction: column;
      gap: 4px;
    }

    .message {
      display: flex;
      align-items: center;
    }

    .message sl-icon {
      font-size: 16px;
    }

    .message sl-icon + * {
      margin-left: 8px;
    }

    .message .product + * {
      margin-left: 4px;
    }

    .product,
    .car {
      color: var(--colorText, rgba(0, 0, 0, 0.88));
    }

    ${fontBaseNormal(".product")}
    ${fontBaseStrong(".car")}
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "my-garage": MyGrarage;
  }
}
