import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { buttonCustomize, buttonFont } from "../mobile/fix-button";

@customElement("fix-pager-number")
export class FixPagerNumber extends LitElement {
  @property()
  active!: boolean;

  render() {
    const classNames = classMap({
      ["active"]: this.active,
    });

    return html`<sl-button class=${classNames}><slot></slot></sl-button>`;
  }

  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "Base/Normal")}
        ${buttonFont(".active", "Base/Strong")}

        sl-button {
      --border-radius: 6px;
      --width: 40px;
      --height: 40px;
    }

    sl-button:not(.active) {
      --color: var(--Components-Pagination-Global-colorText, #27272a);
      --color-bg: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-hover: var(--Components-Pagination-Global-colorText, #27272a);
      --color-bg-hover: var(--Components-Pagination-Global-colorBgTextHover, rgba(0, 0, 0, 0.06));
      --color-border-hover: var(--Components-Pagination-Global-colorBgTextHover, rgba(0, 0, 0, 0.06));
      --color-active: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
      --color-bg-active: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border-active: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
    }

    sl-button.active {
      --color: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
      --color-bg: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
      --color-hover: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
      --color-bg-hover: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border-hover: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
    }
  `;
}
