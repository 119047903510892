import { html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/product-grid";
import "@/elements/fix-tag";
import "@/elements/fix-filter-dropdown";

import { consume } from "@lit/context";
import { Url } from "@/helpers/url";
import { Router, RouterLocation } from "@vaadin/router";
import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { CarBrandsListDocument, B2cBrand, BrandDocument, CategoriesByBrandDocument, B2cCategory } from "@/generated/graphql/b2c";
import { ServiceLocatorContext, Services } from "@/services/services";
import { provideLayoutStyles } from "../product-page/utils";
import { when } from "lit/directives/when.js";
import "../category-page/categories-navigator";
import "@/pages/home-page/extracted/featured-brands-list";
import "@/elements/fix-back-button";
import "@/elements/fix-pager-group";
import { choose } from "lit/directives/choose.js";
import { ProductAreaStore } from "../category-page/product-area-catalog/product-area-store";
import { ProductLoader } from "../category-page/product-loader";
import { PageElement, whenType } from "@/util/element";
import "../category-page/product-area-catalog/product-area-element";
import "@/elements/brands-list-images";
import "@/elements/brands-list-links";
import { t } from "i18next";
import { GlobalElement } from "../category-page/product-area-catalog/product-area-filter-popup";
import type { BeforeLeaveObserver, AfterEnterObserver, PreventCommands } from "@vaadin/router";
import "../category-page/loader-overlay";
import { ParametersValue } from "@/services/parameters";

@customElement("brand-page")
export class BrandPage extends PageElement implements BeforeLeaveObserver, AfterEnterObserver {
  declare type: "sm" | "lg";

  @property()
  location!: RouterLocation;

  @state()
  view: {

  } = {};

  @state()
  category: { name?: string; id?: string } = {};

  @state()
  loading = true;

  @state()
  blank = true;

  @state()
  resultState: null | "empty" | "content" = null;

  @consume({ context: BreadcrumbsContext, subscribe: true })
  public breadcrumbs!: Breadcrumbs;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  brandId!: string;

  loader!: ProductLoader;

  store!: ProductAreaStore;

  @state()
  brand!: B2cBrand

  @state()
  categories!: B2cCategory[]

  @state()
  brands: B2cBrand[] = []

  loadedFor!: ParametersValue

  render() {
    if (this.blank) {
      return this.renderLoaderOverlay();
    }

    return html` ${this.renderContent()} ${when(this.loading, () => this.renderLoaderOverlay())} `;
  }

  renderContent() {
    return choose(this.type, [
      ["sm", this.renderContentSm],
      ["lg", this.renderContentLg],
    ]);
  }

  renderContentSm = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardSm()}
        ${this.renderProductArea()}
        ${this.renderPopularBrands()} ${this.renderAbout()}
      </catalog-layout>
    `;
  };

  renderContentLg = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardLg()}
        ${this.renderProductArea()} 
        ${this.renderPopularBrands()}
        ${this.renderAbout()}
      </catalog-layout>
    `;
  };

  renderTopcardSm = () => {
    return html`
      <div class="section normargin card type-card-top">
        <div class="row navigation">
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="title">${this.category.name}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
      </div>
    `;
  }

  renderTopcardLg = () => {
    const back = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 2];

    return html`
      <div class="section normargin card type-card-top type-card-top-1">
        <div class="row navigation">
          ${when(back, () => html`<fix-back-button class="back" .url=${back.url} .mode=${'link'}></fix-back-button>`)}
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="titles">
            <div class="title">${this.category.name}</div>
            <div class="title-for"></div>
          </div>
          <div class="results">${t("j8cinnz091w154tp.results", "results")}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
      </div>
    `;
  }

  renderLoaderOverlay() {
    return html` <loader-overlay></loader-overlay> `;
  }

  renderPopularBrands() {
    return html`
      <div class="section card type-2">
        <div class="section-title">${t("22462yo1x6yur1ek.car-brands-title", "Buy car parts from the most popular car brands")}</div>
        <div class="section-box">
          <brands-list-links .type=${this.type} .brands=${this.brands}></brands-list-links>
        </div>
      </div>
    `;
  }

  renderAbout() {
    return html`
      <div class="section card type-1">
        <div class="section-title">${this.category.name}</div>
        <div class="section-box">
          <fix-text
            .type=${this.type}
            .text=${`Your vehicle deserves the best, and so do you. Get the auto parts you need at prices that won't break the bank. Whether it's a simple replacement or an upgrade, find parts that fit perfectly and perform flawlessly. With a wide selection of top-grade components, you're just a step away from smoother drives and peace of mind. Great products, great prices—because every journey should be a good one.<br>Keep your car running like new. Shop now and see the difference quality can make.`}
          ></fix-text>
        </div>
      </div>
    `;
  }

  getFoundCategories() {
    const roots = this.services.categories.findTopLevelCategories();
    const whitelist = this.categories.map(item => {
      const [root] = this.services.categories.getParents(item.id);
      return root.id;
    })

    return roots.filter(item => whitelist.includes(item.id));
  }

  renderNavigator() {
    const categories = this.getFoundCategories()
      .map((item) => {
        const category = this.services.categories.getItem(item.id);
        return {
          name: category.name,
          key: category.id,
          id: category.id,
          image: category.image,
          url: category.url
        };
      });

    return html`<categories-preview-custom type=${this.type} .categories=${categories}></categories-preview-custom>`;
  }

  renderProductArea() {
    return html`<product-area-element
      .type=${this.type}
      .store=${this.store}
      .services=${this.services}
      .resultState=${this.resultState}
    ></product-area-element>`;
  }

  async navigate(params: { category: string; params?: URLSearchParams, replace?: boolean }) {
    const search = params.params ?? new URLSearchParams();

    const path = [params.category];

    this.overwriteLocation({ path, search });
    this.handleLocation({ path, search });
  }

  task: null | string = null;

  onAfterEnter(location: RouterLocation) {
    this.handleLocation({
      path: location.params.dynamicPath as string[],
      search: new URLSearchParams(location.search)
    });
    this.loadedFor = this.services.parameters.parameters;
  }

  async onBeforeLeave(location: RouterLocation, commands: PreventCommands) {
    const paramsEqual = this.services.parameters.equalsParameters(
      this.services.parameters.parameters,
      this.loadedFor
    );

    if (location.route?.name === 'dynamic-route' && paramsEqual) {
      const entity = await this.services.routes.resolveFromLocalPath(location.params.dynamicPath as string[]);
      if (entity?.type !== 'brand' || entity?.id !== this.brandId) {
        return;
      }

      // locking navigation for component reload prevent
      // overwrtie location async, because commands.prevent will reset any url changes
      const params = {
        path: location.params.dynamicPath as string[],
        search: new URLSearchParams(location.search)
      };
      setTimeout(() => {
        this.overwriteLocation(params);
        this.handleLocation(params);
      });

      return commands.prevent();
    }
  }

  async overwriteLocation(params: { path: string[], search: URLSearchParams }) {
    const url = Url.to("dynamic-route", { dynamicPath: params.path }, params.search.toString());
    const replace = true;
    const mode = replace ? "replaceState" : "pushState";
    window.history[mode](null, "", url);
  }

  async handleLocation(params: { path: string[], search: URLSearchParams }) {
    const { path, search } = params;
    const registryUrl = this.services.routes.convertToRoute({
      segments: path,
    });

    const route = await this.services.routes.resolve(registryUrl);
    const sameCategory = this.category?.id === route?.id;

    this.brandId = route!.id;
    this.store.readParameters(search);

    if (this.blank) {
      this.updateState('blank');
    } else if (sameCategory) {
      this.updateState('selections');
    } else {
      this.updateState('brand');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.loader = new ProductLoader(this.services);
    this.store = new ProductAreaStore();
    this.prepare();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.store.events.removeEventListeners(this);
    this.store.disconnect(this);
  }

  async prepare() {
    this.store.connect(this);
    this.addEventListener("productarea.update", () => this.emitStoreStateChanges());
    this.addEventListener("productarea.back", () => this.backFromSerch());
    this.requestUpdate();
  }

  async updateState(step: "blank" | "brand" | "selections") {
    this.setLoading(true);

    if (step === "blank") {
      await this.initialFetch();
      this.blank = false;
    }

    if (step === "blank" || step === "brand") {
      await this.configureLayout();
      await this.updateBreadcrumbs();
      await this.configureViewMode();
      await this.fillStoreByFilterOptionsForCurrentCategory();
    }

    await this.fillStoreByUrl();
    await this.performProductsLoad();
    this.setLoading(false);
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  async initialFetch() {
    const { api } = this.services;
    const [brandResponse, categoriesBrandResponse] = await Promise.all([
      await api.client.query({
        query: BrandDocument,
        variables: {
          input: {
            brandId: this.brandId,
          },
        },
      }),
      await api.client.query({
        query: CategoriesByBrandDocument,
        variables: {
          input: {
            brandId: this.brandId,
          },
        },
      }),
    ]);

    this.brand = brandResponse.data.Brand as B2cBrand;
    this.categories = categoriesBrandResponse.data.CategoriesByBrand as B2cCategory[];
    this.brands = this.services.brands.list;
  }

  async fillStoreByUrl() {
    this.store.readParameters(new URLSearchParams(window.location.search));
  }

  async fillStoreByFilterOptionsForCurrentCategory() {
    this.store.setupCategories(this.getFoundCategories());
    this.store.fillForms();
  }

  async emitStoreStateChanges() {
    const params = this.store.serializeParameters()!;
    await this.navigate({
      category: this.getCategoriesPath(),
      params: params,
    });
  }

  async backFromSerch() {
    Router.go(Url.to("home-page-with-lang"));
    // temporary disable cleck
    // if (!this.store.state.isFiltersBlank()) {
    //   this.store.state.clear();
    // } else {
    //   Router.go(Url.to("home-page-with-lang"));
    // }
  }

  async performProductsLoad() {
    await this.loader.load({
      brand: this.brand.id,
    }, this.store);
    this.resultState = this.store.products.length ? "content" : "empty";
  }

  updateBreadcrumbs() {
    this.breadcrumbs.setBreadcrumbs([
      {
        label: this.brand.name,
        url: Url.to("dynamic-route", { dynamicPath: this.location.params.dynamicPath })
      },
    ]);
  }

  getCategoriesPath() {
    return this.services.brands.list.find(item => item.id === this.brandId)!.slug;
  }

  getRootCategory() {
    return this.brandId;
  }

  configureLayout() {
    // const path = this.getCategoriesPath();
    // const categoryId = path[path.length - 1];
    // const category = this.services.categories.getItem(categoryId);
    // this.category = {
    //   name: category.name,
    //   id: category.id,
    // };
  }

  configureViewMode() {

    this.requestUpdate();
  }

  static stylesCommon = css``;

  static stylesSm = css`
    ${provideLayoutStyles("sm")};
  `;

  static stylesLg = css`
    ${provideLayoutStyles("lg")};
  `;

  static get styles() {
    return [this.stylesCommon, whenType("sm", this.stylesSm), whenType("lg", this.stylesLg)];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "brand-page": BrandPage;
  }
}