import { Api } from "@/api";
import { LitElement } from "lit";
import { ContextProvider, createContext } from "@lit/context";
import { StaticPageContentDocument, StaticPageContentType } from "@/generated/graphql/b2c.ts";
import DomPurify from "dompurify";

export const StaticPagesType = {
  aboutUs: "about-us",
  contactUs: "contact-us",
  help: "help",
  privacyPolicy: "privacy-policy",
  payment: "payment",
};

export class StaticPages {
  loading = true;

  protected api: Api;

  protected provider;

  private pagesMapping: Map<string, string>;

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: StaticPagesContext });
    this.api = api;
    this.pagesMapping = new Map();

    this.update();
  }

  fetchStaticPage = async (type: string) => {
    try {
      const payload = await this.requestCurrentCart(type);

      if (!payload) {
        return null;
      }

      this.updateStaticPage(type, payload);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      this.loading = false;
      this.update();
    }
  };

  protected updateStaticPage = (type: string, payload: StaticPageContentType) => {
    this.pagesMapping.set(type, payload.content);
  };

  getStaticPage = (type: string) => {
    const content = this.pagesMapping.get(type);
    if (!content) {
      return "";
    }

    return DomPurify.sanitize(content, {});
  };

  protected requestCurrentCart = async (type: string) => {
    const cartResponse = await this.api.client.query({
      query: StaticPageContentDocument,
      variables: {
        input: {
          name: type,
        },
      },
    });

    return cartResponse.data.StaticPageContent as StaticPageContentType;
  };

  protected update = () => {
    this.provider.setValue(this, true);
  };
}

export const StaticPagesContext = createContext<StaticPages>("static-pages");
