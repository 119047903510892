import { font } from "@/util/fonts";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import sort from "@/assets/sort.svg";
import down from "@/assets/down.svg";
import done from "@/assets/done.svg";
import { classMap } from "lit/directives/class-map.js";

@customElement("fix-filter-dropdown")
export class FixFilterDropdown extends LitElement {
  @query("sl-select")
  select!: HTMLElement;

  @property()
  value!: string;

  @property()
  options: { key: string; name: string; icon: string }[] = [];

  @state()
  placement = "";

  observer: MutationObserver | null = null;

  render() {
    const className = classMap({
      [this.placement]: this.placement.length,
    });

    return html`
      <sl-select class=${className} .value=${this.value} @sl-show=${this.onMenuShow} @sl-hide=${this.onMenuHide}>
        <sl-icon .src=${sort} slot="prefix"></sl-icon>
        <sl-icon .src=${down} slot="expand-icon"></sl-icon>
        ${this.renderDropdownOptions()}
      </sl-select>
    `;
  }

  renderDropdownOptions() {
    return repeat(
      this.options,
      (item) => item.key,
      (item) => html`<sl-option @click=${() => this.onOptionClick(item.key)} .value=${item.key}>${item.name}</sl-option>`,
    );
  }

  getPrefixIcon() {
    const selected = this.options.find((option) => option.key === this.value);
    switch (selected?.icon) {
      case "asc":
        return sort;
      case "desc":
        return sort;
      case "sort":
        return sort;
      default:
        return "arrow-down-up";
    }
  }

  onMenuShow = () => {
    this.togglePositionObserver(true);
  };

  onMenuHide = () => {
    this.togglePositionObserver(false);
  };

  onOptionClick = (key: string) => {
    this.dispatchEvent(new CustomEvent("change", { detail: { key } }));
  };

  togglePositionObserver = (targetState: boolean) => {
    if (!targetState) {
      this.observer?.disconnect();
      this.observer = null;
      this.updatePlacement("");
      return;
    }

    const popup = this.select.shadowRoot!.querySelector("sl-popup")!;
    const observer = new MutationObserver(() => {
      const placement = popup.getAttribute("data-current-placement") ?? "";
      this.updatePlacement(placement ?? "");
    });

    observer.observe(popup, {
      attributes: true,
      attributeFilter: ["data-current-placement"],
    });

    this.observer = observer;
  };

  updatePlacement(value: string) {
    this.placement = value;
  }

  static styles = css`
    :host {
      --main-color: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --sl-input-border-radius-medium: var(--Border-Radius-borderRadius, 6px);
      --sl-input-background-color: var(--main-color);
      --sl-input-border-color: var(--main-color);
      --sl-input-background-color-focus: var(--main-color);
      --sl-input-border-color-focus: var(--main-color);
      --sl-input-spacing-medium: var(--spacing-button-primary, 16px);
      --sl-input-height-medium: 36px;
      --sl-focus-ring-width: 0px;
      --sl-input-color: var(--Components-Button-Component-defaultColor, #27272a);
      --sl-input-spacing-medium: 16px;
      --sl-spacing-small: 8px;
    }

    sl-select::part(combobox) {
      ${font("Base/Normal")};
      border-radius: var(--combobox-border-top, 6px) var(--combobox-border-top, 6px) var(--combobox-border-bottom, 6px) var(--combobox-border-bottom, 6px);
    }

    sl-select::part(listbox) {
      border-radius: var(--listbox-border-top, 6px) var(--listbox-border-top, 6px) var(--listbox-border-bottom, 6px) var(--listbox-border-bottom, 6px);
      border-top-color: var(--listbox-border-top-color, var(--sl-panel-border-color));
      border-bottom-color: var(--listbox-border-bottom-color, var(--sl-panel-border-color));
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    }

    sl-select::part(listbox) {
      padding-block-start: 8px;
      padding-block-end: 16px;
    }

    sl-icon[slot="prefix"] {
      margin-inline-end: 8px;
      font-size: 16px;
      color: var(--Components-Button-Component-defaultColor, #27272a);
    }

    sl-option {
      --sl-color-primary-600: #ffffff;
    }

    sl-option::part(checked-icon) {
      color: #65a30d;
      font-size: 20px;
      padding-inline-end: 0;
    }

    sl-option::part(label) {
      ${font("Base/Normal")};
      color: var(--Components-Button-Component-defaultColor, #27272a);
    }

    sl-option::part(base) {
      flex-direction: row-reverse;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 8px;
      padding-left: 20px;
    }

    sl-select.top {
      --combobox-border-top: 0;
      --listbox-border-bottom: 0;
      --listbox-border-bottom-color: white;
    }

    sl-select.bottom {
      --combobox-border-bottom: 0;
      --listbox-border-top: 0;
      --listbox-border-top-color: white;
    }
  `;
}
