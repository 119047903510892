import { LitElement, PropertyValueMap, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { font } from "@/util/fonts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { layout } from "../product-page/cont";
import { createRef, ref } from "lit/directives/ref.js";
import { localized } from "@/decorators/localized";
import { VariantSwitcher } from "../product-page/switcher";
import { choose } from "lit/directives/choose.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/mobile/fix-input";
import "@/elements/mobile/fix-checkbox";
import "@/pages/product-page/layout/footer";
import "@/elements/mobile/store-header";
import { when } from "lit/directives/when.js";
import { cssLayout } from "@/util/layout";

@customElement("signup-page")
@layout()
@localized()
export class SignupPage extends LitElement {
  layout!: string;

  switcher: VariantSwitcher<SignupPage> = new VariantSwitcher(this);

  @consume({ context: customerChannelContext, subscribe: true })
  @property({ attribute: false })
  public customerContext!: CustomerChannel;

  private ref = createRef<HTMLFormElement>();

  @state()
  private loading = false;

  @state()
  private formData = {
    subscribeNewsletter: true,
    email: "",
    password: "",
    phone: "",
  };

  @state()
  private formErrors = {
    email: "",
    password: "",
    phone: "",
  };

  @state()
  private generalError = "";

  render() {
    return choose(this.layout, [
      ["desktop", this.renderDesktop],
      ["mobile", this.renderMobile],
    ]);
  }

  renderMobile = () => {
    return html`<store-header type="sm"></store-header>${this.renderPage()}`;
  };

  renderDesktop = () => {
    return html` <store-header type="lg">
        <span slot="left-block">${this.renderContinueShoppingLink()}</span>
      </store-header>
      ${this.renderPage()}
      <footer-element></footer-element>`;
  };

  renderPage() {
    return html`
      <div class="signup-page-container">
        ${when(this.layout === "mobile", () => html`<div class="header">${this.renderContinueShoppingLink()}</div>`)}
        <div class="signup-container">
          <form class="signup-form" name="signup" @submit=${this.submit} ${ref(this.ref)}>
            <div><span class="signup-heading">${t("ya79u75dr5ondfid.sign-up", "Sign Up")}</span></div>
            <div class="inputs-container">
              <fix-input
                id="email-input"
                name="email"
                type="email"
                placeholder="${t("stci3dd82scs5j9p.your-email", "* Your email")}"
                helpText="${t("x926694e5jfby7au.email-incorrect", "Please enter a valid email address")}"
                .customError=${this.formErrors.email}
                required
                clearable
              ></fix-input>
              <fix-input
                name="password"
                type="password"
                placeholder="${t("c3m6uuwc048svqa5.password", "* Create a password")}"
                helpText="${t("3t8k9e9jqwp1pn82.please-enter-password", "Please enter password")}"
                .customError=${this.formErrors.password}
                required
                clearable
              ></fix-input>
              <fix-input
                name="phone"
                type="text"
                placeholder="${t("xvf27b4hpsw64a0n.phone-number", "* Phone number")}"
                .customError=${this.formErrors.phone}
                clearable
              ></fix-input>
            </div>
            <fix-button @click=${this.submit} variant="primary" type="${this.elementsType}" class="signup-btn" .loading=${this.loading}>
              ${t("ovd6x5p1j74vsi7u.create-account", "Create your account")}
            </fix-button>
            <fix-checkbox class="newsletter-checkbox" name="newsletter" .checked=${this.formData.subscribeNewsletter} @change=${this.toggleSubscribeNewsletter}>
              ${t("320hfofka78yq629.subscribe-to-newsletter", "Subscribe to newsletter")}
            </fix-checkbox>
          </form>
          <div class="divider"></div>
          <div class="terms-block">
            <span> ${t("7nirh7mrk4ou46rz.agree-policy-text", "By creating an account you are agreeing to our")} </span>
            <fix-link class="policy-link" href="${this.policyLink}" type="sm">${t("i0jm43p91f8qqk3w.policy-link", "Privacy policy and terms of use")}</fix-link>
          </div>
        </div>
      </div>
    `;
  }

  get policyLink() {
    return Url.to("privacy-policy-page");
  }

  get elementsType() {
    return this.layout === "desktop" ? "md" : "sm";
  }

  renderContinueShoppingLink = () => {
    return html`
      <fix-link class="continue-shopping-link" href="${Url.to("home-page-with-lang")}" type=${this.elementsType}>
        <sl-icon src=${arrowOutlined}></sl-icon>
        ${t("k0q7l2w2q1856v4o.continue-shopping", "Continue shopping")}
      </fix-link>
    `;
  };

  toggleSubscribeNewsletter = () => {
    this.formData = {
      ...this.formData,
      subscribeNewsletter: !this.formData.subscribeNewsletter,
    };
  };

  submit = async () => {
    const formData = new FormData(this.ref.value!);
    // @ts-ignore
    const form = this.ref.value;

    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const phone = formData.get("phone") as string;
    const newsletter = formData.get("newsletter") === "true" ? true : false;

    if (!email || !password || !form?.checkValidity()) {
      return;
    }

    this.loading = true;

    const signupResult = await this.customerContext.signup({ email, password, phone, newsletter });

    this.loading = false;

    if (!signupResult.success) {
      // @ts-ignore
      this.formErrors = { ...signupResult.errors };
      return;
    }
    await this.customerContext.login({ email, password });
    await this.customerContext.fetchCustomerChannel();
    this.redirectToAboutYourself();
  };

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(_changedProperties);
    if (this.customerContext?.customer) {
      this.redirectToHomePage();
    }
  }

  private redirectToHomePage() {
    Router.go(Url.to("home-page-with-lang"));
  }

  private redirectToAboutYourself() {
    Router.go(Url.to("about-yourself-page"));
  }

  static styles = css`
    .signup-page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 16px;
      gap: var(--space-margin-margin-lg, 24px);
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
    }

    ${cssLayout("desktop")} .signup-page-container {
      padding: 56px 0px;
    }

    .signup-container {
      display: flex;
      padding: var(--space-padding-padding-xl, 32px) var(--space-padding-padding-md, 24px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: var(--border-radius-border-radius-lg, 8px);
      background: var(--neutral-bg-color-bg-container, #ffffff);
    }

    ${cssLayout("desktop")} .signup-container {
      width: 410px;
      align-self: center;
      padding: var(--space-padding-padding-xl, 32px);
    }

    .header {
      display: flex;
      align-self: stretch;
      padding: 0px var(--header-margin-xs, 8px);
      margin-top: var(--header-margin-xs, 24px);
    }

    .signup-form {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
      align-self: stretch;
    }

    ${cssLayout("mobile")} .signup-heading {
      ${font("Heading/3")};
    }

    ${cssLayout("desktop")} .signup-heading {
      ${font("Heading/2")}
    }

    .inputs-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      align-self: stretch;
    }

    .signup-btn {
      align-self: stretch;
    }

    .divider {
      display: flex;
      height: 1px;
      width: 100%;
      background: var(--neutral-border-color-border-secondary, #f0f0f0);
    }

    .forgot-password {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .terms-block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      text-align: center;
    }

    .terms-block > span {
      ${font("SM/Normal")}
      color: var(--components-typography-global-color-text, #27272a);
    }

    .policy-link {
      ${font("SM/Normal")}
      align-self: center;
    }

    .continue-shopping-link sl-icon {
      margin-right: var(--Space-Padding-paddingXS, 8px);
    }

    .newsletter-checkbox {
      margin-top: 8px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "signup-page": SignupPage;
  }
}
