import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import "../product/product-grid";

@customElement("related-products")
export class RelatedProducts extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  render() {
    return html`<product-grid></product-grid>`;
  }
}
