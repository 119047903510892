import { B2cProductSearchPayload, UrlDataDocument } from "@/generated/graphql/b2c";
import type { Services } from "./services";

import i18next from "i18next";
import { defaultLang } from "@/constants/languages";
import { createProductPath } from "@/pages/category-page/product-area-catalog/product-area-element";

export class Routes {
    settings = {
        rootCategoryConverted: false
    }
    registry: Map<string, { type: 'product' | 'category' | 'brand', id: string }> = new Map();

    constructor(protected services: Services) {

    }

    async load() {
        this.prepareForBrands();
        this.prepareForCategories();
    }

    clear() {
        this.registry.clear();
    }

    prepareForBrands() {
        for (const brand of this.services.brands.list) {
            const route = this.convertToRoute({
                segments: [brand.slug]
            });
            this.setForUrl(route, { type: 'brand', id: brand.id });
        }
    }

    prepareForCategories() {
        //const passenger = this.services.categories.getItem('passenger')!;
        for (const key of this.services.categories.getAll()) {
            if (key === 'passenger') {
                continue;
            }
            const path = this.services.categories.getItemPath(key)!;
            const route = this.convertToRoute({
                segments: [...path]
            });
            this.setForUrl(route, { type: 'category', id: key });
        }
    }

    prepareForProduct(product: B2cProductSearchPayload) {
        const path = createProductPath(product, this.services.categories);
        const route = this.convertToRoute({
            segments: path
        });
        this.setForUrl(route, { type: 'product', id: product.id });
    }

    async resolve(url: string) {
        if (!this.registry.has(url)) {
            await this.updateRegistryForUrl(url);
        }

        return this.resolveFromRegistry(url);
    }

    convertToRoute(params: { segments: string[] }) {
        const prefix = this.services.parameters.parameters.language;
        const routeSegments = [] as string[];

        routeSegments.push(prefix);
        routeSegments.push(...params.segments);

        return routeSegments.join('/');
    }

    async resolveFromLocalPath(segments: string[]) {
        return this.resolve(this.convertToRoute({
            segments
        }));
    }

    async updateRegistryForUrl(url: string) {
        const details = await this.queryDetailsForUrl(url);

        if (details) {
            this.registry.set(url, details);
        }
    }

    resolveFromRegistry(url: string) {
        return this.registry.get(url);
    }

    setForUrl(url: string, details: { type: 'product' | 'category' | 'brand', id: string }) {
        this.registry.set(url, details);
    }

    protected async queryDetailsForUrl(url: string) {
        const response = await this.services.api.client.query({
            query: UrlDataDocument,
            variables: {
                input: {
                    url: url,
                }
            }
        });

        if (response.errors?.length || !response.data.UrlData?.type) {
            return null;
        }

        const type = response.data.UrlData.type as 'product' | 'category' | 'brand';

        return {
            type: type,
            id: response.data.UrlData[type].id
        };
    }
}