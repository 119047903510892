import { customElement, property, state } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import "@/generated/css/variables.css";
import { type } from "@/util/layout";
import "@/elements/mobile/fix-button";
import "@/elements/mobile/fix-button";
import "../layout/page-section-layout";
import { consume } from "@lit/context";
import { type Main, mainContext } from "@/context/main";
import { Category, TopCategoriesDocument } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { default as i18next } from "i18next";
import { ServiceLocatorContext, Services } from "@/services/services";

@customElement("top-categories")
@localized()
export class TopCategories extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ attribute: true, type: Boolean, reflect: true })
  bg = true;

  @property({ type: Boolean }) loading = true;

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  categories: Category[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.fetchTopCategories();
  }

  render() {
    if (!this.list.length) return;

    return html` <page-section-layout type=${this.type} sectionTitle="${t("4ce0774dbc26a246.top-categories-title", "Top categories")}" .bg=${this.bg}>
      <div class="item-list">
        ${this.list.map(
      (item) => html`
            <fix-link class="item" href="${item.url}">
              <div class="item-icon">
                <img src="${item.logoUrl}" alt="${item.name}" />
              </div>
              <div class="item-text">${item.name}</div>
            </fix-link>
          `,
    )}
      </div>
    </page-section-layout>`;
  }

  fetchTopCategories = async () => {
    const response = await this.mainContext?.api.client.query({
      query: TopCategoriesDocument,
      variables: {},
    });

    // @ts-ignore
    this.categories = response.data.TopCategories.map((item) => item.category);

    this.requestUpdate();
  };

  private capitalizeFirstLetter(input: string): string {
    if (input.length === 0) return input;
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  get list() {
    const assetsHost = import.meta.env.PUBLIC_B2C_ASSETS_HOST;

    const categories = this.categories.map((item) => {
      const assetLogo = item.assets.find((asset) => asset.type === "image-logo");
      const key = "name" + this.capitalizeFirstLetter(i18next.language);
      // @ts-ignore
      const name = item.translation.hasOwnProperty(key) ? item.translation[key] : item.translation.nameEn;
      const slugs = this.services.categories.getItemPath(item.id);

      return {
        id: item.id,
        name: name,
        url: Url.to("dynamic-route", { dynamicPath: slugs }),
        hasLogo: !!assetLogo,
        logoUrl: assetsHost + "/" + assetLogo?.path,
      };
    });

    return categories.filter((item) => item.hasLogo);
  }

  static styles = css`
    .item-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-paddingXS, 8px);
    }

    ${type("sm")} .item-list {
      grid-template-columns: 1fr 1fr;
    }

    .item {
      display: flex;
      align-items: center;
    }

    .item-icon {
      min-width: 80px;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-icon img {
      max-width: 80px;
      max-height: 80px;
    }

    ${type("sm")} .item-icon {
      min-width: 48px;
      min-height: 48px;
    }

    ${type("sm")} .item-icon img {
      max-width: 48px;
      max-height: 48px;
    }

    .item-text {
      ${font("Base/Normal")};
      color: var(--Components-Typography-Global-colorText, #27272a);
      word-break: break-word;
      margin: 0 8px 0 var(--space-margin-margin, 16px);
    }

    ${type("sm")} .item-text {
      width: 100%;
    }

    .item:hover .item-text {
      color: var(--brand-link-color-link, #3b82f6);
      text-decoration: underline;
    }

    .item:active .item-text {
      color: var(--brand-link-color-link-active, #2563eb);
    }
  `;
}
