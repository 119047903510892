import { Cart, cartContext } from "@/context/cart";
import { Url } from "@/helpers/url";
import { FormatterServiceContext, Formatter } from "@/services/formatter";
import { consume } from "@lit/context";
import { Router } from "@vaadin/router";
import { html, LitElement, nothing } from "lit";
import { customElement } from "lit/decorators.js";

class CartBaseElement extends LitElement {
  @consume({ context: cartContext, subscribe: true })
  public cart!: Cart;

  @consume({ context: FormatterServiceContext, subscribe: true })
  formatter!: Formatter;

  get count() {
    return this.cart?.count ?? 0;
  }

  get empty() {
    return this.count < 1;
  }

  get total() {
    const amount = this.cart?.subtotal?.amount;
    return amount ? (amount as number) : 0;
  }

  getFotmattedTotal() {
    return this.formatter.price(this.total, "full", false);
  }

  redirectToCart = () => {
    Router.go(Url.to("cart-page"));
  };
}

@customElement("cart-control-dropdown")
export class CartControlDropdown extends CartBaseElement {
  render() {
    return html`
      <fix-dropdown-head .icon=${"custom"} .label=${this.getFotmattedTotal()} @click=${this.redirectToCart}>
        <fix-icon-indicator slot="custom" .count=${this.count}></fix-icon-indicator>
      </fix-dropdown-head>
    `;
  }

  renderDropdown() {
    return nothing;
  }
}

@customElement("cart-control-button")
export class CartControlButton extends CartBaseElement {
  render() {
    return html`
      <fix-dropdown-head .icon=${"custom"} .label=${this.getFotmattedTotal()} @click=${this.redirectToCart}>
        <fix-icon-indicator slot="custom" .count=${this.count}></fix-icon-indicator>
      </fix-dropdown-head>
    `;
  }
}
