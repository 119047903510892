import { customElement, property } from "lit/decorators.js";
import { css, html } from "lit";
import { FilterFormElement } from "@/elements/filter-form/element.js";
import { ProductAreaStore } from "./product-area-store";
import { when } from "lit/directives/when.js";
import { FilterForm } from "@/elements/filter-form/form";
import { t } from "i18next";

@customElement("filters-element-main")
export class FiltersElementMain extends FilterFormElement {
  @property()
  filters!: FilterForm;

  render() {
    const filters = this.filters;
    const hasBrands = filters.getOptions("brands").length > 0;
    const hasCategories = filters.getOptions("categories").length > 0;
    const hasBoth = hasBrands && hasCategories;

    return html`
      <div class="container">
        ${this.renderPriceRange()} 
        ${when(this.type === "sm", () => this.renderGap("x05"))} 
        ${this.renderGap("x2line")} 
        ${when(hasBrands, () => this.renderBrandCheckboxes())}
        ${when(hasBoth, () => {
          return this.renderGap(this.type === "sm" ? "x2line" : "x1");
        })} 
        ${when(hasCategories, () => this.renderCategoryCheckboxes())}
        ${this.renderGap("x1")}
        ${when(this.type === "lg", () => {
          return this.renderClear(() => filters.cleared());
        })}
      </div>
    `;
  }

  renderBrandCheckboxes() {
    const filters = this.filters;

    return this.renderCheckboxes({
      name: t("v0vi8228d2e6gbge.brand", "Brand"),
      search: true,
      onChange: (key) => filters.toggleOption(key),
      items: filters.getOptions("brands", true),
      onSearch: (text) => filters.setSearch("brands", text),
      searchText: filters.getSearch("brands"),
    });
  }

  renderCategoryCheckboxes() {
    const filters = this.filters;

    return this.renderCheckboxes({
      name: t("83ao1kah0uewlr1m.categories", "Categories"),
      search: true,
      onChange: (key) => filters.toggleOption(key),
      items: filters.getOptions("categories", true),
      onSearch: (text) => filters.setSearch("categories", text),
      searchText: filters.getSearch("categories"),
    });
  }

  renderPriceRange() {
    const filters = this.filters;

    return this.renderRange({
      type: "money",
      name: t("r8yxt597wde4kw5n.price", "Price"),
      slider: true,
      value: filters.getRange("price"),
      onChange: (value: { from: null | number; to: null | number }) => filters.selectRange("price", value),
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.filters.setHost(this);
  }
}
