import { createContext } from "@lit/context";
import { Api } from "@/api";
import { ContextProvider } from "@lit/context";
import { LitElement } from "lit";

export class Main {
  api: Api;

  private provider;

  constructor(host: LitElement, api: Api) {
    this.api = api;
    this.provider = new ContextProvider(host, { context: mainContext });
    this.update();
  }

  private update = () => {
    this.provider.setValue(this.value);
  };

  private get value() {
    return {
      ...this,
    };
  }
}

export const mainContext = createContext<Main>("main");

export type MainContextType = typeof mainContext;
