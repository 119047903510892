import { GraphQLErrors } from "@apollo/client/errors";

type FormError = {
  [key: string]: string;
};

export const prepareFormErrors = (errors: GraphQLErrors): FormError => {
  const errorsObject: FormError = {};

  errors.map((error) => {
    // @ts-ignore
    const fieldKey = error?.key;
    errorsObject[fieldKey] = error.message;
  });

  return errorsObject;
};
