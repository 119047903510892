import {css, html, LitElement, unsafeCSS} from "lit";
import { customElement, property } from "lit/decorators.js";
import type { ProductPage } from ".";
import { font } from "@/util/fonts";
import doneSvg from "@/assets/done.svg";
import backSvg from "@/assets/back.svg";
import { card, cardDefault, columnsMainSidebar, layoutOffsetMargin, layoutOffsetPadding } from "./utils";
import { when } from "lit/directives/when.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { features} from "@/constants/features";

const priceCartGroupCss = css`
  .price-cart-group .discount {
    color: var(--color-text-secondary, #737373);
    ${font("Base/Delete")};
  }

  .price-cart-group .price {
    color: var(--color-text-heading, #111827);
    ${font("Heading/1")};
  }

  .price-cart-group .quantity-tip,
  .price-cart-group .var-tip {
    color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
    ${font("SM/Normal")};
    white-space: nowrap;
  }

  .price-cart-group .row-middle {
    display: flex;
    gap: var(--Space-Margin-margin, 16px);
  }

  .price-cart-group .row-middle .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .price-cart-group .row-middle .right:has(vat-notify[visible]) {
    justify-content: flex-end;
  }

  .price-cart-group .row-bottom {
    display: flex;
    gap: 12px;
    align-items: center;
    padding-top: var(--Space-Padding-paddingSM, 12px);
    padding-bottom: var(--Space-Padding-padding, 16px);
  }

  .price-cart-group .row-bottom > *:nth-child(1) {
    flex-basis: 50%;
    flex-grow: 1;
  }

  .price-cart-group .row-bottom > *:nth-child(2) {
    flex-basis: 25%;
  }

  .price-cart-group .row-bottom > *:nth-child(3) {
    flex-basis: 25%;
  }
`;

const topGroupCss = css`
  .top-group {
    padding: var(--Space-Padding-paddingLG, 24px) var(--Space-Margin-marginXXL, 48px);
    display: flex;
    flex-direction: column;
    gap: var(--Space-Padding-padding, 16px);
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .top-group .row.navigation {
    display: flex;
    align-items: center;
    gap: var(--Space-Margin-marginXXL, 48px);
  }

  .top-group .back {
    padding: 0;
    --sl-spacing-medium: 0;
    --sl-input-height-medium: 0;
    --sl-color-primary-600: var(--Components-Typography-Global-colorText, #27272a);
    ${font("SM/Normal")};
  }

  .top-group .back sl-icon {
    font-size: 16px;
  }

  .top-group .row.title {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .top-group .row.title .title {
    color: var(--color-text-heading, #111827);
    ${font("Heading/2")};
  }

  .fits {
    display: flex;
    align-items: center;
  }

  .fits .message {
    ${font("Base/Normal")};
  }

  .fits .message {
    ${font("Base/Normal")};
  }

  .fits .model {
    ${font("Base/Strong")};
  }

  .fits .icon + * {
    margin-left: 2px;
  }

  .fits .message + * {
    margin-left: 4px;
  }

  .fits .model + * {
    margin-left: 4px;
  }

  .fits sl-button::part(base) {
    --sl-spacing-medium: 4px;
    ${font("Base/Normal")};
  }
`;

@customElement("product-page-desktop")
@localized()
export class ProductPageDesktop extends LitElement {
  @property({ type: Object })
  base: ProductPage = null!;

  render() {
    const base = this.base;

    const aboutContent = html`
      <div class="content-segment-lg">
        <div class="title">${t("afp6t38xk75sr191.about", "About")}</div>
        ${base.renderAbout("lg")}
      </div>
      ${when(
        base.hasBlock("situable-for"),
        () => html`
          <div class="content-segment-lg">
            <div class="title">${t("4d431o9n9ol327o8.suitable-summary", "Suitable for")}</div>
            <div>${base.renderSituable("lg")}</div>
          </div>
        `,
      )}
    `;

    return html`
      <catalog-layout type="lg">
        ${this.renderTopSegment()}
        ${when(
          !base.hasBlock("lg.presentation"),
          () => html`
            <div class="primary-group">
              <div class="product-group mainarea">${aboutContent}</div>
              <div class="controls-group sidearea">${this.renderProductPricesSegment()}</div>
            </div>
          `,
        )}
        ${when(
          base.hasBlock("lg.presentation"),
          () => html`
            <div class="primary-group">
              <div class="product-group mainarea">${this.renderProductPresentationSegment()}</div>
              <div class="controls-group sidearea">${this.renderProductPricesSegment()}</div>
            </div>
            <div class="secondary-group">
              <div class="details-group mainarea">${aboutContent}</div>
              ${when(
                  features.product_related,
                  () => html`
                    <div class="related-group sidearea content-segment-lg">
                      <div class="title">${t("j10il6245v20kshl.related-summary", "Related products")}</div>
                      ${base.renderRelated("lg")}
                    </div>
              `)}

            </div>
          `,
        )}
        <div class="benefits-group">${base.renderBenefits("lg")}</div>
      </catalog-layout>
    `;
  }

  renderTopSegment() {
    const base = this.base;

    return html`
      <div class="top-group">
        <div class="row navigation">
          <sl-button class="back" variant="text">
            <sl-icon class="icon" slot="prefix" src=${backSvg}></sl-icon>
            ${t("jw3z85jqo8c7jl6e.back-to-results", "Back to results")}</sl-button
          >
          <fix-breadcrumb></fix-breadcrumb>
        </div>
        <div class="row title">
          <div class="page title">${base.getTitle()}</div>
          ${base.renderMpn()} 
          ${when(
              features.product_rating,
              () =>base.renderRating()
          )}
        </div>
        ${when(
            features.fits_for_car,
            () => html`
              <div class="fits">
                <sl-icon class="icon" src=${doneSvg}></sl-icon>
                <div class="message">This product fits your</div>
                <div class="model">Ford Focus 2015 EcoBoost 2.0 Titanium</div>
                <sl-button class="change" variant="text">${t("5ie0iw1fcx65ftb6.change", "Change")}</sl-button>
              </div>
        `,
        )}

      </div>
    `;
  }

  renderProductPresentationSegment() {
    const base = this.base;
    const carousel = base.hasBlock("carousel");
    const specifications = base.hasBlock("specifications");

    if (carousel && specifications) {
      return html`
        <div class="left">${base.renderCarousel("lg")}</div>
        <div class="right">${base.renderSpecifiactions("lg")}</div>
      `;
    }

    return html` <div class="center">${when(carousel, () => base.renderCarousel("lg"))} ${when(specifications, () => base.renderSpecifiactions("lg"))}</div> `;
  }

  renderProductPricesSegment() {
    const base = this.base;
    const discount = base.hasBlock("discount");
    const stock = base.hasBlock("stock");
    const quantityInput = base.hasBlock("quantity-input");
    const quantityTip = base.hasBlock("quantity-tip");
    const shipping = base.hasBlock("shipping-notify");
    const canBe = base.hasBlock("can-be");

    return html`
      ${when(
          features.product_variations,
          () => html`<div class="variations">${base.renderVariations()}</div>`,
      )}
      
      <div class="dispatch">${base.renderDispatch()}</div>
      <div class="price-cart-group">
        <div class="row-top">${when(discount && features.discount, () => html` ${base.renderDiscount()} ${base.renderBadge()} `)}</div>
        <div class="row-middle">
          <div class="left">${base.renderPrice()}</div>
          <div class="right">${base.renderVat()} ${when(stock, () => base.renderStatus("lg"))}</div>
        </div>
        <div class="row-bottom">${base.renderAdd()} ${when(quantityInput, () => base.renderQuantity())} ${when(quantityTip, () => base.renderTip())}</div>
      </div>
      ${when(shipping, () => base.renderShipping())}
      ${when(
        canBe,
        () => html`
          <div class="delimiter"></div>
          ${base.renderCanBe("lg")}
        `,
      )}
    `;
  }

  get hasProductPresentation() {
    return this.base.hasBlock("carousel") || this.base.hasBlock("specifications");
  }

  static styles = css`
    ${priceCartGroupCss}
    ${topGroupCss}

    .top-group {
      ${layoutOffsetPadding()};
      ${card({ background: "var(--Neutral-colorWhite, #fff)" })};
    }

    .benefits-group {
      ${layoutOffsetMargin()};
    }

    .primary-group {
      ${layoutOffsetMargin()};
      ${columnsMainSidebar({ mode: "separate" })};
      gap: 8px;
    }

    .secondary-group {
      ${layoutOffsetMargin()};
      ${cardDefault()};
      ${columnsMainSidebar({ mode: "solid" })};
      ${when(!features.product_related,
          () => css`
          @media (max-width: 1250px) {
            grid-template-columns: none;
          }`,
          () => css``
      )}
      padding: 32px;
    }

    .mainarea {
      grid-area: main;
    }

    .sidearea {
      grid-area: sidebar;
    }

    .mainarea {
      flex-basis: auto;
    }

    .primary-group {
      margin-bottom: 8px;
    }

    .secondary-group {
      margin-bottom: 8px;
    }

    .benefits-group {
      margin-bottom: 32px;
    }

    .details-group {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .related-group {
    }

    .product-group {
      ${cardDefault()};
      flex-basis: 65%;
    }

    .controls-group {
      ${cardDefault()};
      display: flex;
      flex-direction: column;
    }

    .product-group {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .product-group:has(.carousel) {
      --gap: 24px;
      --size-no-gap: calc(100% - var(--gap));
      padding-left: 32px;
      padding-right: 24px;
      gap: var(--gap);
      display: grid;
      grid-template-columns: calc(var(--size-no-gap) * 0.46) calc(var(--size-no-gap) * 0.54);
    }

    .product-group:has(.center) {
      --gap: 24px;
      --size-no-gap: calc(100% - var(--gap));
      padding-left: 32px;
      padding-right: 24px;
      gap: var(--gap);
      display: grid;
      grid-template-columns: calc(var(--size-no-gap) * 0.54);
    }

    .product-group:not(:has(.carousel)) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .product-group .left {
      flex-basis: 45%;
      height: 100%;
    }

    .product-group .right {
      flex-basis: 55%;
      height: 100%;
    }

    .controls-group {
      padding: var(--Space-Padding-paddingLG, 24px) var(--Space-Padding-paddingXL, 32px);
    }

    .row.title {
      justify-content: space-between;
      align-items: center;
    }

    .delimiter {
      margin-top: 24px;
      margin-bottom: 24px;
      background: var(--Neutral-Border-colorBorderSecondary, #f0f0f0);
      height: 1px;
    }

    .controls-group .variations {
      margin-bottom: 24px;
    }

    .controls-group .dispatch {
      margin-bottom: 32px;
    }

    .content-segment-lg > .title {
      color: var(--color-text-heading, #111827);
      ${font("Heading/4")};
      margin-bottom: 16px;
    }

    .content-segment-sm > .title {
      ${font("SM/Regular")};
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      margin-bottom: 2px;
    }

    .content-segment-md > .title {
      ${font("Heading/5")};
      color: var(--Components-Typography-Global-colorTextHeading, #1f2937);
      margin-bottom: 12px;
    }
  `;
}
