import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import "@/elements/mobile/fix-group";

@customElement("fix-options")
export class FixOptions<T extends { id: string }> extends LitElement {
  @property({ type: String, reflect: true })
  direction = "vertical" as "vertical" | "horizontal";

  @property({ type: Array })
  options = [] as Array<T>;

  @property({ type: Object })
  renderer = (() => null) as any;

  @property({ type: String })
  active = null as null | string;

  render() {
    const { options, active } = this;

    if (!options) return html``;

    return html`
      ${options.map(
        (item) => html`
          <button
            class=${classMap({
              ["option"]: true,
              ["active"]: item.id === active,
            })}
            @click=${() => this.updateActive(item.id)}
          >
            ${this.renderer(item)}
          </button>
        `,
      )}
    `;
  }

  updateActive = (id: string) => {
    this.dispatchEvent(new CustomEvent("updateActive", { detail: { id }, bubbles: true, composed: true }));
  };

  static styles = css`
    :host {
      display: flex;
      gap: var(--Space-Margin-marginXXS, 4px);
      flex-direction: column;
    }

    :host([direction="vertical"]) {
      flex-direction: column;
    }

    :host([direction="horizontal"]) {
      flex-direction: row;
    }

    :host([direction="horizontal"]) .option {
      flex-grow: 1;
      justify-content: center;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: var(--Size-Height-controlHeight, 40px);
      padding: var(--spacing-paddingXS, 8px) var(--Space-Padding-padding, 16px);
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--Neutral-Border-colorBorder, #d9d9d9);
      background: white;
      cursor: pointer;
      position: relative;
    }

    .option.active {
      border: 1px solid var(--Category-Card-colorActiveBorder, #2563eb);
    }

    .option > * {
      color: var(--Components-Typography-Global-colorText, #27272a);
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .option .center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .option .bold {
      font-weight: 600;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-options": FixOptions<{ id: string }>;
  }
}
