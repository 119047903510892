import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import "@/elements/mobile/fix-label";

@customElement("fix-badge")
export class FixBadge extends LitElement {
  render() {
    return html`<sl-badge variant="danger" pill><slot></slot></sl-badge>`;
  }

  static styles = css`
    sl-badge {
      --sl-border-radius-pill: 4px;
      --sl-color-danger-600: var(--colorsLibrary-Red-600, #dc2626);
      --sl-color-neutral-0: var(--colorsLibrary-Base-White, #fff);
      font-family: "SF Pro Display";
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-badge": FixBadge;
  }
}
