import { customElement, property, query } from "lit/decorators.js";
import { css, html, LitElement, PropertyValues } from "lit";
import "./fix-dropdown-option.ts";
import { classMap } from "lit/directives/class-map.js";
import { state } from "lit/decorators.js";
import { FixDropdownOptionItem } from "@/elements/fix-dropdown/fix-dropdown-option.ts";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { OverlayScrollbars, ClickScrollPlugin } from "overlayscrollbars";

OverlayScrollbars.plugin([ClickScrollPlugin]);

@customElement("fix-dropdown")
@localized()
export class FixDropdown extends LitElement {
  @property({ type: Array<FixDropdownOptionItem> })
  items!: Array<FixDropdownOptionItem>;

  @property({ type: Function })
  onSelect = (value: string, index: number) => {
    return true;
  };

  @property({ type: Boolean })
  isSearchable = true;

  @property({ type: Function })
  onSearch = (value: string) => {
    this.searchTerm = value.toLowerCase();
  };

  @state()
  isOpen = false;

  @state()
  selected: FixDropdownOptionItem | undefined;

  @state()
  searchTerm = "";

  @query(".options")
  scrollable!: HTMLElement;

  @property()
  controlled = false

  constructor() {
    super();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    OverlayScrollbars(this.scrollable, {
      //@ts-expect-error
      plugins: {
        clickScroll: { enable: true },
      },
    });
  }

  handleOutsideClick(event: any) {
    const path = event.composedPath();
    if (!path.includes(this.shadowRoot)) {
      this.requestUpdate();
      this.onClose();
    }
  }

  get containerClasses() {
    return { "fix-dropdown-container": true, opened: this.isOpen };
  }

  get selectedItem() {
    if (this.controlled) {
      this.items.find((item) => item.selected);
    }

    return this.selected ?? this.items.find((item) => item.selected);
  }

  get options() {
    return this.searchTerm
      ? this.items.filter((item) => item.label.toLowerCase().includes(this.searchTerm) || item.value.toString().includes(this.searchTerm))
      : this.items;
  }

  onOpen() {
    this.isOpen = true;
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  onClose() {
    this.isOpen = false;
    super.disconnectedCallback();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  onLabelClick = () => {
    !this.isOpen ? this.onOpen() : this.onClose();
  };

  onOptionClick = (value: any, index: number) => {
    if (!this.controlled) {
      this.selected = this.options[index];
    }

    this.onClose();
    this.onSelect(value, index);
  };

  render() {
    const items = this.options.map(
      (item, index) =>
        html`<fix-dropdown-option .selected=${item.value === this.selectedItem?.value} .value="${item.value}" .label="${item.label}" .icon="${item.icon}" @click=${() => this.onOptionClick(item.value, index)}></sl-option>`,
    );

    const search = this.isSearchable
      ? html`
          <div class="fix-dropdown-search">
            <sl-input @input=${(event: any) => this.onSearch(event.target?.value)} placeholder="${t("0x619y4k02smw9cs.search", "Search")}">
              <sl-icon name="search" slot="prefix"></sl-icon>
            </sl-input>
          </div>
        `
      : "";

    return html` <div class=${classMap(this.containerClasses)}>
      <div class="fix-dropdown-label" tabindex="0" @click=${this.onLabelClick}>
        ${this.selectedItem?.icon} <span>${this.selectedItem?.label ?? this.selectedItem?.value}</span>
        <sl-icon class="fix-dropdown-btn-icon" name="chevron-down"></sl-icon>
      </div>
      <div class="fix-dropdown-options-container">
        ${search}
        <div class="options">${items}</div>
      </div>
    </div>`;
  }

  static styles = css`
    .fix-dropdown-container {
      font-family: "SF Pro Text";
      font-weight: 400;
      font-size: 14px;
      position: relative;
    }
    .options {
      max-height: 500px;
      overflow: auto;
    }
    .fix-dropdown-search {
      padding: 16px 16px 5px;
    }
    .fix-dropdown-label {
      cursor: pointer;
      padding: 5px 10px;
      display: flex;
      align-items: center;
    }
    .fix-dropdown-label span {
      margin-left: 4px;
    }
    .fix-dropdown-label:hover {
      color: var(--car-brands-color-border-hover);
    }
    .fix-dropdown-options-container {
      position: absolute;
      min-width: 200px;
      background: #fff;
      z-index: 9;
      box-shadow: 0 4px 12px 0 #00000026;
      border-radius: 6px;
      overflow: hidden;
      display: none;
    }

    .fix-dropdown-container.opened .fix-dropdown-options-container {
      display: block;
    }

    .fix-dropdown-btn-icon {
      transition: 0.2s;
      font-size: 10px;
      margin-left: 5px;
    }

    .fix-dropdown-container.opened .fix-dropdown-btn-icon {
      transform: rotate(180deg);
    }
  `;
}
