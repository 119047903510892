import { customElement, property } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import "@/generated/css/variables.css";
import { Url } from "@/helpers/url";
import { localized } from "@/decorators/localized";
import { default as i18next } from "i18next";
import category from "@/assets/category.svg";
import { repeat } from "lit/directives/repeat.js";
import { MultiTypeElement, whenType } from "@/util/element";

type Category = {
  id: string;
  key: string;
  name: string;
  image: string | null;
  url: string;
};

@customElement("categories-preview-custom")
@localized()
export class CategoriesPreviewCustom extends MultiTypeElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property()
  categories: Category[] = [];

  @property()
  linkOnly = false;

  render() {
    return html` <div class="item-list">${this.renderItems()}</div> `;
  }

  renderItems() {
    return repeat(
      this.categories,
      (item) => item.id,
      (item, index) => html`
        <a class="item" href=${item.url} @click=${(event: MouseEvent) => this.onTabClick(index, event)} draggable="false">
          <div class="item-inner">
            <div class="item-icon">
              <img src=${item.image ?? category} alt=${item.name} />
            </div>
            <div class="item-text">${item.name}</div>
          </div>
        </a>
      `,
    );
  }

  onTabClick(index: number, event: MouseEvent) {
    // if (this.linkOnly) {
    //   return;
    // }

    // event.preventDefault();
    // this.dispatchEvent(
    //   new CustomEvent("category-click", {
    //     detail: {
    //       key: this.categories[index].key,
    //     },
    //   }),
    // );
  }

  private capitalizeFirstLetter(input: string): string {
    if (input.length === 0) return input;
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  get list() {
    const key = "name" + this.capitalizeFirstLetter(i18next.language);

    const categories = (this.categories ?? []).map((item) => {
      // @ts-ignore
      // const name = item.translation.hasOwnProperty(key) ? item.translation[key] : item.translation.nameEn;
      return {
        id: item.id,
        name: item.name,
        url: item.url,
        logoUrl: item.image ?? category,
      };
    });

    return categories;
  }

  static stylesCommon = css`
    .item-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: var(--spacing-paddingXS, 8px);
    }

    .item {
      background: var(--category-card-color-bg-container-filled, #f4f4f5);
      border: 1px solid transparent;
      border-radius: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: all 50ms;
      min-height: 120px;
      min-width: 220px;
      flex-direction: column;
    }

    .item:hover {
      background: var(--category-card-color-bg-container, #ffffff);
      border: 1px solid var(--Category-Card-colorHoverBorder, #60a5fa);
    }

    .item:active {
      border: 1px solid var(--Category-Card-colorActiveBorder, #2563eb);
      background: var(--Category-Card-colorBgContainerFilled, #f4f4f5);
    }

    .item-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      padding-bottom: 16px;
      padding-top: 16px;
      padding-left: 24px;
      padding-right: 24px;
      flex-grow: 1;
      box-sizing: border-box;
      min-width: 100%;
    }

    .item-icon {
      width: 56px;
      height: 56px;
      pointer-events: none;
      user-select: none;
    }

    .item-icon img {
      max-width: 100%;
      max-height: 100%;
    }

    .item-text {
      ${font("LG/Normal")};
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  static stylesSm = css`
    .item-list {
      grid-template-columns: repeat(3, 1fr);
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    .item {
      min-height: 120px;
      min-width: 112px;
      border-radius: 6px;
      flex-basis: calc((100% - 4px * 2) / 3);
      flex-grow: 1;
      box-sizing: border-box;
    }

    .item-inner {
      min-width: 100%;
      min-height: 102px;
      padding-bottom: 8px;
      padding-top: 8px;
      padding-left: 8px;
      padding-right: 8px;
      gap: 0px;
    }

    .item-icon {
      width: 56px;
      height: 56px;
      margin-bottom: 0;
    }

    .item-text {
      ${font("Base/Normal")};
      width: 100%;
    }
  `;

  static stylesLg = css``;

  static styles = [CategoriesPreviewCustom.stylesCommon, whenType("sm", CategoriesPreviewCustom.stylesSm), whenType("lg", CategoriesPreviewCustom.stylesLg)];
}
