import { customElement, property, state } from "lit/decorators.js";
import { html, LitElement } from "lit";
import { LayoutParameters, LayoutParametersContext } from "@/pages/product-page/cont.ts";
import { consume } from "@lit/context";
import "./fix-dropdown/fix-dropdown";
import { CurrenciesDocument, Currency } from "@/generated/graphql/generic.ts";
import { CurrencyConverter } from "@/util/currency-converter.ts";
import { when } from "lit/directives/when.js";
import "@/elements/mobile/dialog";
import { Parameters, ParametersContext } from "@/services/parameters";
import { ServiceLocatorContext, Services } from "@/services/services";

@customElement("fix-currency")
export class FixCurrency extends LitElement {
  @consume({ context: ParametersContext, subscribe: true })
  public parameters!: Parameters;

  @consume({ context: LayoutParametersContext, subscribe: true })
  layoutParameters!: LayoutParameters;

  @state()
  dialogOpen = false;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  get options() {
    const value = this.parameters.getVisibleCurrency();

    return this.services.currency.currencies.map((item) => ({
      label: this.currencyLabel(item.code),
      value: item.code,
      selected: item.code === value,
    }));
  }

  get currentCurrency() {
    return this.currencyLabel(this.parameters.getVisibleCurrency());
  }

  currencyLabel(code: string | null | undefined) {
    return code ? `${code} ${CurrencyConverter.getCurrencySymbol(code)}` : "";
  }

  onCurrencyChange = (currency: string) => {
    this.parameters.changeParameter("currency", currency);
    this.parameters.applyParameters();
  };

  get defaultLayout() {
    return html` <fix-dropdown .items=${this.options} .onSelect=${this.onCurrencyChange} /></fix-dropdown>`;
  }

  get mobileLayout() {
    return html`
      <item-button .title=${"Currency"} .sub=${this.currentCurrency} .schema=${"line"} @click=${() => (this.dialogOpen = true)}></item-button>
      ${when(
      this.dialogOpen,
      () => html`
          <dialog-mobile .label=${"Currency"} @dialogClose=${() => (this.dialogOpen = false)}>
            <fix-dialog-selector
              .items=${this.options ?? []}
              .onSelect=${(value: string) => {
          this.onCurrencyChange(value);
          this.dialogOpen = false;
        }}
            >
            </fix-dialog-selector>
          </dialog-mobile>
        `,
    )}
    `;
  }

  render() {
    return this.layoutParameters.mode === "mobile" ? this.mobileLayout : this.defaultLayout;
  }
}
