import { font } from "@/util/fonts";
import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { createContext, provide } from "@lit/context";
import { renderFragment } from "./util";
import "@/elements/fix-back-button";
import close from "@/assets/close.svg";

const context = createContext<LayoutSmContainer>("layout-sm");

export const layoutSmCss = css`
  *[lightelement] {
    display: contents;
  }

  fragment-element {
    display: contents;
  }

  .delimiter {
    height: 8px;
    position: relative;
  }

  .delimiter::after {
    content: "";
    display: block;
    clear: both;
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    background: var(--Side-menu-border, #d9d9d9);
  }

  .segment .title {
    color: var(--Components-Typography-Global-colorTextHeading, #1f2937);
    ${font("Heading/4")};
  }

  .head .title {
    color: var(--Components-Typography-Global-colorTextHeading, #1f2937);
    ${font("Heading/5")};
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fadeIn {
    animation: slide-in 0.5s;
  }

  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const layoutLgCss = css`
  *[lightelement] {
    display: contents;
  }

  fragment-element {
    display: contents;
  }

  .delimiter {
    height: 40px;
    position: relative;
  }

  .delimiter::after {
    content: "";
    display: block;
    clear: both;
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    background: var(--Side-menu-border, #d9d9d9);
  }

  .segment .title {
    color: var(--Components-Typography-Global-colorTextHeading, #1f2937);
    ${font("Heading/4")};
  }

  .segment .title + * {
    margin-top: 8px;
  }

  .head {
    display: flex;
    gap: 14px;
    align-items: center;
    margin-bottom: var(--Space-Padding-paddingMD, 20px);
  }

  .close {
    --sl-spacing-medium: 0;
  }

  .close::part(base) {
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #6b7280;
  }
`;

@customElement("layout-sm-container")
export class LayoutSmContainer extends LitElement {
  @provide({ context: context })
  layoutSm = this;

  @property()
  content!: unknown;

  render() {
    return html` <div class="fadeIn">${this.content}</div> `;
  }

  renderSection(params: { segment: string; title?: string; content: () => unknown }) {
    const className = classMap({
      segment: true,
      [params.segment]: true,
    });

    return html`
      <div class=${className}>
        ${when(params.title, () => html`<div class="title">${params.title}</div>`)}
        <div class="content">${params.content()}</div>
      </div>
    `;
  }

  renderHeadMain() {
    return html`
      <div class="head main">
        <fix-brand></fix-brand>
      </div>
    `;
  }

  renderHeadStack(params: { title: string; onBack: () => void }) {
    return html`
      <div class="head stack">
        <fix-back-button @click=${params.onBack}></fix-back-button>
        <div class="title">${params.title}</div>
      </div>
    `;
  }

  renderHeadLg(params: { onClose: () => void }) {
    return html`
      <div class="head lg">
        <fix-menu-close-button @click=${params.onClose}></fix-menu-close-button>
        <fix-brand></fix-brand>
      </div>
    `;
  }

  renderDelimiter() {
    return html`<div class="delimiter"></div>`;
  }

  protected createRenderRoot() {
    return this;
  }
}

export const layoutFragment = function (render: (context: LayoutSmContainer) => unknown) {
  return renderFragment(context as any, render);
};
