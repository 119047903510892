import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { consume } from "@lit/context";
import { type Cart, cartContext } from "@/context/cart";
import { Main, mainContext } from "@/context/main";
import { layout } from "../product-page/cont";
import { VariantSwitcher } from "../product-page/switcher";

import "./cart-page-desktop";
import "./cart-page-mobile";

import "@/elements/mobile/fix-loader";
import "@/elements/mobile/cart/item";
import "@/elements/mobile/store-header";
import "@/generated/css/variables.css";

@customElement("cart-page")
@layout()
export class CartPage extends LitElement {
  layout!: string;

  switcher: VariantSwitcher<CartPage> = new VariantSwitcher(this);

  @consume({ context: cartContext, subscribe: true })
  @property({ attribute: true })
  public cart!: Cart;

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  connectedCallback() {
    super.connectedCallback();
    if(this.cart) {
      this.cart.fetchCart();
    }

  }

  render() {
    return choose(this.layout, [
      ["desktop", () => html`<cart-page-desktop></cart-page-desktop>`],
      ["mobile", () => html`<cart-page-mobile></cart-page-mobile>`],
    ]);
  }

  get loader() {
    return html`<fix-loader></fix-loader>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "cart-page": CartPage;
  }
}
