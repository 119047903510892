import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/elements/mobile/fix-group";
import "@/elements/mobile/fix-options";
import { t } from "i18next";
import { font } from "@/util/fonts.ts";

export type ProductDispatchItem = {
  from: number;
  to: number;
  name: string;
  index: string;
};

@customElement("product-dispatch")
export class ProductDispatch extends LitElement {
  @property({ type: Array })
  dispatch = [] as Array<ProductDispatchItem>;

  @property({ type: String })
  active = null as null | string;

  render() {
    const { dispatch, active } = this;
    if(dispatch.length > 1) {
    return html`
      <fix-group .text=${t("bs6v90iqxu790208.dispatch-time", "Dispatch time")}>
        <fix-options .options=${dispatch} .active=${this.active} .renderer=${this.renderOption} direction="horizontal"></fix-options>
      </fix-group>
    `;
    } else {
      return html`<div class="dispatch-time">
        <div class="label">${t("bs6v90iqxu790208.dispatch-time", "Dispatch time")}:</div>
        <div class="value">${this.renderOption(dispatch[0])}</div>
      </div>`;
    }
  }

  renderOption(item: ProductDispatchItem) {
    return html`${t("zk32um9soyc445h3.dispatch-time-range", "{{from}}-{{count}} days {{index}}", {
      count: item.to,
      from: item.from,
      index: item.index,
    })}`;
  }

  static styles = css`
    :host {
      ${font("Base/Normal")};
    }
      
    .dispatch-time {
      display: flex;
      align-items: center;
    }
      
    .dispatch-time .label{
      color: var(--color-text-base, #18181b);
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 8px;
    }

    .dispatch-time .value{
      color: var(--Components-Typography-Global-colorText, #27272a);
      ${font("Base/Normal")};
      font-size: 14px;
      font-weight: 600;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "product-dispatch": ProductDispatch;
  }
}
