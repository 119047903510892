import { customElement, property, state } from "lit/decorators.js";
import { css, html, LitElement, PropertyValueMap } from "lit";
import { B2cCartProduct, B2cProductPrice } from "@/generated/graphql/b2c.ts";
import "@/elements/fix-dropdown/fix-dropdown";
import "@/elements/mobile/price-value";
import { classMap } from "lit/directives/class-map.js";
import { t } from "i18next";
import { consume } from "@lit/context";
import { type Cart, cartContext } from "@/context/cart.ts";
import { when } from "lit/directives/when.js";
import "@/elements/mobile/product-specifications";
import { localized } from "@/decorators/localized.ts";
import { ImageResizer } from "@/util/image-resizer.ts";
import { font } from "@/util/fonts.ts";
import trash from "@/assets/trash.svg";
import { createProductUrl } from "@/pages/category-page/product-area-catalog/product-area-element.ts";
import { Categories, CategoriesContext } from "@/services/categories.ts";
import "@/elements/confirm-dialog";

@localized()
export class CartItemState extends LitElement {
  @consume({ context: cartContext, subscribe: true })
  @state({ hasChanged: () => true })
  cart!: Cart;

  @consume({ context: CategoriesContext, subscribe: true })
  @state({ hasChanged: () => true })
  categories!: Categories;

  @property({ type: Object })
  product!: B2cCartProduct;

  @state()
  protected removeConfirmOpen = false;

  @state()
  protected quantity = 0;

  @state()
  protected specificationsOpened = false;

  @state()
  protected pricesOpened = false;

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.quantity = this.product.quantity ?? 0;
  }

  get price() {
    const discount = 0;
    const totalAmount = Number(this.product.price.amount) * this.quantity;
    return {
      amount: totalAmount,
      discount: totalAmount * (discount / 100),
      discountPercent: discount,
      price: this.product.price.amount,
      total: totalAmount - discount,
    };
  }

  get currentPrice() {
    return this.product.product.prices.find((item) => item.id === this.product.priceId);
  }

  deliveryTermsRange(price: B2cProductPrice) {
    return `${t("rfje21tj4m4fj4hv.delivery-terms-range", "{{minTerm}}-{{maxTerm}} days", { minTerm: price.deliveryTermStandardMin, maxTerm: price.deliveryTermStandardMax })}`;
  }

  get specifications() {
    if (!this.product.product) return [];

    return this.product.product.productParameters
      .filter((parameter) => !!parameter.name)
      .map((spec) => ({
        name: spec.name,
        value: spec.value,
        type: spec.type,
      }));
  }

  setQuantity = (event: CustomEvent<{ quantity: number }>) => {
    this.quantity = event.detail.quantity;
    if (this.product.maxOrderQuantity && this.product.quantity > this.product.maxOrderQuantity) {
      this.quantity = this.product?.maxOrderQuantity;
      notifyQuantityExceedsAvailableStock();
    }

    this.cart.updateItem({
      productId: this.product!.product.id,
      cartProductId: this.product!.id,
      quantity: this.quantity,
      priceId: this.product!.priceId,
    });
  };

  confirmRemoveProductRender = () => {
    return when(
      this.removeConfirmOpen,
      () => html`
        <confirm-dialog
          .text=${t("cart.remove-product-confirm", "Do you really want to delete this item from your basket?")}
          @onAccept=${this.removeProduct}
          @onCancel=${() => (this.removeConfirmOpen = false)}
        ></confirm-dialog>
      `,
    );
  };

  removeProductConfirm = () => {
    this.removeConfirmOpen = true;
  };

  removeProduct = () => {
    this.removeConfirmOpen = false;

    this.cart.updateItem({
      productId: this.product!.product.id,
      cartProductId: this.product!.id,
      quantity: 0,
      priceId: this.product!.priceId,
    });
  };

  changePrice(priceId: string) {
    this.cart.updateItem({
      productId: this.product!.product.id,
      cartProductId: this.product!.id,
      quantity: this.quantity,
      priceId: priceId,
    });
  }

  pricesListRender = () => {
    return this.product.product.prices.map((item) => {
      const classNames = classMap({
        "price-btn": true,
        selected: this.currentPrice?.id === item.id,
      });

      return html`<sl-button @click=${() => this.changePrice(item.id)} class=${classNames}>${this.deliveryTermsRange(item)}</sl-button>`;
    });
  };

  get imgUrl() {
    const images = this.product.product.productAssets
      .filter((asset) => asset.type === "image")
      .map((asset) => ({
        ...asset,
        url: ImageResizer.getProductImageUrl(asset.path, "160x160"),
      }));

    if (images?.length) {
      return images[0]?.url;
    }

    return null;
  }
}

@customElement("cart-item")
@localized()
export class CartItem extends CartItemState {
  priceInfoRender = () => {
    const originPrice = this.price.discount
      ? html`
          <div class="origin-price">
            <price-value class="price" .amount=${this.price.amount}></price-value>
          </div>
        `
      : "";
    const salePrice = html`
      <div class="sale-price">
        <price-value class="price" .amount=${this.price.total}></price-value>
      </div>
    `;

    const perUnitPrice = html` <div class="per-unit-price"><price-value class="price" .amount=${this.price.price}></price-value> per unit</div> `;

    return html`${perUnitPrice} ${originPrice} ${salePrice}`;
  };

  dispatchTimeRender = () => {
    if (this.product.product.prices.length > 1) {
      return html`
        <sl-button class="price-selector-btn" variant="text" @click=${() => (this.pricesOpened = !this.pricesOpened)}>
          ${this.deliveryTermsRange(this.currentPrice!)} <sl-icon name="chevron-down"></sl-icon>
        </sl-button>
      `;
    }

    return html`<span class="price-selector-btn">${this.deliveryTermsRange(this.currentPrice!)}</span>`;
  };

  render() {
    return html`
      ${this.confirmRemoveProductRender()}
      <div class="item-content">
        <div class="product-img">
          <img src="${this.imgUrl}" />
        </div>

        <div class="info">
          <div class="product-header">
            <div class="product-info">
              <div class="title">
                <a class="product-link" href="${createProductUrl(this.product.product, this.categories)}">
                  ${this.product?.product.mpnFormatted} ${this.product?.product.brand.name} ${this.product?.product.productInfo.name}
                </a>
              </div>
              <div class="category">${this.product?.product.categories?.[0].name}</div>
              <div class="dispatch">
                <span>${t("bs6v90iqxu790208.dispatch-time", "Dispatch time:")}</span>
                ${this.dispatchTimeRender()}
              </div>
              ${when(this.pricesOpened, () => html`<div class="prices-list">${this.pricesListRender()}</div>`)}
            </div>
            <div class="product-info-right">
              <div class="product-quantity">
                <fix-quantity
                  class="quantity"
                  .quantity=${this.quantity}
                  .minQuantity=${this.product?.minOrderQuantity}
                  .maxQuantity=${this.product?.maxOrderQuantity}
                  .factorQuantity=${this.product?.factorOrderQuantity}
                  @updateQuantity=${this.setQuantity}
                ></fix-quantity>
                <div class="product-moq">MOQ: ${this.product.minOrderQuantity}</div>
              </div>
              <div class="price-info">${this.priceInfoRender()}</div>
            </div>
          </div>
          <div class="product-footer">
            <div class="actions">
              ${when(
                this.specifications.length,
                () => html`
                  <sl-button variant="text" class="more-btn" @click=${() => (this.specificationsOpened = !this.specificationsOpened)}>
                    ${t("eb32219bfq9o6ph1.more-info", "More info")} <sl-icon name="chevron-down"></sl-icon>
                  </sl-button>
                `,
              )}
              <sl-button variant="text" class="remove-btn" @click=${this.removeProductConfirm}>
                <sl-icon src=${trash}></sl-icon> ${t("upe5v030tlt34hdu.delete", "Delete")}
              </sl-button>
            </div>
          </div>
          ${when(this.specificationsOpened, () => html`<product-specifications .options=${this.specifications}></product-specifications>`)}
        </div>
      </div>
    `;
  }

  static styles = css`
    sl-button.more-btn {
      --sl-spacing-medium: 20px 0 0;
      --sl-color-primary-600: #3b82f6;
    }
    .product-quantity {
      width: 200px;
    }
    .product-info-right {
      display: flex;
      justify-content: space-between;
      max-width: 50%;
    }
    .product-link {
      color: #000;
      text-decoration: unset;
    }

    span.price-selector-btn {
      margin-left: 8px;
    }

    .per-unit-price {
      ${font("Base/Normal")}
      color: #737373;
    }

    sl-button::part(label) {
      ${font("Base/Normal")}
      line-height: inherit;
    }

    sl-button.more-btn::part(label),
    sl-button.more-btn::part(label),
    sl-button.remove-btn::part(label) {
      ${font("SM/Normal")};
    }

    sl-button.remove-btn {
      --sl-color-primary-600: #a3a3a3;
    }

    .product-moq {
      ${font("SM/Regular")};
      text-align: center;
      color: #737373;
    }

    .item-content {
      display: flex;
      margin: 10px;
      padding: 24px 48px;
    }

    .product-img {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      margin-right: 24px;
    }

    .product-img img {
      width: 100%;
    }

    .product-info .title {
      ${font("Heading/5")}
    }

    .product-info .category {
      ${font("SM/Normal")}
      color: #a3a3a3;
    }

    .product-info .dispatch {
      display: flex;
      align-items: center;
      height: 22px;
      ${font("Base/Normal")}
    }

    .product-info .actions {
      display: flex;
      align-items: baseline;
      margin-top: 20px;
    }

    .price-info {
      text-align: right;
    }

    .price-info .quantity {
      margin-top: 10px;
      float: right;
    }

    .sale-price {
      white-space: nowrap;
      ${font("Heading/3")}
    }

    .origin-price {
      font-size: 14px;
      color: #a3a3a3;
      text-decoration: line-through;
    }

    .info {
      width: 100%;
    }

    .price-btn {
      margin-right: 10px;
    }

    .price-btn.selected {
      --sl-color-neutral-300: #3b82f6;
    }

    .price-selector-btn {
      --sl-color-primary-600: #000;
    }

    .prices-list {
      display: flex;
    }

    .product-header {
      display: flex;
      justify-content: space-between;
    }

    .product-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 12px;
    }

    .actions {
      height: 20px;
    }

    .actions sl-button {
      --sl-spacing-medium: 10px 0 0;
    }
  `;
}

const notifyQuantityExceedsAvailableStock = () => {
  _notify(
    t("2k1l3hf6f0682ddk.max-quantity-exceeded", "Quantity exceeds available stock"),
    t("65sk1uc48ji347o8.max-quantity-exceeded-description", "Please, add the rest products from a different supplier"),
    "warning",
    "exclamation-triangle",
    5000,
  );
};
const _notify = (message: string, description = "", variant = "primary", icon = "info-circle", duration = 3000) => {
  const alert = Object.assign(document.createElement("sl-alert"), {
    variant,
    closable: true,
    duration,
    innerHTML: `
      <sl-icon name="${icon}" slot="icon"></sl-icon>
      <strong style="display: block">${message}</strong>
      ${description}
    `,
  });

  document.body.append(alert);
  return alert.toast();
};
