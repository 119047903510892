import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import backward from "@/assets/backward.svg";
import forward from "@/assets/forward.svg";
import { when } from "lit/directives/when.js";
import { createRef, Ref, ref } from "lit/directives/ref.js";
import { type } from "@/util/layout";

@customElement("fix-carousel")
export class FixCarousel extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Array })
  items = [] as Array<{ url: string; alt: string }>;

  @state()
  current = 0;

  ref = createRef<{ goToSlide: (index: number) => void }>();

  render() {
    const pagination = this.type === "sm";
    const thumbnails = this.type === "lg";
    const navigation = this.type === "lg";
    const refItem = this.ref as unknown as Ref<Element>;

    return html`
      <div class="main">
        <sl-carousel .pagination=${pagination} .navigation=${navigation} loop ${ref(refItem)} @sl-slide-change=${this.onSlideChange}>
          ${this.items.map(
            (item) => html`
              <sl-carousel-item>
                <img alt="${item.alt}" src=${item.url} />
              </sl-carousel-item>
            `,
          )}
          <sl-icon slot="previous-icon" src=${backward}></sl-icon>
          <sl-icon slot="next-icon" src=${forward}></sl-icon>
        </sl-carousel>
      </div>
      ${when(thumbnails, () => this.renderThumbnails())}
    `;
  }

  renderThumbnails() {
    return html`
      <div class="thumbnails">
        <div class="thumbnails-list">${map(this.items, (item, index) => this.renderThumbnail(item, index))}</div>
      </div>
    `;
  }

  renderThumbnail(item: { url: string; alt: string }, index: number) {
    const className = classMap({
      ["thumbnail"]: true,
      ["active"]: index === this.current,
    });

    return html` <div class=${className} @click=${() => this.onThumbnailClick(index)}>
      <img alt="thumbnail" src=${item.url} />
    </div>`;
  }

  onThumbnailClick(index: number) {
    this.ref.value?.goToSlide(index);
  }

  onSlideChange(event: CustomEvent) {
    this.current = event.detail.index;
  }

  static styles = css`
    ${type("lg")} {
      --nav-offset: 32px;
    }

    sl-carousel {
      --sl-spacing-small: 3px;
      --sl-spacing-medium: 16px;
      --normal-wh: 10px;
      --active-wh: 15px;
      --diff-wh: calc((var(--active-wh) - var(--normal-wh)));
      --aspect-ratio: auto;
    }

    sl-carousel::part(pagination-item) {
      width: var(--normal-wh);
      height: var(--normal-wh);
      background-color: var(--colorsLibrary-Gray-300, #d4d4d8);
      border: 1px solid var(--colorsLibrary-Gray-300, #d4d4d8);
      transform: unset;
      transition: all var(--sl-transition-fast);
    }

    sl-carousel::part(pagination-item--active) {
      width: var(--active-wh);
      height: var(--active-wh);
      margin-top: calc(var(--diff-wh) * -1 / 2);
      background-color: transparent;
    }

    sl-carousel::part(pagination) {
      min-height: 15px;
    }

    sl-carousel::part(base) {
      gap: var(--sl-spacing-medium) 0px;
    }

    sl-carousel-item {
      padding-left: var(--nav-offset, 0px);
      padding-right: var(--nav-offset, 0px);
    }

    sl-carousel-item img {
      max-width: 256px;
      max-height: 256px;
    }

    .thumbnails .thumbnails-list {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
    }

    .thumbnail {
      width: 72px;
      height: 72px;
      border-radius: 6px;
      border: var(--Size-Line-Width-lineWidth, 1px) solid var(--Neutral-Border-colorBorder, #d9d9d9);
      padding: 8px;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .thumbnail.active {
      border: var(--Size-Line-Width-lineWidth, 1px) solid var(--Base-Blue-6, #3b82f6);
    }

    .thumbnail img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    :host {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%;
    }

    .main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    sl-carousel::part(navigation-button) {
      color: #27272a;
      font-size: 24px;
      padding: 39px 7px;
      border-radius: 6px;
      border: 1px solid var(--Neutral-Border-colorBorder, #d9d9d9);
      background-color: transparent;
    }

    sl-carousel::part(navigation-button):hover {
      background-color: var(--Neutral-Bg-colorBgElevated, #f1f5f9);
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-carousel": FixCarousel;
  }
}
