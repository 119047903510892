import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("fix-label")
export class FixLabel extends LitElement {
  @property()
  text = "";

  render() {
    return html`${this.text}`;
  }

  static styles = css`
    :host {
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-label": FixLabel;
  }
}
