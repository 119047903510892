import { Money } from "@/generated/graphql/b2c";

export class CurrencyConverter {
  static convert(eurValue: Money, target: string, rateEntry: number) {
    const centsConverted = this.convertCents(this.getCents(eurValue), rateEntry);

    return this.packToMoney(centsConverted, target);
  }

  static applyVat(value: Money, vatCoefficient: number) {
    const centsWithVat = (this.getCents(value) * vatCoefficient) / 100;

    return this.packToMoney(centsWithVat, value.currency);
  }

  static convertCents(eurCents: number, rateEntry: number = 1) {
    return (eurCents * rateEntry) / 100;
  }

  protected static getCents(money: Money) {
    let cents = 0;

    if ("cents" in money) {
      cents = parseInt(money.cents);
    }

    if ("amount" in money) {
      cents = parseFloat(money.amount) * 100;
    }

    return cents;
  }

  protected static packToMoney = (cents: number, currency: string): Money => {
    return {
      cents: cents.toString(),
      amount: (cents / 100).toString(),
      currency,
    };
  };

  public static getCurrencySymbol(code: string, country = "en-US"): string {
    return Number(0)
      .toLocaleString(country, {
        style: "currency",
        currency: code,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }
}
