import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("fix-info")
export class FixInfo extends LitElement {
  @property({ type: String })
  summary = "";

  render() {
    return html`
      <div class="container">
        <h4 class="summary">${this.summary}</h4>
        <slot></slot>
      </div>
    `;
  }

  static styles = css`
    .container {
      padding: 24px 0 8px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .summary {
      display: block;
      margin-block-start: 0;
      margin-block-end: 0.1em;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    :host {
      font-family: "SF Pro Display";
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-info": FixInfo;
  }
}
