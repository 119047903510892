import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import menuSvg from "@/assets/menu.svg";
import searchSvg from "@/assets/search.svg";
import cartSvg from "@/assets/cartcurrent.svg";
import { fontBaseNormal } from "@/style/font";
import { Router } from "@vaadin/router";
import { consume } from "@lit/context";
import { type Cart, cartContext } from "@/context/cart";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { Url } from "@/helpers/url";
import "@/pages/product-page/extracted/cart-control";

@customElement("page-header")
@localized()
export class PageHeader extends LitElement {
  @property({ type: String }) searchValue = "";

  @consume({ context: cartContext, subscribe: true })
  @property({ attribute: false })
  public cart?: Cart;

  @query(".menu")
  menu!: Element;

  render() {
    return html`
      <sl-icon-button class="menu" src=${menuSvg} @click=${this.menuClick}> </sl-icon-button>
      <sl-input
        class="search"
        name="mpn-search"
        placeholder="${t("kmaqihq7zxarr34z.search-placeholder", "Search for part number.")}"
        size="medium"
        value=${this.searchValue}
        @sl-change=${this.handleSearchChange}
      >
        <sl-icon class="icon" slot="prefix" src=${searchSvg}></sl-icon>
      </sl-input>
      <cart-control-button></cart-control-button>
    `;
  }

  handleSearchChange(event: CustomEvent) {
    const searchKey = (event.target as HTMLInputElement).value;
    Router.go(Url.to("search-page", {}, `?q=${encodeURIComponent(searchKey)}`));
  }

  menuClick = () => {
    this.dispatchEvent(
      new CustomEvent("menuClick", {
        bubbles: true,
        composed: true,
        detail: { target: this.menu },
      }),
    );
  };

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      color: var(--color-text, rgba(0, 0, 0, 0.88));

      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      padding-left: var(--layout-padding-h);
      padding-right: var(--layout-padding-h);
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }

    .search {
      min-width: 0;
      --sl-input-border-radius-medium: 6px;
      --sl-input-border-color: var(--components-input-global-color-border, #d9d9d9);
    }

    .search .icon {
      font-size: 16px;
      color: #27272a;
      padding-left: 10px;
      margin-inline-start: 0;
    }

    .search::part(input) {
      overflow: hidden;
      color: #27272a;
      text-overflow: ellipsis;
      padding-left: 8px;
      padding-right: 10px;
    }

    .search::part(input)::placeholder {
      overflow: hidden;
      color: var(--components-input-global-color-text-placeholder, #d4d4d4);
      text-overflow: ellipsis;
    }

    ${fontBaseNormal(".search::part(input)")}
    ${fontBaseNormal(".search::part(input)::placeholder")}

    .menu {
      font-size: 16px;
      color: #94a3b8;
    }

    .cart {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }

    .iconwrapper {
      color: #94a3b8;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      font-size: 20px;
    }

    .count {
      border-radius: 50%;
      line-height: 0;
      width: 20px;
      height: 20px;
      background-color: var(--brand-primary-color-primary, #3b82f6);
      color: var(--colors-library-base-white, #fff);
      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      margin-left: -10px;
    }

    .total {
      color: var(--color-text, rgba(0, 0, 0, 0.88));
      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "page-header": PageHeader;
  }
}
