import { B2cCategoryTreePayload, B2cProductSearchPayload } from "@/generated/graphql/b2c.js";
import { FilterForm } from "@/elements/filter-form/form";
import { Events } from "@/elements/product-area/events";
import { UrlState } from "@/elements/product-area/url-state";
import { PagerForm } from "@/elements/fix-pager/form";
import { ReactiveElement } from "lit";
import { ProductFormOptions } from "./product-form-options";
import { ProductQueryState } from "./product-query-state";

export class CategoriesAreaStore {
  categories: B2cCategoryTreePayload[] = [];
  setCategories(list: B2cCategoryTreePayload[]) {
    this.categories = [...list];
  }
}

export class ProductAreaStore {
  aggregated = {
    categories: [] as string[],
  };
  products: B2cProductSearchPayload[] = [];
  pager!: PagerForm;
  events!: Events;
  url = new UrlState();
  popup: null | "filters" | "sorter" = null;
  host!: ReactiveElement;
  filters: FilterForm = new FilterForm({ autoApply: true });
  options = new ProductFormOptions();
  state = new ProductQueryState();
  loadedState: ProductQueryState | null = null;
  navigationBackAvailable = false;

  connect(host: ReactiveElement) {
    this.host = host;
    this.pager = new PagerForm(host);
    this.events = new Events(host);

    this.connectForm(this.filters, true);

    this.host.addEventListener("filters.applied", this.onFiltersApplied);
    this.host.addEventListener("filters.clear", this.onFiltersClear);
    this.host.addEventListener("pager.page", this.onPageChanged);
    this.host.addEventListener("pager.next", this.onPageNext);
    this.host.addEventListener("filter.open", this.onFiltersOpen);
    this.host.addEventListener("sort.open", this.onSortOpen);
  }

  disconnect(host: ReactiveElement) {
    this.host.removeEventListener("filters.applied", this.onFiltersApplied);
    this.host.addEventListener("filters.clear", this.onFiltersClear);
    this.host.removeEventListener("pager.page", this.onPageChanged);
    this.host.removeEventListener("pager.next", this.onPageNext);
    this.host.removeEventListener("filter.open", this.onFiltersOpen);
    this.host.removeEventListener("sort.open", this.onSortOpen);
  }

  onFiltersApplied = ((event: CustomEvent<{ form: FilterForm }>) => {
    this.applyForm(event.detail.form);
  }) as EventListener

  onFiltersClear = ((event: CustomEvent<{ form: FilterForm }>) => {
    this.state = new ProductQueryState();
    this.emitUpdate();
  }) as EventListener

  onPageChanged = ((event: CustomEvent<{ page: number }>) => {
    this.state.page = event.detail.page;
    this.emitUpdate();
  }) as EventListener

  onPageNext = ((event: CustomEvent) => {
    this.state.page = this.state.page + 1;
    this.emitUpdate();
  }) as EventListener

  onFiltersOpen = ((event: CustomEvent) => {
    this.setCurrentPopup("filters");
  }) as EventListener

  onSortOpen = ((event: CustomEvent) => {
    this.setCurrentPopup("sorter");
  }) as EventListener

  setupBrands(list: { id: string; name: string }[]) {
    this.options.brands = list;
  }

  setupCategories(list: { id: string; name: string }[]) {
    this.options.categories = list;
  }

  setupProducts(list: B2cProductSearchPayload[]) {
    if (!this.isProductListCanBeContinued()) {
      this.products = [];
    }

    this.products.push(...list);
    this.loadedState = this.state.clone();
    this.host.requestUpdate();
  }

  setupAggregated(aggregated: { categories: string[] }) {
    this.aggregated = aggregated;
  }

  setupSorters(list: { id: string; name: string }[]) {
    this.options.sorters = list;
  }

  setupSearchQuery(query: string) {
    this.state.search = query;
  }

  readParameters(params: URLSearchParams) {
    const result = this.url.readParam(params, "filters");
    const page = this.url.readIntegerParam(params, "page") ?? 1;
    const query = this.url.readStringParam(params, "q");

    this.state.search = query ?? "";

    if (result) {
      this.state.sort = result.sort;
      this.state.brands = result.brands;
      this.state.categories = result.categories;
      this.state.prices = { from: result.priceMin, to: result.priceMax };
    } else {
      this.state.clear();
    }
    this.state.page = page;

    this.fillForms();
  }

  setNavigationBackAvailable(state: boolean) {
    this.navigationBackAvailable = state;
  }

  serializeParameters(): URLSearchParams {
    const params = new URLSearchParams();

    !this.state.isPagerBlank() && this.url.writeIntegerParam(params, "page", this.state.page);

    !this.state.isFiltersBlank() &&
      this.url.writeParam(params, "filters", {
        sort: this.state.sort,
        brands: this.state.brands,
        categories: this.state.categories,
        priceMin: this.state.prices.from,
        priceMax: this.state.prices.to,
      });
    !!this.state.search && this.url.writeStringParam(params, "q", this.state.search);

    return params;
  }

  setCurrentPopup(name: "filters" | "sorter" | null) {
    this.popup = name;
    this.host.requestUpdate();
  }

  backFromEmptySearch() {
    this.host.dispatchEvent(new CustomEvent("productarea.back"));
  }

  forms = new Set<FilterForm>();

  connectForm(form: FilterForm, state: boolean) {
    if (state) {
      this.forms.add(form);
      this.fillForms();
    } else {
      this.forms.delete(form);
    }
  }

  applyForm(form: FilterForm) {
    const previus = this.state;

    console.log(previus, this.state);

    this.state = new ProductQueryState({
      sort: form.sort!,
      prices: form.getRange("price"),
      brands: form
        .getOptions("brands")
        .filter((item) => item.selected)
        .map((item) => item.id),
      categories: form
        .getOptions("categories")
        .filter((item) => item.selected)
        .map((item) => item.id),
      page: this.pager.page,
      search: previus.search,
    });

    if (!this.state.isProductListCanBeContinued(previus)) {
      this.state.page = 1;
    }

    if (!this.state.equals(previus)) {
      this.fillForms();
      this.emitUpdate();
    }
  }

  fillForms() {
    const { forms, pager, options, state } = this;
    for (const filter of forms) {
      filter.updating = true;
      filter.setSorters(options.sorters);
      filter.setOptions("brands", options.brands);
      filter.setOptions("categories", options.categories);
      filter.selections = new Set([
        ...state.brands.map(id => filter.key("brands", id)),
        ...state.categories.map(id => filter.key("categories", id))
      ]);
      filter.selectSort(state.sort);
      filter.selectRange("price", { from: state.prices.from, to: state.prices.to });
      filter.host?.requestUpdate();
      filter.updating = false;
    }
    pager.page = state.page;
  }

  emitUpdate() {
    this.host.dispatchEvent(new CustomEvent("productarea.update"));
  }

  isProductListCanBeContinued() {
    if (this.loadedState) {
      return this.state.isProductListCanBeContinued(this.loadedState);
    }

    return false;
  }
}