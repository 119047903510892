import "@/elements/mobile/page-header";

import { customElement, property, state } from "lit/decorators.js";
import { desktop, layout, mobile } from "../cont";
import { LitElement, css, html } from "lit";

import "../extracted/fix-brand";
import "../extracted/fix-menu-button";
import "../extracted/fix-icon-indicator";

import "@/elements/mobile/page-header";
import "@/elements/mobile/page-footer";
import "@/elements/mobile/my-garage";
import "@/elements/mobile/fix-breadcrumb";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/product-mpn";
import "@/elements/mobile/fix-rating";
import "@/elements/mobile/fix-carousel";
import "@/elements/mobile/product-variations";
import "@/elements/mobile/product-dispatch";
import "@/elements/mobile/price-value";
import "@/elements/mobile/fix-badge";
import "@/elements/mobile/fix-quantity";
import "@/elements/mobile/fix-button";

@customElement("mobile-head-element")
@layout()
export class MobileHeadElement extends LitElement {
  render() {
    return html` ${this.desktopHead} `;
  }

  get desktopHead() {
    return html`
      <div class="brand">
        <fix-brand type="sm"></fix-brand>
      </div>
      <page-header class="page header selfpadding" .searchValue=${""}></page-header>
    `;
  }

  static styles = css`
    :host {
      background-color: white;
      display: flex;
      flex-direction: column;
    }

    .brand {
      display: flex;
      margin-top: 9px;
      justify-content: center;
      align-items: center;
    }
  `;
}
