import { adoptStyles, LitElement, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { localized } from "@/decorators/localized";
import account from "@/assets/account.svg";
import { when } from "lit/directives/when.js";
import "./popup-backdrop-area";
import { font } from "@/util/fonts";
import { t } from "i18next";
import { Url } from "@/helpers/url";
import { consume, ContextProvider } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import "@/pages/product-page/extracted/menu/customer-actions";

@customElement("customer-head")
@localized()
export class CustomerHead extends LitElement {
  @query("fix-dropdown-head")
  anchor!: HTMLElement;

  @consume({ context: customerChannelContext, subscribe: true })
  customerChannel!: CustomerChannel;

  @state()
  visible = false;

  render() {
    return html`
      <fix-dropdown-head .icon=${account} .label=${this.label} @click=${() => (this.visible = !this.visible)}></fix-dropdown-head>
      ${when(this.visible, () => this.renderPopup())}
    `;
  }

  renderPopup() {
    return html`<customer-popup .topAnchor=${this.anchor} .onClose=${() => (this.visible = false)}></customer-popup>`;
  }

  get label() {
    return this.customerChannel?.isCustomerAuth() ? this.customerChannel.customer?.email : t("7e19h22khwn365zm.sign-in", "Sign in");
  }
}

@customElement("customer-popup")
@localized()
export class CustomerPopup extends LitElement {
  outlet!: HTMLElement;

  private topAnchor!: HTMLElement;

  @consume({ context: customerChannelContext, subscribe: true })
  CustomerChannel!: CustomerChannel;

  @property()
  onClose!: () => void;

  render() {
    return html`
      <popup-backdrop-area @click=${this.onClose}></popup-backdrop-area>
      <sl-popup .anchor=${this.topAnchor} placement="bottom" distance="10" arrow active>
        <div class="customer-popup-container">
          ${when(!this.CustomerChannel.isCustomerAuth(), () => this.renderSignInContent())}
          ${when(this.CustomerChannel.isCustomerAuth(), () => this.renderCustomerContext())}
        </div>
      </sl-popup>
    `;
  }

  renderSignInContent() {
    return html`
      <div class="heading">${t("1ystg2f5ui8t2310.my-account", "My account")}</div>
      <fix-button href="${Url.to("login-page")}" variant="primary" class="login-btn"> ${t("7e19h22khwn365zm.sign-in", "Sign in")} </fix-button>
      <div class="sign-up-container">
        <span class="new-customer-text">${t("gjk3cx3llkfb6f9r.new-customer", "New customer?")}</span>
        <fix-link href="${Url.to("signup-page")}" type="sm"> ${t("ya79u75dr5ondfid.sign-up", "Sign up")}?</fix-link>
      </div>
    `;
  }

  renderCustomerContext() {
    return html` <customer-actions .type=${"lg"}></customer-actions> `;
  }

  static styles = css`
    .customer-popup-container {
      padding: 24px;
      width: 298px;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      background: var(--Neutral-Bg-colorBgContainer, #fff);
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      align-self: stretch;
    }
    sl-popup {
      --arrow-color: #ffffff;
    }

    sl-popup::part(popup) {
      z-index: 101;
    }

    sl-popup::part(arrow) {
      box-shadow: 9px 6px 6px 7px rgb(0, 0, 0, 0.15);
    }

    .login-btn {
      align-self: stretch;
      flex: 1;
    }

    .sign-up-container {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 1 0%;
      align-self: stretch;
      align-items: center;
      justify-content: center;
    }

    .new-customer-text {
      ${font("Base/Normal")}
    }

    .heading {
      ${font("Heading/3")}
    }
  `;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    const div = document.createElement("div");

    document.body.appendChild(div);
    const root = div.attachShadow({ mode: "open" });

    adoptStyles(root, (this.constructor as typeof LitElement).elementStyles!);

    this.outlet = div;
    return root;
  }

  connectedCallback(): void {
    super.connectedCallback();
    new ContextProvider(this.outlet, { context: customerChannelContext, initialValue: this.CustomerChannel });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.body.removeChild(this.outlet);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "customer-popup": CustomerPopup;
    "customer-head": CustomerHead;
  }
}
