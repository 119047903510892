import { LitElement, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";

import "../layout-desktop/head";
import "../layout-mobile/head";
import "./footer";
import "@/elements/mobile/my-garage";
import "../extracted/menu/drawer-panel";
import "../extracted/menu/menu-sm";
import "../extracted/menu/menu-lg";
import "../extracted/menu/menu-categories";
import { when } from "lit/directives/when.js";
import {features} from "@/constants/features";

@customElement("catalog-layout")
export class CatalogLayout extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "sm";

  layout!: string;

  @state()
  popup: null | { target: Element; type: "menu-lg" | "menu-sm" | "menu-categories" } = null;

  render() {
    return choose(this.type, [
      ["lg", () => this.renderDesktop()],
      ["sm", () => this.renderMobile()],
    ]);
  }

  showMenuCategories(event: { detail: { target: Element } }) {
    this.openPopup("menu-categories", event.detail.target);
  }

  showMenuLg(event: { detail: { target: Element } }) {
    this.openPopup("menu-lg", event.detail.target);
  }

  showMenuSm(event: { detail: { target: Element } }) {
    this.openPopup("menu-sm", event.detail.target);
  }

  renderPopup = () => {
    return choose(this.popup!.type, [
      ["menu-sm", () => html`<menu-sm @close=${this.closePopup}></menu-sm>`],
      ["menu-lg", () => html`<menu-lg .anchor=${this.popup!.target} @close=${this.closePopup}></menu-lg>`],
      ["menu-categories", () => html`<menu-categories .anchor=${this.popup!.target} @close=${this.closePopup}></menu-categories>`],
    ]);
  };

  openPopup = (type: "menu-lg" | "menu-sm" | "menu-categories", target: Element) => {
    this.popup = { target, type };
  };

  closePopup = () => {
    this.popup = null;
  };

  renderDesktop() {
    return html` ${when(this.popup, this.renderPopup)}
      <div class="container">
        <desktop-head-element @categoryClick=${this.showMenuCategories} @menuClick=${this.showMenuLg}></desktop-head-element>
        <div>${this.renderContent()}</div>
        ${this.renderFooter()}
      </div>`;
  }

  renderMobile() {
    return html`
      ${when(this.popup, this.renderPopup)}
      <div class="container">
        <mobile-head-element @menuClick=${this.showMenuSm}></mobile-head-element>
        ${when(
            features.garage,
            () => html`<my-garage class="page garage selfpadding" .fitsProduct=${true} .currentCar=${`Ford Focus 2015`}></my-garage>`,
        )}
        <div>${this.renderContent()}</div>
        ${this.renderFooter()}
      </div>
    `;
  }

  renderFooter() {
    return html`<footer-element></footer-element>`;
  }

  renderContent() {
    return html`<slot></slot>`;
  }

  static styles = css`
    .container {
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
      min-height: 100vh;
    }
  `;
}
