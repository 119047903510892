import { ReactiveElement } from "lit";

export function localized() {
  return function <T extends { new (...args: any[]): ReactiveElement }>(constructor: T) {
    return class extends constructor {
      private readonly _boundOnLocaleChange: () => void;

      constructor(...args: any[]) {
        super(...args);
        this._boundOnLocaleChange = this._onLocaleChange.bind(this);
      }

      connectedCallback() {
        super.connectedCallback();
        window.addEventListener("locale-change", this._boundOnLocaleChange);
      }

      disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener("locale-change", this._boundOnLocaleChange);
      }

      _onLocaleChange() {
        this.requestUpdate();
      }
    };
  };
}
