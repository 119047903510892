import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import "./layout-mobile/head";
import { Cart, cartContext } from "@/context/cart.ts";
import "@/elements/mobile/cart/item";
import { consume } from "@lit/context";
import { t } from "i18next";
import { localized } from "@/decorators/localized.ts";
import { when } from "lit/directives/when.js";
import { font } from "@/util/fonts.ts";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

@localized()
@customElement("cart-page-mobile")
export class CartPageMobile extends LitElement {
  @consume({ context: cartContext, subscribe: true })
  @property({ attribute: true })
  public cart!: Cart;

  get checkoutUrl() {
    return import.meta.env.PUBLIC_CHECKOUT_HOST + `/?source=b2c&id=${this.cart?.id}`;
  }

  renderEmptyCart = () => {
    return html`<cart-empty-label></cart-empty-label>`;
  };

  renderNotifications = () => {
    return html`
      <div class="notifications-container">
        <sl-details summary="Notifications" open>
          <cart-notifications .items=${this.cart.errors}></cart-notifications>
        </sl-details>
      </div>
    `;
  }

  renderCartInfo = () => {
    return html`
      <div class="cart-container">
        <div class="cart-items-container">
          <div class="cart-items-total">
            <div class="label">${t("p90ev5c5p8c5048s.shopping-cart", "Shopping cart")}</div>
          </div>
          ${when(this.cart?.errors?.length, () => this.renderNotifications())}
          <div class="products">
            ${this.cart.items?.map(
              (item: any, key: number) => html`${when(key, () => html`<sl-divider></sl-divider>`)}<cart-item-mobile .product=${item}></cart-item-mobile>`,
            )}
          </div>
        </div>
        <div class="cart-totals">
          <div class="subtotal">
            <span>${t("sor9ad5qli79zyjd.subtotal", "Subtotal:")}</span>
            <span class="total-price"><price-value class="price" .amount=${Number(this.cart.grandTotal?.amount)}></price-value></span>
            <span class="tax-info">(${t("9hkouimd77ty1706.vat-include", "Include {{percent}}% VAT", { percent: this.cart.taxAdditionalInfo })})</span>
          </div>
          <sl-button class="checkout-btn" size="large" href=${this.checkoutUrl}>${t("841teeu1p1a02wyl.to-checkout", "Go to checkout")}</sl-button>
          <sl-button class="back-btn" size="large" href="/">${t("e83geotgxa5m02vb.back-to-shipping", "Back to shipping")}</sl-button>
        </div>
      </div>
    `;
  };

  render() {
    const cartContent = this.cart.items?.length ? this.renderCartInfo() : this.renderEmptyCart();
    return html`
      <cart-head-mobile></cart-head-mobile>
      ${cartContent}
    `;
  }

  static styles = css`
      sl-button::part(label) {
          ${font('LG/Normal')}
          line-height: inherit;
      }

      sl-button {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      --sl-input-border-radius-large: 8px;
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
      display: flex;
      min-width: 100%;
    }

    .cart-items-container {
      background: #fff;
      max-width: 100%;
      height: max-content;
      padding: 24px 8px 340px;
    }

    .cart-items-total {
      display: flex;
      align-items: baseline;
    }

    .cart-items-total .label {
      margin-right: 20px;
      ${font('Heading/3')}
    }

    .cart-items-total .sub-title {
      color: #a3a3a3;
      font-size: 20px;
    }

    .cart-totals {
      width: calc(100% - 16px);
      padding: 30px 8px;
      background: #fff;
      height: max-content;
      position: fixed;
      bottom: 0;
      box-shadow: 0px -2px 4px 0px #0000000d;
    }

    .cart-totals .label {
      font-size: 30px;
      font-weight: bold;
    }

    .cart-totals .info {
      margin: 20px 0px;
      font-size: 14px;
    }

    .cart-totals .info .total {
      margin: 20px 0px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }

    .subtotal {
      ${font('Heading/5')}
      width: max-content;
      margin: 0 auto 8px;
    }

    .back-btn {
      margin-top: 10px;
      --sl-color-neutral-0: #f4f4f5;
      --sl-color-neutral-700: #000;
    }

    .tax-info {
      color: #737373;
        ${font('Base/Normal')}
    }

    sl-divider {
      --spacing: 0;
    }

    .total-price {
      margin-left: 8px;
    }

    .tax-info {
      margin-left: 8px;
    }

    .notifications-container {
      padding-top: 20px;
    }

    .notifications-container sl-details::part(summary)  {
      ${font('Heading/4')}
    }
  `;
}
