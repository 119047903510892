import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { font } from "@/util/fonts";
import type { MenuStructure } from "./menu-categories";
import { Categories } from "@/services/categories";

export type MenuItem = {
  key: string;
  name: string;
  url?: string;
  level?: number;
};

@customElement("subcategories-box")
export class SubcategoriesBox extends LitElement {
  @state()
  title!: string;

  @state()
  columns!: MenuItem[][];

  @property()
  menu!: Categories;

  @property()
  rootId!: string;

  protected render() {
    const items = this.columns;

    return html`
      <div class="box">
        <div class="title">${this.title}</div>
        <div class="columns">${map(items, (items) => this.renderColumn(items))}</div>
      </div>
    `;
  }

  renderColumn(items: Array<MenuItem>) {
    return html` <div class="column">${map(items, (item) => this.renderColumnItem(item))}</div> `;
  }

  renderColumnItem(item: MenuItem) {
    const level = `l${item.level}`;
    const className = classMap({
      ["item"]: true,
      [level]: true,
    });

    return html`
      <a class=${className} href=${ifDefined(item.url)} @click=${this.onItemClick}>
        <label class="label">${item.name}</label>
      </a>
    `;
  }

  onItemClick = () => {
    this.dispatchEvent(new CustomEvent("close", { composed: true, bubbles: true }));
  };

  protected update(_changedProperties: PropertyValues): void {
    super.update(_changedProperties);
    if (_changedProperties.has("rootId") || _changedProperties.has("menu")) {
      this.updateMenu();
    }
  }

  protected updateMenu() {
    this.title = this.menu.getItem(this.rootId).name;
    this.columns = this.menu
      .getChilds(this.rootId)
      .map((primary) => {
        const secondary = this.menu.getChilds(primary.key);
        return [primary, ...secondary].map((item) => ({
          key: item.key,
          name: item.name,
          url: item.url,
          level: item.key === primary.key ? 1 : 2,
        }));
      })
      .sort((a, b) => {
        if (a.length > 1 && b.length > 1) return 0;
        return a.length > 1 ? -1 : 1;
      });
  }

  static styles = css`
    .item {
      text-decoration: none;
    }

    .item label {
      cursor: pointer;
    }

    .box {
      white-space: nowrap;
    }

    .columns {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .box .title {
      ${font("Heading/4")};
      color: var(--color-text-heading, #111827);
      padding: 8px;
    }

    .columns {
      gap: 8px 64px;
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    .column .item.l1 {
      padding: 4px 8px;
    }

    .column .item.l1 .label {
      ${font("Heading/5")};
      color: var(--color-text-heading, #111827);
    }

    .column .item.l2 {
      padding: var(--Space-Margin-marginXXXS, 2px) 8px;
    }

    .column .item.l2 .label {
      ${font("Base/Normal")};
      color: var(--Components-Typography-Global-colorText, #27272a);
    }
  `;
}
