import { customElement, property, state } from "lit/decorators.js";
import { PageElement, whenType } from "@/util/element.ts";
import { css, html } from "lit";
import { when } from "lit/directives/when.js";
import { StaticPages, StaticPagesContext, StaticPagesType } from "@/context/static-pages.ts";
import { consume } from "@lit/context";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { choose } from "lit/directives/choose.js";
import { font } from "@/util/fonts.ts";
import { cardDefault, layoutOffsetPadding } from "@/pages/product-page/utils.ts";

@customElement("about-us-page")
export class AboutUsPage extends PageElement {
  declare type: "sm" | "lg";

  @state()
  loading = true;

  @consume({ context: StaticPagesContext, subscribe: true })
  @property({ attribute: true })
  public staticPages!: StaticPages;

  render() {
    return html` ${this.renderContent()} ${when(this.loading, () => this.renderLoaderOverlay())} `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.load();
  }

  async load() {
    await this.staticPages.fetchStaticPage(StaticPagesType.aboutUs);
    this.loading = false;
  }

  renderContent() {
    return choose(this.type, [
      ["sm", this.renderContentSm],
      ["lg", this.renderContentLg],
    ]);
  }

  renderContentSm = () => {
    return html`
      <catalog-layout type=${this.type}>
        <div class="topcard">
          <div class="row-navigation">
            <fix-breadcrumb></fix-breadcrumb>
          </div>
          <div class="row-title">
            <div class="title">About us</div>
          </div>
        </div>
        ${unsafeHTML(this.staticPages.getStaticPage(StaticPagesType.aboutUs))}
      </catalog-layout>
    `;
  };

  renderContentLg = () => {
    return html`
      <catalog-layout type=${this.type}>
        <div class="topcard">
          <div class="row-navigation">
            <fix-breadcrumb></fix-breadcrumb>
          </div>
          <div class="row-title">
            <div class="title">About us</div>
          </div>
        </div>
        ${unsafeHTML(this.staticPages.getStaticPage(StaticPagesType.aboutUs))}
      </catalog-layout>
    `;
  };

  renderLoader() {
    return html`<fix-loader></fix-loader>`;
  }

  renderLoaderOverlay() {
    return html` <div class="overlay">${this.renderLoader()}</div> `;
  }

  static stylesCommon = css``;

  static stylesSm = css`
    .section {
      ${font("SM/Regular")}
      padding: 16px 8px;
      background: #fff;
      margin: 8px 0;
    }
    .topcard .title {
      ${font("Heading/3")};
      margin: 8px;
    }
    p {
      display: block;
      margin: 4px 0 0;
    }
    .about-us-benefits img {
      width: 100%;
    }

    .benefits-option img {
      padding: 16px;
      margin-right: 16px;
      height: 24px;
      width: 24px;
    }

    .benefits-section {
      padding: 20px 16px;
    }

    .benefits-option {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      background: #fff;
      border-radius: 6px;
      ${font("SM/Strong")}
    }
  `;

  static stylesLg = css`
    .topcard .title {
      ${font("Heading/2")};
    }
    .section {
      ${font("Base/Normal")}
    }

    .about-us-benefits {
      display: flex;
    }
    .about-us-benefits img {
      width: 60%;
      border-radius: 6px;
    }
    .benefits-option img {
      padding: 16px;
      margin-right: 16px;
      height: 24px;
      width: 24px;
    }
    .benefits-section {
      width: 40%;
      padding: 0 20px;
    }
    .benefits-option {
      display: flex;
      margin-bottom: 32px;
      align-items: center;
      background: #f2f5f9;
      border-radius: 6px;
      ${font("Heading/5")}
    }
    .ul-dashed li {
      list-style-type: none;
      position: relative;
      padding-left: 28px;
      margin-top: 16px;
    }

    .ul-dashed li::before {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: #60a5fa;
      position: absolute;
      left: 0;
      top: 5px;
    }

    .section-title {
      ${font("Heading/4")}
    }
    .li-title {
      ${font("Heading/5")}
      display: inline-block;
    }
    .topcard {
      ${cardDefault()};
      ${layoutOffsetPadding()};
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .static-page-content {
      margin: 32px 48px;
      padding: 32px;
      background: #fff;
      border-radius: 6px;
    }
    .section-divider {
      background: #f0f0f0;
      height: 1px;
      margin: 32px 0px;
    }

    p {
      ${font("Base/Normal")}
    }

    .ul-dashed {
      margin: 0;
      padding: 0;
    }
  `;

  static get styles() {
    return [this.stylesCommon, whenType("sm", this.stylesSm), whenType("lg", this.stylesLg)];
  }
}
