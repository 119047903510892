import { desktop, layout, mobile } from "@/pages/product-page/cont";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("product-specifications")
@layout()
export class ProductSpecifications extends LitElement {
  @property({ type: Array }) options = [] as Array<{ name: string; value: string; type: string }>;

  render() {
    const { options } = this;

    return html`
      <table class="container">
        ${options.map(
          (item) => html`
            <tr class="row">
              <td class="name">${item.name}</td>
              <td class="value">${item.value} ${item.type}</td>
            </tr>
          `,
        )}
      </table>
    `;
  }

  static styles = css`
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th,
    td {
      width: 50%;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      border: none;
      padding: 8px;
      text-align: left;
      font-family: "SF Pro Display";
    }

    tr:nth-child(odd) {
      background-color: #f4f4f5;
    }

    ${mobile} {
    }

    ${desktop} {
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "product-specifications": ProductSpecifications;
  }
}
