import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/elements/fix-pager-next";
import "@/elements/fix-pager";
import "@/elements/fix-pager-message";
import { when } from "lit/directives/when.js";
import { MultiTypeElement, whenType } from "@/util/element";

@customElement("fix-pager-group")
export class FixPagerGroup extends MultiTypeElement {
  @property()
  type!: "sm" | "lg";

  @property()
  total!: number;

  @property()
  current!: number;

  @property()
  totalItems!: number;

  @property()
  size!: number;

  @property()
  next = false;

  @property()
  pager = false;

  render() {
    return html` <div class="container centred">${when(this.next, this.renderNext)} ${when(this.pager, this.renderPager)}</div> `;
  }

  renderNext = () => {
    return html`<fix-pager-next class="next" .size=${this.size}></fix-pager-next>`;
  };

  renderPager = () => {
    return html`
      <div class="padding centred">
        <fix-pager-message class="message" .current=${this.current} .total=${this.total} .totalItems=${this.totalItems}></fix-pager-message>
        <fix-pager class="pager" .current=${this.current} .total=${this.total}></fix-pager>
      </div>
    `;
  };

  static stylesCommon = css`
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .pager {
      margin-top: var(--Space-Margin-marginXS, 8px);
    }

    .padding {
      padding-left: 70px;
      padding-right: 70px;
    }

    .centred {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `;

  static stylesSm = css`
    .next {
      margin-top: var(--Space-Margin-margin, 16px);
      margin-bottom: var(--Space-Margin-margin, 16px);
      width: 100%;
    }
  `;
  static stylesLg = css`
    .next {
      margin-top: var(--Space-Padding-paddingLG, 24px);
      margin-bottom: var(--Space-Padding-paddingLG, 24px);
      width: 48%;
      min-width: 200px;
      max-width: 600px;
    }
  `;

  static styles = [FixPagerGroup.stylesCommon, whenType("sm", FixPagerGroup.stylesSm), whenType("lg", FixPagerGroup.stylesLg)];
}
