import { Context, ContextConsumer } from "@lit/context";
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { menu, submenu } from "@/stub/menu.json";

const svgList = import.meta.glob("@/assets/category/*.svg", {
  as: "url",
  eager: true,
});

const resolveAlias = (path: string) => {
  return path.replace("@", "src");
};

export const getTopMenuItems = () => {
  return menu;
};

export const getSubMenuItems = () => {
  return submenu;
};

export const getMenuIconUrl = (code: string) => {
  return svgList[resolveAlias(`@/assets/category/${code}.svg`.replace("@", "/src"))];
};

export const getIconUrl = (path: string | null) => {
  if (!path?.length) return path;

  return import.meta.env.PUBLIC_B2C_ASSETS_HOST + "/" + path;
};

export const renderFragment = function <T>(context: Context<T, unknown>, render: (context: T) => unknown) {
  return html`<fragment-element .contextType=${context} .renderer=${render}> </fragment-element>`;
};

@customElement("fragment-element")
export class FragmentElement extends LitElement {
  @property()
  contextType!: unknown;

  @property()
  renderer!: (context: any) => unknown;

  consumer!: ContextConsumer<any, any>;

  render() {
    return this.renderer(this.consumer.value!);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.consumer = new ContextConsumer(this, { context: this.contextType as any, subscribe: true });
  }

  createRenderRoot() {
    return this;
  }
}
