import { createContext } from "@lit/context";

import { Api } from "@/api";
import { ContextProvider } from "@lit/context";
import { B2cProductSearchPayload, Pagination, ProductsSearchDocument } from "@/generated/graphql/b2c";
import { LitElement } from "lit";

export class Search {
  private provider: ContextProvider<{ __context__: Search }, LitElement>;

  searchString = "";
  loading = true;
  products: B2cProductSearchPayload[] = [];
  pagination: Pagination | null = null;

  private api: Api;

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: searchContext, initialValue: this });
    this.api = api;
  }

  private update = () => {
    this.provider.setValue(this, true);
  };

  search = (query: string) => {
    this.loading = true;
    this.searchString = query;
    this.update();
    this.fetchProducts();
  };

  fetchProducts = async () => {
    try {
      const response = await this.api.client.query({
        query: ProductsSearchDocument,
        variables: { input: { query: this.searchString } },
      });
      if (response) {
        //@ts-ignore
        this.products = response.data.ProductsSearch.records;
        this.pagination = response.data.ProductsSearch.pagination;
      }
    } catch (e) {
      console.log("error fetching products", e);
      throw e;
    }

    this.loading = false;
    this.update();
  };
}

export const searchContext = createContext<Search>("search");
