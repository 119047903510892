import { LitElement, PropertyValueMap, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { font } from "@/util/fonts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { Router, RouterLocation } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { layout } from "../product-page/cont";
import { createRef, ref } from "lit/directives/ref.js";
import { localized } from "@/decorators/localized";
import { VariantSwitcher } from "../product-page/switcher";
import { choose } from "lit/directives/choose.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/mobile/fix-input";
import "@/pages/product-page/layout/footer";
import "@/elements/mobile/store-header";
import { when } from "lit/directives/when.js";
import { cssLayout } from "@/util/layout";

@customElement("password-reset-page")
@layout()
@localized()
export class PasswordRecoveryPage extends LitElement {
  layout!: string;

  @property()
  location!: RouterLocation;

  switcher: VariantSwitcher<PasswordRecoveryPage> = new VariantSwitcher(this);

  @consume({ context: customerChannelContext, subscribe: true })
  @property({ attribute: false })
  public customerContext!: CustomerChannel;

  private ref = createRef<HTMLFormElement>();

  @state()
  private loginError = "";

  @state()
  private loading = false;

  render() {
    return choose(this.layout, [
      ["desktop", this.renderDesktop],
      ["mobile", this.renderMobile],
    ]);
  }

  renderMobile = () => {
    return html`<store-header type="sm"></store-header>${this.renderPage()}`;
  };

  renderDesktop = () => {
    return html` <store-header type="lg">
        <span slot="left-block">${this.renderContinueShoppingLink()}</span>
      </store-header>
      ${this.renderPage()}
      <footer-element></footer-element>`;
  };

  renderPage() {
    return html`
      <div class="login-page-container">
        ${when(this.layout === "mobile", () => html`<div class="header">${this.renderContinueShoppingLink()}</div>`)}
        <div class="login-container">
          <form class="login-form" name="login" @submit=${this.submit} ${ref(this.ref)}>
            <div><span class="login-heading">${t("s0hdh7oc8j01ydvf.forgot-password-text", "Forgot your password?")}</span></div>
            <div class="inputs-container">
              <fix-input
                name="newPassword"
                type="password"
                placeholder="${t("ifm2plg74o52mxbv.create-new-password", "Create new password")}"
                helpText="${t("3t8k9e9jqwp1pn82.please-enter-password", "Please enter password")}"
                required
                clearable
              ></fix-input>
              <fix-input
                name="confirmedPassword"
                type="password"
                placeholder="${t("ld981fe5hys8kvws.confirm-your-password", "Confirm your password")}"
                helpText="${t("3t8k9e9jqwp1pn82.please-enter-password", "Please enter password")}"
                .customError=${this.loginError}
                required
                clearable
              ></fix-input>
            </div>
            <fix-button @click=${this.submit} variant="primary" type="${this.elementsType}" class="login-btn" .loading=${this.loading}>
              ${t("min5x9lv5wevap71.change-password", "Change password")}
            </fix-button>
          </form>
        </div>
      </div>
    `;
  }

  get elementsType() {
    return this.layout === "desktop" ? "md" : "sm";
  }

  private redirectToLoginPage() {
    Router.go(Url.to("login-page"));
  }

  renderContinueShoppingLink = () => {
    return html`
      <fix-link class="continue-shopping-link" href="${Url.to("home-page-with-lang")}" type=${this.elementsType}>
        <sl-icon src=${arrowOutlined}></sl-icon>
        ${t("k0q7l2w2q1856v4o.continue-shopping", "Continue shopping")}
      </fix-link>
    `;
  };

  submit = async () => {
    const formData = new FormData(this.ref.value!);

    const newPassword = formData.get("newPassword") as string;
    const confirmedPassword = formData.get("confirmedPassword") as string;
    const resetPasswordToken = this.location.params.token as string;

    const form = this.ref.value;

    if (!newPassword || !confirmedPassword || !form?.checkValidity()) {
      return;
    }

    this.loginError = "";
    this.loading = true;

    const result = await this.customerContext.resetPassword({
      newPassword,
      confirmedPassword,
      resetPasswordToken,
    });

    this.loading = false;

    if (!result.success) {
      this.loginError = result.errors?.[0].message ?? t("vy0rzkl108ag908k.incorrect-username", "Incorrect Username");
      return;
    }

    this.redirectToLoginPage();
  };

  static styles = css`
    .login-page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 16px;
      gap: var(--space-margin-margin-lg, 24px);
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
    }

    ${cssLayout("desktop")} .login-page-container {
      padding: 56px 0px;
    }

    .login-container {
      display: flex;
      padding: var(--space-padding-padding-xl, 32px) var(--space-padding-padding-md, 24px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: var(--border-radius-border-radius-lg, 8px);
      background: var(--neutral-bg-color-bg-container, #ffffff);
    }

    ${cssLayout("desktop")} .login-container {
      width: 410px;
      align-self: center;
      padding: var(--space-padding-padding-xl, 32px);
    }

    .header {
      display: flex;
      align-self: stretch;
      padding: 0px var(--header-margin-xs, 8px);
      margin-top: var(--header-margin-xs, 24px);
    }

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
      align-self: stretch;
    }

    ${cssLayout("mobile")} .login-heading {
      ${font("Heading/3")};
    }

    ${cssLayout("desktop")} .login-heading {
      ${font("Heading/2")}
    }

    .inputs-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      align-self: stretch;
    }

    .login-btn {
      align-self: stretch;
    }

    .continue-shopping-link sl-icon {
      margin-right: var(--Space-Padding-paddingXS, 8px);
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "password-reset-page": PasswordRecoveryPage;
  }
}
