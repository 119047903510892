import { ContextProvider, createContext } from "@lit/context";
import { Api } from "@/api";
import { CustomerDocument, Customer as CustomerType } from "@/generated/graphql/customer.ts";
import { LitElement } from "lit";

type UpdateCustomerParams = {
  countryId?: string;
  currency?: string;
  locale?: string;
};

export class Customer {
  protected api: Api;

  protected provider;

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: customerContext });
    this.api = api;
    this.update();
  }

  protected update = () => {
    this.provider.setValue(this, true);
  };

  updateCustomer = async (params: UpdateCustomerParams) => {
    await this.mutateUpdateCustomer(params);
  };

  protected mutateUpdateCustomer = async (params: UpdateCustomerParams) => {
    const input = structuredClone(params) as Partial<UpdateCustomerParams>;

    const customerResponse = await this.api.client.mutate({
      mutation: CustomerDocument,
      variables: {
        input: {
          countryId: input.countryId,
          currency: input.currency,
          locale: input.locale,
        },
      },
    });

    return customerResponse.data!.Customer as CustomerType;
  };
}

export const customerContext = createContext<Customer>("customer");
