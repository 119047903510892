import { customElement } from "lit/decorators.js";
import { css, html } from "lit";
import "@/elements/fix-dropdown/fix-dropdown";
import "@/elements/mobile/price-value";
import { CartItemState } from "@/elements/cart/item";
import { when } from "lit/directives/when.js";
import { localized } from "@/decorators/localized.ts";
import { t } from "i18next";
import { font } from "@/util/fonts.ts";
import trash from "@/assets/trash.svg";
import { createProductUrl } from "@/pages/category-page/product-area-catalog/product-area-element.ts";

@localized()
@customElement("cart-item-mobile")
export class CartItemMobile extends CartItemState {
  priceInfoRender = () => {
    const salePrice = html`
      <div class="price-container">
        <div class="each-price">
          <span class="unit"><price-value class="price" .amount=${this.product?.price.amount}></price-value> per unit</span>
        </div>
        <div class="sale-price">
          <price-value class="price" .amount=${this.price.total}></price-value>
        </div>
      </div>
    `;

    return html`
      <div class="price-quantity">
        <fix-quantity
          class="quantity"
          .quantity=${this.quantity}
          .minQuantity=${this.product?.minOrderQuantity}
          .maxQuantity=${this.product?.maxOrderQuantity}
          .factorQuantity=${this.product?.factorOrderQuantity}
          .type=${"sm"}
          @updateQuantity=${this.setQuantity}
        ></fix-quantity>
        <div class="product-moq">MOQ: ${this.product.minOrderQuantity}</div>
      </div>
      ${salePrice}
    `;
  };

  dispatchTimeRender = () => {
    if (this.product.product.prices.length > 1) {
      return html`
        <sl-button class="price-selector-btn" variant="text" @click=${() => (this.pricesOpened = !this.pricesOpened)}>
          ${this.deliveryTermsRange(this.currentPrice!)} <sl-icon name="chevron-down"></sl-icon>
        </sl-button>
      `;
    }

    return html`<span class="price-selector-btn">${this.deliveryTermsRange(this.currentPrice!)}</span>`;
  };

  render() {
    return html`
      ${this.confirmRemoveProductRender()}
      <div class="item-content">
        <div class="product-img">
          <img src="${this.imgUrl}" />
        </div>

        <div class="info">
          <div class="product-info">
            <div class="title">
              <a class="product-link" href="${createProductUrl(this.product.product, this.categories)}">
                ${this.product?.product.mpnFormatted} ${this.product?.product.brand.name} ${this.product?.product.productInfo.name}
              </a>
            </div>
            <div class="category">${this.product?.product.categories?.[0].name}</div>
            <div class="dispatch">
              <span>${t("bs6v90iqxu790208.dispatch-time", "Dispatch time:")}</span>
              ${this.dispatchTimeRender()}
            </div>
            ${when(this.pricesOpened, () => html`<div class="prices-list">${this.pricesListRender()}</div>`)}
          </div>
          <div class="price-info">${this.priceInfoRender()}</div>
          <div class="actions">
            ${when(
              this.specifications.length,
              () => html`
                <sl-button variant="text" class="more-btn" @click=${() => (this.specificationsOpened = !this.specificationsOpened)}>
                  ${t("eb32219bfq9o6ph1.more-info", "More info")} <sl-icon name="chevron-down"></sl-icon>
                </sl-button>
              `,
            )}
            <sl-button variant="text" class="remove-btn" @click=${this.removeProductConfirm}>
              <sl-icon src=${trash}></sl-icon> ${t("upe5v030tlt34hdu.delete", "Delete")}
            </sl-button>
          </div>
          ${when(this.specificationsOpened, () => html`<product-specifications .options=${this.specifications}></product-specifications>`)}
        </div>
      </div>
    `;
  }

  static styles = css`
    sl-button.more-btn {
      --sl-spacing-medium: 20px 0 0;
      --sl-color-primary-600: #3b82f6;
    }

    span.price-selector-btn {
      margin-left: 8px;
    }

    sl-button::part(label) {
      ${font("LG/Normal")}
      line-height: inherit;
    }

    sl-button.remove-btn {
      --sl-spacing-medium: 20px 0 0;
      --sl-color-primary-600: #a3a3a3;
    }

    sl-button.remove-btn::part(label) {
      ${font("SM/Normal")};
      line-height: unset;
    }

    .product-moq {
      ${font("SM/Regular")};
      text-align: center;
      color: #737373;
    }

    .product-link {
      color: #000;
      text-decoration: unset;
    }

    .price-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 15px;
    }
    .item-content {
      display: flex;
      margin-top: 10px;
      padding: 9px 0;
    }
    .product-img {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      margin-right: 24px;
    }
    .product-img img {
      width: 100%;
    }
    .product-info .title {
      ${font("Base/Strong")}
    }
    .prices-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
      row-gap: 2px;
      margin-bottom: 16px;
    }
    .price-btn {
      width: calc(50% - 4px);
    }
    .price-btn.selected {
      --sl-color-neutral-300: #3b82f6;
    }
    .product-info .category {
      ${font("SM/Normal")}
      color: #a3a3a3;
      display: flex;
    }
    .product-info .dispatch {
      display: flex;
      height: 30px;
      align-items: center;
    }
    .product-info .actions {
      display: flex;
      align-items: baseline;
      margin-top: 20px;
    }
    .price-info {
      text-align: right;
      display: flex;
      align-items: flex-start;
    }
    .each-price {
      text-align: left;
      white-space: nowrap;
    }
    .each-price .unit {
      color: #737373;
    }
    .sale-price {
      margin-top: 5px;
      ${font("LG/Strong")};
    }
    .origin-price {
      font-size: 14px;
      color: #a3a3a3;
      text-decoration: line-through;
    }
    .info {
      width: 100%;
      position: relative;
    }
    .quantity {
      float: left;
    }
    .price-selector-btn {
      --sl-color-primary-500: #3b82f6;
    }

    sl-button.price-selector-btn .button--text:hover:not(.button--disabled) {
      --sl-color-primary-500: #3b82f6;
    }

    .price-btn {
      --sl-input-border-radius-medium: 6px;
    }
    .dispatch span {
      white-space: nowrap;
    }

    sl-button.price-btn::part(label) {
      font-size: 14px;
    }

    .dispatch span,
    .each-price,
    sl-button.price-selector-btn::part(label),
    sl-button.more-btn::part(label) {
      ${font("SM/Normal")};
      line-height: unset;
    }

    .dispatch sl-button::part(label) {
      padding: 0 10px;
    }
  `;
}
