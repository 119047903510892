import { font } from "@/util/fonts";
import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized.ts";

@customElement("fix-pager-message")
@localized()
export class FixPagerMessage extends LitElement {
  @property()
  current!: number;

  @property()
  total!: number;

  @property()
  totalItems!: number;

  render() {
    return html` <div class="message">${this.renderText()}</div> `;
  }

    renderText() {
        return t("285l1mc8slbric5o.showing-products-size", "Showing {{current}}-{{total}} of {{totalItems}} products", {
          current: this.current,
          total: this.total,
          totalItems: this.formatSpaces(this.totalItems),
        });
    }

  formatSpaces(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  static styles = css`
    .message {
      color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
      ${font("SM/Normal")};
    }
  `;
}
