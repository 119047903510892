import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { t } from "i18next";
import placeholder from "@/assets/img-placeholder.svg";
import { font } from "@/util/fonts";
import { localized } from "@/decorators/localized.ts";

@customElement("fix-image-placeholder")
@localized()
export class FixImagePlaceholder extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  render() {
    return html`<sl-icon class="icon" src=${placeholder}></sl-icon><span class="label">${t("pvip7ln0uenv084u.no-photo", "No photo")}</span>`;
  }

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--No-photo-colorTextAndIcon, #a3a3a3);
      user-select: none;
    }

    :host([type="lg"]) .label {
      ${font("Base/Normal")};
    }

    :host([type="lg"]) .icon {
      font-size: 48px;
    }

    :host([type="sm"]) .label {
      ${font("SM/Normal")};
    }

    :host([type="sm"]) .icon {
      font-size: 24px;
    }
  `;
}
