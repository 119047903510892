import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from, type Operation } from "@apollo/client";

import { Services } from "@/services/services";

export const userLanguageHeaderName = "X-User-Language";
export const userCountryHeaderName = "X-User-Country";
export const userCategoryTypeHeaderName = "X-User-Category-Type";

export class Api {
  host = import.meta.env.PUBLIC_API_HOST;
  base = `${this.host}`;

  client: ApolloClient<object>;

  public services!: Services;

  constructor() {
    const link = createHttpLink({
      uri: (operation: Operation) => this.getUrl(operation),
      credentials: "include",
      headers: {

      },
    });

    const headersLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => {
        const result = {
          headers: {
            ...headers
          } as Record<string, string>
        };

        if (this.lang) {
          result.headers[userLanguageHeaderName] = this.lang;
        }

        if (this.country) {
          result.headers[userCountryHeaderName] = this.country;
        }

        if (this.categoryType) {
          result.headers[userCategoryTypeHeaderName] = this.categoryType;
        }

        return result;
      });

      return forward(operation);
    })

    this.client = new ApolloClient({
      link: from([
        headersLink,
        link
      ]),
      cache: new InMemoryCache({
        possibleTypes: {},
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
      },
    });
  }

  get lang() {
    return this.services.parameters.parameters?.language;
  }

  get country() {
    return this.services.parameters.parameters?.country;
  }

  get categoryType() {
    return 'passenger';
  }

  getEndpoint(operation: Operation) {
    switch (operation.operationName) {
      case "Currencies":
      case "Countries":
        return "generic";
      case "Customer":
        return "customer";
      default:
        return "b2c";
    }
  }

  getUrl(operation: Operation) {
    return `${this.base}/${this.getEndpoint(operation)}`;
  }
}
