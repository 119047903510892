import { type } from "@/util/layout";
import { font } from "@/util/fonts";
import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

@customElement("fix-button")
export class FixButton extends LitElement {
  @property({ type: String, reflect: true })
  variant = "default";

  @property({ type: Boolean, reflect: true })
  disabled = false;

  @property({ type: String, reflect: true })
  size = "default";

  @property({ type: String, reflect: true })
  href = undefined;

  @property({ type: String, reflect: true })
  type = "button";

  @property({ type: Boolean, reflect: true })
  loading = false;

  render() {
    return html`
      <sl-button
        exportparts="base"
        variant=${this.variant}
        href=${this.href}
        type="${this.type}"
        .loading=${this.loading}
        .disabled=${this.disabled || this.loading}
      >
        <slot name="prefix"></slot>
        <slot></slot>
        <slot name="suffix"></slot>
      </sl-button>
    `;
  }

  static styles = css`
    :host {
      display: block;
    }

    :host([variant="text"]) sl-button {
      ${shoelace.neutralColor}:var(--Components-Button-Component-defaultColor, #27272a);
      ${shoelace.effectColor}: var(--Components-Button-Component-defaultColor, #27272a);
      ${shoelace.normalBg}: var(--Components-Button-Component-defaultBg, #f4f4f5);
      ${shoelace.hoverBg}: var(--Components-Button-Component-textHoverBg, rgba(0, 0, 0, 0.06));
      ${shoelace.activeBg}: var(--Components-Button-Global-colorBgTextActive, rgba(0, 0, 0, 0.15));
    }

    :host([variant="primary"]) sl-button {
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
    }

    :host([variant="primary"]) sl-button::part(label) {
      color: var(--Components-Button-Component-primaryColor, #ffffff);
    }

    :host([variant="neutral"]) sl-button {
      ${shoelace.neutralColor}: var(----base-gray-10, #18181B);
    }

    :host([variant="default"]) sl-button {
      ${shoelace.neutralColor}: var(--Components-Button-Component-defaultColor, #27272A);
      ${shoelace.effectColor}: var(--Components-Button-Component-defaultColor, #27272A);
      ${shoelace.normalBg}: var(--Components-Button-Component-defaultBg, #F4F4F5);
      ${shoelace.hoverBg}: var(--Components-Button-Component-defaultHoverBg, #FFF);
      ${shoelace.activeBg}:var(--Components-Button-Component-defaultBg, #F4F4F5);
      border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Components-Button-Component-defaultHoverBorderColor, #60a5fa);
      border-radius: var(--Components-Button-Global-borderRadius, 6px);
    }

    :host([variant="default"]) sl-button::part(label) {
      color: var(--Components-Button-Component-defaultColor, #27272a);
    }

    :host([size="default"]) sl-button {
      ${shoelace.height}: 48px;
      ${shoelace.borderRadius}: 6px;
    }

    :host([size="small"]) sl-button {
      ${shoelace.height}: 32px;
      ${shoelace.borderRadius}: 4px;
    }

    :host([size="large"]) sl-button {
      ${shoelace.height}: 48px;
      ${shoelace.borderRadius}: 8px;
    }

    sl-button {
      --sl-input-font-family: "SF Pro Display";
      --sl-font-weight-semibold: 500;
      --sl-input-font-size-medium: 16px;
      --sl-input-border-width: 0px;
      display: block;
    }

    sl-button::part(label) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    ${type("sm")} sl-button::part(label) {
      ${font("Base/Normal")};
    }

    ${type("lg")} sl-button::part(label) {
      ${font("LG/Normal")};
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-button": FixButton;
  }
}

export const buttonCustomize = () => {
  return css`
    sl-button {
      --sl-input-border-radius-medium: var(--border-radius, 0px);
    }

    sl-button::part(label) {
      --sl-spacing-small: var(--spacing, 8px);
    }

    sl-button::part(base) {
      --sl-color-neutral-0: var(--color-bg, #fff);
      --sl-color-neutral-300: var(--color-border, #fff);
      --sl-color-neutral-700: var(--color, #27272a);
    }

    sl-button:hover::part(base) {
      --sl-color-primary-50: var(--color-bg-hover, var(--color-bg, #fff));
      --sl-color-primary-300: var(--color-border-hover, var(--color-border, #fff));
      --sl-color-primary-700: var(--color-hover, var(--color, #27272a));
    }

    sl-button:active::part(base) {
      --sl-color-primary-100: var(--color-bg-active, var(--color-bg, #fff));
      --sl-color-primary-400: var(--color-border-active, var(--color-border, #fff));
      --sl-color-primary-700: var(--color-active, var(--color, #27272a));
    }

    sl-button::part(base) {
      width: var(--width, auto);
      height: var(--height, auto);
    }

    sl-button::part(label) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;
};

export const buttonFont = (modifer: string, fontval: Parameters<typeof font>[0]) => {
  return unsafeCSS(`
      sl-button${modifer}::part(label) {
          ${font(fontval)};
      }
  `);
};
