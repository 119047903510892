import { customElement, property, state } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import "@/generated/css/variables.css";
import { type } from "@/util/layout";
import rightSvg from "@/assets/right-outlined.svg";
import downSvg from "@/assets/down-outlined.svg";
import "@/elements/mobile/fix-button";
import "@/elements/mobile/fix-button";
import "../layout/page-section-layout";
import { B2cBrand, CarBrandsListDocument } from "@/generated/graphql/b2c";
import { consume } from "@lit/context";
import { type Main, mainContext } from "@/context/main";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("car-brands-list")
@localized()
export class CarBrandsList extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Boolean }) loading = true;

  @state()
  iShowButton = false;

  brands: B2cBrand[] = [];

  smMaxItemsCount = 10;
  lgMaxItemsCount = 15;

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  connectedCallback() {
    super.connectedCallback();
    this.fetchBrands();
  }

  render() {
    if (!this.list.length) return;

    return html`
      <page-section-layout type=${this.type} sectionTitle="${t("22462yo1x6yur1ek.car-brands-title", "Buy car parts from the most popular car brands")}">
        <div class="item-list">
          ${this.list.map(
      (item) => html`
              <fix-link class="item" href="${item.url}">
                <span class="item-inner">
                  <span class="item-text">${item.name}</span>
                  <sl-icon class="icon" slot="prefix" src=${rightSvg}></sl-icon>
                </span>
              </fix-link>
            `,
    )}
        </div>
        ${this.renderMore()}
      </page-section-layout>
    `;
  }

  renderMore() {
    if (this.iShowButton) {
      return html`<div class="controls">
        <sl-button class="button-more" @click=${this.showAll}>
          ${t("76vwwhyjifi741oi.more-car-brands", "More car brands")}
          <sl-icon class="icon" slot="suffix" src=${downSvg}></sl-icon>
        </sl-button>
      </div>`;
    }
  }

  private showAll() {
    this.iShowButton = false;
    this.requestUpdate();
  }

  fetchBrands = async () => {
    const response = await this.mainContext?.api.client.query({
      query: CarBrandsListDocument,
      variables: {},
    });

    // @ts-ignore
    this.brands = response.data.CarBrandsList;
    this.loading = false;
    this.iShowButton = (this.type === "sm" && this.brands.length > this.smMaxItemsCount) || (this.type === "lg" && this.brands.length > this.lgMaxItemsCount);

    this.requestUpdate();
  };

  get list() {
    const brands = this.brands.map((brand) => ({
      ...brand,
      url: Url.to("dynamic-route", { dynamicPath: [brand.slug] }),
    }));

    if (this.type === "sm" && this.iShowButton) {
      return brands.slice(0, this.smMaxItemsCount);
    }

    if (this.type === "lg" && this.iShowButton) {
      return brands.slice(0, this.lgMaxItemsCount);
    }

    return brands;
  }

  static styles = css`
    ::part(base) {
      border-color: var(--neutral-text-color-text-secondary, #737373);
      color: var(----base-gray-10, #18181b);
      ${font("Base/Normal")};
      line-height: var(--size-height-control-height, 40px);
    }

    .item-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: var(--spacing-paddingXS, 8px);
    }

    ${type("sm")} .item-list {
      grid-template-columns: repeat(2, 1fr);
    }

    .item {
      align-items: center;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--car-brands-color-border, #d9d9d9);
      gap: var(--spacing-paddingXS, 8px);
    }

    .item:hover {
      border-color: var(--car-brands-color-border-hover, #3b82f6);
    }

    .item:active {
      border-color: var(--car-brands-color-border-active, #2563eb);
    }

    .item-text {
      display: inline-block;
      align-items: center;
      vertical-align: middle;
      color: var(--neutral-text-color-text, #27272a);
      ${font("Base/Normal")};
      margin-right: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-inner {
      display: flex;
      align-items: center;
      padding: 0 16px;
      min-height: var(--size-base-size-xxl, 48px);
    }

    .icon {
      width: 8px;
      height: 12px;
    }

    .button-more {
      display: inline-flex;
      align-self: center;
      align-items: center;
      align-content: center;
      margin-top: 24px;
    }

    ${type("sm")} .button-more {
      margin-top: 16px;
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
}
