import { property } from "lit/decorators.js";
import { LitElement, css } from "lit";
import { type } from "@/util/layout";
import { font } from "@/util/fonts";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

export abstract class ProductButton extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Boolean })
  disabled = false;

  static styles = css`
    :host {
      width: 100%;
    }

    :host(.state-hover) sl-button {
      ${shoelace.normalBg}: var(--components-button-global-color-primary-hover, #60a5fa);
    }

    :host(.state-active) sl-button {
      ${shoelace.normalBg}: var(--components-button-global-color-primary-active, #2563eb);
    }

    sl-button {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
      display: flex;
      min-width: 100%;
    }

    sl-button::part(base) {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }

    sl-button::part(label) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    ${type("sm")} sl-button::part(label) {
      ${font("Base/Normal")};
    }

    ${type("lg")} sl-button::part(label) {
      ${font("LG/Normal")};
    }

    ${type("sm")} sl-button {
      --sl-input-height-medium: 40px;
      ${shoelace.borderRadius}: 6px;
    }

    ${type("lg")} sl-button {
      --sl-input-height-medium: 48px;
      ${shoelace.borderRadius}: 8px;
    }

    sl-icon {
      font-size: 16px;
    }
  `;
}
