import { customElement, state } from "lit/decorators.js";
import { html, LitElement } from "lit";
import { LayoutParameters, LayoutParametersContext } from "@/pages/product-page/cont.ts";
import { consume } from "@lit/context";
import "./fix-dialog-selector/fix-dialog-selector";
import "./fix-dropdown/fix-dropdown";
import { Countries, CountriesContext } from "@/services/countries.ts";
import { when } from "lit/directives/when.js";
import "@/elements/mobile/dialog";
import { Parameters, ParametersContext } from "@/services/parameters";

@customElement("fix-country")
export class FixCountry extends LitElement {
  @consume({ context: LayoutParametersContext, subscribe: true })
  layoutParameters!: LayoutParameters;

  @consume({ context: CountriesContext, subscribe: true })
  public countries!: Countries;

  @consume({ context: ParametersContext, subscribe: true })
  public parameters!: Parameters;

  @state()
  dialogOpen = false;

  get options() {
    const value = this.parameters.getVisibleCountry();
    const list = this.countries.getChannelCountries() ?? [];

    return list.map(item => ({
      label: item.name,
      value: item.code,
      selected: item.code === value,
    }));
  }

  get currentCountry() {
    return this.countries.getCurrentCountry();
  }

  onCountryChange = (countryCode: string) => {
   // this.countries.setCurrentCountryCode(countryCode);
    this.parameters.changeParameter("country", countryCode);
    this.parameters.applyParameters();
    if (this.dialogOpen) {
      this.hideDialog();
    }
  };

  renderLgView() {
    return html` <fix-dropdown .controlled=${true} .items=${this.options} .onSelect=${this.onCountryChange} /></fix-dropdown>`;
  }

  renderSmView() {
    return html`
      <item-button
        .title=${"Country"}
        .sub=${this.currentCountry?.name}
        .schema=${"line"}
        @click=${this.showDialog}
    ></item-button>
    ${when(this.dialogOpen, () => this.renderSmDialog())}
  `;
  }

  renderSmDialog() {
    return html`
      <dialog-mobile .label=${"Ship to"} @dialogClose=${this.hideDialog}>
        <fix-dialog-selector .items=${this.options} .onSelect=${this.onCountryChange}></fix-dialog-selector>
      </dialog-mobile>
  `;
  }

  render() {
    return this.layoutParameters.mode === "mobile" ? this.renderSmView() : this.renderLgView();
  }

  showDialog = () => {
    this.dialogOpen = true;
  }

  hideDialog = () => {
    this.dialogOpen = false;
  }
}
