import { registerIconLibrary } from "@shoelace-style/shoelace";
import systemLibrary from "@shoelace-style/shoelace/dist/components/icon/library.system";
import check from "@/assets/check.svg";
import indeterminate from "@/assets/indeterminate.svg";
import closetag from "@/assets/closetag.svg";

const flags = import.meta.glob("/node_modules/flag-icons/flags/4x3/*.svg", {
  query: "?url",
  import: "default",
  eager: true,
}) as Record<string, string>;

const overwrite = {
  check: check,
  "x-lg": closetag,
  indeterminate: indeterminate,
} as Record<string, string>;

registerIconLibrary("system", {
  resolver: (name) => {
    return overwrite[name] ?? systemLibrary.resolver(name);
  },
});

const getImportUrl = (name: string, list: Record<string, string>) => {
  return list[name];
};

const getFlagsUrl = (name: string) => {
  return getImportUrl(`/node_modules/flag-icons/flags/4x3/${name}.svg`, flags);
};

registerIconLibrary("fix-icons-flags", {
  resolver: getFlagsUrl,
});
