import { css, html, LitElement, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import right from "@/assets/right.svg";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { font } from "@/util/fonts";

const columnMode = css`
  .item.column .center {
    grid-template-areas:
      "title count"
      "sub sub";
    grid-template-columns: max-content max-content;
    align-items: center;
    gap: 0px 8px;
  }

  .item.column {
    --title-color: var(--Components-Typography-Global-colorText, #27272a);
    --sub-color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
  }

  .item.column:hover {
    --title-color: var(--Brand-Link-colorLink, #3b82f6);
    --sub-color: var(--Components-Typography-Global-colorText, #27272a);
  }

  .item.column:active {
    --title-color: var(--Brand-Link-colorLinkActive, #2563eb);
    --sub-color: var(--Components-Typography-Global-colorText, #27272a);
  }
`;

const lineMode = css`
  .item.line .center {
    grid-template-areas: "title sub count";
    grid-template-columns: max-content max-content;
    gap: 0px 4px;
  }

  .item.line {
    --title-color: var(--Components-Typography-Global-colorText, #27272a);
    --sub-color: var(--Neutral-Text-colorTextSecondary, #737373);
  }

  .item.line:hover {
    --title-color: var(--Brand-Link-colorLink, #3b82f6);
    --sub-color: var(--Neutral-Text-colorTextSecondary, #737373);
  }

  .item.line:active {
    --title-color: var(--Brand-Link-colorLinkActive, #2563eb);
    --sub-color: var(--Neutral-Text-colorTextSecondary, #737373);
  }
`;

@customElement("item-button")
export class ItemButton extends LitElement {
  @property()
  action: "button" | "link" | "link-router-ignore" = "button";

  @property()
  href: null | string = null;

  @property()
  icon: null | string = null;

  @property()
  title = "";

  @property()
  sub: null | string = null;

  @property()
  count?: number;

  @property()
  schema: "line" | "column" = "line";

  @query(".item")
  item!: HTMLElement;

  render() {
    const content = html`
      <div class="left">${when(this.iconEnabled, this.renderIcon)}</div>
      <div class="center">${this.renderTitle()} ${when(this.subEnabled, this.renderSub)} ${when(this.countEnabled, this.renderCount)}</div>
      <div class="right">${this.renderArrow()}</div>
    `;

    return this.renderTag(content);
  }

  get iconEnabled() {
    return typeof this.icon === "string";
  }

  get countEnabled() {
    return typeof this.count === "number";
  }

  get subEnabled() {
    return typeof this.sub === "string" && this.sub.length > 0;
  }

  renderTag(content: unknown) {
    const classNames = classMap({
      icon: Boolean(this.icon),
      item: true,
      [this.schema]: true,
    });

    switch (this.action) {
      case "button": {
        return html`<button class=${classNames} @click=${this.handleClick} @mouseenter=${this.handleHover}>${content}</button>`;
      }
      case "link": {
        return html`<a href=${this.href ?? "#"} class=${classNames} @mouseenter=${this.handleHover}>${content}</a>`;
      }
      case "link-router-ignore": {
        return html`<a href=${this.href ?? "#"} class=${classNames} router-ignore="true" @mouseenter=${this.handleHover}>${content}</a>`;
      }
    }
  }

  handleClick = (event: MouseEvent) => {
    if (this.action === "link" && this.href) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      this.dispatchEvent(
        new CustomEvent("click", {
          bubbles: true,
          composed: true,
          detail: { target: this.item },
        }),
      );
    }
  };

  handleHover = (event: MouseEvent) => {
    event.stopPropagation();
    this.dispatchEvent(
      new CustomEvent("hover", {
        bubbles: true,
        composed: true,
        detail: { target: this.item },
      }),
    );
  };

  renderCount = () => {
    return html`<label class="count">${this.count}</label>`;
  };

  renderTitle = () => {
    return html`<label class="title">${this.title}</label>`;
  };

  renderSub = () => {
    return html`<label class="sub">${this.sub}</label>`;
  };

  renderIcon = () => {
    if (!this.icon || this.icon === "empty") {
      return nothing;
    }

    return html`<sl-icon class="icon" src=${this.icon}></sl-icon>`;
  };

  renderArrow = () => {
    return html`<sl-icon class="arrow" src=${right}></sl-icon>`;
  };

  static styles = css`
    a {
      text-decoration: none;
    }
    
    .item {
      display: flex;
      align-items: center;
      padding: var(--Space-Padding-paddingXS, 8px) 0px;
      user-select: none;
      border: none;
      background-color: transparent;
      width: 100%;
      cursor: pointer;
    }

    .center {
      display: grid;
      flex-grow: 1;
    }

    .title {
      grid-area: title;
      ${font("Base/Normal")};
    }

    .sub {
      grid-area: sub;
    }

    .item.line .sub {
      ${font("Base/Normal")};
    }

    .item.column .sub {
      ${font("SM/Normal")};
    }

    .count {
      grid-area: count;
    }

    .left {
      display: none;
    }

    .item.icon .left {
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    sl-icon {
      font-size: 24px;
    }

    .count {
      ${font("SM/Regular")};
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--Components-Button-Global-colorSecondary, #f97316);
      color: var(--Neutral-colorWhite, #fff);
      line-height: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .icon {
      color: var(--icon-color);
    }

    .arrow {
      color: var(--arrow-color);
    }

    .title {
      color: var(--title-color);
    }

    .sub {
      color: var(--sub-color);
    }

    .item {
      --icon-color: var(--Components-Typography-Global-colorText, #27272a);
      --arrow-color: var(--Components-Typography-Global-colorText, #27272a);
    }

    .item:hover {
      --arrow-color: var(--Brand-Link-colorLink, #3b82f6);
    }

    .item.active {
      --arrow-color: var(--Brand-Link-colorLinkActive, #2563eb);
    }

    .title,
    .sub {
      text-align: left;
    }

    .icon,
    .arrow,
    .title,
    .sub {
      transition: all 150ms ease;
    }

    ${columnMode}
    ${lineMode}
  `;
}
