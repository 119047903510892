import { customElement } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import "@/pages/product-page/extracted/fix-brand";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { font } from "@/util/fonts.ts";
import { localized } from "@/decorators/localized";

@customElement("cart-head-desktop")
@localized()
export class CartHeadDesktop extends LitElement {
  render() {
    return html`
      <div class="head">
        <div class="back-button col-2">
          <fix-link class="continue-shopping-link" href="${Url.to("home-page-with-lang")}" type="md">
            <sl-icon src=${arrowOutlined}></sl-icon>
            ${t("k0q7l2w2q1856v4o.continue-shopping", "Continue shopping")}
          </fix-link>
        </div>
        <div class="brand col-8">
          <fix-brand type="lg"></fix-brand>
        </div>
      </div>
    `;
  }
  static styles = css`
    .continue-shopping-link {
       ${font('SM/Normal')}
    }
      
    .continue-shopping-link sl-icon {
       margin-right: 4px;
    }
      
    .head {
      background: #fff;
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 40px;
      border-bottom: 1px #d9d9d9 solid;
    }
      
    .col-2 {
      width: 16.6%;
    }
      
    .col-8 {
      width: 66.6%;
    }
      
    .brand {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
}
