import { LitElement, ReactiveController } from "lit";
import { Ref, createRef, ref } from "lit/directives/ref.js";

export class VariantSwitcher<T extends LitElement> implements ReactiveController {
  child: Ref<T> = createRef<T>();

  constructor(private host: T) {
    host.addController(this as ReactiveController);
  }

  hostUpdated(): void {
    this.child.value?.requestUpdate();
  }

  props() {
    return {
      ref: ref(this.child),
      base: this.host,
    };
  }
}
