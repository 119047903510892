import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import alipay from "@/assets/footer/logos/alipay.svg";
import itelia from "@/assets/footer/logos/itelia.svg";
import maestro from "@/assets/footer/logos/maestro.svg";
import master from "@/assets/footer/logos/master.svg";
import paypal from "@/assets/footer/logos/paypal.svg";
import visa from "@/assets/footer/logos/visa.svg";
import raben from "@/assets/footer/logos/raben.svg";
import tnt from "@/assets/footer/logos/tnt.svg";
import sepa from "@/assets/footer/logos/sepa.svg";
import fedex from "@/assets/footer/logos/fedex.svg";
import dhl from "@/assets/footer/logos/dhl.svg";

import store from "@/assets/footer/store.svg";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("page-footer")
@localized()
export class PageFooter extends LitElement {
  @property({ type: Number })
  inCartQuantity = 0;

  render() {
    return html`
      <div>
        <div class="logos">
          <sl-icon src=${visa} class="logo-item"></sl-icon>
          <sl-icon src=${master} class="logo-item"></sl-icon>
          <sl-icon src=${alipay} class="logo-item"></sl-icon>
          <sl-icon src=${fedex} class="logo-item"></sl-icon>
          <sl-icon src=${dhl} class="logo-item"></sl-icon>
          <sl-icon src=${paypal} class="logo-item"></sl-icon>
          <sl-icon src=${tnt} class="logo-item"></sl-icon>
        </div>
        <h4>Store <sl-icon src=${store} class="store-icon"></sl-icon></h4>
        <span class="info">Quality materials, good designs, professional craftsmanship and sustainability.</span>
        <sl-details class="custom-details" summary="${t("g78tgk7885234se9.footer-customer-service", "Customer service")}"> TBD... </sl-details>
        <sl-details class="custom-details" summary="${t("vn1s7g08z0gzut53.footer-information", "Information")}"> TBD... </sl-details>
        <sl-details class="custom-details" summary="${t("9d7866wn3k1o4w68.footer-contacts", "Contacts")}"> TBD... </sl-details>
      </div>
    `;
  }

  static styles = css`
    :host {
      font-family: SF Pro Display;
      padding: var(--sl-spacing-medium);
    }

    h4 {
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      display: flex;
      align-items: center;
    }

    h4 .store-icon {
      font-size: 32px;
      margin-left: 5px;
    }

    .logos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--space-margin-margin, 10px);
    }

    .logo-item {
      font-size: 4rem;
      flex: 1 1 auto;
      padding: 0px;
      text-align: center;
      min-width: 60px;
      max-width: 70px;
    }

    .info {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: var(--Neutral-Text-colorTextSecondary, #737373);
    }

    .custom-details::part(base) {
      font-family: SF Pro Display;
      border: none;
      border-bottom: 1px solid var(--Footer-colorDevider, #d9d9d9);
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }

    .custom-details::part(header) {
      padding: var(--sl-spacing-medium) 0px;
    }

    .custom-details::part(content) {
      font-weight: 400;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "page-footer": PageFooter;
  }
}
