import { customElement, property } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("vat-notify")
@localized()
export class VatNotify extends LitElement {
  @consume({ context: customerChannelContext, subscribe: true })
  @property({ attribute: false })
  public customerContext!: CustomerChannel;

  @property({ attribute: true, reflect: true, type: Boolean })
  visible = false;

  render() {
    const percent = this.customerContext?.vatCoefficient % 100;
    if (percent) {
      return html` (${t("9hkouimd77ty1706.vat-include", "Include {{percent}}% VAT", {
        percent: percent,
      })})`;
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.visible = Boolean(this.customerContext?.vatCoefficient % 100);
  }

  static styles = css`
    :host {
      display: none;
      color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
      ${font("SM/Normal")};
    }

    :host([visible]) {
      display: flex;
    }
  `;
}
