import { customElement } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { buttonCustomize, buttonFont } from "@/elements/mobile/fix-button";
import filter from "@/assets/filter.svg";
import sort from "@/assets/sort.svg";

class ProductButton extends LitElement {
  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "Base/Normal")}

    sl-button {
      --border-radius: 4px;
      --width: auto;
      --height: 32px;
      --sl-spacing-medium: 16px;
      --sl-input-height-medium: 32px;
      --sl-spacing-small: 16px;
    }

    sl-button {
      --color: var(--Components-Button-Component-defaultColor, #27272a);
      --color-bg: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-border: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-hover: var(--Components-Button-Component-defaultHoverColor, #60a5fa);
      --color-bg-hover: var(--Components-Button-Component-defaultHoverBg, #fff);
      --color-border-hover: var(--Components-Button-Component-defaultHoverColor, #60a5fa);
      --color-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
      --color-bg-active: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
    }

    sl-icon {
      color: #27272a;
      font-size: 16px;
    }
  `;
}

@customElement("filters-button-element")
export class FiltersButtonElement extends ProductButton {
  render() {
    return html`<sl-button @click=${this.emitClick}><sl-icon slot="prefix" src=${filter}></sl-icon> Filter</sl-button>`;
  }

  emitClick = () => {
    this.dispatchEvent(new CustomEvent("filter.open", { bubbles: true, composed: true }));
  };
}

@customElement("sorters-button-element")
export class SortersButtonElement extends ProductButton {
  render() {
    return html`<sl-button @click=${this.emitClick}><sl-icon slot="prefix" src=${sort}></sl-icon> Sort by</sl-button>`;
  }

  emitClick = () => {
    this.dispatchEvent(new CustomEvent("sort.open", { bubbles: true, composed: true }));
  };
}
