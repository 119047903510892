import { SlDrawer } from "@shoelace-style/shoelace";
import { css, CSSResultGroup, html, LitElement, PropertyValues } from "lit";
import { customElement, property } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";
import close from "@/assets/close.svg";

@customElement("drawer-panel")
export class DrawerPanel extends LitElement {
  @property({ type: String })
  state: "hidden" | "visible" = "hidden";

  drawerRef = createRef<SlDrawer>();

  render() {
    return html`
      <sl-drawer class="drawer" placement="start" ${ref(this.drawerRef)} .noHeader=${true} />
        <div class="box">
          <slot></slot>
        </div>
        <div class="side">
          <sl-button class="close" variant="text"  @click=${this.hide}>
            <sl-icon class="icon" src=${close}></sl-icon>
          </sl-button>
        </div>
      </sl-drawer>
    `;
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this.show();
  }

  show = () => {
    this.drawerRef.value?.show();
  };

  hide = async () => {
    await this.drawerRef.value?.hide();
    this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true }));
  };

  static styles = css`
    .drawer {
      --size: 100vw;
      --body-spacing: 0px;
      --sl-overlay-background-color: transparent;
      --sl-panel-background-color: transparent;
      --sl-shadow-x-large: none;
      --sl-spacing-large: 0px;
    }

    .drawer::part(body) {
      display: flex;
    }

    .box {
      width: 268px;
      padding: 16px 24px;
      background: #fff;
      height: fit-content;
      min-height: 100%;
    }

    .side {
      display: flex;
      background: #0e0e0e;
      opacity: 0.75;
      transition: opacity 0.3s ease;
      color: white;
      flex-grow: 1;
      justify-content: flex-start;
      position: sticky;
      top: 0;
    }

    .close {
      --sl-input-border-radius-medium: 0px;
      --sl-input-height-medium: 0px;
      --sl-input-border-width: 0px;
      --sl-button-font-size-medium: 16px;
      height: fit-content;
    }

    .close::part(base) {
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }

    .close::part(base) {
      color: #d4d4d8;
    }

    .close:hover::part(base) {
      color: #71717a;
    }

    .close:active::part(base) {
      color: #52525b;
    }
  `;
}
