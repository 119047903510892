import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import "../fix-brand";
import "./item-button";
import { font } from "@/util/fonts";
import { choose } from "lit/directives/choose.js";
import "./subcategories-screen";
import "./main-screen";
import { layoutSmCss } from "./layout-sm";
import "./layout-sm";
import { createRef, ref } from "lit/directives/ref.js";
import { DrawerPanel } from "./drawer-panel";
import { Categories, CategoriesContext } from "@/services/categories";
import { consume } from "@lit/context";

@customElement("menu-sm")
export class MenuSm extends LitElement {
  @state()
  step: "main" | "subcategory" | "account" | "garage" = "main";

  @state()
  categoryId: null | string = null

  panelRef = createRef<DrawerPanel>();

  @consume({ context: CategoriesContext, subscribe: true })
  categories!: Categories;

  render() {
    return html` <drawer-panel ${ref(this.panelRef)}> ${this.renderScreen()} </drawer-panel> `;
  }

  renderScreen() {
    return choose(this.step, [
      ["main", this.renderMainScreen],
      ["subcategory", this.renderSubcategoryScreen],
    ]);
  }

  get logged() {
    return true;
  }

  onCategoryClick = (key: string) => {
    if (this.step === "main") {
      this.step = "subcategory";
    } else {
      this.step = "main";
    }
  };

  toRootCategory = (event: CustomEvent<{ key: string }>) => {
    this.step = "subcategory";
    this.categoryId = event.detail.key;
  };

  toSubcategory = (key: string) => {
    this.panelRef.value?.hide();
  };

  onBackClick = () => {
    this.step = "main";
  };

  renderSubcategoryScreen = () => {
    const items = this.categories.getChilds(this.categoryId!);
    return html`<subcategories-screen .items=${items} @toCategory=${this.toSubcategory} @back=${this.onBackClick}></subcategories-screen>`;
  };

  renderMainScreen = () => {
    const items = this.categories.findTopLevelCategories().map(item => this.categories.getItem(item.id));
    return html`<main-screen .items=${items} @toCategory=${this.toRootCategory}></main-screen>`;
  };
}
