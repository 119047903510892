import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";
import "./placeholder";

@customElement("fix-image")
export class FixImage extends LitElement {
  @property()
  src!: string;

  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ attribute: true, reflect: true, type: Boolean })
  content = false;

  imgref = createRef<HTMLImageElement>();

  render() {
    const placeholder = this.content ? nothing : this.placeholder();
    const image = this.src ? this.image() : nothing;

    return html`${image}${placeholder}`;
  }

  placeholder() {
    return html`<fix-image-placeholder class="placeholder" type=${this.type}></fix-image-placeholder>`;
  }

  image() {
    return html`<img class="image" ${ref(this.imgref)} src=${this.src} @load=${this.makeVisible} />`;
  }

  makeVisible = () => {
    this.content = true;
  };

  static styles = css`
    :host {
      position: relative;
      width: var(--size, 100%);
      padding-bottom: calc(var(--size, 100%) / var(--aspect, 1));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image,
    .placeholder {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      user-select: none;
    }

    .image {
      visibility: hidden;
      max-width: 100%;
      max-height: 100%;
    }

    .placeholder {
      position: absolute;
    }

    :host([content]) .image {
      visibility: visible;
    }
  `;
}
