import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/mobile/page-header";
import "@/elements/mobile/page-footer";
import "@/elements/mobile/my-garage";
import "@/elements/mobile/fix-breadcrumb";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/product-mpn";
import "@/elements/mobile/fix-rating";
import "@/elements/mobile/fix-carousel";
import "@/elements/mobile/product-variations";
import "@/elements/mobile/product-dispatch";
import "@/elements/mobile/price-value";
import "@/elements/mobile/fix-badge";
import "@/elements/mobile/fix-quantity";
import "@/elements/mobile/fix-button";

@customElement("navigation-layout")
export class NavigationLayout extends LitElement {
  render() {
    const { breadcrumbs } = this;

    return html`
      <page-header class="page header selfpadding" .searchValue=${this.searchVal}></page-header>
      <fix-breadcrumb class="page breadcrumb" .path=${breadcrumbs}></fix-breadcrumb>
      <slot></slot>
      <div class="delimiter"></div>
      <page-footer></page-footer>
    `;
  }

  breadcrumbs = [
    { label: "Home", url: "/" },
    { label: "Service parts", url: "products" },
    { label: "Brake disk", url: "products/test" },
    { label: "Ford" },
    { label: "Focus" },
    { label: "2015" },
  ];

  @property({ type: Object }) location = {} as Location;

  @state()
  cart = {
    quantity: 0 as number,
  };

  get searchVal() {
    const urlParams = new URLSearchParams(this.location.search);
    return urlParams.get("q") || "";
  }

  static styles = css`
    :host {
      --layout-padding-h: 8px;
      --layout-padding-v: 4px;
    }

    :host > *:not(.selfpadding) {
      display: block;
      margin-left: var(--layout-padding-h);
      margin-right: var(--layout-padding-h);
    }

    .page.garage + *,
    .page.breadcrumb + * {
      margin-top: 8px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "navigation-layout": NavigationLayout;
  }
}
