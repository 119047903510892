import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import type { HomePage } from ".";
import { Url } from "@/helpers/url";
import banner_mahle from "@/assets/banner_mahle.webp";
import banner_febi from "@/assets/banner_febi.webp";

@customElement("home-page-desktop")
export class HomePageDesktop extends LitElement {
  @property({ type: Object })
  base: HomePage = null!;

  render() {
    const base = this.base;
    return html`
      <catalog-layout type="lg">
        <div class="offsets offset-y">
        <page-section-layout type=${'lg'}>
        ${base.renderCategoriesPreview("lg")}
        </page-section-layout>
          ${base.renderTopCategories("lg")} ${base.renderCarBrands("lg")}
          <div class="container hidden">
            <div class="left">
              ${base.renderMainBanner("lg")}
            </div>
            <div class="right">
              <div class="top">
                <a href="${Url.to("dynamic-route", { dynamicPath: ['mahle'] })}">
                  <img src=${banner_mahle} alt="Mahle" />
                </a>
              </div>
              <div class="bottom">
                <a href="${Url.to("dynamic-route", { dynamicPath: ['febi'] })}">
                  <img src=${banner_febi} alt="Febi" />
                </a>
              </div>
            </div>
          </div>
          ${base.renderFeaturedBrands("lg")} ${base.renderBenefitsList("lg")}
        </div>
      </catalog-layout>
    `;
  }

  static styles = css`
      .hidden {
        overflow: hidden;
      }
      
    .offsets {
      margin: 0 var(--space-margin-margin-xxl, 48px);
      margin-bottom: 8px;
    }

    .offset-y {
      margin-bottom: 32px;
    }

    .container {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
    }

    .container .left {
      flex: 1;
      height: 100%;
    }

    .container .right {
      width: 668px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .container .right .top,
    .container .right .bottom {
      flex: 1;
    }
  `;
}
