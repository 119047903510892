import { LitElement, html, css, PropertyValues } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import plus from "@/assets/plus.svg";
import minus from "@/assets/minus.svg";
import { classMap } from "lit/directives/class-map.js";
import { Ref, createRef, ref } from "lit/directives/ref.js";
import { type } from "@/util/layout";
import { font } from "@/util/fonts";
import { map } from "lit/directives/map.js";

export type SituableForDatasetEntry = {
  brand: { id: string; name: string };
  model: { name: string; id: string };
  modification: { id: string; name: string };
  yearFrom: string;
  yearTo: string;
};

export type SituableForDataset = {
  list: Array<SituableForDatasetEntry>;
};

type DataTree = Record<
  string,
  {
    id: string;
    name: string;
    models: Record<
      string,
      {
        id: string;
        name: string;
        modifications: Record<
          string,
          {
            id: string;
            name: string;
            yearFrom: string;
            yearTo: string;
          }
        >;
      }
    >;
  }
>;

type DataList = Array<{
  id: string;
  name: string;
  models: Array<{
    id: string;
    name: string;
    modifications: Array<{
      id: string;
      name: string;
      yearFrom: string;
      yearTo: string;
    }>;
  }>;
}>;

@customElement("situable-for")
export class SituableFor extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property()
  data = [] as Array<SituableForDatasetEntry>;

  refs = {} as Record<string, Ref<HTMLElement>>;

  @state()
  toggled = [] as Array<string>;

  get collapsable() {
    return true;
  }

  render() {
    const groups = this.createGroupsList();

    return html` ${groups.map((brand) => this.renderSegment(brand))} `;
  }

  renderSegment(brand: DataList[number]) {
    const models = brand.models;
    const opened = this.toggled.includes(brand.id);
    const button = html`<sl-icon class="button" src=${opened ? minus : plus}></sl-icon>`;
    const name = html`<div class="name">${brand.name}</div>`;
    const left = html`<div class="left">${button}${name}</div>`;
    const className = classMap({
      ["container"]: true,
      ["closed"]: !opened,
      ["collapsable"]: this.collapsable,
    });
    const segmentRef = this.defineRef(`${brand.id}`);

    return html`
      <div class=${className}>
        <div ${ref(segmentRef)} class="segment" @click=${() => this.toggle(brand.id)}>${left}${models.map((model) => this.renderModel(model))}</div>
      </div>
    `;
  }

  renderModel(value: DataList[number]["models"][number]) {
    return html`<div class="model">
      <div class="name">${value.name}</div>
      <div class="modifications">
        ${map(
          value.modifications,
          (modification) =>
            html` <div class="modification">
              <div class="name">${modification.name}</div>
              <div class="year">(${modification.yearFrom}-${modification.yearTo})</div>
            </div>`,
        )}
      </div>
    </div>`;
  }

  defineRef(id: string) {
    this.refs[id] = this.refs[id] ?? createRef();
    return this.refs[id];
  }

  toggle(id: string) {
    if (this.toggled.includes(id)) {
      this.toggled = this.toggled.filter((item) => item !== id);
    } else {
      this.toggled.push(id);
    }
    this.requestUpdate();
  }

  measure() {
    for (const [id, ref] of Object.entries(this.refs)) {
      const element = ref.value;

      if (element) {
        const height = element.getBoundingClientRect().height;
        element.parentElement!.style.setProperty("--height", height + "px");
      }
    }
  }

  updated(props: PropertyValues) {
    super.updated(props);
    if (this.collapsable) {
      this.measure();
    }
  }

  createGroups() {
    const brands = {} as DataTree;

    for (const item of this.data) {
      brands[item.brand.id] ??= {
        id: item.brand.id,
        name: item.brand.name,
        models: {},
      };
      brands[item.brand.id].models[item.model.id] ??= {
        id: item.model.id,
        name: item.model.name,
        modifications: {},
      };
      brands[item.brand.id].models[item.model.id]["modifications"][item.modification.id] ??= {
        id: item.modification.id,
        name: item.modification.name,
        yearFrom: item.yearFrom,
        yearTo: item.yearTo,
      };
    }

    return brands;
  }

  createGroupsList(): DataList {
    const brands = this.createGroups();

    const result = Object.values(brands).map((brand) => ({
      ...brand,
      models: Object.values(brand.models).map((model) => ({
        ...model,
        modifications: Object.values(model.modifications),
      })),
    }));

    for (const brand of result) {
      brand.models.sort((a, b) => a.name.localeCompare(b.name));
      for (const model of brand.models) {
        model.modifications.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  }

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
    }

    .segment {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 8px 8px;
      cursor: pointer;
    }

    .segment .left {
      grid-row: 1;
      grid-column: 1;
    }

    .segment .model {
      grid-column: 2;
    }

    .model .name {
      color: var(--Neutral-Text-colorText, #27272a);
    }

    .model .year {
      color: var(--Neutral-Text-colorTextSecondary, #737373);
    }

    .model {
      display: flex;
      gap: 8px;
      white-space: nowrap;
    }

    .left {
      display: flex;
      gap: 8px;
    }

    .button {
      font-size: 24px;
    }

    ${type("sm")} {
      gap: 1px;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--Neutral-Border-colorBorder, #d9d9d9);
    }

    ${type("sm")} .container + .container {
      box-shadow: 0px -1px 0px var(--Neutral-Border-colorBorder, #d9d9d9);
    }

    ${type("sm")} .segment {
      padding: var(--Space-Padding-padding, 16px);
      ${font("Base/Normal")};
    }

    ${type("lg")} {
      gap: 1px;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--Neutral-Border-colorBorder, #d9d9d9);
    }

    ${type("lg")} .container + .container {
      box-shadow: 0px -1px 0px var(--Neutral-Border-colorBorder, #d9d9d9);
    }

    ${type("lg")} .segment {
      padding: var(--Space-Padding-padding, 16px) var(--Space-Padding-paddingXL, 32px);
      ${font("Base/Normal")};
    }

    .collapsable.container {
      transition: max-height 250ms linear;
      max-height: var(--height, auto);
      overflow: hidden;
    }

    .collapsable.container.closed {
      max-height: calc(24px + 16px * 2);
    }

    .collapsable .segment .model {
      transition: opacity 250ms linear;
      opacity: 1;
    }

    .collapsable.closed .segment .model {
      opacity: 0;
    }

    .model {
      display: flex;
      flex-direction: column;
    }

    .modifications {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .modification {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      justify-content: flex-start;
      padding-left: 16px;
    }

    .modification .name {
      ${font("Base/Normal")};
      color: var(--Neutral-Text-colorText, #27272a);
    }

    .modification .year {
      ${font("Base/Normal")};
    }

    .modification::before {
      content: "";
      display: block;
      background-color: #6b7280;
      width: 4px;
      height: 4px;
      border-radius: 1px;
    }
  `;
}
