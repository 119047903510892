export class ProductQueryState {
    search: string = "";
    sort: string = "price.asc";
    prices: { from: null | number; to: null | number } = { from: null, to: null };
    brands: string[] = [];
    categories: string[] = [];
    page: number = 1;
    _hash = null as null | string;

    constructor(values?: Pick<ProductQueryState, "sort" | "prices" | "brands" | "categories" | "page" | 'search'>) {
        if (values) {
            this.fill(values);
        }
    }

    fill(values: Pick<ProductQueryState, "sort" | "prices" | "brands" | "categories" | "page" | 'search'>) {
        this.sort = values.sort;
        this.prices = { ...values.prices };
        this.brands = [...values.brands];
        this.categories = [...values.categories];
        this.search = values.search;
        this.page = values.page;
        this._hash = null;
    }

    clear() {
        this.sort = "price.asc";
        this.prices = { from: null, to: null };
        this.brands = [];
        this.categories = [];
    }

    get hash() {
        if (!this._hash) {
            const { _hash, ...values } = this;
            this._hash = JSON.stringify(values);
        }

        return this._hash;
    }

    blank(type: 'brands' | 'categories' | 'ranges' | 'sort' | 'page') {
        switch (type) {
            case 'brands':
                return !this.brands.length;
            case 'categories':
                return !this.categories.length;
            case 'ranges':
                return this.prices.from === null && this.prices.to === null;
            case 'sort':
                return this.sort === 'price.asc';
            case 'page':
                return this.page === 1;
        }
    }

    equalType(state: ProductQueryState, type: 'brands' | 'categories' | 'ranges' | 'sort' | 'page') {
        switch (type) {
            case 'ranges':
                return this.prices.from === state.prices.from && this.prices.to === state.prices.to;
            case 'brands':
            case 'categories':
                return this[type].length === state[type].length && this[type].every((item) => state[type].includes(item));
            case 'page':
            case 'sort':
                return this[type] === state[type];
            default: return false;
        }
    }

    equals(state: ProductQueryState) {
        return this.hash === state.hash;
    }

    clone() {
        return new ProductQueryState(this);
    }

    isFiltersBlank() {
        return ProductQueryState.filterTypes.every((type) => this.blank(type));
    }

    isPagerBlank() {
        return this.blank('page');
    }

    isProductListCanBeContinued(previusState: ProductQueryState) {
        if (this.page === previusState.page + 1) {
            return ProductQueryState.filterTypes.every((type) => this.equalType(previusState, type));
        }

        return false;
    }

    static filterTypes = [
        'brands',
        'categories',
        'ranges',
        'sort'
    ] as const;
}