import { LitElement, html, css, PropertyValueMap } from "lit";
import { customElement, property } from "lit/decorators.js";
import { font } from "@/util/fonts";
import { createRef, ref } from "lit/directives/ref.js";
import { SlSelect } from "@shoelace-style/shoelace";

@customElement("fix-select")
export class FixSelect extends LitElement {
  @property({ type: String, reflect: true })
  name = "";

  @property({ type: String, reflect: true })
  type = undefined;

  @property({ type: String, reflect: true })
  placeholder = undefined;

  @property({ type: Boolean, reflect: true })
  required = false;

  @property({ type: Boolean, reflect: true })
  size = "large";

  @property({ type: Boolean, reflect: true })
  clearable = false;

  @property({ type: String, reflect: true })
  helpText = null;

  @property({ type: String, reflect: true })
  value = "";

  @property({ type: Array })
  options = [];

  @property({ type: String, attribute: true, reflect: true })
  customError = "";

  internals: ElementInternals;

  private ref = createRef<SlSelect>();

  static get formAssociated() {
    return true;
  }

  constructor() {
    super();
    this.internals = this.attachInternals();
  }

  render() {
    return html`
      <sl-select
        ${ref(this.ref)}
        name="${this.name}"
        type="${this.type}"
        placeholder="${this.placeholder}"
        required=${this.required}
        size="${this.size}"
        .value=${this.value}
        @sl-change=${this.changeHandler}
      >
        ${this.renderItems()}
        <span slot="help-text" class="help-text">${this.helpMessage}</span>
      </sl-select>
    `;
  }

  renderItems = () => {
    return html`${this.options.map(({ id, name }) => html`<sl-option value=${id}>${name}</sl-option>`)}`;
  };

  get helpMessage() {
    return this.customError ? this.customError : this.helpText;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.firstUpdated(_changedProperties);
    this.internals.setFormValue(this.value);
  }

  protected async updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    super.updated(_changedProperties);

    const input = this.ref.value!;
    await this.updateComplete;

    if (this.customError) {
      input.setCustomValidity(this.customError);
    } else {
      input.setCustomValidity("");
    }
  }

  changeHandler = (event: { target: { value: string; validity: ValidityState } }) => {
    this.value = event.target.value;

    const input = this.ref.value!;
    input.setCustomValidity("");
    if (this.customError) {
      this.customError = "";
    }

    this.internals.setFormValue(this.value);

    this.internals.setValidity(event.target.validity, this.helpText ?? "");
  };

  static styles = css`
    :host([invalid]) .help-text {
      color: red;
    }

    sl-select::part(display-input)::placeholder {
      ${font("Base/Normal")}
    }
    sl-select::part(combobox) {
      border-radius: var(--components-input-global-border-radius-lg, 8px);
      border: var(--components-input-global-line-width, 1px) solid var(--components-input-global-color-border, #d9d9d9);
      background: var(--components-input-global-color-bg-container, #ffffff);
    }
    sl-select::part(display-input) {
      ${font("LG/Normal")}
    }

    /* user invalid styles */
    sl-select[data-user-invalid]::part(combobox) {
      border-color: var(--Components-Input-Global-colorError, #ff4d4f);
    }

    sl-select[data-user-invalid]::part(form-control-label) {
      color: var(--Components-Input-Global-colorError, #ff4d4f);
    }

    sl-select:focus-within[data-user-invalid]::part(combobox) {
      border-color: var(--Components-Input-Global-colorError, #ff4d4f);
      box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-300);
    }

    sl-select .clear-icon {
      display: none;
    }

    sl-select .help-text {
      display: none;
    }
    .help-text {
      ${font("Base/Normal")}
    }

    sl-select[data-user-invalid] .clear-icon,
    sl-select:focus-within[data-user-invalid] .clear-icon {
      display: flex;
      align-content: center;
      color: var(--Components-Input-Global-colorError, #ff4d4f);
    }

    sl-select[data-user-invalid] .help-text,
    sl-select:focus-within[data-user-invalid] .help-text {
      display: flex;
      color: var(--Components-Input-Global-colorError, #ff4d4f);
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-select": FixSelect;
  }
}
