import { localized } from "@/decorators/localized";
import { B2cBrand } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { MultiTypeElement, whenType } from "@/util/element";
import { font } from "@/util/fonts";
import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { choose } from "lit/directives/choose.js";
import { getIconUrl } from "@/pages/product-page/extracted/menu/util";

type FeaturedItem = {
  id: string;
  name: string;
  slug: string;
  url: string;
  hasLogo: boolean;
  logoUrl: string;
};

@customElement("brands-list-images")
@localized()
export class BrandsListImages extends MultiTypeElement {
  @property()
  type!: "sm" | "lg";

  @property()
  brands!: Array<B2cBrand>;

  render() {
    return html` <div class="item-list">${this.renderList()}</div> `;
  }

  renderList() {
    return repeat(
      this.list,
      (item) => item.id,
      (item) => {
        return choose(item.hasLogo, [
          [true, () => this.renderLogo(item)],
          [false, () => this.renderText(item)],
        ]);
      },
    );
  }

  renderLogo(item: FeaturedItem) {
    return html`
      <a class="item" href="${item.url}">
        <span class="icon-wrapper">
          <img class="icon" src=${item.logoUrl} alt="${item.name}" title="${item.name}" />
        </span>
      </a>
    `;
  }

  renderText(item: FeaturedItem) {
    return html`
      <a class="item" href="${item.url}">
        <span class="text-wrapper">${item.name}</span>
      </a>
    `;
  }

  get list() {
    return this.brands.map((item) => {
      const assetLogo = Array.isArray(item.assets) ? (item.assets?.find((asset) => asset.type === "image-logo")?.path ?? null) : null;
      const logoUrl = getIconUrl(assetLogo);

      return {
        id: item.id,
        name: item.name,
        slug: item.slug,
        url: Url.to("dynamic-route", { dynamicPath: [item.slug] }),
        hasLogo: Boolean(logoUrl),
        logoUrl: logoUrl,
      } as FeaturedItem;
    });
  }

  static stylesCommon = css`
    .item-list {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-paddingXS, 8px);
    }

    .item-list > *:nth-child(n + 19) {
      display: none;
    }

    .item {
      flex: 1 1 auto;
      flex-basis: calc(100% / 7);
      height: 82px;
      min-width: 200px;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--brand-card-color-border, #d9d9d9);
    }

    .item:hover {
      border-color: var(--brand-card-color-border-hover, #3b82f6);
    }

    .item:active {
      border-color: var(--brand-card-color-border-active, #2563eb);
    }

    a.item,
    a.item:visited,
    a.item:hover,
    a.item:active {
      ${font("LG/Normal")};
      text-decoration: none;
      color: var(--neutral-color-text-base, #000000);
    }

    .icon-wrapper {
      width: 92px;
      height: 42px;
      display: block;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  `;

  static stylesSm = css`
    .item {
      flex-basis: calc(100% / 4);
      height: var(--size-height-control-height-lg, 48px);
      min-width: 100px;
      max-width: none;
    }

    .item-list > *:nth-child(n + 7) {
      display: none;
    }
  `;

  static stylesLg = css``;

  static styles = [BrandsListImages.stylesCommon, whenType("sm", BrandsListImages.stylesSm), whenType("lg", BrandsListImages.stylesLg)];
}
