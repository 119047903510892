import { ContextProvider, createContext } from "@lit/context";
import { LitElement } from "lit";
import { Breadcrumbs } from "./breadcrumbs";
import { Search } from "./search";
import { Api } from "@/api";
import { CurrencyService } from "./currency-service";
import { Formatter } from "./formatter";
import { CustomerChannel } from "./customer-channel";
import { Main } from "@/context/main.ts";
import { Customer } from "@/context/customer.ts";
import { Cart } from "@/context/cart.ts";
import { Categories } from "./categories";
import { StaticPages } from "@/context/static-pages.ts";
import { Countries } from "./countries.ts";
import { Routes } from "./routes";
import { Brands } from "./brands";
import { Initialize } from "./initialize.ts";
import { Parameters } from "./parameters.ts";
import i18next from "i18next";

export class Services {
  private provider: ContextProvider<{ __context__: Services }, LitElement>;

  private host: LitElement;

  public breadcrumbs!: Breadcrumbs;
  public search!: Search;

  public mainContext!: Main;
  public currency!: CurrencyService;
  public formatter!: Formatter;
  public customerChannel!: CustomerChannel;
  public customer!: Customer;
  public cart!: Cart;
  public categories!: Categories;
  public brands!: Brands;
  public api!: Api;
  public staticPages!: StaticPages;
  public countries!: Countries;
  public params: Map<string, string>;
  public routes!: Routes;
  public initialize!: Initialize
  public parameters!: Parameters;

  constructor(host: LitElement, api: Api) {
    this.params = new Map();
    Services.instance = this;
    this.host = host;
    this.provider = new ContextProvider(host, { context: ServiceLocatorContext, initialValue: this });

    this.mainContext = new Main(host, api);
    this.breadcrumbs = new Breadcrumbs(host, api);
    this.search = new Search(host, api);
    this.currency = new CurrencyService(host, api);
    this.formatter = new Formatter(host, this.currency);
    this.customerChannel = new CustomerChannel(host, api);
    this.categories = new Categories(host, api);
    this.brands = new Brands(host, api);
    this.customer = new Customer(host, api);
    this.cart = new Cart(host, api);
    this.countries = new Countries(host, this.customerChannel, this.formatter, this.customer, this);
    this.staticPages = new StaticPages(host, api);
    this.api = api;
    this.routes = new Routes(this);
    this.initialize = new Initialize(this);
    this.parameters = new Parameters(host, this);
  }

  static instance: Services = null!;

  static setParams(params: Record<string, string>, clear = false) {
    if (clear) {
      this.instance.params.clear();
    }

    for (const [key, value] of Object.entries(params)) {
      this.instance.params.set(key, value);
    }
  }

  static getParam(key: string) {
    return this.instance.params.get(key);
  }

  setLoading(loading: boolean) {
    this.host.dispatchEvent(new CustomEvent("loading", { detail: { loading }, bubbles: true, composed: true }));
  }

  async parametersUpdated() {
    await this.initialize.reloadServices(
      this.parameters.parameters.language,
      this.parameters.parameters.country
    );

    const country = this.countries.getByCode(this.parameters.parameters.country)!;

    this.formatter.setDefaultVat(country.vatRate + 100);
    this.formatter.setDefaultCurrency(this.parameters.parameters.currency);
    this.customerChannel.setDefaultVat(country.vatRate + 100);
    await i18next.changeLanguage(this.parameters.parameters.language);
  }
}

export const ServiceLocatorContext = createContext<Services>("ServiceLocator");
