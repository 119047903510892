import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-lang";
import { localized } from "@/decorators/localized";
import { t } from "i18next";

@customElement("not-found-page")
@localized()
export class NotFoundPage extends LitElement {
  render() {
    return html` <fix-heading class="page title" text="${t("ufed3d04ewyvup2n.404-not-found", "404 Page not found")}"></fix-heading> `;
  }

  static styles = css``;
}

declare global {
  interface HTMLElementTagNameMap {
    "not-found-page": NotFoundPage;
  }
}
