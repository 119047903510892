import { customElement, property, state } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import "@/generated/css/variables.css";
import { type } from "@/util/layout";
import "@/elements/mobile/fix-button";
import "@/elements/mobile/fix-button";
import "../layout/page-section-layout";
import { consume } from "@lit/context";
import { type Main, mainContext } from "@/context/main";
import { Category, RootCategoriesPreviewDocument } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { localized } from "@/decorators/localized";
import { default as i18next } from "i18next";
import { ServiceLocatorContext, Services } from "@/services/services";

@customElement("categories-preview")
@localized()
export class CategoriesPreview extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Boolean }) loading = true;

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  categories: Category[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.fetchCategories();
  }

  render() {
    if (!this.list.length) return;

    return html` <page-section-layout type=${this.type} .smOffsets=${true}>
      <div class="item-list">
        ${this.list.map(
      (item) => html`
            <fix-link class="item" href="${item.url}">
              <div class="item-inner">
                <div class="item-icon">
                  <img src="${item.logoUrl}" alt="${item.name}" />
                </div>
                <div class="item-text">${item.name}</div>
              </div>
            </fix-link>
          `,
    )}
      </div>
    </page-section-layout>`;
  }

  fetchCategories = async () => {
    const response = await this.mainContext?.api.client.query({
      query: RootCategoriesPreviewDocument,
      variables: {},
    });

    // @ts-ignore
    this.categories = response.data.RootCategoriesPreview;

    this.requestUpdate();
  };

  private capitalizeFirstLetter(input: string): string {
    if (input.length === 0) return input;
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  get list() {
    const assetsHost = import.meta.env.PUBLIC_B2C_ASSETS_HOST;
    const key = "name" + this.capitalizeFirstLetter(i18next.language);

    const categories = this.categories.map((item) => {
      const assetLogo = item.assets.find((asset) => asset.type === "image-logo");
      // @ts-ignore
      const name = item.translation.hasOwnProperty(key) ? item.translation[key] : item.translation.nameEn;
      const route = this.services.categories.getItemPath(item.id);
      return {
        id: item.id,
        name: name,
        url: Url.to("dynamic-route", { dynamicPath: [route] }),
        hasLogo: !!assetLogo,
        logoUrl: assetsHost + "/" + assetLogo?.path,
      };
    });

    return categories.filter((item) => item.hasLogo);
  }

  static styles = css`
    .item-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: var(--spacing-paddingXS, 8px);
    }

    ${type("sm")} .item-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }

    .item {
      background: var(--category-card-color-bg-container-filled, #f4f4f5);
      border: 1px solid transparent;
      border-radius: 12px;
    }

    ${type("sm")} .item {
      display: block;
      border-radius: 6px;
    }

    .item:hover {
      background: var(--category-card-color-bg-container, #ffffff);
      border: 1px solid var(--Components-Input-Component-hoverBorderColor, #60a5fa);
    }

    .item:active {
      background: var(--category-card-color-bg-container-filled, #f4f4f5);
      border: 1px solid var(--category-card-color-active-border, #2563eb);
    }

    ${type("sm")} .item:hover {
      background: var(--category-card-color-bg-container, #ffffff);
      border: 1px solid var(--neutral-border-color-border, #d9d9d9);
    }

    ${type("sm")} .item:active {
      background: var(--category-card-color-bg-container, #ffffff);
      border: 1px solid var(--category-card-color-active-border, #2563eb);
    }

    .item-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 180px;
      width: 100%;
      padding: var(--space-margin-margin-xs) var(--space-padding-padding-xs) var(--space-margin-margin-xs) var(--space-padding-padding-xs);
      padding-bottom: 27px;
      box-sizing: border-box;
    }

    ${type("sm")} .item-inner {
      min-width: 100%;
      min-height: 102px;
      padding-bottom: 0;
    }

    .item-icon {
      margin-bottom: 16px;
      padding-top: 27px;
    }

    ${type("sm")} .item-icon {
      padding-top: 8px;
    }

    .item-icon img {
      max-width: 80px;
      max-height: 80px;
    }

    ${type("sm")} .item-icon {
      margin-bottom: 0;
    }

    ${type("sm")} .item-icon img {
      width: 64px;
      height: 64px;
    }

    .item-text {
      ${font("LG/Normal")};
      color: var(--Neutral-colorTextBase, #000);
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      padding: 0 16px 0;
      box-sizing: border-box;
    }

    ${type("sm")} .item-text {
      ${font("Base/Normal")};
      width: 100%;
      padding: 0 8px 8px;
    }
  `;
}
