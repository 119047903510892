import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { map } from "lit/directives/map.js";
import { LitElement, PropertyValues, css, html } from "lit";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";
import { B2cProductPayload } from "@/generated/graphql/b2c";
import { Formatter, FormatterServiceContext } from "@/services/formatter";
import { consume } from "@lit/context";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { createRef, ref } from "lit/directives/ref.js";

type Dataset = {
  key: "original" | "premium" | "budget";
  title: string;
  prices: string;
  options: {
    id: string;
    name: string;
    price: any;
  }[];
  empty: boolean;
}[];

@customElement("product-can-be")
@localized()
export class ProductCanBe extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property()
  data!: Array<B2cProductPayload>;

  @consume({ context: FormatterServiceContext, subscribe: true })
  formatter!: Formatter;

  @property()
  active: null | string = null;

  @state()
  calculatedDataset!: Dataset

  @state()
  activeTab: null | string = null;

  tabsRef = createRef<{ show: (value: string) => void } & HTMLElement>();

  render() {
    const keys = this.calculatedDataset
      .filter(item => !item.empty)
      .map((item) => item.key);

    const heads = map(keys, (key) => this.renderHead(key));
    const tabs = map(keys, (key) => this.renderTab(key));

    return html` <sl-tab-group ${ref(this.tabsRef)}> ${heads} ${tabs} </sl-tab-group> `;
  }

  renderHead(key: string) {
    const item = this.calculatedDataset.find((item) => item.key === key)!;
    const prices = this.type === "lg";

    return html`
      <sl-tab slot="nav" panel=${key}>
        <div class="title">${item.title}</div>
        ${when(prices, () => html`<div class="prices">${item.prices}</div>`)}
      </sl-tab>
    `;
  }

  renderTab(key: string) {
    const item = this.calculatedDataset.find((item) => item.key === key)!;

    return html`
      <sl-tab-panel name=${key}>
        <fix-options
          @updateActive=${this.onOptionClick}
          .options=${item.options}
          .active=${this.active}
          .renderer=${this.renderOption}
          direction="vertical"
        ></fix-options>
      </sl-tab-panel>
    `;
  }

  renderOption = (item: { id: string; name: string; size: string; price: number }) => {
    return html`
      <div class="name">${item.name}</div>
      <div class="price bold">${this.formatter.price(item.price)}</div>
    `;
  };

  getGroupValues(key: "original" | "premium" | "budget") {
    switch (key) {
      case "original":
        return { title: t("yh809i5831915t0u.original", "Original"), type: "oe" };
      case "premium":
        return { title: t("88y21tbl8qz11dcg.premium-analogue", "Premium analogue"), type: "oem" };
      case "budget":
        return { title: t("oz9f39a92yrf0j8t.budget-analogue", "Budget analogue"), type: "aftermarket" };
    }
  }

  getPricesText(items: B2cProductPayload[]) {
    const amounts = [];
    for (const item of items) {
      for (const price of item.prices) {
        amounts.push(parseFloat(price.price.amount));
      }
    }

    const max = Math.max(...amounts, 0);
    const min = Math.min(...amounts, max);

    return this.formatter.priceRange([min, max]);
  }

  getMinPrice(prices: B2cProductPayload["prices"]) {
    return prices.slice().sort((a, b) => a.price.amount.localeCompare(b.price.amount))[0];
  }

  getGroupItems(type: string) {
    return this.data.filter((item) => {
      const productType = item.productType || item.brand.defaultProductType;
      switch (type) {
        case 'original': return productType === 'oe';
        case 'premium': return productType === 'oem';
        case 'budget': return productType === 'aftermarket';
      }

      return productType === null;
    });
  }

  createOptions(crosses: B2cProductPayload[]) {
    return crosses
      .map((cross) => ({
        id: cross.id,
        name: cross.brand.name,
        price: this.getMinPrice(cross.prices)?.price.amount ?? 0,
      }))
      .sort((a, b) => a.price - b.price);
  }

  onOptionClick = (event: { detail: { id: string } }) => {
    this.active = event.detail.id;
    this.dispatchEvent(new CustomEvent("navigateToProduct", { detail: { id: event.detail.id, reason: "product-can-be" } }));
  };

  protected willUpdate(_changedProperties: PropertyValues): void {
    if (_changedProperties.has("data")) {
      this.calculatedDataset = this.calculateDataset();

      const nonEmptyTabs = this.calculatedDataset.filter((item) => !item.empty);
      const firstTab = nonEmptyTabs[0];
      const activeTab = this.active
        ? nonEmptyTabs.find((item) => item.options.some((option) => option.id === this.active))
        : null;

      this.activeTab = activeTab?.key ?? firstTab?.key ?? null;
    }
  }

  protected updated(_changedProperties: PropertyValues): void {
    const tab = this.activeTab;
    if (_changedProperties.has("activeTab") && tab) {
      // bug https://github.com/shoelace-style/shoelace/issues/1145
      // don't fix, cause component will be removed for our implementation
      setTimeout(() => {
        this.tabsRef.value?.show(tab);
      }, 10);
    }
  }

  calculateDataset(): Dataset {
    const result = [];
    const groups = ["original", "premium", "budget"] as const;
    for (const key of groups) {
      const { title, type } = this.getGroupValues(key);
      const items = this.getGroupItems(key);
      const options = this.createOptions(items);
      const prices = this.getPricesText(items);

      const item = {
        key,
        title,
        prices,
        options,
        empty: options.length === 0,
      };

      result.push(item);
    }

    return result;
  }

  static styles = css`
    :host {
    }

    ::part(tabs) {
      --track-color: transparent;
    }

    ::part(active-tab-indicator) {
      /* border-bottom: solid var(--track-width) var(--indicator-color); */
      border-bottom: solid var(--track-width) transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    ::part(active-tab-indicator)::before {
      display: block;
      content: "";
      width: calc(100% - 15px * 2);
      height: 2px;
      background: var(--indicator-color);
      border-radius: 2px;
      position: absolute;
      transform: translateY(1px);
    }

    ::part(active-tab-indicator)::after {
      display: block;
      content: "";
      width: 12px;
      height: 12px;
      transform: translate(2px, 5px) rotate(45deg);
      background: var(--indicator-color);
      position: absolute;
    }

    sl-tab::part(base) {
      color: #3b82f6;
      padding-bottom: 4px;
      padding-top: 0px;
    }

    sl-tab::part(base):hover {
      color: #60a5fa;
    }

    sl-tab[active]::part(base) {
      color: #27272a;
    }

    sl-tab::part(base) {
      display: flex;
      flex-direction: column;
    }

    sl-tab::part(base) {
    }

    sl-tab[active]::part(base) {
    }

    sl-tab-panel::part(base) {
      --padding: 8px 0px;
    }

    .title {
    }

    .prices {
      color: var(--Components-Typography-Global-colorText, #27272a);
    }

    ${type("lg")} .title {
      ${font("Base/Strong")};
    }

    ${type("lg")} .prices {
      ${font("SM/Normal")};
    }

    ${type("sm")} .title,
    ${type("sm")} .prices {
      ${font("SM/Normal")};
    }
  `;
}
