import { font } from "@/util/fonts";
import { SlCheckbox } from "@shoelace-style/shoelace";
import { LitElement, html, css, PropertyValues } from "lit";
import { customElement, property } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";
import "../system-icons-update";

@customElement("fix-checkbox")
export class FixCheckbox extends LitElement {
  @property({ reflect: true })
  name!: string;

  @property()
  indeterminate!: boolean;

  @property()
  checked!: boolean;

  internals: ElementInternals;

  private inputRef = createRef<SlCheckbox>();

  static get formAssociated() {
    return true;
  }

  constructor() {
    super();
    this.internals = this.attachInternals();
  }

  render() {
    return html`<sl-checkbox
      ${ref(this.inputRef)}
      @sl-change=${this.handleChange}
      .checked=${this.checked}
      .name=${this.name}
      .indeterminate=${this.indeterminate}
      ><slot></slot>
    </sl-checkbox>`;
  }

  handleChange = () => {
    this.resyncProps();
    this.dispatchEvent(new CustomEvent("change"));
  };

  resyncProps = () => {
    this.input.checked = this.checked;
    this.input.indeterminate = this.indeterminate;
  };

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    this.internals.setFormValue(String(this.inputRef.value?.checked));
  }

  get input() {
    return this.inputRef.value!;
  }

  static styles = css`
    :host {
      display: block;
    }

    sl-checkbox {
      /* --sl-input-background-color: #D9D9D9; */
      --sl-input-border-color: #d9d9d9;
      --sl-color-primary-600: #3b82f6;
      --sl-color-primary-500: #5a94f4;
    }

    sl-checkbox::part(base) {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    sl-checkbox::part(label) {
      color: var(--Components-Checkbox-Global-colorText, #27272a);
      ${font("Base/Normal")};
      margin-inline-start: 0px;
    }

    sl-checkbox::part(control) {
      border-radius: 4px;
      --toggle-size: 16px;
      --sl-input-border-color-hover: #3b82f6;
    }

    sl-checkbox::part(control--indeterminate):hover {
      border-color: var(--sl-color-primary-500);
      background-color: var(--sl-input-background-color);
    }

    sl-checkbox::part(control--indeterminate) {
      border-color: var(--sl-input-border-color);
      background-color: var(--sl-input-background-color);
      --sl-color-neutral-0: #3b82f6;
    }

    sl-checkbox::part(control--indeterminate):hover {
      border-color: var(--sl-color-primary-500);
      background-color: var(--sl-input-background-color);
    }
  `;
}
