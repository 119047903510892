import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import type { HomePage } from ".";

@customElement("home-page-mobile")
export class HomePageMobile extends LitElement {
  @property({ type: Object })
  base: HomePage = null!;

  render() {
    const base = this.base;
    return html`
      <catalog-layout type="sm">
        <div class="offset-y">
        <page-section-layout type=${'sm'}>
        ${base.renderCategoriesPreview("sm")}
        </page-section-layout>
          ${base.renderTopCategories("sm", false)} 
          ${base.renderMainBanner("sm")} 
          ${base.renderFeaturedBrands("sm")}
          ${base.renderCarBrands("sm")}
          <div class="offsets">${base.renderBenefitsList("sm")}</div>
        </div>
      </catalog-layout>
    `;
  }

  static styles = css`
    .offsets {
      margin: 0 16px;
    }

    .offset-y {
      margin-bottom: 28px;
    }
  `;
}
