import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "./category-type";
import { consume } from "@lit/context";
import { Categories, CategoriesContext } from "@/services/categories";
import "./categories-preview";
import { when } from "lit/directives/when.js";

@customElement("categories-navigator")
export class CategoriesNavigator extends LitElement {
  @property()
  type!: "lg" | "sm";

  @state()
  tabsRoot?: string | null = null;

  @state()
  tabsActive?: string | null = null;

  @state()
  cardsRoot?: string | null = null;

  @consume({ context: CategoriesContext, subscribe: true })
  categories!: Categories;

  render() {
    return html` <div class="container">${when(this.tabsRoot, this.renderCategoryTabs)} ${when(this.cardsRoot, this.renderSubcategories)}</div> `;
  }

  renderCategoryTabs = () => {
    return html`
      <category-type
        .mode=${this.categoryNavigatorMode}
        .active=${this.tabsActive!}
        .categories=${this.getTopCategoeies()}
      ></category-type>
    `;
  };

  renderSubcategories = () => {
    return html`<categories-preview-custom
      type=${this.type}
      .categories=${this.getSubcategories()}
    ></categories-preview-custom>`;
  };

  getTopCategoeies() {
    return this.categories
      .getChilds(this.tabsRoot!)
      .map((category) => ({
        name: category.name,
        key: category.id,
        icon: category.icon!,
        url: category.url
      }))
      .filter((category) => category.icon);
  }

  getSubcategories() {
    return this.categories.getChilds(this.cardsRoot!).map((category) => ({
      name: category.name,
      key: category.id,
      id: category.id,
      image: category.image,
      url: category.url
    }));
  }

  get categoryNavigatorMode() {
    return this.type === "sm" ? "line" : "all";
  }

  static styles = css`
    .subcategories {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;
      padding: 10px;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  `;
}
