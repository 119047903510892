import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import "./layout-desktop/head";
import "./layout-desktop/notifications";
import { Cart, cartContext } from "@/context/cart.ts";
import "@/elements/cart/item";
import "@/elements/mobile/cart/empty-lable";
import { consume } from "@lit/context";
import { localized } from "@/decorators/localized.ts";
import i18next, { t } from "i18next";
import { when } from "lit/directives/when.js";
import arrowOutlinedRight from "@/assets/arrow-outlined-right.svg";
import { font } from "@/util/fonts.ts";
import { Countries, CountriesContext } from "@/services/countries.ts";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

@localized()
@customElement("cart-page-desktop")
export class CartPageDesktop extends LitElement {
  @consume({ context: cartContext, subscribe: true })
  @property({ attribute: true })
  public cart!: Cart;

  @consume({ context: CountriesContext, subscribe: true })
  public countries!: Countries;

  get checkoutUrl() {
    return import.meta.env.PUBLIC_CHECKOUT_HOST + `/?source=b2c&id=${this.cart?.id}&lang=${i18next.language}&country=${this.countries.getCurrentCountryCode()}`;
  }

  renderEmptyCart = () => {
    return html`<cart-empty-label></cart-empty-label>`;
  };

  renderNotifications = () => {
    return html`
      <div class="notifications-container">
        <sl-details summary="Notifications" open>
          <cart-notifications .items=${this.cart.errors}></cart-notifications>
        </sl-details>
      </div>
    `;
  };

  renderCartInfo = () => {
    return html`
      <div class="cart-container">
        <div class="cart-items-container">
          <div class="cart-items-total">
            <div class="label">${t("zxjlya047dy77p7w.cart", "Cart")}</div>
            <div class="sub-title">${t("r7p16j1hyjl2luuk.products-count", "{{count}} products", { count: this.cart.items?.length ?? 0 })}</div>
          </div>
          ${when(this.cart.errors?.length, () => this.renderNotifications())}
          <div class="products">
            ${this.cart.items?.map(
              (item: any, key: number) => html`${when(key, () => html`<sl-divider></sl-divider>`)}<cart-item .product=${item}></cart-item>`,
            )}
          </div>
        </div>
        <div class="cart-totals">
          <div class="label">${t("ziihw8w2uag97uj9.order-summary", "Order summary")}</div>
          <div class="info">
            <div class="total">
              <span>${t("sor9ad5qli79zyjd.subtotal", "Subtotal:")}</span>
              <span><price-value class="price" .amount=${Number(this.cart.subtotal?.amount)}></price-value></span>
            </div>
            <div class="total">
              <span>Tax:</span>
              <span><price-value class="price" .amount=${Number(this.cart.taxTotal?.amount)}></price-value></span>
            </div>
            <div class="subtotal">
              <span>Total:</span>
              <span class="total-price"><price-value class="price" .amount=${Number(this.cart.grandTotal?.amount)}></price-value></span>
            </div>
          </div>
          <sl-divider></sl-divider>
          <div class="total">
            <span>Total weight:</span>
            <span>${this.cart.totalWeight} kg</span>
          </div>
          <sl-button class="checkout-btn" variant="default" size="large" href=${this.checkoutUrl}>
            <sl-icon src=${arrowOutlinedRight} class="cart-button-icon"></sl-icon>${t("vx7hvi0v1mex3f6o.proceed-to-checkout", "Proceed to checkout")}
          </sl-button>
        </div>
      </div>
    `;
  };

  render() {
    const cartContent = this.cart.items?.length ? this.renderCartInfo() : this.renderEmptyCart();
    return html`
      <cart-head-desktop></cart-head-desktop>
      ${cartContent}
      <footer-element></footer-element>
    `;
  }

  static styles = css`
    sl-button::part(label) {
      ${font("LG/Normal")}
      line-height: inherit;
    }

    sl-button {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      --sl-input-border-radius-large: 8px;
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
      display: flex;
      min-width: 100%;
    }

    .checkout-btn {
      margin-top: 24px;
    }

    .cart-container {
      margin: 8px 40px 40px;
      display: flex;
    }

    .cart-items-container {
      background: #fff;
      border-radius: 6px;
      margin-right: 8px;
      width: 100%;
      padding: 30px 20px;
    }

    .cart-items-total {
      display: flex;
      align-items: baseline;
    }

    .cart-items-total .label {
      margin-right: 20px;
      ${font("Heading/2")}
    }

    .cart-items-total .sub-title {
      color: #a3a3a3;
      ${font("XL/Normal")}
    }

    .cart-totals {
      max-width: 470px;
      width: 100%;
      padding: 30px 20px;
      background: #fff;
      border-radius: 6px;
      height: max-content;
    }

    .cart-totals .label {
      ${font("Heading/2")}
    }

    .cart-totals .info {
      margin: 20px 0;
      font-size: 14px;
    }

    .cart-totals .total {
      margin: 9px 0;
      ${font("Base/Normal")}
      display: flex;
      justify-content: space-between;
    }

    .subtotal {
      ${font("Heading/4")}
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 21px 0;
    }

    .total-price {
      ${font("Heading/2")}
    }

    .cart-button-icon {
      font-size: 11px;
      margin: 0 7px 2px;
    }

    .notification-btn {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    sl-divider {
      --spacing: 16px;
    }

    .box {
      width: 100px;
      height: 50px;
      background: #0c4a6e;
      border-radius: 5px;
    }

    .notifications-container {
      padding-top: 20px;
    }

    .notifications-container sl-details::part(summary) {
      ${font("Heading/4")}
    }
  `;
}
