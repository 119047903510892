import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { default as i18next } from "i18next";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { routerInstance } from "@/app-root";
import { supportedLanguages } from "@/constants/languages";
import { localized } from "@/decorators/localized";

@customElement("fix-lang")
@localized()
export class FixLang extends LitElement {
  render() {
    return html`
      <sl-select value=${i18next.language} @sl-change=${this.changeLang}>
        ${supportedLanguages.map((lang) => html` <sl-option value="${lang.code}">${lang.name}</sl-option> `)}
      </sl-select>
    `;
  }

  changeLang = (event: { target: { value: string } }) => {
    const lang = event.target.value;
    let path = null;
    if (routerInstance?.location) {
      path = Url.getRoutePathByRouteLocation(routerInstance.location);
    }

    const params = routerInstance.location.params;
    params.lang = lang;

    if (path && path !== "/") {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          // @ts-ignore
          path = path.replace(":" + key, params[key]);
        }
      }

      window.history.pushState({}, "", path);
    }

    const customEvent = new CustomEvent("locale-change", {
      detail: { locale: lang },
    });

    i18next.changeLanguage(lang).then(() => window.dispatchEvent(customEvent));
  };

  static styles = css`
    :host {
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      font-family: "SF Pro Display";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-lang": FixLang;
  }
}
