import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/elements/mobile/fix-button";
import { createRef, ref } from "lit/directives/ref.js";
import { SlInput } from "@shoelace-style/shoelace";
import minus from "@/assets/minusq.svg";
import plus from "@/assets/plusq.svg";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";

@customElement("fix-quantity")
export class FixQuantity extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Number })
  quantity = 1;

  @property({ type: Number })
  minQuantity = 0;

  @property({ type: Number })
  maxQuantity = null;

  @property({ type: Number })
  factorQuantity = null;

  input = createRef<SlInput>();

  render() {
    return html`
      <sl-input ${ref(this.input)} .value=${this.quantity.toString()} @sl-change=${this.changeQuantity} placeholder="1">
        ${this.renderButton("prefix", minus, this.decreaseQuantity, this.isDisabled("decrease"))}
        ${this.renderButton("suffix", plus, this.increaseQuantity, this.isDisabled("increase"))}
      </sl-input>
    `;
  }

  renderButton(slot: string, icon: string, click: () => void, disabled: boolean) {
    return html`
      <sl-button slot=${slot} variant="text" @click=${click} .disabled=${disabled}>
        <sl-icon src=${icon}></sl-icon>
      </sl-button>
    `;
  }

  updateQuantity = (quantity: number, validationMessage: string | null) => {
    if (this.quantity !== quantity) {
      this.dispatchEvent(new CustomEvent("updateQuantity", { detail: { quantity }, bubbles: true, composed: true }));
    } else if (this.input.value) {
      this.input.value.value = this.quantity.toString();
    }

    if (validationMessage) {
      this.input.value?.setCustomValidity(validationMessage);
      this.input.value?.reportValidity();
    }
  };

  changeQuantity = (event: { target: { value: number } }) => {
    this.updateQuantity(this.normalizeQuantity(event.target.value), this.validateQuantity(event.target.value));
  };

  increaseQuantity = () => {
    const value = this.quantity + (this.factorQuantity ? this.minQuantity : 1);

    this.updateQuantity(this.normalizeQuantity(value), this.validateQuantity(value));
  };

  decreaseQuantity = () => {
    const value = this.quantity - (this.factorQuantity ? this.minQuantity : 1);

    this.updateQuantity(this.normalizeQuantity(value), this.validateQuantity(value));
  };

  normalizeQuantity(value: number) {
    if (this.factorQuantity) {
      value = Math.max(this.minQuantity, Math.ceil(value / this.factorQuantity) * this.factorQuantity);
    }

    if (this.maxQuantity === null) {
      return Math.max(value, this.minQuantity);
    }

    return Math.min(Math.max(value, this.minQuantity), this.maxQuantity);
  }

  validateQuantity(value: number): string | null {
    if (this.factorQuantity && value % this.factorQuantity !== 0) {
      return "Value should be multiple of " + this.factorQuantity;
    }

    if (this.maxQuantity && value > this.maxQuantity) {
      return "Value greater then max not allowed";
    }

    if (value < this.minQuantity) {
      return "Value less then min not allowed";
    }

    return null;
  }

  isDisabled(action: "increase" | "decrease") {
    switch (action) {
      case "increase":
        return false;
      case "decrease":
        return typeof this.quantity === "number" ? this.quantity <= 1 : false;
    }
  }

  static styles = css`
    :host {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
    }

    sl-input {
      --sl-input-height-medium: 48px;
      --sl-input-spacing-medium: var(--Components-Input-Global-controlPaddingHorizontal, 12px);
      --sl-input-font-family: "SF Pro Display";
      --sl-input-font-weight: 500;
      --sl-input-font-size-medium: 16px;
      --sl-input-color: var(--Garage-colorText, #27272a);
      --sl-input-border-radius-medium: 6px;
      --sl-input-border-width: var(--Components-Input-Global-lineWidth, 1px);
      --sl-input-border-color: var(--Components-Input-Global-colorBorder, #d9d9d9);
      --sl-input-border-color-hover: var(--Brand-Info-colorInfoBorderHover, #93c5fd);

      display: flex;
    }

    sl-input::part(base) {
      width: 100px;
    }

    sl-input::part(input) {
      flex-basis: 30px;
      flex-shrink: 0;
      padding: 0;
      ${font("LG/Normal")};
    }

    sl-button {
      --sl-input-border-width: 0px;
      --sl-color-primary-600: #27272a;
      --sl-color-primary-500: #2563eb;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }

    sl-button[slot="prefix"] {
      margin-inline-start: -1px;
    }

    sl-button[slot="prefix"]::part(label) {
      padding-left: calc(4px + 12px);
      padding-right: 4px;
    }

    sl-button[slot="suffix"] {
      margin-inline-end: -1px;
    }

    sl-button[slot="suffix"]::part(label) {
      padding-right: calc(4px + 12px);
      padding-left: 4px;
    }

    sl-icon {
      font-size: 16px;
    }

    sl-input::part(input) {
      width: 100%;
      text-align: center;
      line-height: 24px;
    }

    ${type("lg")} sl-input {
      --sl-input-height-medium: 48px;
    }

    ${type("sm")} sl-input {
      --sl-input-height-medium: 40px;
    }

    :host(.wide)::part(base) {
      width: 100%;
      flex-grow: 1;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-quantity": FixQuantity;
  }
}
