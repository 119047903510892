import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import "@/elements/product-element";

@customElement("product-grid")
export class ProductGrid extends LitElement {
  render() {
    return html`<div class="container">
      <product-element type="lg"></product-element>
      <product-element type="lg"></product-element>
      <product-element type="lg"></product-element>
      <product-element type="lg"></product-element>
    </div>`;
  }

  static styles = css`
    .container {
      display: grid;
      grid-template-columns: repeat(2, minmax(213px, 1fr));
      gap: 16px;
    }
  `;
}
