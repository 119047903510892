import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import type { ProductPage } from ".";
import { font } from "@/util/fonts";
import { when } from "lit/directives/when.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import {features} from "@/constants/features";

const priceCartGroupCss = css`
  .price-cart-group .discount {
    color: var(--color-text-secondary, #737373);
    ${font("Base/Delete")};
  }

  .price-cart-group .price {
    color: var(--color-text-heading, #111827);
    ${font("Heading/2")};
  }

  .price-cart-group .quantity-tip,
  .price-cart-group .var-tip {
    color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
    ${font("SM/Normal")};
    white-space: nowrap;
    text-align: center;
  }

  .price-cart-group {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
  }

  .price-cart-group > * {
    flex-basis: 50%;
    flex-shrink: 0;
  }

  .price-cart-group .left .row {
    display: flex;
    gap: 8px;
  }

  .price-cart-group .right {
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .price-cart-group .right > * {
    width: 100%;
  }
`;

const commons = css`
  .content-segment {
    background: var(--Neutral-colorWhite, #fff);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: var(--Space-Padding-paddingLG, 24px);
    padding-bottom: var(--Space-Padding-paddingLG, 24px);
  }

  .content-segment.transparent {
    background: transparent;
  }

  .content-segment.content-h {
    padding-top: 0;
    padding-bottom: 0;
  }

  .content-segment > .title {
    ${font("Heading/4")};
    margin-bottom: var(--Space-Padding-padding, 16px);
  }

  .content-segment.compact > .title {
    margin-bottom: var(--Space-Margin-marginXS, 8px);
  }

  * > .content-segment:not(:last-child) {
    margin-bottom: 8px;
  }
`;

@customElement("product-page-mobile")
@localized()
export class ProductPageMobile extends LitElement {
  @property({ type: Object })
  base: ProductPage = null!;

  render() {
    const base = this.base;
    const specifications = base.hasBlock("specifications");
    const situableFor = base.hasBlock("situable-for");
    const canBe = base.hasBlock("can-be");
    const carousel = base.hasBlock("carousel");

    return html`
      <catalog-layout type="sm">
        <div class="content-segment">
          ${this.renderTopSegment()} 
          ${when(carousel, () => base.renderCarousel("sm"))}
          ${when(features.product_variations, () =>base.renderVariations())} 
          ${base.renderDispatch()}
          ${this.renderProductPricesSegment()}
          <div class="product-shipping">
            ${base.renderShipping()}
          </div>
        </div>
        ${when(
          specifications,
          () => html`
            <div class="content-segment">
              <div class="title">${t("jtsow6p79bxmpjox.specifications", "Specifications")}</div>
              ${base.renderSpecifiactions("sm")}
            </div>
          `,
        )}
        ${when(
          canBe,
          () => html`
            <div class="content-segment">
              <div class="title">${t("c7yuh48tbek57zqw.product-can-be", "This product can be")}</div>
              ${base.renderCanBe("sm")}
            </div>
          `,
        )}
        <div class="content-segment compact">
          <div class="title">${t("afp6t38xk75sr191.about", "About")}</div>
          ${base.renderAbout("sm")}
        </div>
        ${when(
          situableFor,
          () => html`
            <div class="content-segment">
              <div class="title">${t("4d431o9n9ol327o8.suitable-summary", "Suitable for")}</div>
              ${base.renderSituable("sm")}
            </div>
          `,
        )}
        ${when(
            features.product_related,
            () => html`
              <div class="content-segment">
                <div class="title">${t("j10il6245v20kshl.related-summary", "Related products")}</div>
                ${base.renderRelated("sm")}
              </div>
        `)}
        <div class="content-segment transparent content-h">${base.renderBenefits("sm")}</div>
      </catalog-layout>
    `;
  }

  renderTopSegment() {
    const base = this.base;

    return html`
      <div class="top-group box">
        <div class="navigation">
          <fix-breadcrumb></fix-breadcrumb>
        </div>
        <div class="title">${base.getTitle()}</div>
        <div class="indicators">
          ${base.renderStatus("sm")}
          ${base.renderMpn()}
          ${when(features.product_rating,() => base.renderRating())}
        </div>
      </div>
    `;
  }

  renderProductPricesSegment() {
    const base = this.base;
    const discount = base.hasBlock("discount");
    const quantityInput = base.hasBlock("quantity-input");
    const quantityTip = base.hasBlock("quantity-tip");

    return html`
      <div class="price-cart-group">
        <div class="left">
          ${when(discount && features.discount, () => html` <div class="row">${base.renderDiscount()} ${base.renderBadge()}</div> `)} ${base.renderPrice()} ${base.renderVat()}
        </div>
        <div class="right">${base.renderAdd()} ${when(quantityInput, () => base.renderQuantity())} ${when(quantityTip, () => base.renderTip())}</div>
      </div>
    `;
  }

  static styles = css`
    ${priceCartGroupCss}
    ${commons}

    .box {
      background: var(--Neutral-colorWhite, #fff);
    }

    .top-group {
    }

    .top-group .title {
      ${font("Heading/3")};
    }

    .top-group .indicators {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    fix-carousel {
      display: block;
      padding-top: 16px;
      padding-bottom: 16px;
      background: var(--Neutral-colorWhite, #fff);
    }

    .box.offsetsX {
      padding-left: 8px;
      padding-right: 8px;
    }

    .box.offsetsY {
      padding-top: var(--Space-Padding-paddingLG, 24px);
      padding-bottom: var(--Space-Padding-paddingLG, 24px);
    }

    .box + * {
      margin-top: 8px;
    }
      
    .product-shipping {
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
  `;
}
