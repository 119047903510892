import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonCustomize, buttonFont } from "../mobile/fix-button";
import more from "@/assets/more.svg";

@customElement("fix-pager-next")
export class FixPagerNext extends LitElement {
  @property()
  size!: number;

  render() {
    return html`<sl-button @click=${this.onNext}>${this.renderIcon()} ${this.renderText()}</sl-button>`;
  }

  renderIcon() {
    return html`<sl-icon src=${more} slot="prefix"></sl-icon>`;
  }

  renderText() {
    return `Next ${this.size}`;
  }

  onNext = () => {
    this.dispatchEvent(new CustomEvent("pager.next", { bubbles: true, composed: true }));
  };

  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "LG/Normal")}

        sl-button {
      --border-radius: 8px;
      --width: auto;
      --height: 48px;
    }

    sl-button {
      --color: var(--Components-Button-Component-defaultColor, #27272a);
      --color-bg: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-border: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-hover: var(--Components-Button-Component-defaultHoverColor, #60a5fa);
      --color-bg-hover: var(--Components-Button-Component-defaultHoverBg, #fff);
      --color-border-hover: var(--Components-Button-Component-defaultHoverBorderColor, #60a5fa);
      --color-active: var(--Components-Button-Component-defaultActiveColor, #2563eb);
      --color-bg-active: var(--Components-Button-Component-defaultActiveBg, #fff);
      --color-border-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
    }

    sl-button {
      --width: 100%;
      width: 100%;
    }
  `;
}
