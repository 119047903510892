import { customElement, query, state } from "lit/decorators.js";
import { layout } from "../cont";
import { LitElement, css, html } from "lit";
import "@/elements/mobile/fix-lang";

import "../extracted/fix-search";
import "../extracted/fix-dropdown-head";
import "../extracted/fix-brand";
import "../extracted/fix-menu-button";
import "../extracted/fix-garage-button";
import "../extracted/fix-icon-indicator";
import "../extracted/cart-control";
import "../../../elements/fix-language";
import "../../../elements/fix-country";
import "../../../elements/fix-currency";

import "./fix-categories-button";
import "../extracted/cart-control";
import "./customer-popup";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { consume } from "@lit/context";
import { routerInstance } from "@/app-root.ts";
import {when} from "lit/directives/when.js";
import {features} from "@/constants/features";
import { Url } from "@/helpers/url.ts";

@customElement("desktop-head-element")
@layout()
export class DesktopHeadElement extends LitElement {
  @query("fix-menu-button")
  menuButton!: HTMLElement;

  @query("fix-categories-button")
  categoryButton!: HTMLElement;

  @consume({ context: customerChannelContext })
  customerContext!: CustomerChannel;

  render() {
    return html` ${this.desktopHead} `;
  }

  get desktopHead() {
    const isHomePage = ["home-page", "home-page-with-lang"].includes(routerInstance.location?.route?.name || "");

    return html`
      <div class="head">
        ${when(
            features.menu,
            () => html`
              <div class="menu">
                <fix-menu-button @click=${this.onMenuClick}></fix-menu-button>
              </div>`,
        )}
        <div class="brand">
          ${when(isHomePage,
            () => html`<fix-brand type="lg"></fix-brand>`,
            () => html`<fix-link class="item" href="${Url.to("home-page-with-lang")}"><fix-brand type="lg"></fix-brand></fix-link>`
          )}
        </div>
        <div class="controls center">
          <fix-language></fix-language>
          <fix-country></fix-country>
          <fix-currency></fix-currency>
        </div>
        <div class="controls right">
          <customer-head></customer-head>
          <cart-control-dropdown></cart-control-dropdown>
        </div>
        <div class="delimiter"></div>
        <div class="categories">
          <fix-categories-button @click=${this.onCategoryClick}></fix-categories-button>
        </div>
        <div class="searchline">
          <div class="search">
            <fix-search type="lg"></fix-search>
          </div>
          ${when(
              features.product_variations,
              () => html`
                <div class="garage">
                  <fix-garage-button></fix-garage-button>
                </div>`,
          )}
        </div>
      </div>
    `;
  }

  onMenuClick = () => {
    this.dispatchEvent(
      new CustomEvent("menuClick", {
        bubbles: true,
        composed: true,
        detail: { target: this.menuButton },
      }),
    );
  };

  onCategoryClick = () => {
    this.dispatchEvent(
      new CustomEvent("categoryClick", {
        bubbles: true,
        composed: true,
        detail: { target: this.categoryButton },
      }),
    );
  };

  static styles = css`
    :host {
      background: white;
      display: block;
    }

    .head {
      --outer-gap-h: 48px;
      --inner-gap-h-1: 24px;
      --inner-gap-h-2: 14px;
      --inner-gap-h-controls-center: 21px;
      --inner-gap-h-controls-right: 24px;
      --line-min-height: 64px;
      display: grid;
      grid-template-columns: min-content min-content 1fr min-content;
      grid-template-rows: minmax(var(--line-min-height), auto) minmax(var(--line-min-height), auto);
      align-items: center;
      margin-left: var(--outer-gap-h);
      margin-right: var(--outer-gap-h);
      white-space: nowrap;
    }

    .menu + * {
      margin-left: var(--inner-gap-h-2);
    }

    * + .searchline {
      margin-left: var(--inner-gap-h-1);
    }

    .searchline {
      gap: var(--inner-gap-h-1);
    }

    .menu {
      grid-row: 1;
      grid-column: 1;
    }

    .brand {
      grid-row: 1;
      //grid-column: -1 / 2;
    }

    .delimiter {
      grid-row: 2;
      grid-column: 1 / -1;
      border-top: 1px solid var(--Neutral-Border-colorBorder, #d9d9d9);
      height: 100%;
      position: relative;
      box-sizing: border-box;
      z-index: 0;
      margin-left: calc(-1 * var(--outer-gap-h));
      margin-right: calc(-1 * var(--outer-gap-h));
    }

    .controls.center {
      grid-row: 1;
      grid-column: 3;
      margin-left: var(--inner-gap-h-1);
    }

    .controls.right {
      grid-row: 1;
      grid-column: 4;
    }

    .categories {
      grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .searchline {
      grid-row: 2;
      grid-column-start: 3;
      grid-column-end: 5;
    }

    .controls.center {
      --icon-gap: 10px;
      display: flex;
      gap: 21px;
    }

    .controls.right {
      --icon-gap: 4px;
      display: flex;
      gap: 24px;
    }

    .searchline {
      display: flex;
    }

    .search {
      flex-grow: 1;
    }
  `;
}
