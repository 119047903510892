import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
};

export type AccountInput = {
  billingEmail?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Address = {
  __typename?: 'Address';
  additionalInformation: Maybe<Scalars['String']['output']>;
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  companyReg: Maybe<Scalars['String']['output']>;
  companyVat: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  defaultBillingAddress: Maybe<Scalars['Boolean']['output']>;
  defaultShippingAddress: Maybe<Scalars['Boolean']['output']>;
  defaultTransitAddress: Maybe<Scalars['Boolean']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vat: Maybe<Vat>;
  zip: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyReg?: InputMaybe<Scalars['String']['input']>;
  companyVat?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  defaultTransitAddress?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AggregatedOrderTotal = {
  __typename?: 'AggregatedOrderTotal';
  additionalInfo: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  value: Maybe<Money>;
};

export type AggregationBucketPayload = {
  __typename?: 'AggregationBucketPayload';
  count: Maybe<Scalars['Int']['output']>;
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type AggregationFacetInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AggregationInput = {
  items?: InputMaybe<Array<InputMaybe<AggregationFacetInput>>>;
};

export type AggregationPayload = {
  __typename?: 'AggregationPayload';
  buckets: Maybe<Array<Maybe<AggregationBucketPayload>>>;
  name: Maybe<Scalars['String']['output']>;
  pagination: Maybe<Pagination>;
};

export type AvailableBrsb = {
  __typename?: 'AvailableBrsb';
  chainHash: Maybe<Scalars['String']['output']>;
  childRelationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  relationSupplierHash: Maybe<Scalars['String']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
};

export type BoxTemplate = {
  __typename?: 'BoxTemplate';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  depth: Maybe<Scalars['Int']['output']>;
  height: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  sortWeight: Maybe<Scalars['Int']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type BrandInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  specificationAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandWeight = {
  __typename?: 'BrandWeight';
  brand: Maybe<Scalars['String']['output']>;
  weight: Maybe<Weight>;
};

export type Business = {
  code: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultCurrency: Maybe<Currency>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  legalAddress: Maybe<Scalars['String']['output']>;
  legalAddressCity: Maybe<Scalars['String']['output']>;
  legalAddressRegion: Maybe<Scalars['String']['output']>;
  legalAddressZip: Maybe<Scalars['String']['output']>;
  location: Maybe<Array<Maybe<WarehouseLocation>>>;
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessBrand = {
  __typename?: 'BusinessBrand';
  business: Maybe<Business>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryTermExpress: Maybe<Scalars['Int']['output']>;
  deliveryTermStandard: Maybe<Scalars['Int']['output']>;
  esteriolHash: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  specificationAvailable: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessDistributor = Business & {
  __typename?: 'BusinessDistributor';
  actualAddress: Maybe<Scalars['String']['output']>;
  banks: Maybe<Array<Maybe<BusinessDistributorBank>>>;
  code: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultCurrency: Maybe<Currency>;
  deleted: Maybe<Scalars['Boolean']['output']>;
  domain: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceLanguage: Maybe<Scalars['String']['output']>;
  invoiceNumberPrefix: Maybe<Scalars['String']['output']>;
  isAdyenEnabled: Maybe<Scalars['Boolean']['output']>;
  isDuplicateMailSentToSupplier: Maybe<Scalars['Boolean']['output']>;
  isInvoiceExporterNoteEnabled: Maybe<Scalars['Boolean']['output']>;
  isPaypalEnabled: Maybe<Scalars['Boolean']['output']>;
  issuer: Maybe<Scalars['String']['output']>;
  legalAddress: Maybe<Scalars['String']['output']>;
  legalAddressCity: Maybe<Scalars['String']['output']>;
  legalAddressRegion: Maybe<Scalars['String']['output']>;
  legalAddressZip: Maybe<Scalars['String']['output']>;
  location: Maybe<Array<Maybe<WarehouseLocation>>>;
  logo: Maybe<Scalars['String']['output']>;
  main: Maybe<Scalars['Boolean']['output']>;
  markupFormulaExtraCosts: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  phone: Maybe<Scalars['String']['output']>;
  regNr: Maybe<Scalars['String']['output']>;
  skipProfitInManagerGoals: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vatCoefficient: Maybe<Scalars['Int']['output']>;
  vatNr: Maybe<Scalars['String']['output']>;
};

export type BusinessDistributorBank = {
  __typename?: 'BusinessDistributorBank';
  accounts: Maybe<Array<Maybe<BusinessDistributorBankAccount>>>;
  address: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  default: Maybe<Scalars['Boolean']['output']>;
  iban: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  swift: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessDistributorBankAccount = {
  __typename?: 'BusinessDistributorBankAccount';
  account: Maybe<Scalars['String']['output']>;
  businessDistributorBank: Maybe<BusinessDistributorBank>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currency: Maybe<Currency>;
  id: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessRelationship = {
  __typename?: 'BusinessRelationship';
  child: Maybe<Business>;
  createBrsbOnBrandAdd: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  markup: Maybe<Scalars['Int']['output']>;
  parent: Maybe<Business>;
  synchronize: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessRelationshipSupplierBrand = {
  __typename?: 'BusinessRelationshipSupplierBrand';
  businessRelationship: Maybe<BusinessRelationship>;
  businessSupplierBrand: Maybe<BusinessSupplierBrand>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  deliveryTermExpressMax: Maybe<Scalars['Int']['output']>;
  deliveryTermExpressMin: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMax: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMin: Maybe<Scalars['Int']['output']>;
  directDistributor: Maybe<Business>;
  id: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  isParentEnabled: Maybe<Scalars['Boolean']['output']>;
  markup: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<Employee>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessSupplier = Business & {
  __typename?: 'BusinessSupplier';
  brands: Maybe<Array<Maybe<BusinessSupplierBrand>>>;
  code: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultCurrency: Maybe<Currency>;
  email: Maybe<Scalars['String']['output']>;
  enabledSupplierLocations: Maybe<Array<Maybe<SupplierLocation>>>;
  id: Maybe<Scalars['String']['output']>;
  invoiceComment: Maybe<Scalars['String']['output']>;
  legalAddress: Maybe<Scalars['String']['output']>;
  legalAddressCity: Maybe<Scalars['String']['output']>;
  legalAddressRegion: Maybe<Scalars['String']['output']>;
  legalAddressZip: Maybe<Scalars['String']['output']>;
  location: Maybe<Array<Maybe<WarehouseLocation>>>;
  name: Maybe<Scalars['String']['output']>;
  onlyStockProducts: Maybe<Scalars['Boolean']['output']>;
  publicName: Maybe<Scalars['String']['output']>;
  realName: Maybe<Scalars['String']['output']>;
  sellAllowed: Maybe<Scalars['Boolean']['output']>;
  supplierLocations: Maybe<Array<Maybe<SupplierLocation>>>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProcessor: Maybe<Scalars['String']['output']>;
};

export type BusinessSupplierBrand = {
  __typename?: 'BusinessSupplierBrand';
  brand: Maybe<BusinessBrand>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryTermExpressMax: Maybe<Scalars['Int']['output']>;
  deliveryTermExpressMin: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMax: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMin: Maybe<Scalars['Int']['output']>;
  distributor: Maybe<BusinessDistributor>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  supplier: Maybe<BusinessSupplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type Cart = {
  __typename?: 'Cart';
  availableCoupons: Maybe<Array<Maybe<Coupon>>>;
  billingAddressId: Maybe<Scalars['String']['output']>;
  billingAddressLine1: Maybe<Scalars['String']['output']>;
  billingAddressLine2: Maybe<Scalars['String']['output']>;
  billingCity: Maybe<Scalars['String']['output']>;
  billingCompanyName: Maybe<Scalars['String']['output']>;
  billingCompanyReg: Maybe<Scalars['String']['output']>;
  billingCompanyVat: Maybe<Scalars['String']['output']>;
  billingCountry: Maybe<Country>;
  billingFirstName: Maybe<Scalars['String']['output']>;
  billingLastName: Maybe<Scalars['String']['output']>;
  billingNickname: Maybe<Scalars['String']['output']>;
  billingPhone: Maybe<Scalars['String']['output']>;
  billingRegion: Maybe<Scalars['String']['output']>;
  billingSameAsShipping: Maybe<Scalars['Boolean']['output']>;
  billingVatData: Maybe<Vat>;
  billingZip: Maybe<Scalars['String']['output']>;
  cartProducts: Maybe<Array<Maybe<CartProduct>>>;
  comment: Maybe<Scalars['String']['output']>;
  coupons: Maybe<Array<Maybe<Coupon>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currencyCode: Maybe<Scalars['String']['output']>;
  currencyRate: Maybe<Scalars['Int']['output']>;
  customer: Maybe<Customer>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceReference: Maybe<Scalars['String']['output']>;
  isShippingInsuranceApplied: Maybe<Scalars['Boolean']['output']>;
  isTransitIncluded: Maybe<Scalars['Boolean']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  paymentType: Maybe<Scalars['String']['output']>;
  shippingAddressId: Maybe<Scalars['String']['output']>;
  shippingAddressLine1: Maybe<Scalars['String']['output']>;
  shippingAddressLine2: Maybe<Scalars['String']['output']>;
  shippingCity: Maybe<Scalars['String']['output']>;
  shippingCompanyName: Maybe<Scalars['String']['output']>;
  shippingCompanyReg: Maybe<Scalars['String']['output']>;
  shippingCompanyVat: Maybe<Scalars['String']['output']>;
  shippingCountry: Maybe<Country>;
  shippingFirstName: Maybe<Scalars['String']['output']>;
  shippingLastName: Maybe<Scalars['String']['output']>;
  shippingMethod: Maybe<Scalars['String']['output']>;
  shippingNickname: Maybe<Scalars['String']['output']>;
  shippingPhone: Maybe<Scalars['String']['output']>;
  shippingRegion: Maybe<Scalars['String']['output']>;
  shippingSelfPickup: Maybe<Scalars['Boolean']['output']>;
  shippingSupplier: Maybe<Scalars['String']['output']>;
  shippingVatData: Maybe<Vat>;
  shippingZip: Maybe<Scalars['String']['output']>;
  totals: Maybe<Array<Maybe<CartTotal>>>;
  transitAddressId: Maybe<Scalars['String']['output']>;
  transitAddressLine1: Maybe<Scalars['String']['output']>;
  transitAddressLine2: Maybe<Scalars['String']['output']>;
  transitCity: Maybe<Scalars['String']['output']>;
  transitCompanyName: Maybe<Scalars['String']['output']>;
  transitCompanyReg: Maybe<Scalars['String']['output']>;
  transitCompanyVat: Maybe<Scalars['String']['output']>;
  transitCountry: Maybe<Country>;
  transitFirstName: Maybe<Scalars['String']['output']>;
  transitLastName: Maybe<Scalars['String']['output']>;
  transitNickname: Maybe<Scalars['String']['output']>;
  transitPhone: Maybe<Scalars['String']['output']>;
  transitRegion: Maybe<Scalars['String']['output']>;
  transitZip: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CartPayload = {
  __typename?: 'CartPayload';
  adyenClientKey: Maybe<Scalars['String']['output']>;
  adyenEnvironment: Maybe<Scalars['String']['output']>;
  adyenPaymentMethods: Maybe<PaymentMethodsList>;
  cart: Maybe<Cart>;
  currency: Maybe<Scalars['String']['output']>;
  defaultCountry: Maybe<Scalars['String']['output']>;
  needToAgreeSanctionTerms: Maybe<Scalars['Boolean']['output']>;
  paypalClientId: Maybe<Scalars['String']['output']>;
  paypalCommit: Maybe<Scalars['String']['output']>;
  paypalIntent: Maybe<Scalars['String']['output']>;
  shippingSelfPickup: Maybe<Scalars['Boolean']['output']>;
};

export type CartProduct = {
  __typename?: 'CartProduct';
  addedFromBrand: Maybe<Product>;
  addedFromMpn: Maybe<Scalars['String']['output']>;
  addedFromProduct: Maybe<Product>;
  addedFromType: Maybe<Scalars['String']['output']>;
  availableQuantity: Maybe<Scalars['Int']['output']>;
  businessRelationshipSupplierChainHash: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  depositAmount: Maybe<Money>;
  discount: Maybe<Scalars['Int']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  exchangeProduct: Maybe<Product>;
  id: Maybe<Scalars['String']['output']>;
  initialPrice: Maybe<Money>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  productDiscount: Maybe<Discount>;
  quantity: Maybe<Scalars['Int']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CartTotal = {
  __typename?: 'CartTotal';
  additionalInfo: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Money>;
};

export type Company = {
  __typename?: 'Company';
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  id: Maybe<Scalars['String']['output']>;
  isEuropeanUnion: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vat: Maybe<Scalars['String']['output']>;
};

export type Country = {
  __typename?: 'Country';
  code: Maybe<Scalars['String']['output']>;
  currencyCode: Maybe<Scalars['String']['output']>;
  esteriolHash: Maybe<Scalars['String']['output']>;
  europeanUnion: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  nameRu: Maybe<Scalars['String']['output']>;
  vatRate: Maybe<Scalars['Int']['output']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  businesses: Maybe<Array<Maybe<BusinessDistributor>>>;
  cartFilter: Maybe<Scalars['String']['output']>;
  cartProductFilter: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  dateTimeFrom: Maybe<Scalars['DateTime']['output']>;
  dateTimeTo: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountType: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  mathOperator: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  profit: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Float']['output']>;
  status: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  usedCount: Maybe<Scalars['Int']['output']>;
  value: Maybe<Scalars['Int']['output']>;
};

export type CreditNoteCoupon = {
  __typename?: 'CreditNoteCoupon';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCreditNote: Maybe<InvoiceCreditNote>;
  orderCoupon: Maybe<OrderCoupon>;
  total: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CreditNoteDocumentInput = {
  creditNoteId?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
};

export type CreditNotesInput = {
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreditNotesPayload = {
  __typename?: 'CreditNotesPayload';
  creditNotes: Maybe<Array<Maybe<InvoiceCreditNote>>>;
  pagination: Maybe<Pagination>;
};

export type Currency = {
  __typename?: 'Currency';
  base: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  date: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type Customer = {
  abbr: Maybe<Scalars['String']['output']>;
  accountingMarker: Maybe<Scalars['String']['output']>;
  activatedAt: Maybe<Scalars['DateTime']['output']>;
  addresses: Maybe<Array<Maybe<Address>>>;
  balance: Maybe<Money>;
  bankTransfer: Maybe<Scalars['Boolean']['output']>;
  banned: Maybe<Scalars['Boolean']['output']>;
  billingEmail: Maybe<Scalars['String']['output']>;
  boxes: Maybe<Array<Maybe<CustomerBox>>>;
  brandMarkups: Maybe<Array<Maybe<CustomerBrandMarkup>>>;
  business: Maybe<Business>;
  canNotOrderLessThanMin: Maybe<Scalars['Boolean']['output']>;
  cart: Maybe<Cart>;
  cartProductsCount: Maybe<Scalars['Int']['output']>;
  comments: Maybe<Array<Maybe<CustomerComment>>>;
  companyName: Maybe<Scalars['String']['output']>;
  companyReg: Maybe<Scalars['String']['output']>;
  companyVat: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdFromOrder: Maybe<Order>;
  creditLimit: Maybe<Money>;
  currency: Maybe<Scalars['String']['output']>;
  dateFormat: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  expiredInvoicesNotifications: Maybe<Scalars['Boolean']['output']>;
  guest: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  individualDelivery: Maybe<Scalars['Boolean']['output']>;
  isBusinessCabinetAllowed: Maybe<Scalars['Boolean']['output']>;
  isCompany: Maybe<Scalars['Boolean']['output']>;
  isFullOrderPacking: Maybe<Scalars['Boolean']['output']>;
  isInvoiceHSCodeVisible: Maybe<Scalars['Boolean']['output']>;
  isNotPackingBrandedTape: Maybe<Scalars['Boolean']['output']>;
  isNotPackingPallet: Maybe<Scalars['Boolean']['output']>;
  isReconciliationDownloadable: Maybe<Scalars['Boolean']['output']>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  lastOrder: Maybe<Order>;
  lastVisit: Maybe<Scalars['DateTime']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  manager: Maybe<Employee>;
  markupFormulaExtraCosts: Maybe<Scalars['String']['output']>;
  notifications: Maybe<Array<Maybe<CustomerNotification>>>;
  number: Maybe<Scalars['Int']['output']>;
  orderLock: Maybe<Scalars['Boolean']['output']>;
  orders: Maybe<Array<Maybe<Order>>>;
  ordersCount: Maybe<Scalars['Int']['output']>;
  ordersTotal: Maybe<Money>;
  packingBoxTemplate: Maybe<BoxTemplate>;
  packingCustomConditions: Maybe<Scalars['String']['output']>;
  paymentTerm: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  potential: Maybe<Scalars['Boolean']['output']>;
  remainingBalance: Maybe<Money>;
  requests: Maybe<Array<Maybe<CustomerRequest>>>;
  selfPickup: Maybe<Scalars['Boolean']['output']>;
  shippingIndividual: Maybe<Scalars['Boolean']['output']>;
  source: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  useAsRootPrice: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerAddressesPayload = {
  __typename?: 'CustomerAddressesPayload';
  Addresses: Maybe<Array<Maybe<Address>>>;
  Pagination: Maybe<Pagination>;
};

export type CustomerBox = {
  __typename?: 'CustomerBox';
  childBoxes: Maybe<Array<Maybe<CustomerBox>>>;
  code: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customer: Maybe<Customer>;
  id: Maybe<Scalars['String']['output']>;
  latestPackingList: Maybe<Document>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  packingLists: Maybe<Array<Maybe<Document>>>;
  parent: Maybe<CustomerBox>;
  products: Maybe<Array<Maybe<CustomerBoxProduct>>>;
  productsCount: Maybe<Scalars['Int']['output']>;
  qrLabelComment: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  status: Maybe<Scalars['String']['output']>;
  totalWeight: Maybe<Weight>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerBoxProduct = {
  __typename?: 'CustomerBoxProduct';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customerBox: Maybe<CustomerBox>;
  id: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  orderProduct: Maybe<OrderProduct>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerBoxProductsListInput = {
  customerBoxId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerBoxProductsListPayload = {
  __typename?: 'CustomerBoxProductsListPayload';
  pagination: Maybe<Pagination>;
  products: Maybe<Array<Maybe<CustomerBoxProduct>>>;
};

export type CustomerBrandMarkup = {
  __typename?: 'CustomerBrandMarkup';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customer: Maybe<Customer>;
  id: Maybe<Scalars['String']['output']>;
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  markup: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<Employee>;
  publicMarkup: Maybe<Scalars['Int']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerComment = {
  __typename?: 'CustomerComment';
  content: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerInvoicesInput = {
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerInvoicesPayload = {
  __typename?: 'CustomerInvoicesPayload';
  expiredInvoicesNotifications: Maybe<Scalars['Boolean']['output']>;
  invoices: Maybe<Array<Maybe<InvoiceCustomer>>>;
  pagination: Maybe<Pagination>;
};

export type CustomerMutationType = {
  __typename?: 'CustomerMutationType';
  AccountRemoveRequest: Customer;
  AddRequestFromCart: CustomerRequest;
  Address: Address;
  ChangePassword: Customer;
  Customer: Customer;
  CustomerLastVisit: Scalars['Boolean']['output'];
  CustomerPassword: Maybe<Scalars['Boolean']['output']>;
  CustomerPreferences: CustomerPreference;
  CustomerRequestToWorkspace: CustomerWorkspace;
  CustomerWorkspaceClear: CustomerWorkspace;
  CustomerWorkspaceCreate: CustomerWorkspace;
  CustomerWorkspaceCreateRequest: CustomerRequest;
  CustomerWorkspaceFixQuantities: CustomerWorkspace;
  CustomerWorkspaceProductByCode: CustomerWorkspace;
  CustomerWorkspaceProductById: CustomerWorkspace;
  CustomerWorkspaceProductChangeSupplier: CustomerWorkspace;
  CustomerWorkspaceProductQuantity: CustomerWorkspace;
  CustomerWorkspaceProductsActivate: CustomerWorkspace;
  CustomerWorkspaceProductsByCodes: CustomerWorkspace;
  CustomerWorkspaceProductsByFile: CustomerWorkspace;
  CustomerWorkspaceProductsByIdsAndSuppliers: CustomerWorkspace;
  CustomerWorkspaceProductsChangeSuppliers: CustomerWorkspace;
  CustomerWorkspaceProductsRemove: CustomerWorkspace;
  CustomerWorkspaceProductsReview: CustomerWorkspace;
  CustomerWorkspaceRemove: CustomerWorkspace;
  CustomerWorkspaceRename: CustomerWorkspace;
  CustomerWorkspaceToCart: Cart;
  DefectiveRequest: Maybe<Scalars['Boolean']['output']>;
  Export: Maybe<Scalars['String']['output']>;
  Login: CustomerPayload;
  RegisterFromOrder: Customer;
  RemoveAddress: Address;
  RemoveUploadedFile: Maybe<Scalars['String']['output']>;
  RequestDocumentUrl: UploadedFile;
  ResetPasswordRequest: Maybe<Scalars['Boolean']['output']>;
  WorkspaceDocumentUrl: UploadedFile;
};


export type CustomerMutationTypeAccountRemoveRequestArgs = {
  agree: Scalars['Boolean']['input'];
  domain: Scalars['String']['input'];
};


export type CustomerMutationTypeAddRequestFromCartArgs = {
  cartId: Scalars['String']['input'];
};


export type CustomerMutationTypeAddressArgs = {
  input: AddressInput;
};


export type CustomerMutationTypeChangePasswordArgs = {
  input: PasswordInput;
};


export type CustomerMutationTypeCustomerArgs = {
  input: AccountInput;
};


export type CustomerMutationTypeCustomerPasswordArgs = {
  input: ResetPasswordInput;
};


export type CustomerMutationTypeCustomerPreferencesArgs = {
  input: CustomerPreferenceInput;
};


export type CustomerMutationTypeCustomerRequestToWorkspaceArgs = {
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceClearArgs = {
  id: InputMaybe<Scalars['String']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceCreateArgs = {
  name: InputMaybe<Scalars['String']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceCreateRequestArgs = {
  input: CustomerWorkspaceCreateRequestInput;
};


export type CustomerMutationTypeCustomerWorkspaceFixQuantitiesArgs = {
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceProductByCodeArgs = {
  filter: InputMaybe<CustomerWorkspaceFilter>;
  input: ProductSearchInput;
};


export type CustomerMutationTypeCustomerWorkspaceProductByIdArgs = {
  businessRelationSupplierBrandId: InputMaybe<Scalars['String']['input']>;
  chainHash: InputMaybe<Scalars['String']['input']>;
  exchangeFromProductId: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CustomerWorkspaceFilter>;
  fromId: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  quantity: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceProductChangeSupplierArgs = {
  businessRelationSupplierBrandId: Scalars['String']['input'];
  chainHash: Scalars['String']['input'];
  customerWorkspaceProductId: Scalars['String']['input'];
  filter: InputMaybe<CustomerWorkspaceFilter>;
};


export type CustomerMutationTypeCustomerWorkspaceProductQuantityArgs = {
  customerWorkspaceProductId: Scalars['String']['input'];
  filter: InputMaybe<CustomerWorkspaceFilter>;
  quantity: Scalars['Int']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceProductsActivateArgs = {
  customerWorkspaceProductIds: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceProductsByCodesArgs = {
  brands: InputMaybe<Array<InputMaybe<BrandInput>>>;
  codes: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceProductsByFileArgs = {
  brands: InputMaybe<Array<InputMaybe<BrandInput>>>;
  file: Scalars['String']['input'];
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceProductsByIdsAndSuppliersArgs = {
  input: CustomerWorkspaceProductsByIdsAndSuppliersInput;
};


export type CustomerMutationTypeCustomerWorkspaceProductsChangeSuppliersArgs = {
  customerWorkspaceId: Scalars['String']['input'];
  customerWorkspaceProductIds: Array<InputMaybe<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceProductsRemoveArgs = {
  customerWorkspaceProductIds: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceProductsReviewArgs = {
  customerWorkspaceProducts: InputMaybe<Array<InputMaybe<WorkspaceProductReviewInput>>>;
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type CustomerMutationTypeCustomerWorkspaceRemoveArgs = {
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceRenameArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type CustomerMutationTypeCustomerWorkspaceToCartArgs = {
  id: Scalars['String']['input'];
  specificProducts: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CustomerMutationTypeDefectiveRequestArgs = {
  input: DefectiveRequestInput;
};


export type CustomerMutationTypeExportArgs = {
  input: InputMaybe<ExportInput>;
};


export type CustomerMutationTypeLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type CustomerMutationTypeRegisterFromOrderArgs = {
  input: RegisterFromOrderInput;
};


export type CustomerMutationTypeRemoveAddressArgs = {
  id: Scalars['String']['input'];
};


export type CustomerMutationTypeRemoveUploadedFileArgs = {
  fileName: Scalars['String']['input'];
};


export type CustomerMutationTypeRequestDocumentUrlArgs = {
  lang: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
};


export type CustomerMutationTypeResetPasswordRequestArgs = {
  email: Scalars['String']['input'];
};


export type CustomerMutationTypeWorkspaceDocumentUrlArgs = {
  lang: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type CustomerNotification = {
  __typename?: 'CustomerNotification';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerOrdersListInput = {
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerOrdersPayload = {
  __typename?: 'CustomerOrdersPayload';
  counts: Maybe<OrderCounts>;
  orders: Maybe<Array<Maybe<Order>>>;
  pagination: Maybe<Pagination>;
  totals: Maybe<OrderTotals>;
};

export type CustomerPayload = {
  __typename?: 'CustomerPayload';
  Customer: Maybe<Customer>;
  authToken: Maybe<Scalars['String']['output']>;
};

export type CustomerPreference = Preference & {
  __typename?: 'CustomerPreference';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type CustomerPreferenceInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CustomerProductInStockInput = {
  productId: Scalars['String']['input'];
  supplierId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerProductInStockPayload = {
  __typename?: 'CustomerProductInStockPayload';
  inStockCount: Maybe<Scalars['Int']['output']>;
  productId: Maybe<Scalars['String']['output']>;
  realStock: Maybe<Scalars['Boolean']['output']>;
  supplierId: Maybe<Scalars['String']['output']>;
};

export type CustomerProductListPayload = {
  __typename?: 'CustomerProductListPayload';
  pagination: Maybe<Pagination>;
  products: Maybe<Array<Maybe<OrderProduct>>>;
  totalPieces: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Money>;
  totalProducts: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Weight>;
};

export type CustomerProductOrdersInput = {
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerProductsInStockInput = {
  products?: InputMaybe<Array<CustomerProductInStockInput>>;
};

export type CustomerProductsOnPackingListInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerQuery = {
  __typename?: 'CustomerQuery';
  Address: Address;
  AddressList: CustomerAddressesPayload;
  CreditNoteDocument: UploadedFile;
  CreditNotes: Maybe<CreditNotesPayload>;
  Customer: Customer;
  CustomerBox: Maybe<CustomerBox>;
  CustomerBoxInvoiceList: Maybe<Array<Maybe<InvoiceCustomer>>>;
  CustomerBoxPackingListUrl: UploadedFile;
  CustomerBoxProducts: CustomerBoxProductsListPayload;
  CustomerBoxesByOrder: Maybe<Array<Maybe<CustomerBox>>>;
  CustomerBrands: Maybe<Array<Maybe<BusinessBrand>>>;
  CustomerByResetPasswordToken: Scalars['Boolean']['output'];
  CustomerInvoiceUrl: UploadedFile;
  CustomerInvoices: Maybe<CustomerInvoicesPayload>;
  CustomerOrders: Maybe<Array<Maybe<Order>>>;
  CustomerPreferences: Maybe<Array<Maybe<CustomerPreference>>>;
  CustomerReconciliationAccountList: Maybe<CustomerReconciliationAccountListPayload>;
  CustomerReconciliationAccountSummary: Maybe<CustomerReconciliationAccountSummaryPayload>;
  CustomerSearchProducts: Maybe<Array<Maybe<CustomerSearchProductPayload>>>;
  CustomerWorkspace: CustomerWorkspace;
  CustomerWorkspaceProductsInStock: Maybe<Array<Maybe<CustomerProductInStockPayload>>>;
  CustomerWorkspaces: Maybe<Array<Maybe<CustomerWorkspace>>>;
  DecodeVin: Maybe<DecodedVinPayload>;
  ExportGenerationStatus: Maybe<ExportStatusPayload>;
  FindOrderProducts: OrdersProductsPayload;
  FindOrderProductsAggregation: Maybe<Array<Maybe<AggregationPayload>>>;
  FindOrderProductsSummary: Maybe<FindOrderProductsSummaryPayload>;
  HolidayEvents: Maybe<Array<Maybe<HolidayEvent>>>;
  InvoiceDocument: UploadedFile;
  LastOrder: Order;
  Order: Order;
  OrderDocumentUrl: UploadedFile;
  OrdersList: CustomerOrdersPayload;
  Product: Product;
  ProductsOnBackorderList: Maybe<CustomerProductListPayload>;
  ProductsOnPackingList: Maybe<CustomerProductListPayload>;
  Request: CustomerRequest;
  RequestList: CustomerRequestPayload;
  SignForUpload: Scalars['String']['output'];
  VatValidation: Maybe<Vat>;
  WorkspaceCustomerCart: Maybe<CartPayload>;
};


export type CustomerQueryAddressArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueryAddressListArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerQueryCreditNoteDocumentArgs = {
  input: CreditNoteDocumentInput;
};


export type CustomerQueryCreditNotesArgs = {
  input: CreditNotesInput;
};


export type CustomerQueryCustomerBoxArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueryCustomerBoxInvoiceListArgs = {
  customerBoxId: Scalars['String']['input'];
};


export type CustomerQueryCustomerBoxPackingListUrlArgs = {
  customerBoxId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  ext: Scalars['String']['input'];
};


export type CustomerQueryCustomerBoxProductsArgs = {
  input: CustomerBoxProductsListInput;
};


export type CustomerQueryCustomerBoxesByOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type CustomerQueryCustomerByResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};


export type CustomerQueryCustomerInvoiceUrlArgs = {
  customerInvoiceId: Scalars['String']['input'];
  ext: Scalars['String']['input'];
};


export type CustomerQueryCustomerInvoicesArgs = {
  input: CustomerInvoicesInput;
};


export type CustomerQueryCustomerReconciliationAccountListArgs = {
  input: CustomerReconciliationAccountListInput;
};


export type CustomerQueryCustomerReconciliationAccountSummaryArgs = {
  filter: InputMaybe<Array<InputMaybe<FilterInput>>>;
};


export type CustomerQueryCustomerSearchProductsArgs = {
  mpn: Scalars['String']['input'];
};


export type CustomerQueryCustomerWorkspaceArgs = {
  filter: InputMaybe<CustomerWorkspaceFilter>;
  id: Scalars['String']['input'];
};


export type CustomerQueryCustomerWorkspaceProductsInStockArgs = {
  input: CustomerProductsInStockInput;
};


export type CustomerQueryDecodeVinArgs = {
  vin: Scalars['String']['input'];
};


export type CustomerQueryExportGenerationStatusArgs = {
  processId: Scalars['String']['input'];
};


export type CustomerQueryFindOrderProductsArgs = {
  input: InputMaybe<CustomerProductOrdersInput>;
};


export type CustomerQueryFindOrderProductsAggregationArgs = {
  input: AggregationInput;
};


export type CustomerQueryFindOrderProductsSummaryArgs = {
  filter: InputMaybe<Array<InputMaybe<FilterInput>>>;
};


export type CustomerQueryInvoiceDocumentArgs = {
  input: InvoiceDocumentInput;
};


export type CustomerQueryOrderArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueryOrderDocumentUrlArgs = {
  documentId: Scalars['String']['input'];
  ext: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};


export type CustomerQueryOrdersListArgs = {
  input: CustomerOrdersListInput;
};


export type CustomerQueryProductArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueryProductsOnBackorderListArgs = {
  input: InputMaybe<CustomerProductsOnPackingListInput>;
};


export type CustomerQueryProductsOnPackingListArgs = {
  input: InputMaybe<CustomerProductsOnPackingListInput>;
};


export type CustomerQueryRequestArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueryRequestListArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};


export type CustomerQuerySignForUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type CustomerQueryVatValidationArgs = {
  input: InputMaybe<VatValidationInput>;
};

export type CustomerReconciliationAccountListInput = {
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerReconciliationAccountListPayload = {
  __typename?: 'CustomerReconciliationAccountListPayload';
  pagination: Maybe<Pagination>;
  reconciliationAccountList: Maybe<Array<Maybe<ReconciliationAccount>>>;
};

export type CustomerReconciliationAccountSummaryPayload = {
  __typename?: 'CustomerReconciliationAccountSummaryPayload';
  previousMaxDate: Maybe<Scalars['DateTime']['output']>;
  previousMinDate: Maybe<Scalars['DateTime']['output']>;
  previousTotalCredit: Maybe<Money>;
  previousTotalDebit: Maybe<Money>;
  rangeMaxDate: Maybe<Scalars['DateTime']['output']>;
  rangeMinDate: Maybe<Scalars['DateTime']['output']>;
  rangeTotalCredit: Maybe<Money>;
  rangeTotalDebit: Maybe<Money>;
  totalCredit: Maybe<Money>;
  totalDebit: Maybe<Money>;
  totalDebt: Maybe<Money>;
};

export type CustomerRequest = {
  __typename?: 'CustomerRequest';
  accessCode: Maybe<Scalars['String']['output']>;
  closedDate: Maybe<Scalars['DateTime']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  completion: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  hash: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isArchived: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  orderDate: Maybe<Scalars['DateTime']['output']>;
  parent: Maybe<CustomerRequest>;
  requestProducts: Maybe<Array<Maybe<CustomerRequestProduct>>>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  sentLog: Maybe<Array<Maybe<CustomerRequestSent>>>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerRequestPayload = {
  __typename?: 'CustomerRequestPayload';
  Pagination: Maybe<Pagination>;
  Requests: Maybe<Array<Maybe<CustomerRequest>>>;
  completedCount: Maybe<Scalars['Int']['output']>;
  processedCount: Maybe<Scalars['Int']['output']>;
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type CustomerRequestProduct = {
  __typename?: 'CustomerRequestProduct';
  businessRelationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  chainHash: Maybe<Scalars['String']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customerWorkspaceProduct: Maybe<CustomerWorkspaceProduct>;
  deliveryDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  linkedRequestProduct: Maybe<CustomerRequestProduct>;
  number: Maybe<Scalars['Int']['output']>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  request: Maybe<CustomerRequest>;
  stockCount: Maybe<Scalars['Int']['output']>;
  stockExchangeMpn: Maybe<Scalars['String']['output']>;
  stockStatus: Maybe<Scalars['String']['output']>;
  totalPrice: Maybe<Money>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerRequestSent = {
  __typename?: 'CustomerRequestSent';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  request: Maybe<CustomerRequest>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  sentTo: Maybe<Scalars['String']['output']>;
  sentToBusinessName: Maybe<Scalars['String']['output']>;
  toBusiness: Maybe<Business>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerSearchProductPayload = {
  __typename?: 'CustomerSearchProductPayload';
  originMpn: Maybe<Scalars['String']['output']>;
  originProductId: Maybe<Scalars['String']['output']>;
  product: Maybe<Product>;
};

export type CustomerWorkspace = {
  __typename?: 'CustomerWorkspace';
  activeItemCount: Maybe<Scalars['Int']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  files: Maybe<Array<Maybe<CustomerWorkspaceFile>>>;
  id: Maybe<Scalars['String']['output']>;
  isProcessing: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  products: Maybe<Array<Maybe<CustomerWorkspaceProduct>>>;
  requests: Maybe<Array<Maybe<CustomerRequest>>>;
  status: Maybe<Scalars['String']['output']>;
  subtotal: Maybe<Money>;
  totalWeight: Maybe<Weight>;
  totalWeightInKg: Maybe<Scalars['Float']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerWorkspaceCreateRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  workspaceProductIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CustomerWorkspaceFile = {
  __typename?: 'CustomerWorkspaceFile';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customer: Maybe<Customer>;
  fileSize: Maybe<Scalars['Int']['output']>;
  filename: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerWorkspaceFilter = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAllItems?: InputMaybe<Scalars['Boolean']['input']>;
  isAnalogues?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerWorkspaceProduct = {
  __typename?: 'CustomerWorkspaceProduct';
  analogues: Maybe<Array<Maybe<Product>>>;
  businessRelationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  businessRelationshipSupplierChainHash: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  fromCustomerWorkspaceProduct: Maybe<CustomerWorkspaceProduct>;
  fromCustomerWorkspaceProductBrand: Maybe<BusinessBrand>;
  fromCustomerWorkspaceProductMpn: Maybe<Scalars['String']['output']>;
  fromCustomerWorkspaceProductStatus: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  lastOrderedProduct: Maybe<OrderProduct>;
  lastProductRequest: Maybe<CustomerRequestProduct>;
  orderedAt: Maybe<Scalars['DateTime']['output']>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  productMpn: Maybe<Scalars['String']['output']>;
  productStatus: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  requestProducts: Maybe<Array<Maybe<CustomerRequestProduct>>>;
  searchedData: Maybe<SearchedData>;
  sort: Maybe<Scalars['Int']['output']>;
  status: Maybe<Scalars['String']['output']>;
  total: Maybe<Money>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerWorkspaceProductByIdAndSupplierInput = {
  businessRelationSupplierBrandId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type CustomerWorkspaceProductsByIdsAndSuppliersInput = {
  filter?: InputMaybe<CustomerWorkspaceFilter>;
  products?: InputMaybe<Array<CustomerWorkspaceProductByIdAndSupplierInput>>;
  workspaceId: Scalars['String']['input'];
};

export type DecodedVinPayload = {
  __typename?: 'DecodedVinPayload';
  assemblyPlant: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  manufacturer: Maybe<Scalars['String']['output']>;
  modelYear: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  serialNumber: Maybe<Scalars['String']['output']>;
  vds: Maybe<Scalars['String']['output']>;
  vis: Maybe<Scalars['String']['output']>;
  wmi: Maybe<Scalars['String']['output']>;
  year: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DefectiveRequestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  mpn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  depth: Scalars['Decimal']['output'];
  height: Scalars['Decimal']['output'];
  isOverSize: Scalars['Boolean']['output'];
  isOverVolume: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
  volume: Scalars['Decimal']['output'];
  width: Scalars['Decimal']['output'];
};

export type Discount = {
  __typename?: 'Discount';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dateTimeFrom: Maybe<Scalars['DateTime']['output']>;
  dateTimeTo: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  filter: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  mathOperator: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Int']['output']>;
};

export type Document = {
  __typename?: 'Document';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  files: Maybe<Array<Maybe<File>>>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  status: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type Employee = {
  __typename?: 'Employee';
  business: Maybe<Business>;
  businessAccess: Maybe<Array<Maybe<EmployeeBusinessAccess>>>;
  businessAccessGroups: Maybe<Array<Maybe<EmployeeBusinessAccessGroup>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultAccessGroup: Maybe<EmployeeBusinessAccessGroup>;
  email: Maybe<Scalars['String']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  name: Maybe<Scalars['String']['output']>;
  notifications: Maybe<Array<Maybe<EmployeeNotification>>>;
  permissions: Maybe<Array<Maybe<EmployeePermission>>>;
  phone: Maybe<Scalars['String']['output']>;
  preferences: Maybe<Array<Maybe<EmployeePreference>>>;
  role: Maybe<Role>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouseCode: Maybe<Scalars['String']['output']>;
  warehouseCodeCreatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeeBusinessAccess = {
  __typename?: 'EmployeeBusinessAccess';
  business: Maybe<Business>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeeBusinessAccessGroup = {
  __typename?: 'EmployeeBusinessAccessGroup';
  accessGroupBusinesses: Maybe<Array<Maybe<EmployeeBusinessAccessGroupBusiness>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeeBusinessAccessGroupBusiness = {
  __typename?: 'EmployeeBusinessAccessGroupBusiness';
  business: Maybe<Business>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employeeBusinessAccessGroup: Maybe<EmployeeBusinessAccessGroup>;
  id: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeeNotification = {
  __typename?: 'EmployeeNotification';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Boolean']['output']>;
};

export type EmployeePermission = {
  __typename?: 'EmployeePermission';
  action: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  permission: Maybe<Permission>;
  role: Maybe<Role>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeePreference = Preference & {
  __typename?: 'EmployeePreference';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type EventProductToStock = WarehouseEvent & {
  __typename?: 'EventProductToStock';
  action_type: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  product: Maybe<Product>;
  quantity: Maybe<Scalars['Int']['output']>;
  shelf: Maybe<WarehouseShelf>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProduct: Maybe<WarehouseWaybillProduct>;
};

export type ExportInput = {
  ext: Scalars['String']['input'];
  filter?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  type: Scalars['String']['input'];
};

export type ExportStatusPayload = {
  __typename?: 'ExportStatusPayload';
  fileUrl: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<Document>;
  ext: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  uploadedFile: Maybe<UploadedFile>;
};

export type FilterInput = {
  _isFreeText?: InputMaybe<Scalars['Boolean']['input']>;
  _key?: InputMaybe<Scalars['String']['input']>;
  func?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  isExcluded?: InputMaybe<Scalars['Boolean']['input']>;
  op?: InputMaybe<Scalars['String']['input']>;
  prop?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FindOrderProductsSummaryPayload = {
  __typename?: 'FindOrderProductsSummaryPayload';
  totalBackorderQuantity: Maybe<Scalars['Int']['output']>;
  totalPackedQuantity: Maybe<Scalars['Int']['output']>;
  totalQuantity: Maybe<Scalars['Int']['output']>;
};

export type HolidayEvent = {
  __typename?: 'HolidayEvent';
  code: Maybe<Scalars['String']['output']>;
  dateFrom: Maybe<Scalars['DateTime']['output']>;
  dateTo: Maybe<Scalars['DateTime']['output']>;
  message: Maybe<Scalars['String']['output']>;
  showAlert: Maybe<Scalars['Boolean']['output']>;
  showOnce: Maybe<Scalars['Boolean']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  document: Maybe<Document>;
  documentLanguage: Maybe<Scalars['String']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceTemplate: Maybe<Scalars['String']['output']>;
  logPayments: Maybe<Array<Maybe<PaymentLog>>>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  payment: Maybe<Payment>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  totals: Maybe<Array<Maybe<InvoiceTotal>>>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type InvoicePaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCoupon = {
  __typename?: 'InvoiceCoupon';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customerInvoice: Maybe<InvoiceCustomer>;
  id: Maybe<Scalars['String']['output']>;
  orderCoupon: Maybe<OrderCoupon>;
  total: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceCreditNote = Invoice & {
  __typename?: 'InvoiceCreditNote';
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  coupons: Maybe<Array<Maybe<CreditNoteCoupon>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customer: Maybe<Customer>;
  document: Maybe<Document>;
  documentLanguage: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceTemplate: Maybe<Scalars['String']['output']>;
  logPayments: Maybe<Array<Maybe<PaymentLog>>>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  orderProducts: Maybe<Array<Maybe<OrderProductAbstract>>>;
  payment: Maybe<Payment>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  totalWeight: Maybe<Weight>;
  totals: Maybe<Array<Maybe<InvoiceTotal>>>;
  tracking: Maybe<ShippingTracking>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type InvoiceCreditNotePaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCustomer = Invoice & {
  __typename?: 'InvoiceCustomer';
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  coupons: Maybe<Array<Maybe<InvoiceCoupon>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customer: Maybe<Customer>;
  document: Maybe<Document>;
  documentLanguage: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceTemplate: Maybe<Scalars['String']['output']>;
  logPayments: Maybe<Array<Maybe<PaymentLog>>>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  orderProducts: Maybe<Array<Maybe<OrderProductAbstract>>>;
  paidAt: Maybe<Scalars['DateTime']['output']>;
  payment: Maybe<Payment>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  specification: Maybe<Specification>;
  status: Maybe<Scalars['String']['output']>;
  totalWeight: Maybe<Weight>;
  totals: Maybe<Array<Maybe<InvoiceTotal>>>;
  tracking: Maybe<ShippingTracking>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type InvoiceCustomerPaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceDocumentInput = {
  ext?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
};

export type InvoicePaymentAdyen = Payment & {
  __typename?: 'InvoicePaymentAdyen';
  amount: Maybe<Money>;
  amountAuthorized: Maybe<Money>;
  amountCapture: Maybe<Money>;
  captureError: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<Scalars['String']['output']>;
  pspReference: Maybe<Scalars['String']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  refundAmount: Maybe<Money>;
  refusalReason: Maybe<Scalars['String']['output']>;
  refusalReasonCode: Maybe<Scalars['String']['output']>;
  resultCode: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type InvoicePaymentBank = Payment & {
  __typename?: 'InvoicePaymentBank';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type InvoicePaymentPaypal = Payment & {
  __typename?: 'InvoicePaymentPaypal';
  amount: Maybe<Money>;
  authorizationId: Maybe<Scalars['String']['output']>;
  authorizationStatus: Maybe<Scalars['String']['output']>;
  captureError: Maybe<Scalars['String']['output']>;
  captureId: Maybe<Scalars['String']['output']>;
  captureReason: Maybe<Scalars['String']['output']>;
  captureStatus: Maybe<Scalars['String']['output']>;
  checkoutStatus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  customId: Maybe<Scalars['String']['output']>;
  failedIssue: Maybe<Scalars['String']['output']>;
  failedName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  payerId: Maybe<Scalars['String']['output']>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  refundAmount: Maybe<Money>;
  refundId: Maybe<Scalars['String']['output']>;
  refundStatus: Maybe<Scalars['String']['output']>;
  transactionId: Maybe<Scalars['String']['output']>;
  transactionStatus: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceProductsList = Invoice & {
  __typename?: 'InvoiceProductsList';
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  document: Maybe<Document>;
  documentLanguage: Maybe<Scalars['String']['output']>;
  employee: Maybe<Employee>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceTemplate: Maybe<Scalars['String']['output']>;
  logPayments: Maybe<Array<Maybe<PaymentLog>>>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  order: Maybe<Order>;
  payment: Maybe<Payment>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  totals: Maybe<Array<Maybe<InvoiceTotal>>>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type InvoiceProductsListPaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceProforma = Invoice & {
  __typename?: 'InvoiceProforma';
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customer: Maybe<Customer>;
  document: Maybe<Document>;
  documentLanguage: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['DateTime']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceTemplate: Maybe<Scalars['String']['output']>;
  logPayments: Maybe<Array<Maybe<PaymentLog>>>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  order: Maybe<Order>;
  orderProducts: Maybe<Array<Maybe<OrderProduct>>>;
  payment: Maybe<Payment>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  totalWeight: Maybe<Weight>;
  totals: Maybe<Array<Maybe<InvoiceTotal>>>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


export type InvoiceProformaPaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceTotal = {
  __typename?: 'InvoiceTotal';
  additionalInfo: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Money>;
};

export type MarkupPrice = {
  __typename?: 'MarkupPrice';
  ProductPrice: Maybe<ProductPrice>;
  chainHash: Maybe<Scalars['String']['output']>;
  customerMarkup: Maybe<Scalars['Int']['output']>;
  deliveryTermExpressMax: Maybe<Scalars['Int']['output']>;
  deliveryTermExpressMin: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMax: Maybe<Scalars['Int']['output']>;
  deliveryTermStandardMin: Maybe<Scalars['Int']['output']>;
  hasCustomerMarkup: Maybe<Scalars['Boolean']['output']>;
  isEnabledCustomerMarkup: Maybe<Scalars['Boolean']['output']>;
  markup: Maybe<Scalars['Int']['output']>;
  price: Maybe<Money>;
  purchasePrice: Maybe<Money>;
  relationSupplierHash: Maybe<Scalars['String']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  totalMarkup: Maybe<Scalars['Int']['output']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal']['output'];
  cents: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export type Order = {
  __typename?: 'Order';
  aggregatedDocuments: Maybe<Array<Maybe<Document>>>;
  aggregatedOrderTotals: Maybe<Array<Maybe<AggregatedOrderTotal>>>;
  archived: Maybe<Scalars['Boolean']['output']>;
  billingAddress: Maybe<Address>;
  billingAddressHash: Maybe<Scalars['String']['output']>;
  billingAddressLine1: Maybe<Scalars['String']['output']>;
  billingAddressLine2: Maybe<Scalars['String']['output']>;
  billingCity: Maybe<Scalars['String']['output']>;
  billingCompanyName: Maybe<Scalars['String']['output']>;
  billingCompanyReg: Maybe<Scalars['String']['output']>;
  billingCompanyVat: Maybe<Scalars['String']['output']>;
  billingCountry: Maybe<Country>;
  billingFirstName: Maybe<Scalars['String']['output']>;
  billingLastName: Maybe<Scalars['String']['output']>;
  billingNickname: Maybe<Scalars['String']['output']>;
  billingPhone: Maybe<Scalars['String']['output']>;
  billingRegion: Maybe<Scalars['String']['output']>;
  billingSameAsShipping: Maybe<Scalars['Boolean']['output']>;
  billingVatData: Maybe<Vat>;
  billingZip: Maybe<Scalars['String']['output']>;
  brands: Maybe<Array<Maybe<BusinessBrand>>>;
  brandsWeight: Maybe<Array<Maybe<BrandWeight>>>;
  business: Maybe<Business>;
  cart: Maybe<Cart>;
  cartId: Maybe<Scalars['String']['output']>;
  checkoutError: Maybe<Scalars['String']['output']>;
  checkoutStatus: Maybe<Scalars['String']['output']>;
  childOrders: Maybe<Array<Maybe<Order>>>;
  comment: Maybe<Scalars['String']['output']>;
  completion: Maybe<Scalars['Int']['output']>;
  coupons: Maybe<Array<Maybe<OrderCoupon>>>;
  createFromOrderId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  currencyCode: Maybe<Scalars['String']['output']>;
  currencyRate: Maybe<Scalars['Int']['output']>;
  customer: Maybe<Customer>;
  customerEmail: Maybe<Scalars['String']['output']>;
  displayId: Maybe<Scalars['String']['output']>;
  document: Maybe<Document>;
  domain: Maybe<Scalars['String']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  incoterms: Maybe<Scalars['String']['output']>;
  invoiceProductsList: Maybe<Array<Maybe<InvoiceProductsList>>>;
  invoiceReference: Maybe<Scalars['String']['output']>;
  isPacked: Maybe<Scalars['Boolean']['output']>;
  isPaid: Maybe<Scalars['Boolean']['output']>;
  isShippingInsuranceApplied: Maybe<Scalars['Boolean']['output']>;
  isTransitIncluded: Maybe<Scalars['Boolean']['output']>;
  language: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Int']['output']>;
  packStatus: Maybe<Scalars['String']['output']>;
  parent: Maybe<Order>;
  payReference: Maybe<Scalars['String']['output']>;
  payment: Maybe<Payment>;
  paymentFrom: Maybe<Scalars['String']['output']>;
  paymentType: Maybe<Scalars['String']['output']>;
  payments: Maybe<Array<Maybe<Payment>>>;
  products: Maybe<Array<Maybe<OrderProduct>>>;
  profitAmount: Maybe<Money>;
  profitability: Maybe<Scalars['Int']['output']>;
  proformas: Maybe<Array<Maybe<InvoiceProforma>>>;
  purchasingTotal: Maybe<Money>;
  secondaryProducts: Maybe<Array<Maybe<OrderProductSecondary>>>;
  sentLog: Maybe<Array<Maybe<OrderSend>>>;
  shippingAddress: Maybe<Address>;
  shippingAddressHash: Maybe<Scalars['String']['output']>;
  shippingAddressLine1: Maybe<Scalars['String']['output']>;
  shippingAddressLine2: Maybe<Scalars['String']['output']>;
  shippingCity: Maybe<Scalars['String']['output']>;
  shippingCompanyName: Maybe<Scalars['String']['output']>;
  shippingCompanyReg: Maybe<Scalars['String']['output']>;
  shippingCompanyVat: Maybe<Scalars['String']['output']>;
  shippingCountry: Maybe<Country>;
  shippingEstimatedAt: Maybe<Scalars['DateTime']['output']>;
  shippingExpenses: Maybe<Money>;
  shippingFirstName: Maybe<Scalars['String']['output']>;
  shippingLastName: Maybe<Scalars['String']['output']>;
  shippingMethod: Maybe<Scalars['String']['output']>;
  shippingNickname: Maybe<Scalars['String']['output']>;
  shippingPhone: Maybe<Scalars['String']['output']>;
  shippingRegion: Maybe<Scalars['String']['output']>;
  shippingSelfPickup: Maybe<Scalars['Boolean']['output']>;
  shippingSupplier: Maybe<Scalars['String']['output']>;
  shippingVatData: Maybe<Vat>;
  shippingZip: Maybe<Scalars['String']['output']>;
  shortDisplayId: Maybe<Scalars['String']['output']>;
  sourceSystem: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  totalPackedQuantity: Maybe<Scalars['Int']['output']>;
  totalQuantity: Maybe<Scalars['Int']['output']>;
  totalWeight: Maybe<Weight>;
  totals: Maybe<Array<Maybe<OrderTotal>>>;
  totalsOrdersCountry: Maybe<Scalars['Int']['output']>;
  transitAddress: Maybe<OrderAddress>;
  transitCountry: Maybe<Country>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  utmCampaign: Maybe<Scalars['String']['output']>;
  utmMedium: Maybe<Scalars['String']['output']>;
  utmSource: Maybe<Scalars['String']['output']>;
  vatCoefficient: Maybe<Scalars['Int']['output']>;
  vatCountryCode: Maybe<Scalars['String']['output']>;
  waybillProducts: Maybe<Array<Maybe<WarehouseWaybillProduct>>>;
};


export type OrderPaymentArgs = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type OrderAddress = {
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  companyReg: Maybe<Scalars['String']['output']>;
  companyVat: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type OrderCounts = {
  __typename?: 'OrderCounts';
  accepted: Maybe<Scalars['Int']['output']>;
  all: Maybe<Scalars['Int']['output']>;
  backorder: Maybe<Scalars['Int']['output']>;
  canceled: Maybe<Scalars['Int']['output']>;
  done: Maybe<Scalars['Int']['output']>;
  inPreparation: Maybe<Scalars['Int']['output']>;
  onPacking: Maybe<Scalars['Int']['output']>;
  open: Maybe<Scalars['Int']['output']>;
  shippedToCustomer: Maybe<Scalars['Int']['output']>;
  waitingForPayment: Maybe<Scalars['Int']['output']>;
};

export type OrderCoupon = {
  __typename?: 'OrderCoupon';
  cartFilter: Maybe<Scalars['String']['output']>;
  cartProductFilter: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  coupon: Maybe<Coupon>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  creditNoteCoupons: Maybe<Array<Maybe<CreditNoteCoupon>>>;
  customer: Maybe<Customer>;
  discountType: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCoupons: Maybe<Array<Maybe<InvoiceCoupon>>>;
  isValid: Maybe<Scalars['Boolean']['output']>;
  mathOperator: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Int']['output']>;
};

export type OrderPaymentAdyen = Payment & {
  __typename?: 'OrderPaymentAdyen';
  amount: Maybe<Money>;
  amountAuthorized: Maybe<Money>;
  amountCapture: Maybe<Money>;
  captureError: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<Scalars['String']['output']>;
  paymentStatus: Maybe<Scalars['String']['output']>;
  pspReference: Maybe<Scalars['String']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  refundAmount: Maybe<Money>;
  refusalReason: Maybe<Scalars['String']['output']>;
  refusalReasonCode: Maybe<Scalars['String']['output']>;
  resultCode: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderPaymentBank = Payment & {
  __typename?: 'OrderPaymentBank';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  paymentStatus: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderPaymentPaypal = Payment & {
  __typename?: 'OrderPaymentPaypal';
  amount: Maybe<Money>;
  authorizationId: Maybe<Scalars['String']['output']>;
  authorizationStatus: Maybe<Scalars['String']['output']>;
  captureError: Maybe<Scalars['String']['output']>;
  captureId: Maybe<Scalars['String']['output']>;
  captureReason: Maybe<Scalars['String']['output']>;
  captureStatus: Maybe<Scalars['String']['output']>;
  checkoutStatus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  customId: Maybe<Scalars['String']['output']>;
  failedIssue: Maybe<Scalars['String']['output']>;
  failedName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  payerId: Maybe<Scalars['String']['output']>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  paymentStatus: Maybe<Scalars['String']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  refundAmount: Maybe<Money>;
  refundId: Maybe<Scalars['String']['output']>;
  refundStatus: Maybe<Scalars['String']['output']>;
  transactionId: Maybe<Scalars['String']['output']>;
  transactionStatus: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderProduct = OrderProductAbstract & {
  __typename?: 'OrderProduct';
  actualProduct: Maybe<Product>;
  availableBrsbs: Maybe<Array<Maybe<AvailableBrsb>>>;
  brand: Maybe<BusinessBrand>;
  businessRelationshipSupplierChainHash: Maybe<Scalars['String']['output']>;
  createFromOrderProductId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customerBoxProduct: Maybe<CustomerBoxProduct>;
  customerInvoice: Maybe<InvoiceCustomer>;
  depositAmount: Maybe<Money>;
  desiredQuantity: Maybe<Scalars['Int']['output']>;
  dimensions: Maybe<Dimensions>;
  discount: Maybe<Scalars['Int']['output']>;
  exchangeBrand: Maybe<BusinessBrand>;
  exchangeProduct: Maybe<Product>;
  expectedArrivalDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCreditNote: Maybe<InvoiceCreditNote>;
  modifiedBy: Maybe<Employee>;
  number: Maybe<Scalars['Int']['output']>;
  order: Maybe<Order>;
  orderSend: Maybe<OrderSend>;
  originalPurchasePriceMoney: Maybe<Money>;
  packedAt: Maybe<Scalars['DateTime']['output']>;
  packedQuantity: Maybe<Scalars['Int']['output']>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  profitAmount: Maybe<Money>;
  proformaInvoice: Maybe<InvoiceProforma>;
  purchasePrice: Maybe<Money>;
  quantity: Maybe<Scalars['Int']['output']>;
  relationSupplierHash: Maybe<Scalars['String']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  secondaryProducts: Maybe<Array<Maybe<OrderProductSecondary>>>;
  stockQuantity: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Money>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProductOrderProducts: Maybe<Array<Maybe<WarehouseWaybillProductOrderProduct>>>;
  weight: Maybe<Weight>;
  weightInKg: Maybe<Scalars['Float']['output']>;
};

export type OrderProductAbstract = {
  actualProduct: Maybe<Product>;
  availableBrsbs: Maybe<Array<Maybe<AvailableBrsb>>>;
  brand: Maybe<BusinessBrand>;
  businessRelationshipSupplierChainHash: Maybe<Scalars['String']['output']>;
  createFromOrderProductId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customerBoxProduct: Maybe<CustomerBoxProduct>;
  customerInvoice: Maybe<InvoiceCustomer>;
  depositAmount: Maybe<Money>;
  desiredQuantity: Maybe<Scalars['Int']['output']>;
  dimensions: Maybe<Dimensions>;
  discount: Maybe<Scalars['Int']['output']>;
  exchangeBrand: Maybe<BusinessBrand>;
  exchangeProduct: Maybe<Product>;
  expectedArrivalDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCreditNote: Maybe<InvoiceCreditNote>;
  modifiedBy: Maybe<Employee>;
  number: Maybe<Scalars['Int']['output']>;
  order: Maybe<Order>;
  orderSend: Maybe<OrderSend>;
  originalPurchasePriceMoney: Maybe<Money>;
  packedAt: Maybe<Scalars['DateTime']['output']>;
  packedQuantity: Maybe<Scalars['Int']['output']>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  profitAmount: Maybe<Money>;
  proformaInvoice: Maybe<InvoiceProforma>;
  purchasePrice: Maybe<Money>;
  quantity: Maybe<Scalars['Int']['output']>;
  relationSupplierHash: Maybe<Scalars['String']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  stockQuantity: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Money>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProductOrderProducts: Maybe<Array<Maybe<WarehouseWaybillProductOrderProduct>>>;
  weight: Maybe<Weight>;
  weightInKg: Maybe<Scalars['Float']['output']>;
};

export type OrderProductSecondary = OrderProductAbstract & {
  __typename?: 'OrderProductSecondary';
  actualProduct: Maybe<Product>;
  availableBrsbs: Maybe<Array<Maybe<AvailableBrsb>>>;
  brand: Maybe<BusinessBrand>;
  businessRelationshipSupplierChainHash: Maybe<Scalars['String']['output']>;
  createFromOrderProductId: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customerBoxProduct: Maybe<CustomerBoxProduct>;
  customerInvoice: Maybe<InvoiceCustomer>;
  depositAmount: Maybe<Money>;
  desiredQuantity: Maybe<Scalars['Int']['output']>;
  dimensions: Maybe<Dimensions>;
  discount: Maybe<Scalars['Int']['output']>;
  exchangeBrand: Maybe<BusinessBrand>;
  exchangeProduct: Maybe<Product>;
  expectedArrivalDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCreditNote: Maybe<InvoiceCreditNote>;
  modifiedBy: Maybe<Employee>;
  number: Maybe<Scalars['Int']['output']>;
  order: Maybe<Order>;
  orderSend: Maybe<OrderSend>;
  originalOrderProduct: Maybe<OrderProduct>;
  originalPurchasePriceMoney: Maybe<Money>;
  packedAt: Maybe<Scalars['DateTime']['output']>;
  packedQuantity: Maybe<Scalars['Int']['output']>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  profitAmount: Maybe<Money>;
  proformaInvoice: Maybe<InvoiceProforma>;
  purchasePrice: Maybe<Money>;
  quantity: Maybe<Scalars['Int']['output']>;
  relationSupplierHash: Maybe<Scalars['String']['output']>;
  relationshipSupplierBrand: Maybe<BusinessRelationshipSupplierBrand>;
  stockQuantity: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Money>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProductOrderProducts: Maybe<Array<Maybe<WarehouseWaybillProductOrderProduct>>>;
  weight: Maybe<Weight>;
  weightInKg: Maybe<Scalars['Float']['output']>;
};

export type OrderSend = {
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  orderProducts: Maybe<Array<Maybe<OrderProduct>>>;
  sender: Maybe<Employee>;
  sentAt: Maybe<Scalars['DateTime']['output']>;
  sentToBusinessName: Maybe<Scalars['String']['output']>;
  toBusiness: Maybe<Business>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderTotal = {
  __typename?: 'OrderTotal';
  additionalInfo: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  sequence: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Money>;
};

export type OrderTotals = {
  __typename?: 'OrderTotals';
  sub: Maybe<Scalars['Int']['output']>;
};

export type OrdersProductsPayload = {
  __typename?: 'OrdersProductsPayload';
  OrderProduct: Maybe<Array<Maybe<OrderProduct>>>;
  Pagination: Maybe<Pagination>;
};

export type Pagination = {
  __typename?: 'Pagination';
  current: Maybe<Scalars['Int']['output']>;
  limit: Maybe<Scalars['Int']['output']>;
  totalCount: Maybe<Scalars['Int']['output']>;
  totalPages: Maybe<Scalars['Int']['output']>;
};

export type PasswordInput = {
  confirmedPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type Payment = {
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  order: Maybe<Order>;
  paymentDetails: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PaymentLog = {
  __typename?: 'PaymentLog';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  hash: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  invoicePaymentAdyen: Maybe<InvoicePaymentAdyen>;
  invoicePaymentBank: Maybe<InvoicePaymentBank>;
  invoicePaymentPaypal: Maybe<OrderPaymentPaypal>;
  order: Maybe<Order>;
  orderPaymentAdyen: Maybe<OrderPaymentAdyen>;
  orderPaymentBank: Maybe<OrderPaymentBank>;
  orderPaymentPaypal: Maybe<OrderPaymentPaypal>;
  request: Maybe<Scalars['String']['output']>;
  response: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  transactionId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brands: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  details: Maybe<Array<Maybe<PaymentMethodDetail>>>;
  name: Maybe<Scalars['String']['output']>;
  processor: Maybe<Scalars['String']['output']>;
  supportsRecurring: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodDetail = {
  __typename?: 'PaymentMethodDetail';
  key: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodGroup = {
  __typename?: 'PaymentMethodGroup';
  name: Maybe<Scalars['String']['output']>;
  types: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PaymentMethodsList = {
  __typename?: 'PaymentMethodsList';
  groups: Maybe<Array<Maybe<PaymentMethodGroup>>>;
  paymentMethods: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type Preference = {
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  analogues: Maybe<Array<Maybe<Product>>>;
  brand: Maybe<BusinessBrand>;
  countryOfOrigin: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  depth: Maybe<Scalars['Int']['output']>;
  dimensions: Maybe<Dimensions>;
  discount: Maybe<Discount>;
  ean: Maybe<Scalars['String']['output']>;
  esteriolId: Maybe<Scalars['Int']['output']>;
  exchangeMpn: Maybe<Scalars['String']['output']>;
  exchanges: Maybe<Array<Maybe<ProductExchange>>>;
  group: Maybe<ProductGroup>;
  height: Maybe<Scalars['Int']['output']>;
  hsCode: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  info: Maybe<ProductInfo>;
  keepFlat: Maybe<Scalars['Boolean']['output']>;
  minimalOrderQuantity: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<Employee>;
  mpn: Maybe<Scalars['String']['output']>;
  mpnAnalyzed: Maybe<Scalars['String']['output']>;
  mpnExist: Maybe<Scalars['String']['output']>;
  mpnFormatted: Maybe<Scalars['String']['output']>;
  originals: Maybe<Array<Maybe<ProductExchange>>>;
  price: Maybe<Money>;
  prices: Maybe<Array<Maybe<MarkupPrice>>>;
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productPrices: Maybe<Array<Maybe<ProductPrice>>>;
  requestProducts: Maybe<Array<Maybe<CustomerRequestProduct>>>;
  sku: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  weight: Maybe<Weight>;
  weightInKg: Maybe<Scalars['String']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type ProductExchange = {
  __typename?: 'ProductExchange';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  product: Maybe<Product>;
  toProduct: Maybe<Product>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  brand: Maybe<BusinessBrand>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  alt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isMain: Maybe<Scalars['Boolean']['output']>;
  link: Maybe<Scalars['String']['output']>;
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  descriptionDe: Maybe<Scalars['String']['output']>;
  descriptionEn: Maybe<Scalars['String']['output']>;
  descriptionEs: Maybe<Scalars['String']['output']>;
  descriptionFr: Maybe<Scalars['String']['output']>;
  descriptionPl: Maybe<Scalars['String']['output']>;
  descriptionRu: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  metaDescriptionDe: Maybe<Scalars['String']['output']>;
  metaDescriptionEn: Maybe<Scalars['String']['output']>;
  metaDescriptionEs: Maybe<Scalars['String']['output']>;
  metaDescriptionFr: Maybe<Scalars['String']['output']>;
  metaDescriptionPl: Maybe<Scalars['String']['output']>;
  metaDescriptionRu: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  nameDe: Maybe<Scalars['String']['output']>;
  nameEn: Maybe<Scalars['String']['output']>;
  nameEs: Maybe<Scalars['String']['output']>;
  nameFr: Maybe<Scalars['String']['output']>;
  namePl: Maybe<Scalars['String']['output']>;
  nameRu: Maybe<Scalars['String']['output']>;
  slugDe: Maybe<Scalars['String']['output']>;
  slugEn: Maybe<Scalars['String']['output']>;
  slugEs: Maybe<Scalars['String']['output']>;
  slugFr: Maybe<Scalars['String']['output']>;
  slugPl: Maybe<Scalars['String']['output']>;
  slugRu: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  customerMarkup: Maybe<Scalars['Int']['output']>;
  depositPrice: Maybe<Money>;
  distributorPrice: Maybe<Money>;
  id: Maybe<Scalars['String']['output']>;
  importPrice: Maybe<Money>;
  markup: Maybe<Scalars['Int']['output']>;
  modifiedBy: Maybe<Employee>;
  price: Maybe<Money>;
  purchasePrice: Maybe<Money>;
  supplier: Maybe<BusinessSupplier>;
  totalMarkup: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ProductSearchInput = {
  brands?: InputMaybe<Array<InputMaybe<BrandInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type ReconciliationAccount = {
  business: Maybe<Business>;
  company: Maybe<Company>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  credit: Maybe<Money>;
  customer: Maybe<Customer>;
  date: Maybe<Scalars['DateTime']['output']>;
  debit: Maybe<Money>;
  id: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RegisterFromOrderInput = {
  orderId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  confirmedPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  __typename?: 'Role';
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type SearchedData = {
  __typename?: 'SearchedData';
  brands: Maybe<Array<Maybe<BusinessBrand>>>;
  code: Maybe<Scalars['String']['output']>;
  crossBrandName: Maybe<Scalars['String']['output']>;
};

export type ShippingTracking = {
  __typename?: 'ShippingTracking';
  customerInvoice: Maybe<InvoiceCustomer>;
  id: Maybe<Scalars['String']['output']>;
  invoiceCreditNote: Maybe<InvoiceCreditNote>;
  trackingNumber: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Specification = {
  __typename?: 'Specification';
  business: Maybe<Business>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  declarationNumber: Maybe<Scalars['String']['output']>;
  delivered: Scalars['Boolean']['output'];
  deliveryDate: Maybe<Scalars['DateTime']['output']>;
  documentsReadyDate: Maybe<Scalars['DateTime']['output']>;
  exportDeclaration: Scalars['Boolean']['output'];
  files: Maybe<Array<Maybe<File>>>;
  id: Maybe<Scalars['String']['output']>;
  invoices: Maybe<Array<Maybe<InvoiceCustomer>>>;
  number: Maybe<Scalars['Int']['output']>;
  packingType: Scalars['String']['output'];
  placeNumber: Scalars['Int']['output'];
  productsReadyDate: Maybe<Scalars['DateTime']['output']>;
  shippingDate: Scalars['DateTime']['output'];
  totalPrice: Maybe<Money>;
  totalWeight: Maybe<Weight>;
  transactionDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  typeName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type SupplierLocation = {
  __typename?: 'SupplierLocation';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  supplier: Maybe<BusinessSupplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  ext: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  signedUrl: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type Vat = {
  __typename?: 'Vat';
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  lastValidationAt: Maybe<Scalars['DateTime']['output']>;
  number: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  valid: Maybe<Scalars['Boolean']['output']>;
  validationStatus: Maybe<Scalars['String']['output']>;
};

export type VatValidationInput = {
  countryCode: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
};

export type WarehouseEvent = {
  action_type: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  employee: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  product: Maybe<Product>;
  quantity: Maybe<Scalars['Int']['output']>;
  shelf: Maybe<WarehouseShelf>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProduct: Maybe<WarehouseWaybillProduct>;
};

export type WarehouseImportProduct = {
  __typename?: 'WarehouseImportProduct';
  brand: Maybe<BusinessBrand>;
  brandName: Maybe<Scalars['String']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  mpn: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  shelfName: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  stock: Maybe<WarehouseStock>;
  stockImport: Maybe<WarehouseStockImport>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseLocation = {
  __typename?: 'WarehouseLocation';
  address: Maybe<Scalars['String']['output']>;
  business: Maybe<Business>;
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  default: Maybe<Scalars['Boolean']['output']>;
  employees: Maybe<Array<Maybe<Employee>>>;
  id: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  shelves: Maybe<Array<Maybe<WarehouseShelf>>>;
  supplier: Maybe<BusinessSupplier>;
  supplierLocations: Maybe<Array<Maybe<SupplierLocation>>>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybills: Maybe<Array<Maybe<WarehouseWaybill>>>;
  zip: Maybe<Scalars['String']['output']>;
};

export type WarehouseShelf = {
  __typename?: 'WarehouseShelf';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  name: Maybe<Scalars['String']['output']>;
  stocks: Maybe<Array<Maybe<WarehouseStock>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseStock = {
  __typename?: 'WarehouseStock';
  availableQuantity: Maybe<Scalars['Int']['output']>;
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  eventProductToStock: Maybe<Array<Maybe<EventProductToStock>>>;
  id: Maybe<Scalars['String']['output']>;
  importProducts: Maybe<Array<Maybe<WarehouseImportProduct>>>;
  import_hash: Maybe<Scalars['String']['output']>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  quantity: Maybe<Scalars['Int']['output']>;
  reservedQuantity: Maybe<Scalars['Int']['output']>;
  shelf: Maybe<WarehouseShelf>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybill: Maybe<WarehouseWaybill>;
};

export type WarehouseStockImport = {
  __typename?: 'WarehouseStockImport';
  clearOnImport: Scalars['Boolean']['output'];
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  documentKey: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  importProducts: Maybe<Array<Maybe<WarehouseImportProduct>>>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseWaybill = {
  __typename?: 'WarehouseWaybill';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  distributedProductsCount: Maybe<Scalars['Int']['output']>;
  documentKey: Maybe<Scalars['String']['output']>;
  employees: Maybe<Array<Maybe<Employee>>>;
  error: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  internalDocumentNr: Maybe<Scalars['String']['output']>;
  invoiceNr: Maybe<Scalars['String']['output']>;
  isDeletable: Maybe<Scalars['Boolean']['output']>;
  location: Maybe<WarehouseLocation>;
  modifiedBy: Maybe<Employee>;
  originWaybill: Maybe<WarehouseWaybill>;
  products: Maybe<Array<Maybe<WarehouseWaybillProduct>>>;
  productsCount: Maybe<Scalars['Int']['output']>;
  status: Maybe<Scalars['String']['output']>;
  stockProducts: Maybe<Array<Maybe<WarehouseStock>>>;
  stockProductsCount: Maybe<Scalars['Int']['output']>;
  supplier: Maybe<BusinessSupplier>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type WarehouseWaybillProduct = {
  __typename?: 'WarehouseWaybillProduct';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  deliveryNote: Maybe<Scalars['String']['output']>;
  distributedQuantity: Maybe<Scalars['Int']['output']>;
  exchangeMpn: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  importStatus: Maybe<Scalars['String']['output']>;
  isCreatedFromOrder: Maybe<Scalars['Boolean']['output']>;
  isProductExistsInOrder: Maybe<Scalars['Boolean']['output']>;
  modifiedBy: Maybe<Employee>;
  mpn: Maybe<Scalars['String']['output']>;
  mpnFormatted: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  orderSuggestions: Maybe<Array<Maybe<WarehouseWaybillProductOrderSuggestion>>>;
  price: Maybe<Money>;
  product: Maybe<Product>;
  productQuantityInOrder: Maybe<Scalars['Int']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  supplierRetailDiscount: Maybe<Scalars['Int']['output']>;
  supplierRetailPrice: Maybe<Money>;
  title: Maybe<Scalars['String']['output']>;
  toStockEvents: Maybe<Array<Maybe<EventProductToStock>>>;
  totalPrice: Maybe<Money>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybill: Maybe<WarehouseWaybill>;
  waybillProductOrderProducts: Maybe<Array<Maybe<WarehouseWaybillProductOrderProduct>>>;
};

export type WarehouseWaybillProductOrderProduct = {
  __typename?: 'WarehouseWaybillProductOrderProduct';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  createdBy: Maybe<Employee>;
  id: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<Employee>;
  orderProduct: Maybe<OrderProduct>;
  quantity: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybillProduct: Maybe<WarehouseWaybillProduct>;
};

export type WarehouseWaybillProductOrderSuggestion = {
  __typename?: 'WarehouseWaybillProductOrderSuggestion';
  id: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  waybill: Maybe<WarehouseWaybill>;
};

export type Weight = {
  __typename?: 'Weight';
  unit: Scalars['String']['output'];
  value: Scalars['Decimal']['output'];
};

export type WorkspaceProductReviewInput = {
  productChecked?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerMutationVariables = Exact<{
  input: AccountInput;
}>;


export type CustomerMutation = { __typename?: 'CustomerMutationType', Customer: never };


export const CustomerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Customer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Customer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CustomerMutation, CustomerMutationVariables>;