import { whenType } from "@/util/element";
import { html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { GlobalElement } from "./product-area-catalog/product-area-filter-popup";

@customElement("loader-overlay")
export class LoaderOverlay extends GlobalElement {
    @property({ type: String })
    type!: "sm" | "lg";

    protected render() {
        return html`<div class="overlay"></div>`;
    }

    static stylesCommon = css`
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
    }
  `;

    static stylesSm = css``;
    static stylesLg = css``;

    static styles = [
        LoaderOverlay.stylesCommon,
        whenType("sm", LoaderOverlay.stylesSm),
        whenType("lg", LoaderOverlay.stylesLg)
    ]
}