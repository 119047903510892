import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import productPhoto from "@/assets/stub/product-photo.png";
import "@/elements/mobile/fix-button";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("product-analogues")
@localized()
export class ProductAnalogues extends LitElement {
  @property({ type: Array })
  options = [] as Array<object>;

  render() {
    const { options } = this;

    if (!options) return html``;

    return html`
      <div class="container">
        ${options.map(
          () => html`
            <sl-card class="card-overview">
              <img slot="image" src=${productPhoto} alt="A kitten sits patiently between a terracotta pot and decorative grasses." />

              <sl-rating></sl-rating><br />
              ${t("product-analogues.brake-disc", "Brake disc")}<br />
              <small>${t("wwx593qvd0zl1lnj.in-stock", "In Stock")}</small><br />
              <strong>€76.50 - €91.20</strong><br />

              <div slot="footer">
                <fix-button variant="primary" size="small"> ${t("mxa4s697394822iw.add-to-cart", "Add to cart")} </fix-button>
              </div>
            </sl-card>
          `,
        )}
      </div>
    `;
  }

  static styles = css`
    .container {
      display: flex;
      gap: var(--space-margin-margin, 4px);
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .card-overview {
      min-width: 180px;
      flex: 1 1 180px;
      text-align: left;
    }

    :host {
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "product-analogues": ProductAnalogues;
  }
}
