import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import separator from "@/assets/separator.svg";
import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { consume } from "@lit/context";
import { map } from "lit/directives/map.js";
import { font } from "@/util/fonts";

@customElement("fix-breadcrumb")
export class FixBreadcrumb extends LitElement {
  @consume({ context: BreadcrumbsContext, subscribe: true })
  breadcrumbsContext?: Breadcrumbs;

  render() {
    return html`
      <sl-breadcrumb>
        <sl-icon src=${separator} slot="separator"></sl-icon>
        ${map(this.items, (item) => this.renderItem(item))}
      </sl-breadcrumb>
    `;
  }

  renderItem(item: { url: string; label: string }) {
    return html`<sl-breadcrumb-item .href="${item.url}">${item.label}</sl-breadcrumb-item>`;
  }

  get items() {
    return this.breadcrumbsContext?.breadcrumbs || [];
  }

  static styles = css`
    :host {
      --components-breadcrumb-component-link-color: rgba(0, 0, 0, 0.88);
      --components-breadcrumb-component-link-cover-color: rgba(0, 0, 0, 0.55);
      --components-breadcrumb-component-last-item-color: #737373;

      --sl-color-primary-600: var(--components-breadcrumb-component-link-color);
      --sl-color-primary-500: var(--components-breadcrumb-component-link-hover-color);
      --sl-color-neutral-600: var(--Components-Breadcrumb-component-last-item-color);

      --sl-spacing-x-small: 0px;
    }

    sl-breadcrumb-item {
      --sl-color-primary-500: rgba(0, 0, 0, 0.55);
    }

    sl-breadcrumb-item:last-child {
      --sl-color-primary-500: red;
      --sl-color-neutral-600: var(--color-text-secondary, #737373);
    }

    sl-breadcrumb-item {
      ${font("SM/Regular")};
    }

    sl-icon {
      font-size: 16px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-breadcrumb": FixBreadcrumb;
  }
}
