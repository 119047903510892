import { routerInstance } from "@/app-root";
import { Services } from "./services";
import { createProductPath } from "@/pages/category-page/product-area-catalog/product-area-element";
import { B2cProductPayload, ProductDocument } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { Router } from "@vaadin/router";
import i18next from "i18next";

export class Initialize {
    constructor(protected services: Services) {

    }

    async initializeForChangedLanguage(lang: string, countryCode: string) {
        this.services.setLoading(true);

        if (routerInstance.location.route!.name === 'dynamic-route') {
            const entity = await this.findEntityForCurrentDynamicRoute();
            await this.reloadServices(lang, countryCode);
            if (entity) {
                const path = await this.findUpdatedPathForEntity(entity);
                const url = Url.to('dynamic-route', { dynamicPath: path });
                Router.go(url);
            }
        } else {
            await this.reloadServices(lang, countryCode);
        }

        this.services.setLoading(false);
        this.postLocaleChange(lang);
    }

    async findEntityForCurrentDynamicRoute() {
        const { routes } = this.services;

        if (routerInstance.location.route!.name === 'dynamic-route') {
            const path = routerInstance.location.params.dynamicPath as string[];
            const route = routes.convertToRoute({
                segments: path
            });
            const entity = routes.registry.get(route);

            return entity;
        };
    }

    async findUpdatedPathForEntity(entity: { id: string, type: 'brand' | 'category' | 'product' }) {
        const { api, categories, brands } = this.services;

        if (entity.type === 'brand') {
            return [brands.brands.get(entity.id)!.slug];
        }

        if (entity.type === 'category') {
            return categories.getItemPath(entity.id);
        }

        if (entity.type === 'product') {
            const response = await api.client.query({
                query: ProductDocument,
                variables: { input: { id: entity.id } },
            });

            const product = response.data.Product as B2cProductPayload;

            return createProductPath(product, categories);
        }
    }

    async reloadServices(lang: string, countryCode: string) {
        const services = this.services;

        Services.setParams({ countryCode });
        await i18next.changeLanguage(lang);
        services.categories.clear();
        services.brands.clear();
        services.routes.clear();

        await Promise.all([
            services.categories.load(),
            services.brands.load(),
        ]);

        await services.routes.load();
    }

    postLocaleChange(lang: string) {
        const customEvent = new CustomEvent("locale-change", {
            detail: { locale: lang },
        });
        window.dispatchEvent(customEvent);
    }
}