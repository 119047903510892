import { LitElement, html, css, PropertyValueMap } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { Router, Route, Context, ActionFn } from "@vaadin/router";
import { createRef, ref } from "lit/directives/ref.js";
import i18next from "i18next";
import i18nOptions from "../i18next.config";
import Backend from "i18next-http-backend";
import { Api } from "@/api";
import { supportedLanguages, defaultLang } from "@/constants/languages";
import { LayoutParameters } from "./pages/product-page/cont";
import { Services } from "@/services/services";
import { browserDetectLanguage } from "@/util/browser-language-detector";
import "@/pages/cart-page";
import "@/pages/home-page";
import "@/pages/search-page";
import "@/pages/not-found-page";
import "@/pages/product-page";
import "@/layouts/navigation-layout";
import "@/layouts/cart-layout";
import "@/pages/category-page";
import "@/pages/login-page";
import "@/pages/signup-page";
import "@/pages/about-yourself-page";
import "@/pages/forgot-password-page";
import "@/pages/password-recovery-page";
import "@/pages/brand-page";
import "@/pages/policy-page";
import "@/pages/about-us-page";
import "@/pages/payment-terms-page";
import "@/pages/help";
import "@/elements/loader";
import { AddRouterPlugins } from "./router";
import { defaultCountryCode } from "@/services/countries.ts";
import { Commands } from "@vaadin/router";
import { when } from "lit/directives/when.js";
import { Url } from "./helpers/url";

export const routerInstance = new Router();
// AddRouterPlugins(routerInstance);

@customElement("app-root")
export class AppRoot extends LitElement {
  outlet = createRef();

  services!: Services;

  @property({ type: String, reflect: true, attribute: "data-theme" })
  dataTheme = "Default";

  @state()
  loader = false

  state: 'blank' | 'loaded' = 'blank';

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener("loading", this.onLoaderChange);
    new LayoutParameters(this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener("loading", this.onLoaderChange);
  }

  constructor() {
    super();

    this.services = new Services(this, new Api());
    this.services.api.services = this.services;
  }

  render() {
    return html`
      <div ${ref(this.outlet)}></div>
      ${when(this.loader, () => html`<loader-circle></loader-circle>`)}
    `;
  }

  onLoaderChange = ((event: CustomEvent<{ loading: boolean }>) => {
    this.loader = event.detail.loading;
  }) as EventListener

  async firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): Promise<void> {
    super.firstUpdated(_changedProperties);

    const outlet = this.outlet.value!;
    routerInstance.setOutlet(outlet);
    await i18next.use(Backend).init(i18nOptions);
    await routerInstance.setRoutes(this.buildRoutes());
  }

  private async fetchInitialData() {
    // parallel fetch
    await Promise.all([
      this.services.customerChannel.fetchIfNotLoaded(),
      this.services.currency.load(),
      this.services.brands.load(),
      this.services.categories.load(),
      this.services.cart.fetchCart(),
    ]);

    await this.services.routes.load(); // load routes after categories and brands
  }

  configureLanguage: ActionFn = async (context, commands, parseManually = false) => {
    // let lang;
    // lang = context.params.lang;
    // if (parseManually) {
    //   // don't reuse, will be removed
    //   const parsed = context.pathname.split("/");
    //   if (parsed.length > 2 && parsed[1].includes('-')) {
    //     lang = parsed[1].split('-')[0];
    //   }
    // }
    // if (lang && typeof lang === "string") {
    //   const languageCodes = supportedLanguages.map((lang) => lang.code);
    //   if (!languageCodes.includes(lang)) {
    //     await i18next.changeLanguage(defaultLang);

    //     return commands.redirect(routerInstance.urlForName("not-found-page"));
    //   }

    //   await i18next.changeLanguage(lang);
    //   return;
    // }

    // console.log(localStorage.getItem("lang"));

    // const { customer } = this.services.customerChannel;
    // const channelDefaultLanguage = this.services.customerChannel.channelOptions?.defaultLanguage;
    // const customerLang = customer?.locale?.split("0")[0];
    // lang = customerLang ?? localStorage.getItem("lang") ?? channelDefaultLanguage ?? browserDetectLanguage() ?? defaultLang;
    // if (!lang) {
    //   lang = defaultLang;
    // }

    // await i18next.changeLanguage(lang);
  }

  buildRoutes(): Route[] {
    const resolveDynamicRoute: ActionFn = async (context, commands) => {
      if (context.params.dynamicPath?.length) {
        await this.services.routes.load();
        const segments = context.params.dynamicPath as string[];
        const path = this.services.routes.convertToRoute({
          segments,
        });

        const detail = await this.services.routes.resolve(path);

        if (detail?.type === 'category') {
          return commands.component('category-page');
        }

        if (detail?.type === 'product') {
          return commands.component('product-page');
        }

        if (detail?.type === 'brand') {
          return commands.component('brand-page');
        }
      }

      return context.next();
    };
    const parameters = this.services.parameters;

    const locagionGuard: ActionFn = async (context, commands) => {
      // fix vaadin-router behavior, when location not changed on navigation inside the same route with different params
      routerInstance.location.params = context.params as Record<string, string>;
    };

    const parameterGuard: ActionFn = async (context, commands) => {
      const route = context.route;
      const isRouteHasParams = route.path.startsWith('/:lang-:country');
      const isHomeRouteWithoutParams = route.path === '/';

      if (isRouteHasParams) {
        const params = {
          country: context.params.country as string,
          language: context.params.lang as string,
        };

        await parameters.firstVisit(params);
        if (parameters.isParametersNormalizationNeeded(params)) {
          const url = parameters.createNormalizedUrl({
            path: context.route.path,
            params: context.params as Record<string, string>
          });
          return commands.redirect(url);
        }
      }

      if (isHomeRouteWithoutParams) {
        await parameters.firstVisit({});
        const url = parameters.createNormalizedUrl({
          path: `/:lang-:country`,
          params: context.params as Record<string, string>
        });
        return commands.redirect(url);
      }
    };

    const translationGuard: ActionFn = async (context, commands) => {
      const sameLanguage = i18next.language === parameters.parameters.language;
      if (!sameLanguage) {
        await i18next.changeLanguage(parameters.parameters.language);
      }
    };

    const dataLoagingGuard: ActionFn = async (context, commands) => {
      if (this.state === 'blank') {
        this.state = 'loaded';
        await this.fetchInitialData();
      }
    };

    const vatGuard: ActionFn = async (context, commands) => {
      const country = this.services.countries.getByCode(parameters.parameters.country)!;

      this.services.formatter.setDefaultCurrency(parameters.parameters.currency);
      this.services.formatter.setDefaultVat(country.vatRate + 100);
      this.services.customerChannel.setDefaultVat(country.vatRate + 100);
    };

    const basicPrepareRoute: ActionFn = async (context: Context, commands: Commands) => {

    };

    const createAction = (type: 'normal' | 'dynamic'): ActionFn => {
      return async (context, commands) => {
        const actions = [
          locagionGuard,
          parameterGuard,
          translationGuard,
          dataLoagingGuard,
          vatGuard,
        ];

        for (const action of actions) {
          const result = await action(context, commands);
          if (result) {
            return result;
          }
        }

        if (type === 'dynamic') {
          return await resolveDynamicRoute(context, commands);
        }
        await basicPrepareRoute(context, commands);
      };
    };

    const normalAction = createAction('normal');
    const dynamicAction = createAction('dynamic');

    return [
      {
        path: "/",
        children: [
          { path: "/", name: "home-page", component: "home-page", action: normalAction },
          { path: "/page-not-found", name: "not-found-page", component: "not-found-page" },
          { path: "/:lang-:country", name: "home-page-with-lang", component: "home-page", action: normalAction },
          { path: "/:lang-:country/search", name: "search-page", component: "search-page", action: normalAction },
          { path: "/:lang-:country/login", name: "login-page", component: "login-page", action: normalAction },
          { path: "/:lang-:country/cart", name: "cart-page", component: "cart-page", action: normalAction },
          { path: "/:lang-:country/sign-up", name: "signup-page", component: "signup-page", action: normalAction },
          { path: "/:lang-:country/about-yourself", name: "about-yourself-page", component: "about-yourself-page", action: normalAction },
          { path: "/:lang-:country/forgot-password", name: "forgot-password-page", component: "forgot-password-page", action: normalAction },
          { path: "/:lang-:country/password-recovery/:token", name: "password-reset-page", component: "password-reset-page", action: normalAction },
          { path: "/:lang-:country/privacy-policy", name: "privacy-policy-page", component: "privacy-policy-page", action: normalAction },
          { path: "/:lang-:country/about-us", name: "about-us-page", component: "about-us-page", action: normalAction },
          { path: "/:lang-:country/payment-terms", name: "payment-terms-page", component: "payment-terms-page", action: normalAction },
          { path: "/:lang-:country/help", name: "help-page", component: "help-page", action: normalAction },
          { path: "/:lang-:country/help#:section", name: "help-page-with-section", component: "help-page", action: normalAction },
          { path: "/:lang-:country/:dynamicPath*", name: "dynamic-route", action: dynamicAction },
          { path: "(.*)", component: "not-found-page" },
        ],
      },
    ];
  }

  static styles = css``;
}

declare global {
  interface HTMLElementTagNameMap {
    "app-root": AppRoot;
  }
}
