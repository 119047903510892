import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import "../fix-dropdown/fix-dropdown-option.ts";
import { state } from "lit/decorators.js";
import { FixDropdownOptionItem } from "@/elements/fix-dropdown/fix-dropdown-option.ts";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("fix-dialog-selector")
@localized()
export class FixDialogSelector extends LitElement {
  @property({ type: Array<FixDropdownOptionItem> })
  items!: Array<FixDropdownOptionItem>;

  @property({ type: Function })
  onSelect = (value: string, index: number) => {
    return true;
  };

  @property({ type: Boolean })
  isSearchable = true;

  @property({ type: Function })
  onSearch = (value: string) => {
    this.searchTerm = value.toLowerCase();
  };

  @property({ type: String })
  label?: string;

  @state()
  selected: FixDropdownOptionItem | undefined;

  @state()
  searchTerm = "";

  get options() {
    return this.searchTerm
      ? this.items.filter((item) => item.label.toLowerCase().includes(this.searchTerm) || item.value.toString().includes(this.searchTerm))
      : this.items;
  }

  onOptionClick = (value: any, index: number) => {
    this.selected = this.options[index];
    this.onSelect(value, index);
  };

  render() {
    const items = this.options.map(
      (item, index) =>
        html`<fix-dropdown-option .selected=${item.selected}  .value="${item.value}" .label="${item.label}" .icon="${item.icon}" @click=${() => this.onOptionClick(item.value, index)}></sl-option>`,
    );

    const search = this.isSearchable
      ? html`
          <div class="fix-dropdown-search">
            <sl-input @input=${(event: any) => this.onSearch(event.target?.value)} placeholder="${t("0x619y4k02smw9cs.search", "Search")}">
              <sl-icon name="search" slot="prefix"></sl-icon>
            </sl-input>
          </div>
        `
      : "";

    return html`
      <div class="fix-dropdown-options-container">
        ${search}
        <sl-divider></sl-divider>
        <div class="options">${items}</div>
      </div>
    `;
  }

  static styles = css`
    :host {
      --sl-panel-background-color: #fff;
      font-family: "SF Pro Text";
      --sl-shadow-x-large: 0 4px 12px 0 #00000026;
      --sl-border-radius-medium: 6px;
      --sl-spacing-large: 20px;
    }

    .fix-dropdown-search {
      padding-bottom: 5px;
    }

    .options {
      overflow: auto;
      max-height: 100%;
    }

    .fix-dropdown-options-container {
      min-width: 200px;
      overflow: hidden;
      height: 100vh;
    }
  `;
}
