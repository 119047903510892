import { css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("backdrop-area")
export class BackdropArea extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 100;
    }
  `;
}
