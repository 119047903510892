import { adoptStyles, css, html, LitElement, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import "./item-button";
import "./subcategories-box";
import { getMenuIconUrl, getSubMenuItems, getTopMenuItems } from "./util";
import { map } from "lit/directives/map.js";
import { when } from "lit/directives/when.js";
import banner from "@/assets/stub/banner.svg";
import "./backdrop-area";
import car from "@/assets/car.svg";
import account from "@/assets/account.svg";
import { layoutFragment, layoutLgCss } from "./layout-sm";
import type { VirtualElement } from "@shoelace-style/shoelace/dist/components/popup/popup.component";
import {features} from "@/constants/features";

@customElement("menu-lg")
export class MenuLg extends LitElement {
  @property()
  anchor: Element | null = null;

  @state()
  subcategory: null | {
    key: string;
    target: Element;
  } = null;

  outlet!: HTMLElement;

  topAnchor: VirtualElement = {
    getBoundingClientRect: () => {
      return {
        bottom: 0,
        height: 0,
        left: 24,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
        toJSON: () => {},
      };
    },
  };

  render() {
    return html`
      <backdrop-area @click=${this.onCloseMenu}></backdrop-area>
      <sl-popup .anchor=${this.topAnchor} placement="bottom-start" strategy="fixed" active> ${this.renderBox()} </sl-popup>
    `;
  }

  renderBox() {
    return html`
      <div class="container">
        <div class="box">${this.renderBox2()}</div>
        ${when(this.subcategory, this.renderSubcategories)}
      </div>
    `;
  }

  renderBox2 = () => {
    const content = html`
      ${layoutFragment((layout) => layout.renderHeadLg({ onClose: this.onCloseMenu }))} ${this.renderCategories()}
      ${layoutFragment((layout) => layout.renderDelimiter())} ${this.renderAccount()}
    `;
    return html`<layout-sm-container .content=${content}></layout-sm-container>`;
  };

  onCategoryClick = (key: string, event: { detail: { target: Element } }) => {
    this.subcategory = { key, target: event.detail.target };
  };

  renderSubcategories = () => {
    const subcategory = this.subcategory!;
    const item = getTopMenuItems().find((item) => item.key === subcategory.key)!;
    const title = item.name;
    const items = getSubMenuItems();

    return html`
      <div class="subcategoiries-panel">
        <div class="subcategories-box">
          <subcategories-box .title=${title} .items=${items}></subcategories-box>
        </div>
        <div class="banner-box">
          <img class="banner" src=${banner} alt="banner" />
        </div>
      </div>
    `;
  };

  renderAccount = () => {
    const items = html`
      <item-button .title=${"My account"} .sub=${"Hello, Martin"} .schema=${"column"} .icon=${account}></item-button>
      ${when(features.garage, () => html`<item-button .title=${"My garage"} .sub=${"2015 Ford Focus"} .count=${1} .schema=${"column"} .icon=${car}></item-button>`)}
    `;

    return layoutFragment((layout) =>
      layout.renderSection({
        segment: "account",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      }),
    );
  };

  renderCategories = () => {
    const items = map(getTopMenuItems(), (item) => {
      return html`<item-button
        .title=${item.name}
        .icon=${getMenuIconUrl(item.icon)}
        @hover=${(event: { detail: { target: Element } }) => this.onCategoryClick(item.key, event)}
        @click=${(event: { detail: { target: Element } }) => this.onCategoryClick(item.key, event)}
      ></item-button>`;
    });

    return layoutFragment((layout) =>
      layout.renderSection({
        segment: "categories",
        title: "Categories",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      }),
    );
  };

  onCloseMenu = () => {
    this.dispatchEvent(new CustomEvent("close"));
  };

  static styles = css`
    sl-popup::part(popup) {
      z-index: 101;
    }

    .container {
      display: flex;
    }

    .box {
      position: relative;
      display: flex;
      gap: 0px;
      background-color: white;
      z-index: 101;
      overflow: hidden;
      flex-direction: column;
      padding: 16px 24px;
      height: fit-content;
    }

    .categories-box {
    }

    .subcategoiries-panel {
      height: fit-content;
    }

    .subcategories-box {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: calc(50px + 16px);
    }

    .banner-box {
      background-color: black;
      overflow: hidden;
      width: 230px;
      position: relative;
    }

    .banner {
      display: block;
      max-height: 100%;
      position: absolute;
      top: 0;
      max-width: 100%;
    }

    .subcategoiries-panel {
      display: flex;
    }

    .subcategoiries-panel {
      border-radius: 6px;
      background-color: white;
      border: 1px solid var(--Brand-Primary-colorPrimary, #3b82f6);
      border-radius: 0px 6px 6px 6px;
    }

    ${layoutLgCss}
  `;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    const div = document.createElement("div");

    document.body.appendChild(div);
    const root = div.attachShadow({ mode: "open" });

    adoptStyles(root, (this.constructor as typeof LitElement).elementStyles!);

    this.outlet = div;
    return root;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.body.removeChild(this.outlet);
  }
}
