import { localized } from "@/decorators/localized.ts";
import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { font } from "@/util/fonts.ts";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

@localized()
@customElement("confirm-dialog")
export class ConfirmDialog extends LitElement {
  @property({ type: String, reflect: true })
  label = null;

  @property({ type: String, reflect: true })
  text = null;

  onAccept = () => {
    this.dispatchEvent(new CustomEvent("onAccept", {}));
  };

  onCancel = () => {
    this.dispatchEvent(new CustomEvent("onCancel", {}));
  };

  render() {
    return html`
      <sl-dialog @sl-hide=${this.onCancel} open=${true} label="${this.label}" class="dialog">
        <div class="dialog-container">
          <div class="dialog-content">${this.text}</div>
          <div class="dialog-footer">
            <sl-button variant="default" size="large" class="cancel-btn" @click=${this.onCancel}>No</sl-button>
            <sl-button variant="default" size="large" class="accept-btn" @click=${this.onAccept}>Yes</sl-button>
          </div>
        </div>
      </sl-dialog>
    `;
  }

  static styles = css`
    .dialog-footer {
      display: flex;
    }
    sl-button {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      --sl-input-border-radius-large: 8px;
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
      display: flex;
      width: 50%;
    }

    sl-button.cancel-btn {
      margin-right: 8px;
    }

    sl-button.accept-btn {
      margin-left: 8px;
      ${shoelace.neutralColor}: #000;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #f4f4f5);
    }

    sl-dialog::part(header) {
      display: none;
    }
    :host {
      ${font("Heading/5")}
    }
    .dialog {
      --body-spacing: 32px;
      --sl-border-radius-medium: 6px;
      --width: max-content;
    }
    .dialog-content {
      text-align: center;
      margin-bottom: 16px;
    }
  `;
}
