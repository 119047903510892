import { localized } from "@/decorators/localized.ts";
import { customElement } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import cart from "@/assets/shopping-cart.svg";
import { t } from "i18next";
import { font } from "@/util/fonts.ts";

const shoelace = {
  normalBg: css`--sl-color-neutral-0`,
  hoverBg: css`--sl-color-primary-50`,
  activeBg: css`--sl-color-primary-100`,
  neutralColor: css`--sl-color-neutral-700`,
  effectColor: css`--sl-color-primary-700`,
  height: css`--sl-input-height-medium`,
  borderRadius: css`--sl-input-border-radius-medium`,
};

@localized()
@customElement("cart-empty-label")
export class CartEmptyLabel extends LitElement {
  render() {
    return html`
      <div class="cart-empty">
        <sl-icon src=${cart}></sl-icon>
        <div class="label">${t("y9ht8nx7p3ni80xt.empty-label", "Your shopping cart is empty")}</div>
        <sl-button variant="default" size="large" href="/">${t("e83geotgxa5m02vb.back-to-shopping", "Back to shopping")}</sl-button>
      </div>
    `;
  }

  static styles = css`
    sl-button::part(label) {
      ${font('LG/Normal')}
      line-height: inherit;
    }
      
    sl-icon {
      font-size: 90px;
    }

    .cart-empty {
      text-align: center;
      padding: 30px;
      background: #fff;
      border-radius: 6px;
    }

    .label {
      margin: 20px 0px;
      ${font('Heading/5')}
    }

    sl-button {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      --sl-input-border-radius-large: 8px;
      ${shoelace.neutralColor}: #ffffff;
      ${shoelace.effectColor}: #ffffff;
      ${shoelace.normalBg}: var(--components-button-global-color-primary, #3b82f6);
      ${shoelace.hoverBg}: var(--components-button-global-color-primary-hover, #60a5fa);
      ${shoelace.activeBg}: var(--components-button-global-color-primary-active, #2563eb);
      display: flex;
      margin: auto;
      max-width: 480px;
    }
  `;
}
