import { css, html, LitElement, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import backSvg from "@/assets/back.svg";
import { font } from "@/util/fonts";
import { t } from "i18next";
import { localized } from "@/decorators/localized.ts";
import { ifDefined } from "lit/directives/if-defined.js";

@customElement("fix-back-button")
@localized()
export class FixBackButton extends LitElement {
  @property()
  text = t("en5102c65e3r2vnz.back", "Back");

  @property()
  url!: string;

  @property()
  mode: 'button' | 'link' = 'button';

  render() {
    return html`
      <sl-button class="back" variant="text" .href=${ifDefined(this.href)}>
        <sl-icon class="icon" slot="prefix" src=${backSvg}></sl-icon>
        ${this.text}
      </sl-button>
    `;
  }

  get href() {
    if (this.mode === 'link') {
      return this.url;
    }
  }

  static styles = css`
    .back {
      ${font("Base/Normal")};
      padding: 0;
      --sl-spacing-medium: 0;
      --sl-input-height-medium: 0;
      --sl-spacing-small: 0px;
      --sl-color-primary-600: var(--Components-Typography-Global-colorLink, #3b82f6);
      --sl-color-primary-500: var(--Brand-Primary-colorPrimaryHover, #60a5fa);
      --sl-color-primary-600: var(--Brand-Primary-colorPrimaryActive, #2563eb);
    }

    .back::part(base) {
      gap: 8px;
    }

    .back sl-icon {
      font-size: 16px;
    }
  `;
}
