import { customElement } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import spare1 from "@/assets/loader-icons/spare-1.svg";
import spare2 from "@/assets/loader-icons/spare-2.svg";
import spare3 from "@/assets/loader-icons/spare-3.svg";
import spare4 from "@/assets/loader-icons/spare-4.svg";

@customElement("loader-circle")
export class Loader extends LitElement {
  render() {
    return html`
      <div class="loader-overlay">
        <div class="loader">
          <div class="spares">
            <div class="spares-line">
              <sl-icon class="spares-item" src=${spare1}></sl-icon>
              <sl-icon class="spares-item" src=${spare2}></sl-icon>
              <sl-icon class="spares-item" src=${spare3}></sl-icon>
              <sl-icon class="spares-item" src=${spare4}></sl-icon>
              <sl-icon class="spares-item" src=${spare1}></sl-icon>
            </div>
          </div>
          <div class="ellipse">
            <div class="ellipse-left"></div>
            <div class="ellipse-right"></div>
          </div>
        </div>
      </div>
    `;
  }

  static styles = css`
    .spares-line {
      height: max-content;
      animation: lineup 2s ease-in-out infinite;
    }

    .loader-overlay {
      width: 100%;
      height: 100vh;
      top: 0;
      bottom: 0;
      backdrop-filter: blur(4px);
      background: #ffffff78;
      position: fixed;
      z-index: 999;
    }

    .loader {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: max-content;
      height: max-content;
      overflow: hidden;
    }

    .ellipse {
      width: 125px;
      height: 125px;
      position: relative;
      background: conic-gradient(from 272deg, rgb(59, 130, 246) 0%, rgba(255, 255, 255, 0) 50%),
        conic-gradient(from 92deg, rgb(126, 156, 245) 0%, rgba(255, 255, 255, 0) 50%);
      mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 18px), rgb(0, 0, 0) 0px);
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      animation: rotation 2s ease-in-out infinite;
    }

    .spares {
      position: absolute;
      width: 75px;
      height: 75px;
      overflow: hidden;
      border-radius: 100%;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    .ellipse-left {
      width: 125px;
      position: absolute;
      height: 125px;
      background: linear-gradient(0deg, rgb(59, 130, 246) 53%, rgba(0, 212, 255, 0) 55%);
      aspect-ratio: 1 / 1;
      clip-path: xywh(0px 37px 18px 30px round 2%);
    }

    .ellipse-right {
      width: 125px;
      position: absolute;
      height: 125px;
      aspect-ratio: 1;
      clip-path: xywh(107px 60px 18px 28px round 2%);

      background: linear-gradient(180deg, #7e9cf5 53%, rgba(0, 212, 255, 0) 55%);
    }

    .spares-item {
      width: 45px;
      height: 45px;
      margin: 15px auto;
      display: block;
    }

    @keyframes lineup {
      0% {
        transform: translate(0px, 0px);
      }
      25% {
        transform: translate(0px, -60px);
      }
      50% {
        transform: translate(0px, -120px);
      }
      75% {
        transform: translate(0px, -180px);
      }
      100% {
        transform: translate(0px, -240px);
      }
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(90deg);
      }
      50% {
        transform: rotate(180deg);
      }
      75% {
        transform: rotate(270deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
}
