import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";

export interface FixDropdownOptionItem {
  label: string;
  value: string | number;
  icon: any;
  selected: boolean;
}

@customElement("fix-dropdown-option")
export class FixDropdownOption extends LitElement {
  @property({ type: String })
  label!: string;

  @property({ type: String })
  value!: string;

  @property({ type: Boolean })
  selected = false;

  @property({ type: LitElement })
  icon: any;

  get classes() {
    return { "fix-dropdown-option": true };
  }

  get selectedIcon() {
    return this.selected ? html`<div class="fix-dropdown-option-selected-icon"><sl-icon name="check-lg"></sl-icon></div>` : "";
  }

  render() {
    return html`<div class=${classMap(this.classes)}>${this.icon} <span>${this.label ?? this.value}</span>${this.selectedIcon}</div>`;
  }

  static styles = css`
    .fix-dropdown-option {
      padding: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
    }
    .fix-dropdown-option span {
      margin-left: 4px;
    }
    .fix-dropdown-option-selected-icon {
      position: absolute;
      right: 10px;
      color: green;
    }
    .fix-dropdown-option:hover,
    .fix-dropdown-option.selected {
      background: var(--car-brands-color-border-hover, #3b82f6);
      color: #ffffff;
    }
  `;
}
