import { Api } from "@/api";
import { Currency } from "@/generated/graphql/b2c";
import { CurrenciesDocument } from "@/generated/graphql/generic";
import { ContextProvider, createContext } from "@lit/context";
import { LitElement } from "lit";

export class CurrencyService {
  provider: ContextProvider<{ __context__: CurrencyService }, LitElement>;

  private api: Api;

  private rates = new Map<string, number>();

  currencies: Currency[] = [];

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: CurrencyServiceContext, initialValue: this });
    this.api = api;
  }

  private async fetchCurrencies() {
    const response = await this.api.client.query({
      query: CurrenciesDocument,
      variables: {},
    });

    return response.data.Currencies;
  }

  public async load() {
    const currencies = await this.fetchCurrencies();

    // @ts-ignore
    this.currencies = currencies;
    this.setRates(currencies);
    this.update();
  }

  update = () => {
    this.provider.setValue(this, true);
  };

  private setRates = (data: Array<{ code: string; rate: number }>) => {
    for (const item of data) {
      this.rates.set(item.code, item.rate);
    }
    this.provider.setValue(this, true);
  };

  public getRates() {
    return this.rates;
  }

  public getRate(code: string) {
    const rate = this.rates.get(code);
    if (!rate) {
      throw new Error(`no currency rate for [${code}]`);
    }

    return rate;
  }
}

export const CurrencyServiceContext = createContext<CurrencyService>("currency-service");
