import { localized } from "@/decorators/localized";
import { B2cBrand } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { MultiTypeElement, whenType } from "@/util/element";
import { font } from "@/util/fonts";
import { css, html } from "lit";
import { t } from "i18next";
import { customElement, property, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import rightSvg from "@/assets/right-outlined.svg";
import downSvg from "@/assets/down-outlined.svg";
import { when } from "lit/directives/when.js";

@customElement("brands-list-links")
@localized()
export class BrandsListLinks extends MultiTypeElement {
  @property()
  type!: "sm" | "lg";

  @property()
  brands!: Array<B2cBrand>;

  counts = {
    sm: 10,
    lg: 15,
  }

  @state()
  cursor = 0;

  render() {
    return html`
      <div class="item-list">${this.renderList()}</div>
      ${when(this.isShowMoreVisible, () => this.renderMore())}
    `;
  }

  renderList() {
    return repeat(
      this.list,
      (item) => item.id,
      (item) => {
        return html`
          <fix-link class="item" href="${item.url}">
            <span class="item-inner">
              <span class="item-text">${item.name}</span>
              <sl-icon class="icon" slot="prefix" src=${rightSvg}></sl-icon>
            </span>
          </fix-link>
        `;
      },
    );
  }

  renderMore() {
    return html`<div class="controls">
      <sl-button class="button-more" @click=${this.onShowMoreClick}>
        ${t("76vwwhyjifi741oi.more-car-brands", "More car brands")}
        <sl-icon class="icon" slot="suffix" src=${downSvg}></sl-icon>
      </sl-button>
    </div>`;
  }

  private onShowMoreClick() {
    this.cursor = Math.min(this.cursor + this.step, this.brands.length);
  }

  get list() {
    const brands = this.brands.slice(0, this.cursor).map((brand) => ({
      ...brand,
      url: Url.to("dynamic-route", { dynamicPath: [brand.slug] }),
    }));

    return brands;
  }

  updated(changedProperties: Map<string | number | symbol, unknown>) {
    super.updated(changedProperties);
    if (changedProperties.has("brands")) {
      this.cursor = Math.min(this.step, this.brands.length);
    }
  }

  get step() {
    return this.counts[this.type];
  }

  get isShowMoreVisible() {
    return this.brands.length > this.cursor;
  }

  static stylesCommon = css`
    ::part(base) {
      border-color: var(--neutral-text-color-text-secondary, #737373);
      color: var(----base-gray-10, #18181b);
      ${font("Base/Normal")};
      line-height: var(--size-height-control-height, 40px);
    }

    .item-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: var(--spacing-paddingXS, 8px);
    }

    .item {
      align-items: center;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--car-brands-color-border, #d9d9d9);
      gap: var(--spacing-paddingXS, 8px);
    }

    .item:hover {
      border-color: var(--car-brands-color-border-hover, #3b82f6);
    }

    .item:active {
      border-color: var(--car-brands-color-border-active, #2563eb);
    }

    .item-text {
      display: inline-block;
      align-items: center;
      vertical-align: middle;
      color: var(--neutral-text-color-text, #27272a);
      ${font("Base/Normal")};
      margin-right: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-inner {
      display: flex;
      align-items: center;
      padding: 0 16px;
      min-height: var(--size-base-size-xxl, 48px);
    }

    .icon {
      width: 8px;
      height: 12px;
    }

    .button-more {
      display: inline-flex;
      align-self: center;
      align-items: center;
      align-content: center;
      margin-top: 24px;
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  static stylesSm = css`
    .item-list {
      grid-template-columns: repeat(2, 1fr);
    }

    .button-more {
      margin-top: 16px;
    }
  `;

  static stylesLg = css``;

  static styles = [BrandsListLinks.stylesCommon, whenType("sm", BrandsListLinks.stylesSm), whenType("lg", BrandsListLinks.stylesLg)];
}
