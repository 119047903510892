import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { ProductAreaStore } from "./product-area-store";

@customElement("tags-element")
export class TagsElement extends LitElement {
  @property()
  store!: ProductAreaStore;

  render() {
    const items = repeat(
      ["brands", "categories"] as const,
      (type) => type,
      (type) => html` <div class="group">${this.getOptionsFor(type)}</div> `,
    );

    return html` <div class="tags">${items}</div> `;
  }

  getOptionsFor(type: string) {
    return repeat(
      this.store.filters.getOptions(type).filter((option) => option.selected),
      (item) => item.key,
      (item) => html` <fix-tag .name=${this.getTagName(type)} .value=${item.name} .id=${item.key} @remove=${() => this.onRemove(item.key)}></fix-tag>`,
    );
  }

  getTagName(type: string) {
    switch (type) {
      case "brand":
        return "Brand";
      case "category":
        return "Category";
      default:
        return "Value";
    }
  }

  onRemove = (key: string) => {
    this.store.filters.setOption(key, false);
    this.store.filters.immediateApply();
  };

  connectedCallback() {
    super.connectedCallback();
    this.store.host.addEventListener("productarea.update", this.storeChanged);
  }

  disconnectedCallback() {
    this.store.host.removeEventListener("productarea.update", this.storeChanged);
  }

  storeChanged = () => {
    this.requestUpdate();
  }

  static styles = css`
    .tags,
    .group {
      display: flex;
      gap: 8px;
    }
  `;
}
