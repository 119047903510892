export class Debouncer {
  protected timeout: number | null = null;

  constructor(
    private timeoutMs: number,
    protected immediate: boolean = false,
  ) {}

  call(callback: () => void) {
    this.release();
    if (this.immediate && this.timeout === null) {
      callback();
      this.scheduleExecution(() => {});
    } else {
      this.scheduleExecution(callback);
    }
  }

  active() {
    return this.timeout !== null;
  }

  release() {
    if (this.active()) {
      clearTimeout(this.timeout!);
    }
    this.timeout = null;
  }

  protected scheduleExecution(callback: () => void) {
    // @ts-ignore
    this.timeout = setTimeout(() => {
      this.release();
      callback();
    }, this.timeoutMs);
  }
}
