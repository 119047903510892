import { LitElement, html, css, PropertyValueMap } from "lit";
import { customElement } from "lit/decorators.js";
import "@/elements/mobile/fix-heading";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { Url } from "@/helpers/url";
import { consume } from "@lit/context";
import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { layout } from "../product-page/cont";
import { VariantSwitcher } from "@/pages/product-page/switcher";
import { choose } from "lit/directives/choose.js";
import "./layout/page-section-layout";

import "./home-page-mobile";
import "./home-page-desktop";

import "./extracted/categories-preview";
import "./extracted/top-categories";
import "./extracted/car-brands-list";
import "./extracted/main-banner";
import "./extracted/featured-brands-list";
import "../product-page/extracted/benefits-list";
import "../category-page/categories-preview";
import { ServiceLocatorContext, Services } from "@/services/services";

@customElement("home-page")
@layout()
@localized()
export class HomePage extends LitElement {
  layout!: string;

  switcher: VariantSwitcher<HomePage> = new VariantSwitcher(this);

  @consume({ context: BreadcrumbsContext })
  private breadcrumbsContext!: Breadcrumbs;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(_changedProperties);
    this.breadcrumbsContext.setBreadcrumbs([]);
  }

  render() {
    const { ref, base } = this.switcher.props();

    return choose(this.layout, [
      ["desktop", () => html`<home-page-desktop ${ref} .base=${base}></home-page-desktop>`],
      ["mobile", () => html`<home-page-mobile ${ref} .base=${base}></home-page-mobile>`],
    ]);
  }

  renderCategoriesPreview(type: "lg" | "sm") {
    const items = this.services.categories.findTopLevelCategories().map(category => this.services.categories.getItem(category.id));
    return html` <categories-preview-custom type=${type} .categories=${items}></categories-preview-custom>`;
  }

  renderMainBanner(type: "lg" | "sm") {
    return html`<main-banner type=${type}></main-banner>`;
  }

  renderTopCategories(type: "lg" | "sm", bg = true) {
    return html`<top-categories type=${type} .bg=${bg}></top-categories>`;
  }

  renderCarBrands(type: "lg" | "sm") {
    return html`<car-brands-list type=${type}></car-brands-list>`;
  }

  renderFeaturedBrands(type: "lg" | "sm") {
    return html`<featured-brands-list type=${type}></featured-brands-list>`;
  }

  renderBenefitsList(type: "lg" | "sm") {
    return html`<benefits-list type=${type}></benefits-list>`;
  }

  static styles = css``;
}

declare global {
  interface HTMLElementTagNameMap {
    "home-page": HomePage;
  }
}
