import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/elements/mobile/fix-label";

@customElement("fix-group")
export class FixGroup extends LitElement {
  @property({ type: String })
  text = "";

  render() {
    return html`<fix-label .text=${this.text}></fix-label><slot></slot>`;
  }

  static styles = css`
    fix-label + ::slotted(*) {
      margin-top: 2px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-group": FixGroup;
  }
}
