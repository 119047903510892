import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/elements/mobile/fix-group";
import "@/elements/mobile/fix-options";

@customElement("product-variations")
export class ProductVariations extends LitElement {
  @property({ type: Array })
  variations = [] as Array<{
    id: string;
    name: string;
    size: string;
    price: string;
  }>;

  @property({ type: String })
  active = null as null | string;

  render() {
    const { variations, active } = this;

    if (!variations) return html``;

    return html`
      <fix-group .text=${"Variations"}>
        <fix-options .options=${variations} .active=${active} .renderer=${this.renderOption}></fix-options>
      </fix-group>
    `;
  }

  renderOption(item: { id: string; name: string; size: string; price: string }) {
    return html`
      <div class="name">${item.name}</div>
      <div class="size center">${item.size}</div>
      <div class="price bold">${item.price}</div>
    `;
  }

  static styles = css``;
}

declare global {
  interface HTMLElementTagNameMap {
    "product-variations": ProductVariations;
  }
}
