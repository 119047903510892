export class ProductFormOptions {
    brands: Array<{ id: string; name: string }> = [];
    categories: Array<{ id: string; name: string }> = [];
    sorters: Array<{ id: string; name: string }> = [];

    constructor() {
        this.sorters = [
            { id: "price.asc", name: "Price: Low to High" },
            { id: "price.desc", name: "Price: High to Low" },
            { id: "dispatch.asc", name: "Dispatch: Low to High" },
            { id: "dispatch.desc", name: "Dispatch: High to Low" },
        ];
    }
}