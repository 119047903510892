import { customElement, property, query, state } from "lit/decorators.js";
import { LitElement, PropertyValues, css, html } from "lit";
import { type } from "@/util/layout";
import { font } from "@/util/fonts";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import DomPurify from "dompurify";
import { MultiTypeElement, whenType } from "@/util/element";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

@customElement("fix-text")
export class FixText extends MultiTypeElement {
  @property()
  type: "lg" | "sm" = "lg";

  @property()
  text = "";

  @state()
  more = true;

  @state()
  collapsed = true;

  @query(".text")
  textElement!: HTMLDivElement;

  render() {
    const classNames = classMap({
      container: true,
      collapsed: this.collapsed,
    });

    return html`
      <div class=${classNames}>
        <div class="text">${unsafeHTML(this.getSafeHtml())} ${when(this.collapsed, () => this.renderOverlay())}</div>
        ${when(this.more, () => this.renderMore())}
      </div>
    `;
  }

  renderMore() {
    return html`
            <sl-button class="more-button" variant="text" size="small" caret @click=${this.onMoreClick}>Show more</button>
        `;
  }

  renderOverlay() {
    return html`<div class="overlay"></div>`;
  }

  getSafeHtml() {
    return DomPurify.sanitize(this.text, {});
  }

  onMoreClick = () => {
    this.more = false;
    this.collapsed = false;
  };

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this.collapsed = this.textElement.scrollHeight >= 100;
    this.more = this.collapsed;
  }

  static styleCommon = css`
    :host {
      display: block;
    }

    .container {
      position: relative;
      overflow: hidden;
    }

    .text {
      position: relative;
    }

    .overlay {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) -23.26%, #fff 81.4%);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    .more-button {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .collapsed .text {
      max-height: calc(--line-height * 4);
      overflow: hidden;
      margin-bottom: 2em;
    }
  `;

  static styleSm = css`
    .text {
      color: #000;
      ${font("SM/Regular")};
      --line-height: 20px;
    }
  `;

  static styleLg = css`
    .text {
      color: var(--Components-Typography-Global-colorText, #27272a);
      ${font("Base/Normal")};
      --line-height: 22px;
    }
  `;

  static styles = [FixText.styleCommon, whenType("sm", FixText.styleSm), whenType("lg", FixText.styleLg)];
}
