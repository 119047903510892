import { adoptStyles, css, html, LitElement, nothing } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import "./item-button";
import "./subcategories-box";
import { map } from "lit/directives/map.js";
import { when } from "lit/directives/when.js";
import banner from "@/assets/stub/banner.svg";
import "./backdrop-area";
import { Categories, CategoriesContext } from "@/services/categories";
import { consume } from "@lit/context";
import { repeat } from "lit/directives/repeat.js";
import { B2cCategoryTreePayload } from "@/generated/graphql/b2c";

const getIconUrl = (path: string | null) => {
  if (!path?.length) return path;

  return import.meta.env.PUBLIC_B2C_ASSETS_HOST + "/" + path;
};

@customElement("menu-categories")
export class MenuCategories extends LitElement {
  @consume({ context: CategoriesContext, subscribe: true })
  categories!: Categories;

  @property()
  anchor: Element | null = null;

  @state()
  subcategory: null | {
    key: string;
    target: Element;
  } = null;

  outlet!: HTMLElement;

  value = new MenuStructure();

  render() {
    return html`
      <backdrop-area @click=${this.onCloseMenu}></backdrop-area>
      <sl-popup .anchor=${this.anchor} placement="bottom-start" active> ${this.renderBox()} </sl-popup>
    `;
  }

  renderBox() {
    return html` <div class="box">${this.renderCategories()} ${when(this.subcategory, this.renderSubcategories)}</div> `;
  }

  onCategoryClick = (key: string, event: { detail: { target: Element } }) => {
    this.subcategory = { key, target: event.detail.target };
  };

  renderSubcategories = () => {
    const rootId = this.subcategory!.key;

    return html`
      <div class="subcategoiries-panel">
        <div class="subcategories-box">
          <subcategories-box .menu=${this.categories} .rootId=${rootId} @close=${this.onClose}></subcategories-box>
        </div>
        <div class="banner-box">
          <img src=${banner} alt="banner" />
        </div>
      </div>
    `;
  };

  onClose = () => {
    this.dispatchEvent(new CustomEvent('close', { composed: true, bubbles: true }))
  }

  renderCategories = () => {
    const items = repeat(
      this.value.getRoots(),
      (item) => item.id,
      (item) => {
        return html`<item-button
          .title=${item.name}
          .icon=${item.icon}
          @hover=${(event: { detail: { target: Element } }) => this.onCategoryClick(item.key, event)}
          @click=${(event: { detail: { target: Element } }) => this.onCategoryClick(item.key, event)}
        ></item-button>`;
      },
    );

    return html`<div class="categories-box">${items}</div>`;
  };

  onCloseMenu = () => {
    this.dispatchEvent(new CustomEvent("close"));
  };

  connectedCallback(): void {
    super.connectedCallback();
    this.value.setTree(this.categories.findTopLevelCategories());
  }

  static styles = css`
    sl-popup::part(popup) {
      z-index: 101;
    }

    .box {
      position: relative;
      display: flex;
      gap: 0px;
      border-radius: 6px;
      background-color: white;
      border: 1px solid var(--Brand-Primary-colorPrimary, #3b82f6);
      border-radius: 0px 6px 6px 6px;
      z-index: 101;
      margin-right: 48px;
      overflow: hidden;
    }

    .categories-box {
      padding: 16px;
    }

    .subcategories-box {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: calc(50px + 16px);
    }

    .banner-box {
      background-color: black;
      display: none;
    }

    .subcategoiries-panel {
      display: flex;
    }
  `;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    const div = document.createElement("div");

    document.body.appendChild(div);
    const root = div.attachShadow({ mode: "open" });

    adoptStyles(root, (this.constructor as typeof LitElement).elementStyles!);

    this.outlet = div;
    return root;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    document.body.removeChild(this.outlet);
  }
}

type MenuItem = {
  id: string;
  key: string;
  name: string;
  icon: string | null;
  level: number;
  url: string;
};

export class MenuStructure {
  items: Record<string, MenuItem> = {};
  links: Record<
    string,
    {
      parent: string[];
      children: string[];
    }
  > = {};

  setTree(list: B2cCategoryTreePayload[]) {
    const queue = [...list] as B2cCategoryTreePayload[];
    while (queue.length) {
      const item = queue.shift()!;

      this.links[item.id] ??= {
        parent: [],
        children: [],
      };
      this.items[item.id] = this.toMenuItem(item);

      const parent = (this.links[item.id].parent ?? []).concat(item.id);

      for (const child of item.children) {
        this.links[item.id].children.push(child.id);
        this.links[child.id] = {
          parent: parent,
          children: [],
        };
        queue.push(child);
      }
    }
  }

  getItem(id: string) {
    return this.items[id];
  }

  getChilds(id: string) {
    return (this.links[id].children ?? []).map((id) => this.items[id]);
  }

  getParents(id: string) {
    return (this.links[id].parent ?? []).map((id) => this.items[id]);
  }

  getRoots() {
    return Object.values(this.items).filter((item) => this.links[item.id].parent.length === 0);
  }

  toMenuItem(value: B2cCategoryTreePayload) {
    const parents = this.getParents(value.id);
    const ids = [...parents.map((item) => item.id), value.id];
    const path = ids.join("/");

    return {
      id: value.id,
      key: value.id,
      name: value.name,
      icon: getIconUrl(value.icon),
      level: parents.length,
      url: `en/categories/${path}`,
    };
  }
}
