import { customElement, property, state } from "lit/decorators.js";
import { LitElement, css, html, PropertyValueMap } from "lit";
import { font } from "@/util/fonts";
import shippingSvg from "@/assets/shipping.svg";
import { consume } from "@lit/context";
import { Main, mainContext } from "@/context/main";
import { MinimalShippingRateDocument } from "@/generated/graphql/b2c";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { Parameters, ParametersContext } from "@/services/parameters.ts";

@customElement("shipping-notify")
@localized()
export class ShippingNotify extends LitElement {
  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  @consume({ context: ParametersContext, subscribe: true })
  public parameters!: Parameters;

  @property({ type: String })
  productId = "";

  @property({ type: String })
  priceId = "";

  @state()
  private rate = "";

  @state()
  private countryCode = "";

  async connectedCallback() {
    super.connectedCallback();
    await this.fetchShippingRate();
  }

  protected async updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    super.updated(_changedProperties);
    const hashChanged = _changedProperties.get("priceId");
    if (hashChanged) {
      await this.fetchShippingRate();
    }
  }

  render() {
    if (this.rate && this.countryCode) {
      return html`
        <sl-icon src=${shippingSvg}></sl-icon>
        <div class="text">
          ${t("ae7dj7i556457vta.minimal-shipping-rate", "Shipping to {{countryCode}} starts from {{val, currency(EUR)}}", {
            val: this.rate,
            countryCode: this.countryCode,
          })}
        </div>
      `;
    }
  }

  fetchShippingRate = async () => {
    const currentCountryCode = this.parameters.getVisibleCountry();

    try {
      const response = await this.mainContext?.api.client.query({
        query: MinimalShippingRateDocument,
        variables: {
          input: {
            countryCode: currentCountryCode,
            products: [{ id: this.productId, chainHash: this.priceId }],
          },
        },
      });

      const data = response.data.MinimalShippingRate;
      this.countryCode = data.country?.code;
      this.rate = data.shippingMethod?.price?.amount;
    } catch (error) {
      console.error("Fetching minimal shipping rate failed:", error);
    }
  };

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      gap: var(--Space-Padding-paddingSM, 12px);
    }

    .text {
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      ${font("SM/Regular")};
    }

    sl-icon {
      font-size: 20px;
    }
  `;
}
