import { css, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("popup-backdrop-area")
export class PopupBackdropArea extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }
  `;
}
