import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("fix-loader")
export class FixLoader extends LitElement {
  render() {
    return html`
      <div class="loader-container">
        <sl-spinner style="font-size: 3rem;"></sl-spinner>
      </div>
    `;
  }

  static styles = css`
    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 200px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-loader": FixLoader;
  }
}
