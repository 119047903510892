import { font } from "@/util/fonts";
import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { styleMap } from "lit/directives/style-map.js";
import moreSvg from "@/assets/more-vert.svg";
import { repeat } from "lit/directives/repeat.js";
import { Debouncer } from "@/util/schedule";

@customElement("category-type")
export class CategoryType extends LitElement {
  @queryAll(".tab")
  tabs!: NodeListOf<HTMLDivElement>;

  @query(".container")
  container!: HTMLDivElement;

  @state()
  indicator = {};

  @state()
  all = false;

  @state()
  items: Array<{ key: string; name: string; icon: string }> = [];

  @state()
  index: null | number = null;

  @property()
  active: string | null = null;

  @property()
  categories: Array<{ name: string; key: string; icon: string; url?: string }> = [];

  @property()
  mode: "all" | "line" = "all";

  debouncer = new Debouncer(0);

  render() {
    return html`
      <div class="container" @mouseleave=${this.setupInitialIndicatorPosition}>
        <div class="items">
          ${repeat(
      this.items,
      (item, index) => item.key,
      (item, index) => this.renderCategoryTab(item, index),
    )}
        </div>
        ${this.renderIndicator()}
      </div>
    `;
  }

  renderCategoryTab(params: { name: string; key: string; icon: string; url?: string }, index: number) {
    const classNames = classMap({
      tab: true,
      active: this.active === params.key,
    });

    return html`
      <a
        class="link"
        href=${params.url ?? "#"}
        router-local
        @click=${(event: MouseEvent) => this.onTabClick(index, event)}
        @mouseenter=${() => this.activateTab(index)}
      >
        <div class=${classNames}>
          <sl-icon class="icon" src=${params.icon}></sl-icon>
          <div class="label">${params.name}</div>
        </div>
      </a>
    `;
  }

  renderIndicator() {
    return html`
      <div class="indicator" style=${styleMap(this.indicator)}>
        <div class="arrow"></div>
      </div>
    `;
  }

  getRelativeRect(element: HTMLElement) {
    const containerRect = this.container.getBoundingClientRect();
    const rect = element.getBoundingClientRect();

    return {
      left: rect.left - containerRect.left,
      right: containerRect.right - rect.right,
      top: rect.top - containerRect.top,
      bottom: containerRect.bottom - rect.bottom,
      width: rect.width,
    };
  }

  activateTab(index: number) {
    this.setupIndexPosition(index);
  }

  setupInitialIndicatorPosition() {
    this.index = this.getActiveTabIndex();
  }

  setupIndexPosition(index: number) {
    this.index = index;
  }

  getActiveTabIndex() {
    const index = this.items.findIndex((item) => item.key === this.active);
    return index === -1 ? null : index;
  }

  async onTabClick(index: number, event: MouseEvent) {
    const item = this.items[index];
    const isMoreItem = item.key === "more";
    if (isMoreItem) {
      event.preventDefault();

      this.updateItems(true);
    } else {
      // event.preventDefault();
      // event.stopPropagation();
      // event.stopImmediatePropagation();
      // this.dispatchEvent(new CustomEvent("category-click", { detail: { key: item.key } }));
    }
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has("index") || _changedProperties.has("items")) {
      this.moveIndicator(this.index);
    }
  }

  protected moveIndicator(index: number | null) {
    if (index === null) {
      this.indicator = {
        display: "none",
      };
    } else {
      const tab = this.tabs[index];
      const rect = this.getRelativeRect(tab);
      this.indicator = {
        display: "block",
        "--left": `${rect.left}px`,
        "--right": `${rect.right}px`,
        "--top": `${rect.bottom}px`,
        "--width": `${rect.width}px`,
      };
    }
  }

  sizes = {
    item: 90,
    gap: 4,
  };

  protected calculateLineItems() {
    const { item, gap } = this.sizes;
    const width = this.getBoundingClientRect().width;
    const countHead = Math.floor(width / (item + gap));
    const countTail = width % (item + gap) >= item ? 1 : 0;

    return countHead + countTail;
  }

  protected calculateExpectedWidth(count: number) {
    const { item, gap } = this.sizes;

    return count * item + (count - 1) * gap;
  }

  connectedCallback(): void {
    super.connectedCallback();
    window.addEventListener("resize", this.onResize);
    this.updateItems(this.mode === "all");
    this.setupInitialIndicatorPosition();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener("resize", this.onResize);
  }

  protected onResize = () => {
    this.debouncer.call(() => {
      this.moveIndicator(this.index);
    });
  };

  protected updateItems(all: boolean) {
    this.all = all;
    this.items = this.createItemsForCurrentMode();
  }

  protected createItemsForCurrentMode() {
    const categories = this.categories.length;

    if (this.all) {
      return this.createItems(categories);
    }

    const width = this.getBoundingClientRect().width;
    const allItemsWidth = this.calculateExpectedWidth(this.categories.length);
    const count = Math.min(categories, this.calculateLineItems());
    const moreInsteadLastItem = width < allItemsWidth;

    return this.createItems(count, moreInsteadLastItem);
  }

  protected createItems(count: number, more = false) {
    const lastItemIndex = count - 1;
    const items = [] as Array<{ key: string; name: string; icon: string; url?: string }>;
    for (let index = 0; index < count; index++) {
      const category = this.categories[index];
      if (!category) {
        break;
      }

      const isMoreItem = more && index === lastItemIndex;
      const item = isMoreItem
        ? {
          key: "more",
          name: "More",
          icon: moreSvg,
        }
        : {
          key: category.key,
          name: category.name,
          icon: category.icon,
          url: category.url,
        };

      items.push(item);
    }

    return items;
  }

  static styles = css`
    .container {
      position: relative;
    }

    .items {
      display: grid;
      grid-template-columns: repeat(auto-fit, 90px);
      gap: 12px 4px;
      justify-content: space-between;
    }

    .tab {
      --color: var(--colorText, rgba(0, 0, 0, 0.88));
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0px;
      user-select: none;
      cursor: pointer;
      overflow: hidden;
      max-width: 100%;
      padding-bottom: 9px;
    }

    .tab:hover {
      --color: var(--Category-Type-colorIconHover, #3b82f6);
    }

    .tab:active {
      --color: var(--Category-Type-colorIconClick, #2563eb);
    }

    .tab.active {
      --color: var(--Category-Type-colorIconClick, #2563eb);
    }

    .icon {
      font-size: 24px;
      color: var(--color);
    }

    .label {
      color: var(--color);
      text-align: center;
      ${font("Base/Normal")};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .indicator {
      position: absolute;
      height: var(--Size-Base-sizeXXXS, 2px);
      background: var(--Category-Type-colorDevider, #3b82f6);
      border-radius: var(--Border-Radius-borderRadiusXS, 2px);
      left: var(--left);
      right: var(--right);
      bottom: calc(var(--top));
      width: var(--width);
      transition:
        left 0.24s,
        right 0.24s,
        width 0.24s;
    }

    .indicator .arrow {
      background: var(--Category-Type-colorDevider, #3b82f6);
      position: absolute;
      left: 50%;
      top: -4px;
      height: 5px;
      width: 11px;
      transform: translate(-50%, 0);
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    }

    .link {
      text-decoration: none;
    }
  `;
}
