import { Api } from "@/api";
import { B2cCategoryTreePayload, CategoriesTreeDocument } from "@/generated/graphql/b2c";
import { Url } from "@/helpers/url";
import { getIconUrl } from "@/pages/product-page/extracted/menu/util";
import { ContextProvider, createContext } from "@lit/context";
import { LitElement } from "lit";

export type MenuItem = {
  id: string;
  key: string;
  name: string;
  icon: string | null;
  image: string | null;
  level: number;
  slug: string;
  url: string;
};

export class Categories {
  protected provider: ContextProvider<{ __context__: Categories }, LitElement>;
  protected api!: Api;
  protected tree!: B2cCategoryTreePayload[];
  protected items: Record<string, MenuItem> = {};
  protected links: Record<
    string,
    {
      parent: string[];
      children: string[];
    }
  > = {};
  public root!: MenuItem

  constructor(host: LitElement, api: Api) {
    this.api = api;
    this.provider = new ContextProvider(host, { context: CategoriesContext, initialValue: this });
  }

  async load() {
    const categoriesResponse = await this.api.client.query({
      query: CategoriesTreeDocument,
      variables: {},
    });

    this.tree = categoriesResponse.data.CategoriesTree as B2cCategoryTreePayload[];
    this.setTree(this.tree);
    this.provider.setValue(this, true);
  }

  async rebuild() {
    this.setTree(this.tree);
    this.provider.setValue(this, true);
  }

  clear() {
    this.items = {};
    this.links = {};
    this.tree = [];
  }

  setTree(list: B2cCategoryTreePayload[]) {
    this.items = {};
    this.links = {
      "*": {
        parent: [],
        children: [],
      },
    };

    const queue = [...list] as B2cCategoryTreePayload[];
    while (queue.length) {
      const item = queue.shift()!;

      this.links[item.id] ??= {
        parent: [],
        children: [],
      };
      this.items[item.id] = this.toMenuItem(item);

      const parent = (this.links[item.id].parent ?? []).concat(item.id);

      for (const child of item.children) {
        this.links[item.id].children.push(child.id);
        this.links[child.id] = {
          parent: parent,
          children: [],
        };
        queue.push(child);
      }
    }

    for (const item of list) {
      this.links["*"].children.push(item.id);
    }
  }

  getChilds(id: string) {
    return (this.links[id]?.children ?? []).map((id) => this.items[id]);
  }

  getParents(id: string) {
    return (this.links[id]?.parent ?? []).map((id) => this.items[id]);
  }

  getItem(id: string) {
    return this.items[id]!;
  }

  getItemPathElements(id: string) {
    return [...this.getParents(id), this.getItem(id)];
  }

  getItemPath(id: string) {
    return this.getItemPathElements(id).map((item) => item.slug);
  }

  findTopLevelCategories() {
    return (this.tree ?? []).filter((item) => item.icon);
  }

  toMenuItem(value: B2cCategoryTreePayload) {
    const parents = this.getParents(value.id);
    const slugs = [...parents, value].map((item) => item.slug);

    return {
      id: value.id,
      key: value.id,
      name: value.name,
      icon: getIconUrl(value.icon),
      image: getIconUrl(value.image),
      level: parents.length,
      slug: value.slug,
      url: Url.to("dynamic-route", { dynamicPath: slugs })
    };
  }

  getAll() {
    return Object.keys(this.items);
  }
}

export const CategoriesContext = createContext<Categories>("Categories");
