import { LitElement, html, css, unsafeCSS, PropertyValues, PropertyDeclaration, ReactiveElement, ReactiveController, Part } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import {
  B2cCar,
  B2cProductParameter,
  B2cProductPayload,
  B2cProductPrice,
  ProductDocument
} from "@/generated/graphql/b2c";
import { consume, ContextProvider, createContext, provide } from "@lit/context";
import { type Cart, cartContext } from "@/context/cart";
import { type Main, mainContext } from "@/context/main";
import { t } from "i18next";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/product-mpn";
import "@/elements/mobile/fix-rating";
import "@/elements/mobile/fix-carousel";
import "@/elements/mobile/product-variations";
import "@/elements/mobile/product-dispatch";
import "@/elements/mobile/product-analogues";
import "@/elements/mobile/fix-badge";
import "@/elements/mobile/fix-quantity";
import "@/elements/mobile/fix-button";
import "@/elements/mobile/fix-info";
import "@/elements/mobile/fix-link";
import "@/generated/css/variables.css";
import { localized } from "@/decorators/localized";
import { Router, RouterLocation } from "@vaadin/router";
import "./layout/catalog-layout";
import "./product-page-mobile";
import "./product-page-desktop";

import { choose } from "lit/directives/choose.js";
import { layout } from "./cont";
import { createRef, Ref, ref } from "lit/directives/ref.js";
import { VariantSwitcher } from "./switcher";

import "./extracted/benefits-list";
import "./extracted/specifications-list";
import "./extracted/shipping-notify";
import "./extracted/vat-notify";
import "./extracted/product-status";
import "./extracted/product-can-be";
import "@/elements/fix-text";
import "@/elements/add-to-cart";
import "./extracted/related-products";
import "./extracted/situable-for";

import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { Url } from "@/helpers/url";
import { getBreadcrumbsByCategories } from "@/helpers/breadcrumbs";
import { ProductDispatchItem } from "@/elements/mobile/product-dispatch";
import { getProductSpecificationsStub } from "@/stub/product";
import { SituableForDatasetEntry } from "./extracted/situable-for";
import { ImageResizer } from "@/util/image-resizer.ts";
import { ServiceLocatorContext, Services } from "@/services/services";
import { createProductUrl } from "../category-page/product-area-catalog/product-area-element";

class ProductState extends LitElement {
  @consume({ context: cartContext, subscribe: true })
  @state({ hasChanged: () => true })
  cart!: Cart;

  product: B2cProductPayload | null = null;

  @state()
  selections = {
    quantity: 1 as number,
    dispatch: "1" as string,
    variation: "1" as null | string,
  };

  getInCartState(product: B2cProductPayload, priceId: string | null) {
    return this.cart.getItem(product.id, priceId);
  }

  configureSelections(product: B2cProductPayload, priceId: string | null) {
    const inCart = this.getInCartState(product, priceId);

    if (inCart.added) {
      this.selections.quantity = inCart.quantity;
      this.selections.dispatch = inCart.priceId!;
    } else {
      this.selections.quantity = product.minimalOrderQuantity;
      this.selections.dispatch = product.prices[0]?.id;
    }
    this.selections.variation = "1";
  }

  addToCartClick = () => {
    if (this.isAddedToCart()) {
      this.goToCart();
    } else {
      this.addToCart();
    }
  };

  addToCart = () => {
    const inCart = this.getInCartState(this.product!, this.selections.dispatch);
    const quantity = this.selections.quantity + (inCart.added ? inCart.quantity : 0);

    this.cart.updateItem({
      cartProductId: inCart?.cartProductId,
      productId: this.product!.id,
      priceId: this.selections.dispatch,
      quantity,
    });
  };

  goToCart = () => {
    Router.go(Url.to("cart-page"));
  };

  isAddedToCart() {
    return this.getInCartState(this.product!, this.selections.dispatch).added;
  }

  get maxOrderQuantity() {
    return this.getMaxQuantity(this.getSelectedPrice()!);
  }

  get factorOrderQuantity() {
    return this.isStockSupplierSelected() ? null : this.getMinQuantity("product");
  }

  get minOrderQuantity() {
    return this.isStockSupplierSelected() ? this.getMinQuantity("stock") : this.getMinQuantity("product");
  }

  getMinQuantity(type: "stock" | "product") {
    switch (type) {
      case "stock":
        return 1;
      case "product":
        return this.product!.minimalOrderQuantity ?? 1;
    }
  }

  getMaxQuantity(price: B2cProductPrice) {
    if (price.isLocalStockSupplier) {
      return price.availableStockCount ?? 0;
    }

    return null;
  }

  isStockSupplierSelected() {
    return Boolean(this.getSelectedPrice()?.isLocalStockSupplier);
  }

  getSelectedPrice() {
    return this.product!.prices.find((price) => price.id === this.selections.dispatch);
  }
}

@customElement("product-page")
@layout()
@localized()
export class ProductPage extends ProductState {
  layout!: string;
  switcher: VariantSwitcher<ProductPage> = new VariantSwitcher(this);

  @property({ type: {} }) location!: RouterLocation;

  @property({ type: Boolean }) loading = true;

  @state()
  quantity = 0;

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  @consume({ context: BreadcrumbsContext, subscribe: true })
  private breadcrumbs!: Breadcrumbs;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  @state()
  canBeActiveId: string | null = null;

  render() {
    if (this.loading) {
      return this.loader;
    }

    const { ref, base } = this.switcher.props();

    return choose(this.layout, [
      ["desktop", () => html`<product-page-desktop ${ref} .base=${base}></product-page-desktop>`],
      ["mobile", () => html`<product-page-mobile ${ref} .base=${base}></product-page-mobile>`],
    ]);
  }

  get loader() {
    return html`<fix-loader></fix-loader>`;
  }

  renderCarousel(type: "lg" | "sm") {
    return html` <fix-carousel class="card carousel" .items=${this.images} type=${type}></fix-carousel>`;
  }

  renderSpecifiactions(type: "lg" | "sm") {
    return html`<specifications-list .options=${this.specifications} type=${type}></specifications-list>`;
  }

  renderVariations() {
    return html`
      <product-variations
        .variations=${[
        { id: "1", name: "Black", size: "22", price: 100 },
        { id: "2", name: "White", size: "22", price: 100 },
        { id: "3", name: "Red", size: "22", price: 100 },
      ]}
      ></product-variations>
    `;
  }

  renderDispatch() {
    const { product, selections, dispatchItems, stockStatus } = this;

    return html`
      <product-dispatch class="card dispatch" .dispatch=${dispatchItems} .active=${selections.dispatch} @updateActive=${this.setDispatch}></product-dispatch>
    `;
  }

  renderDiscount() {
    return html` <price-value class="discount" .amount=${this.price.discount} .isVatApplicable=${true}></price-value> `;
  }

  renderBadge() {
    return html`<fix-badge class="badge">Save ${this.price.discountPercent}%</fix-badge>`;
  }

  renderPrice() {
    return html` <price-value class="price" .amount=${this.price.amount} .isVatApplicable=${true}></price-value> `;
  }

  renderStatus(type: "lg" | "sm") {
    return html` <product-status .status=${this.stockStatus} type=${type}></product-status> `;
  }

  renderVat() {
    return html` <vat-notify class="vat-tip"></vat-notify> `;
  }

  renderShipping() {
    return html`<shipping-notify .productId=${this.product!.id} .priceId=${this.selections.dispatch}></shipping-notify>`;
  }

  renderQuantity() {
    return html`
      <fix-quantity
        class="quantity wide"
        .quantity=${this.selections.quantity}
        .minQuantity=${this.minOrderQuantity}
        .maxQuantity=${this.maxOrderQuantity}
        .factorQuantity=${this.factorOrderQuantity}
        @updateQuantity=${this.setQuantity}
      ></fix-quantity>
    `;
  }

  renderTip() {
    return html`<div class="quantity-tip">min. ${this.minOrderQuantity} units</div>`;
  }

  renderAdd() {
    return html`<add-to-cart type="lg" @click=${this.addToCartClick} .done=${this.isAddedToCart()}></add-to-cart> `;
  }

  renderCanBe(type: "lg" | "sm") {
    const active = this.canBeActiveId ?? this.product!.id;
    const data = this.filteredCrosses;

    return html` <product-can-be type=${type} .active=${active} .data=${data} @navigateToProduct=${this.navigateToProduct}></product-can-be> `;
  }

  hasBlock(
    type:
      | "can-be"
      | "situable-for"
      | "shipping-notify"
      | "carousel"
      | "specifications"
      | "lg.presentation"
      | "quantity-tip"
      | "quantity-input"
      | "discount"
      | "stock",
  ): boolean {
    switch (type) {
      case "can-be":
        return this.filteredCrosses.length > 0;
      case "situable-for":
        return this.situableFor.length > 0 && false;
      case "shipping-notify":
        return Boolean(this.product?.id && this.selections.dispatch);
      case "carousel":
        return this.images.length > 0;
      case "specifications":
        return this.specifications.length > 0;
      case "lg.presentation":
        return this.hasBlock("carousel") || this.hasBlock("specifications");
      case "quantity-tip":
        return this.isAddedToCart();
      case "quantity-input":
        return this.isAddedToCart();
      case "discount":
        return true;
      case "stock":
        return true;
      default:
        return false;
    }
  }

  renderSituable(type: "lg" | "sm") {
    return html` <situable-for type=${type} .data=${this.situableFor}></situable-for> `;
  }

  renderDefaultDescription() {
    const product = this.product!;

    return `<div>${t(
      "13gp0j350ubqbb4q.product-default-description",
      "Buy original {{brandName}} {{mpn}} {{productName}} online at {{domain}}. Enjoy competitive prices on new parts, fast delivery, top-quality products, and exceptional service. Shop now for the best deals on {{domain}}.",
      {
        brandName: product.brand.name,
        mpn: product.mpn,
        productName: product.productInfo.name,
        domain: window.location.host,
      },
    )}</div>`;
  }

  renderAbout(type: "lg" | "sm") {
    let text = this.product!.productInfo.description?.trim() ?? "";
    if (!text.length) {
      text = this.renderDefaultDescription();
    }
    return html`<fix-text type=${type} .text=${text}></fix-text>`;
  }

  renderRelated(type: "lg" | "sm") {
    return html`<related-products type=${type}></related-products>`;
  }

  renderMpn() {
    return html`<product-mpn .code=${this.getMpn()}></product-mpn>`;
  }

  renderRating() {
    return html`<fix-rating .value=${0}></fix-rating>`;
  }

  renderBenefits(type: "lg" | "sm") {
    return html`<benefits-list type=${type}></benefits-list>`;
  }

  getTitle() {
    return this.getMpn() + " " + this.product!.brand.name + " " + this.product!.productInfo!.name;
  }

  getMpn() {
    return this.product?.mpn ?? this.product!.mpnFormatted;
  }

  get filteredCrosses() {
    const allowed = ['oe', 'oem', 'aftermarket'];
    const all = this.product?.crosses ?? [];
    const filtered = [this.product!, ...all].filter((product) => {
      const productType = product.productType || product.brand.defaultProductType;
      if (allowed.includes(productType) && product.state === 1) {
        return product.prices.some((price) => parseFloat(price.price.amount) > 0);
      }
    });
    return filtered;
  }

  get images() {
    return (
      this.product?.productAssets
        .filter((asset) => asset.type === "image")
        .map((asset) => ({
          ...asset,
          url: ImageResizer.getProductImageUrl(asset.path, "300x300"),
        })) ?? []
    );
  }

  get situableFor() {
    const cars = this.product?.cars ?? [];
    const findDeep = (item: null | B2cCar, type: string) => {
      let current: null | B2cCar = item;

      while (current) {
        if (current.type === type) {
          return current;
        }
        current = current.parent ?? null;
      }

      return current;
    };

    const createSituableForEntry = (item: B2cCar) => {
      const modification = findDeep(item, "modification")!;
      const year = findDeep(item, "year")!;
      const model = findDeep(item, "model")!;
      const brand = findDeep(item, "brand")!;

      const [dateFrom, dateTo] = year.name.split("-").map((item) => item.trim());
      const [parsedFrom, parsedTo] = [dateFrom, dateTo].map((item) => item.match(/^(\d+)\.(\d+)/));
      const [yearFrom, yearTo] = [parsedFrom, parsedTo ?? parsedFrom].map((item) => parseInt(item![2]));

      return {
        brand: {
          id: brand.id,
          name: brand.name,
        },
        model: {
          id: model.id,
          name: model.name,
        },
        modification: {
          id: modification.id,
          name: modification.name,
        },
        yearFrom: yearFrom.toString(),
        yearTo: yearTo.toString(),
      } satisfies SituableForDatasetEntry;
    };

    return cars.map(createSituableForEntry);
  }

  get specifications() {
    const specifications = this.product!.productParameters.filter((parametr) => !!parametr.name).map((spec) => ({
      name: spec.name,
      value: spec.value,
      type: spec.type,
    }))

    let staticSpecifications: B2cProductParameter[] = [];
    if (this.product?.weight.value) {
      staticSpecifications.push({
        id: 'static-param-weight',
        name: t("gj7ao5qyeb898nyx.weight", "Weight"),
        type: t("kll27o98o69dqzw0.kg", this.product?.weight.unit),
        value: this.product?.weight.value
      })
    }

    if (this.product?.dimensions.width || this.product?.dimensions.height || this.product?.dimensions.depth) {
      const dimensionsValue = `${this.product?.dimensions.width || "--"} x ${this.product?.dimensions.height || "--"} x ${this.product?.dimensions.depth || "--"}`;

      staticSpecifications.push({
        id: 'static-param-dimensions',
        name: t("92jj5c7lfh3fiqt2.dimensions", "Dimensions"),
        type: t("dw613mgqhvlda8ea.mm", this.product?.dimensions.unit),
        value: dimensionsValue
      })
    }

    return (
      getProductSpecificationsStub() ?? [...specifications, ...staticSpecifications]
    );
  }

  get dispatchItems() {
    const items = this.prices.map((price) => ({
      id: price.id,
      name: price.supplierName,
      from: price.deliveryTermStandardMin,
      to: price.deliveryTermStandardMax,
      index: "",
    })) as Array<ProductDispatchItem>;

    const acc = {} as Record<string, Array<{ index: string }>>;

    for (const item of items) {
      const key = `${item.from}-${item.to}`;
      const list = acc[key] ?? [];
      acc[key] = list.concat(item);
    }

    for (const list of Object.values(acc)) {
      const duplicated = list.length > 1;
      for (const [index, item] of list.entries()) {
        item.index = duplicated ? `#${index + 1}` : "";
      }
    }

    return items;
  }

  get stockStatus() {
    return this.isStockSupplierSelected() ? "in-stock" : "out-stock";
  }

  get prices() {
    return this.product?.prices ?? [];
  }

  get price() {
    const priceItem = this.getSelectedPrice();

    if (priceItem) {
      const discount = 0;
      const totalAmount = Number(priceItem.price.amount);
      return {
        amount: totalAmount,
        discount: totalAmount * (discount / 100),
        discountPercent: discount,
      };
    }

    return {
      amount: 0,
      discount: 0,
      discountPercent: 0,
    };
  }

  setVariation(event: CustomEvent<{ id: string }>) {
    this.selections.variation = event.detail.id;
    this.requestUpdate();
  }

  setDispatch = (event: CustomEvent<{ id: string }>) => {
    this.selections.dispatch = event.detail.id;

    if (this.factorOrderQuantity) {
      const value = Math.ceil(this.selections.quantity / this.factorOrderQuantity) * this.factorOrderQuantity;

      if (this.selections.quantity !== value) {
        this.selections.quantity = value;
        notifyQuantityShouldBeMultiple({ factorOrderQuantity: this.factorOrderQuantity });
      }
    }

    if (this.maxOrderQuantity && this.selections.quantity > this.maxOrderQuantity) {
      this.selections.quantity = this.maxOrderQuantity;
      notifyQuantityExceedsAvailableStock();
    }

    if (this.minOrderQuantity && this.selections.quantity < this.minOrderQuantity) {
      this.selections.quantity = this.minOrderQuantity;
      notifyProductQuantityLessThanMinimalOrderQuantity();
    }

    this.requestUpdate();
  };

  setQuantity = (event: CustomEvent<{ quantity: number }>) => {
    this.selections.quantity = event.detail.quantity;
    if (this.maxOrderQuantity && this.selections.quantity > this.maxOrderQuantity) {
      this.selections.quantity = this.maxOrderQuantity;
      notifyQuantityExceedsAvailableStock();
    }

    const inCart = this.getInCartState(this.product!, this.selections.dispatch);
    if (inCart?.added) {
      const quantity = this.selections.quantity;

      this.cart.updateItem({
        cartProductId: inCart?.cartProductId,
        productId: this.product!.id,
        priceId: this.selections.dispatch,
        quantity,
      });
    }
    this.requestUpdate();
  };

  connectedCallback() {
    super.connectedCallback();
    this.fetchProduct();
  }

  fetchProduct = async () => {
    try {
      const path = this.location.params.dynamicPath as string[];
      const registryUrl = this.services.routes.convertToRoute({
        segments: path,
      });
      const detail = await this.services.routes.resolve(registryUrl);

      const response = await this.services.api.client.query({
        query: ProductDocument,
        variables: { input: { id: detail!.id as string } },
      });
      await this.cart.fetchCart();

      this.product = response.data.Product as B2cProductPayload;

      this.updateBreadcrumbs();
      this.configureSelections(this.product!, null);
    } catch (error) {
      console.error("Fetching product failed:", error);
      // Handle error state here, e.g., show an error message to the user
    }

    this.loading = false;
    this.requestUpdate();
  };

  updateBreadcrumbs() {
    const pathSegments = this.location.params.dynamicPath as string[];
    const categorySegments = pathSegments.slice(0, pathSegments.length - 1);
    const parentCategorySlug = categorySegments.join("/");

    const product = this.product!;
    const parentCategory = product.categories.find((category) => {
      const path = this.services.categories.getItemPathElements(category.id);
      const slug = path.map(item => item.slug).join("/");

      return parentCategorySlug === slug;
    }) ?? product.categories[0];

    const path = parentCategory
      ? this.services.categories.getItemPathElements(parentCategory.id)
      : [];

    const breadcrumbs = path.map((item) => {
      const url = Url.to("dynamic-route", { dynamicPath: this.services.categories.getItemPath(item.id) });
      return {
        label: item.name,
        url: url,
      };
    });

    breadcrumbs.push({
      label: product.productInfo.name,
      url: Url.to("dynamic-route", { dynamicPath: pathSegments }),
    });

    this.breadcrumbs.setBreadcrumbs(breadcrumbs);
  }

  navigateToProduct = (event: CustomEvent<{ id: string; reason: string }>) => {
    const { id, reason } = event.detail;
    const product = this.filteredCrosses.find((product) => product.id === id)!;
    const url = createProductUrl(product, this.services.categories);
    Router.go(url);
  }
}

const notifyProductQuantityLessThanMinimalOrderQuantity = () => {
  _notify(
    t("42uv7ieg6f1d7spf.min-quantity-alert", "Product quantity is less than minimal order quantity"),
    t("k235nww356g847zu.min-quantity-alert-description", "Quantity has been set to minimal order quantity"),
    "warning",
    "exclamation-triangle",
    5000,
  );
};

const notifyQuantityExceedsAvailableStock = () => {
  _notify(
    t("2k1l3hf6f0682ddk.max-quantity-exceeded", "Quantity exceeds available stock"),
    t("65sk1uc48ji347o8.max-quantity-exceeded-description", "Please, add the rest products from a different supplier"),
    "warning",
    "exclamation-triangle",
    5000,
  );
};

const notifyQuantityShouldBeMultiple = (params: { factorOrderQuantity: number }) => {
  _notify(
    t("o45cqxotk22x53j3.factor-quantity-exceeded", "Quantity should be multiple of {{val}}", { val: params.factorOrderQuantity }),
    t("dep4ud17cll0t4b9.factor-quantity-exceeded-description", "Quantity has been set to multiple of minimal order quantity"),
    "warning",
    "exclamation-triangle",
    5000,
  );
};

const _notify = (message: string, description = "", variant = "primary", icon = "info-circle", duration = 3000) => {
  const alert = Object.assign(document.createElement("sl-alert"), {
    variant,
    closable: true,
    duration,
    innerHTML: `
      <sl-icon name="${icon}" slot="icon"></sl-icon>
      <strong style="display: block">${message}</strong>
      ${description}
    `,
  });

  document.body.append(alert);
  return alert.toast();
};
declare global {
  interface HTMLElementTagNameMap {
    "product-page": ProductPage;
  }
}
