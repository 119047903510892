import { createContext } from "@lit/context";
import { B2cCartPayload, B2cCartProduct, CartProductValidationMessage, CartTotal, CartUpdateProductDocument } from "@/generated/graphql/b2c";
import { CartDocument } from "@/generated/graphql/b2c";

import { Api } from "@/api";
import { ContextProvider } from "@lit/context";
import { LitElement } from "lit";

type UpdateProductParams = {
  productId: string;
  cartProductId?: string;
  quantity: number;
  priceId: string;
};

export class Cart {
  id: string | undefined;
  items: B2cCartProduct[] = [];
  totals: CartTotal[] = [];
  errors: CartProductValidationMessage[] = [];
  count = 0;

  loading = true;

  protected api: Api;

  protected provider;

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: cartContext });
    this.api = api;

    this.update();
  }

  protected update = () => {
    this.provider.setValue(this, true);
  };

  get subtotal() {
    return this.totals.find((total) => total.type === "sub")?.value;
  }

  get taxTotal() {
    return this.totals.find((total) => total.type === "tax")?.value;
  }

  get taxAdditionalInfo() {
    return this.totals.find((total) => total.type === "tax")?.additionalInfo;
  }

  get discountTotal() {
    return this.totals.find((total) => total.type === "discount")?.value;
  }

  get grandTotal() {
    return this.totals.find((total) => total.type === "grand")?.value;
  }

  get totalWeight() {
    return this.items?.reduce((previousValue, currentValue) => Number(previousValue) + currentValue.product.weight.value * currentValue.quantity, 0).toFixed(2);
  }

  fetchCart = async () => {
    try {
      const payload = await this.requestCurrentCart();

      if (!payload) {
        return null;
      }

      this.updateValueByCart(payload);
    } catch (error: any) {
      console.error("Fetching cart failed:", error);
      throw new Error(error.message);
    } finally {
      this.loading = false;
      this.update();
    }
  };

  updateItem = async (params: UpdateProductParams) => {
    const payload = await this.mutateUpdateItem(params);

    this.updateValueByCart(payload);
    this.update();
  };

  getItem = (productId: string, priceId: string | null) => {
    let found;
    if (priceId) {
      found = this.items.find((item) => item.product.id === productId && item.priceId === priceId);
    } else {
      found = this.items.find((item) => item.product.id === productId);
    }

    if (found) {
      return {
        added: true as const,
        cartProductId: found.id,
        quantity: found.quantity,
        priceId: found.priceId,
      };
    }

    return { added: false as const };
  };

  protected updateValueByCart = (cart: B2cCartPayload) => {
    this.id = cart.id;
    this.totals = cart.totals;
    this.items = cart.cartProducts;
    this.errors = cart.errors;
    this.count = this.items.reduce((total, item) => total + item.quantity, 0);
  };

  protected mutateUpdateItem = async (params: UpdateProductParams) => {
    const input = structuredClone(params) as Partial<UpdateProductParams>;
    if (input.cartProductId) {
      // cartProductId is priority field
      delete input.productId;
    }

    const cartResponse = await this.api.client.mutate({
      mutation: CartUpdateProductDocument,
      variables: {
        input: {
          cartProductId: input.cartProductId,
          productId: input.productId,
          quantity: input.quantity!,
          priceId: input.priceId!,
          cartId: this.id,
        },
      },
    });

    return cartResponse.data!.CartUpdateProduct as B2cCartPayload;
  };

  protected requestCurrentCart = async () => {
    const cartResponse = await this.api.client.query({
      query: CartDocument,
    });

    return cartResponse.data.Cart as B2cCartPayload;
  };
}

export const cartContext = createContext<Cart>("cart");

export type CartContextType = typeof cartContext;
