import { css, unsafeCSS } from "lit";

export const fontBaseNormal = (selector: string) => {
  return css`
    ${unsafeCSS(selector)} {
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  `;
};

export const fontBaseStrong = (selector: string) => {
  return css`
    ${unsafeCSS(selector)} {
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  `;
};
