import i18next from "i18next";
import { routerInstance } from "@/app-root";
import { Route, RouterLocation } from "@vaadin/router";
import { Services } from "@/services/services.ts";

export class Url {
  static getPathByName(name: string) {
    return routerInstance.urlForName(name, { lang: i18next.language });
  }

  static getQueryParams(query: string): URLSearchParams {
    return new URLSearchParams(query);
  }

  static getQueryParam(paramName: string, query: string): string | null {
    const queryParams = Url.getQueryParams(query);

    return queryParams.get(paramName);
  }

  static to(name: string, params: object = {}, search?: string): string {
    const parameters = Services.instance.parameters;
    // fix toMenuItem bug
    const allParams = {
      ...params,
      lang: parameters.parameters?.language ?? "en",
      country: parameters.parameters?.country ?? "lv"
    };
    const path = routerInstance.urlForName(name, allParams);
    const routePath = this.getRoutePathByName(name);
    const routeParamsKeys = this.extractParamsFromRoutePath(routePath);

    const queryParams = Object.keys(allParams).filter((param) => !routeParamsKeys.includes(param));

    const url = new URL(path, window.location.origin);

    if (search) {
      const searchParams = new URLSearchParams(search);
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    queryParams.forEach((key) => {
      // @ts-ignore
      url.searchParams.append(key, allParams[key]);
    });

    return url.toString();
  }

  static getRoutePathByName(name: string): string | undefined {
    function findRoute(routes: Route[], name: string): string | undefined {
      for (const route of routes) {
        if (route.name === name) {
          return route.path;
        }
        if (route.children) {
          // @ts-ignore
          const childPath = findRoute(route.children, name);
          if (childPath) {
            return childPath;
          }
        }
      }
      return undefined;
    }

    return findRoute(routerInstance.getRoutes(), name);
  }

  static getRoutePathByRouteLocation(location: RouterLocation) {
    let routePath = "";
    location.routes.forEach((route: Route) => {
      routePath += route.path;
    });
    routePath = routePath.replace("//", "/");

    return routePath + location.search;
  }

  static extractParamsFromRoutePath(routePath: string | undefined): string[] {
    if (routePath === undefined) {
      return [];
    }

    const paramsRegex = /:(\w+)/g;
    const params: string[] = [];
    let match: RegExpExecArray | null;
    while ((match = paramsRegex.exec(routePath)) !== null) {
      params.push(match[1]);
    }

    return params;
  }
}
