import { ContextProvider, createContext } from "@lit/context";
import { LitElement } from "lit";
import { Services } from "@/services/services.ts";
import { routerInstance } from "@/app-root";
import { Router } from "@vaadin/router";
import { CustomerDocument } from "@/generated/graphql/customer";
import { browserDetectLanguage } from "@/util/browser-language-detector";
import { supportedLanguages, defaultLang } from "@/constants/languages";
import { defaultCurrency } from "@/constants/currency";
import { Url } from "@/helpers/url";

export const defaultCountry = "LV";

export type ParametersValue = {
    country: string;
    language: string;
    currency: string;
};

export class Parameters {
    protected provider: ContextProvider<{ __context__: Parameters }, LitElement>;
    protected currentCountryCode: string | undefined;
    public parameters: ParametersValue = null!;
    public changedParameters: Partial<ParametersValue> = {};

    constructor(
        host: LitElement,
        protected serices: Services
    ) {
        this.provider = new ContextProvider(host, { context: ParametersContext, initialValue: this });
    }

    getVisibleLanguage() {
        return this.changedParameters.language ?? this.parameters.language;
    }

    getVisibleCountry() {
        return this.changedParameters.country ?? this.parameters.country;
    }

    getVisibleCurrency() {
        return this.changedParameters.currency ?? this.parameters.currency;
    }

    changeParameter<Key extends keyof ParametersValue>(type: Key, value: ParametersValue[Key]) {
        this.changedParameters[type] = value;
    }

    async findNextLocation() {
        if (routerInstance.location.route!.name === 'dynamic-route') {
            return {
                target: 'dynamic-route' as const,
                entity: await this.serices.initialize.findEntityForCurrentDynamicRoute(),
                path: routerInstance.location.route!.path,
                params: routerInstance.location.params as Record<string, string>
            };
        } else {
            return {
                target: 'route' as const,
                path: routerInstance.location.route!.path,
                params: routerInstance.location.params as Record<string, string>
            };
        }
    }

    async applyParameters() {
        const nextLocation = await this.findNextLocation();
        await this.updateParametersFromUserInput({
            ...this.parameters,
            ...this.changedParameters,
        });
        this.changedParameters = {};

        await this.serices.parametersUpdated();

        if (nextLocation.target === 'dynamic-route') {
            const path = await this.serices.initialize.findUpdatedPathForEntity(nextLocation.entity!);
            const url = Url.to('dynamic-route', { dynamicPath: path });
            Router.go(url);
        }

        if (nextLocation.target === 'route') {
            const url = routerInstance.urlForPath(nextLocation.path, {
                ...nextLocation.params,
                lang: this.parameters.language,
                country: this.parameters.country,
            });
            Router.go(url);
        }

        this.provider.setValue(this, true);
    }

    async readDefaultParameters() {
        await this.serices.customerChannel.fetchIfNotLoaded();
        const customerChannel = this.serices.customerChannel!;
        const channelOptions = customerChannel.channelOptions!;
        const currency = channelOptions?.currency?.code || defaultCurrency;
        const country = customerChannel.currentCountry?.code || defaultCountry;
        const browserLanguageCode = browserDetectLanguage()!;
        const language = supportedLanguages.find((item) => item.code === browserLanguageCode)?.code
            ?? channelOptions?.defaultLanguage
            ?? defaultLang;

        return { country, language, currency } satisfies ParametersValue;
    }

    async performUrlCorrection() {
        if (routerInstance.location.route!.name === 'dynamic-route') {
            const entity = await this.serices.initialize.findEntityForCurrentDynamicRoute();
        }

        const url = this.createNormalizedUrl({
            path: routerInstance.location.route!.path,
            params: routerInstance.location.params as Record<string, string>,
        });
        const same = url === routerInstance.location.pathname;
        if (!same) {
            Router.go(url);
        }
    }

    async firstVisit(parameters: Partial<ParametersValue>) {
        if (this.parameters) {
            return;
        }

        const saved = await this.readParametersFromLocalStorage();
        const priority = [
            'received',
            'saved',
            'passed',
            'default',
        ] as const;

        for (const type of priority) {
            let value: Partial<ParametersValue> | undefined | null;

            if (type === 'received') {
                value = await this.readParametersFromApiPreferences();
            }

            if (type === 'saved') {
                value = saved;
            }

            if (type === 'passed') {
                value = parameters;
            }

            if (type === 'default') {
                value = await this.readDefaultParameters();
            }

            if (value && this.validateParameters(value)) {
                this.parameters = value;

                break;
            }
        }

        if (!saved || !this.equalsParameters(saved, this.parameters)) {
            await this.writeParametersToLocalStorage(this.parameters);
        }

        this.provider.setValue(this, true);
    }

    isParametersNormalizationNeeded(parameters: Partial<ParametersValue>) {
        return !this.equalsParameters(this.parameters, parameters, ['country', 'language']);
    }

    createNormalizedUrl(params: { path: string, params: Record<string, string> }) {
        return routerInstance.urlForPath(params.path, {
            ...params.params,
            lang: this.parameters.language,
            country: this.parameters.country,
        });
    }

    validateParameters(parameters?: Partial<ParametersValue>): parameters is ParametersValue {
        try {
            return Boolean(parameters?.country?.length && parameters?.language?.length);
        } catch (error) {
            return false;
        }
    }

    equalsParameters(a: Partial<ParametersValue>, b: Partial<ParametersValue>, keys = ['country', 'language', 'currency']) {
        //@ts-expect-error
        return keys.every((key) => a[key] === b[key]);
    }

    async readParametersFromLocalStorage() {
        try {
            const parameters = localStorage.getItem("parameters");
            if (parameters) {
                return JSON.parse(parameters) as ParametersValue;
            }
        } catch (error) {
            console.error("Error reading parameters from local storage", error);
        }
    }

    async readParametersFromApiPreferences() {
        await this.serices.customerChannel.fetchIfNotLoaded();
        const customer = this.serices.customerChannel.customer;
        if (customer) {
            const defaults = await this.readDefaultParameters();

            return {
                country: customer.country?.code ?? defaults.country,
                language: customer.locale ?? defaults.language,
                currency: customer.currency ?? defaults.currency,
            } satisfies ParametersValue;
        }
    }

    async writeParametersToApiPreferences(parameters: ParametersValue) {
        const countryId = this.serices.countries.getChannelCountries().find((item) => item.code === parameters.country)?.id;
        const customerResponse = await this.serices.api.client.mutate({
            mutation: CustomerDocument,
            variables: {
                input: {
                    countryId: countryId,
                    currency: parameters.currency,
                    locale: parameters.language,
                },
            },
        });
    }

    async writeParametersToLocalStorage(parameters: ParametersValue) {
        localStorage.setItem("parameters", JSON.stringify(parameters));
    }

    async updateParametersFromApiPreferences() {
        const parameters = await this.readParametersFromApiPreferences();
        if (parameters) {
            await this.updateParameters(parameters, 'local');
        }
    }

    async updateParametersFromUserInput(parameters: ParametersValue) {
        await this.updateParameters(parameters, 'api', false);
    }

    async updateParameters(parameters: ParametersValue, type: 'local' | 'api', correction = true) {
        if (!this.equalsParameters(this.parameters, parameters)) {
            this.parameters = parameters;

            if (type === 'api' && this.serices.customerChannel.customer) {
                await this.writeParametersToApiPreferences(parameters);
            }

            if (type === 'api' || type === 'local') {
                await this.writeParametersToLocalStorage(parameters);
            }

            if (correction) {
                await this.performUrlCorrection();
            }
        }
    }
}

export const ParametersContext = createContext<Parameters>("Parameters");
