import { ContextProvider, createContext } from "@lit/context";
import { LitElement } from "lit";
import { CustomerChannel } from "@/services/customer-channel.ts";
import { Formatter } from "@/services/formatter.ts";
import { Customer } from "@/context/customer.ts";
import { Services } from "@/services/services.ts";

export const defaultCountryCode = "LV";

export class Countries {
  protected provider: ContextProvider<{ __context__: Countries }, LitElement>;
  protected currentCountryCode: string | undefined;

  constructor(
    host: LitElement,
    protected customerChannel: CustomerChannel,
    protected formatter: Formatter,
    protected customer: Customer,
    protected serices: Services
  ) {
    this.provider = new ContextProvider(host, { context: CountriesContext, initialValue: this });
  }

  getCurrentCountryCode() {
    return this.currentCountryCode ?? defaultCountryCode.toLowerCase();
  }

  getCurrentCountry() {
    return this.getChannelCountries()?.find((item) => item.code === this.currentCountryCode);
  }

  getByCode(code: string) {
    return this.getChannelCountries()?.find((item) => item.code === code);
  }

  getChannelCountries() {
    return this.customerChannel?.availableCountries;
  }
}

export const CountriesContext = createContext<Countries>("Countries");
