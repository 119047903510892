import { customElement, property } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import "@/generated/css/variables.css";
import { type } from "@/util/layout";
import "../layout/page-section-layout";
import { BusinessBrand, FeaturedBrandsListDocument } from "@/generated/graphql/b2c";
import { t } from "i18next";
import { consume } from "@lit/context";
import { type Main, mainContext } from "@/context/main";
import { Url } from "@/helpers/url";
import { font } from "@/util/fonts";
import { localized } from "@/decorators/localized";

type FeaturedItem = {
  id: string;
  name: string;
  slug: string;
  url: string;
  hasLogo: boolean;
  logoUrl: string;
};

@customElement("featured-brands-list")
@localized()
export class FeaturedBrandsList extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @consume({ context: mainContext })
  @property({ attribute: false })
  public mainContext!: Main;

  brands: BusinessBrand[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.fetchBrands();
  }

  render() {
    if (!this.list.length) return;

    return html`
      <page-section-layout type=${this.type} sectionTitle="${t("wr3vc34d2y7w6nt9.featured-brands-title", "Featured Brands")}">
        <div class="item-list">${this.list.map((item) => (item.hasLogo ? this.renderLogo(item) : this.renderText(item)))}</div>
      </page-section-layout>
    `;
  }

  renderLogo(item: FeaturedItem) {
    return html`
      <a class="item" href="${item.url}">
        <span class="icon-wrapper">
          <img class="icon" src=${item.logoUrl} alt="${item.name}" title="${item.name}" />
        </span>
      </a>
    `;
  }

  renderText(item: FeaturedItem) {
    return html`
      <a class="item" href="${item.url}">
        <span class="text-wrapper">${item.name}</span>
      </a>
    `;
  }

  fetchBrands = async () => {
    const response = await this.mainContext?.api.client.query({
      query: FeaturedBrandsListDocument,
      variables: {},
    });

    // @ts-ignore
    this.brands = response.data.FeaturedBrandsList.map((item) => item.brand);

    this.requestUpdate();
  };

  get list() {
    const assetsHost = import.meta.env.PUBLIC_B2C_ASSETS_HOST;

    return this.brands.map((item) => {
      const assetLogo = item.assets.find((asset) => asset.type === "image-logo");
      return {
        id: item.id,
        name: item.name,
        slug: item.slug,
        url: Url.to("dynamic-route", { dynamicPath: [item.slug] }),
        hasLogo: !!assetLogo,
        logoUrl: assetsHost + "/" + assetLogo?.path,
      };
    });
  }

  static styles = css`
    .item-list {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-paddingXS, 8px);
    }

    .item-list > *:nth-child(n + 19) {
      display: none;
    }

    ${type("sm")} .item-list > *:nth-child(n+7) {
      display: none;
    }

    .item {
      flex: 1 1 auto;
      flex-basis: calc(100% / 7);
      height: 82px;
      min-width: 200px;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--brand-card-color-border, #d9d9d9);
    }

    .item:hover {
      border-color: var(--brand-card-color-border-hover, #3b82f6);
    }

    .item:active {
      border-color: var(--brand-card-color-border-active, #2563eb);
    }

    a.item,
    a.item:visited,
    a.item:hover,
    a.item:active {
      ${font("LG/Normal")};
      text-decoration: none;
      color: var(--neutral-color-text-base, #000000);
    }

    ${type("sm")} .item {
      flex-basis: calc(100% / 4);
      height: var(--size-height-control-height-lg, 48px);
      min-width: 100px;
      max-width: none;
    }

    .icon-wrapper {
      width: 92px;
      height: 42px;
      display: block;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  `;
}
