import { customElement, property } from "lit/decorators.js";
import { desktop, layout, mobile } from "../cont";
import { LitElement, css, html } from "lit";

import mail from "@/assets/footer/icons/mail.svg";
import office from "@/assets/footer/icons/office.svg";
import description from "@/assets/footer/icons/description.svg";
import "../extracted/fix-details";
import "../extracted/fix-brand";
import "../extracted/partners-group";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { Url } from "@/helpers/url.ts";
import { font } from "@/util/fonts.ts";
import { routerInstance } from "@/app-root.ts";
import {when} from "lit/directives/when.js";

@customElement("footer-element")
@localized()
@layout()
export class FooterElement extends LitElement {
  layout!: string;

  render() {
    const type = this.layout === "desktop" ? "lg" : "sm";
    const isHomePage = ["home-page", "home-page-with-lang"].includes(routerInstance.location?.route?.name || "");

    return html`
      <footer-grid class="footer">
        <partners-group slot="top" type=${type}></partners-group>
        <div slot="main" class="group main">
          ${when(isHomePage,
            () => html`<fix-brand type="lg"></fix-brand>`,
            () => html`<fix-link class="item" href="${Url.to("home-page-with-lang")}"><fix-brand type="lg"></fix-brand></fix-link>`
          )}
          <div class="text">
            ${t("n909bz0ydgcwm4xg.footer-text", "The \"Fixparts\" Group, established in 2010, has a proven track record in successfully implementing wholesale deliveries of trucks.")}
          </div>
        </div>
        <content-group slot="service" .title=${t("g78tgk7885234se9.footer-customer-service", "Customer service")} .variant=${this.variant} class="group menu">
          ${this.linkIgnoreRoute(t("o9n2uwt3nza3u3ri.my-account", "My account"), "/business-v2")}
          ${this.link(t("s6e6f5foojcu719q.warranty", "Warranty"), Url.to("help-page-with-section", { section: "warranty-and-returns" }))}
        </content-group>
        <content-group slot="information" .title=${t("vn1s7g08z0gzut53.footer-information", "Information")} .variant=${this.variant} class="group menu">
          ${this.link(t("kh08f0scyoc3nfp0.company", "Company"), Url.to("about-us-page"))} 
          ${this.link(t("1h06pmbvy0noasg3.help", "Help"), Url.to("help-page"))}
          ${this.link(t("83p9sscfj2b6t121.privacy-policy", "Privacy policy"), Url.to("privacy-policy-page"))}
          ${this.link(t("otstyqf59gqt6d8z.payment", "Payment"), Url.to("payment-terms-page"))}
        </content-group>
        <content-group slot="contacts" .title=${t("9d7866wn3k1o4w68.footer-contacts", "Contacts")} .variant=${this.variant} class="group contacts">
          <div class="row text"><sl-icon src=${mail} class="icon"></sl-icon>sales@fixparts-online.com</div>
          <div class="row text"><sl-icon src=${office} class="icon"></sl-icon>Rēzeknes street 5B, Riga, LV-1073, Latvia</div>
          <div class="row text description">
            <sl-icon src=${description} class="icon"></sl-icon>
            <div class="reg">Reg.nr. 40103495107</div>
            <div class="vat">VAT.Nr. LV40103495107</div>
          </div>
        </content-group>
      </footer-grid>
    `;
  }

  linkIgnoreRoute(text: string, href: string) {
    const link = location.origin.replace("www.", "") + href;

    return html`<a class="link-router-ignore" href=${link} router-ignore="true">${text}</a>`;
  }

  link(text: string, href: string) {
    return html`<sl-button class="link" variant="text" size="small" href=${href}>${text}</sl-button>`;
  }

  navigate = (event: MouseEvent) => {
    event.preventDefault();
  };

  get variant() {
    switch (this.layout) {
      case "desktop":
        return "column" as const;
      case "mobile":
        return "details" as const;
    }
    return null!;
  }

  static styles = css`
    .container {
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
      min-height: 100vh;
    }

    .column .link + * {
      margin-top: 8px;
    }

    .column .link {
    }

    .column.contacts .row + * {
      margin-top: 16px;
    }

    .column.contacts .row {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .text {
      color: var(--Neutral-Text-colorTextSecondary, #737373);
    }

    .row.text {
      color: var(--Components-Typography-Global-colorText, #27272a);
    }

    sl-button.link::part(label),
    .link-router-ignore {
      ${font("Base/Normal")}
    }

    .text,
    .row.text,
    .row.link {
      font-family: "SF Pro Text";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    .footer {
      background: var(--Components-Table-Global-colorBgContainer, #fff);
      padding: var(--spacing-marginXL, 32px) var(--spacing-margin, 16px);
      display: block;
    }

    .row.text .icon {
      font-size: 20px;
    }

    .footer-cols > *:nth-child(1) {
      max-width: 267px;
    }

    .row.text.description {
      display: grid;
      grid-template-columns: min-content 1fr;
    }

    .description .icon {
      grid-column: 1;
    }

    .description .reg,
    .description .vat {
      grid-column: 2;
    }

    .group.main {
    }

    .group.main {
      align-items: flex-start;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    .group.main .text {
      grid-area: text;
    }

    .group.menu,
    .group.contacts {
    }

    .row.text,
    .row.link {
    }

    .row.text,
    .row.link {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .row .icon {
      font-size: 20px;
      flex-shrink: 0;
    }

    .link-router-ignore {
      text-decoration: unset;
      color: #27272a;
    }

    .link-router-ignore:hover {
      color: var(--sl-color-primary-500);
    }

    .link {
      display: flex;
      align-items: flex-start;
      --sl-input-height-small: 22px;
      --sl-input-border-width: 0;
      --sl-spacing-small: 0;
      --sl-color-primary-600: var(--Components-Typography-Global-colorText, #27272a);
      /* --sl-color-primary-500: var(--Brand-Link-colorLink, #3B82F6);
      --sl-color-primary-700: var(--Brand-Link-colorLinkActive, #2563EB); */
    }

    .link::part(base) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  `;
}

@customElement("footer-grid")
@layout()
export class FooterGrid extends LitElement {
  @property({ type: String })
  layout!: string;

  render() {
    return html`
      <slot name="top"></slot>
      <div class="divider spacing"></div>
      <div class="bottom">
        <slot name="main"></slot>
        <slot name="service"></slot>
        <slot name="information"></slot>
        <slot name="contacts"></slot>
      </div>
    `;
  }

  static styles = css`
    .bottom {
      display: flex;
    }

    :host([layout="mobile"]) .bottom {
      flex-direction: column;
    }

    :host([layout="desktop"]) .bottom {
      justify-content: space-between;
      gap: 32px;
    }

    :host([layout="desktop"]) slot[name="main"] {
      display: block;
      max-width: 267px;
    }

    .divider {
      background: var(--Base-Gray-3, #e4e4e7);
      height: 1px;
    }

    .spacing {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  `;
}

@customElement("content-group")
@layout()
export class ContentGroup extends LitElement {
  @property({ type: String })
  title!: string;

  @property({ type: String })
  variant!: "details" | "column";

  render() {
    switch (this.variant) {
      case "details":
        return this.getDetailsView();
      case "column":
        return this.getColumnView();
      default:
        return null;
    }
  }

  getDetailsView() {
    return html` <fix-details class="group details" summary=${this.title}><slot></slot></fix-details>`;
  }

  getColumnView() {
    return html`<div class="group column">
      <div class="title">${this.title}</div>
      <slot></slot>
    </div>`;
  }

  static styles = css`
    .group.column .title {
      color: var(--Components-Typography-Global-colorTextDescription, #a3a3a3);
      /* LG/Strong */
      font-family: "SF Pro Text";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .group.column .title + * {
      margin-top: 12px;
    }
  `;
}
