import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { consume } from "@lit/context";
import { Formatter, FormatterServiceContext } from "@/services/formatter";

@customElement("price-value")
export class PriceValue extends LitElement {
  @property({ type: Number })
  amount = 0;

  @property({ type: Boolean })
  isVatApplicable = false;

  @consume({ context: FormatterServiceContext, subscribe: true })
  formatter!: Formatter;

  render() {
    return html`${this.formatter.price(this.amount, "full", this.isVatApplicable)}`;
  }
}
