import { customElement, property } from "lit/decorators.js";
import { LitElement, css, html, unsafeCSS } from "lit";
import handshake from "@/assets/benefits/handshake.svg";
import packingTime from "@/assets/benefits/packingTime.svg";
import worldWideDelivery from "@/assets/benefits/worldWideDelivery.svg";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("benefits-list")
@localized()
export class BenefitsList extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  render() {
    return html`
      ${this.list.map(
        (item) => html`
          <div class="item">
            <sl-icon src=${item.icon} class="icon"></sl-icon>
            <div class="text">${item.text}</div>
          </div>
        `,
      )}
    `;
  }

  get list() {
    return [
      { icon: handshake, text: t("01zmit608r1fv4zy.benefits-text-1", "10 years in the auto parts market") },
      { icon: packingTime, text: t("x7rr8i0y6t8rcroc.benefits-text-2", "Safe order 10 days") },
      { icon: worldWideDelivery, text: t("crtuxg6deh132czh.benefits-text-3", "Worldwide delivery") },
    ];
  }

  static styles = css`
    :host {
      display: flex;
    }

    ${type("sm")} {
      gap: var(--spacing-paddingXXS, 4px);
      flex-direction: column;
    }

    ${type("lg")} {
      gap: var(--Space-Margin-marginXS, 8px);
    }

    .item {
      display: flex;
      flex-basis: calc(100% / 3);
      border-radius: var(--rounding-base, 6px);
      background: var(--Neutral-Bg-colorBgContainer, #fff);
      gap: var(--spacing-paddingXS, 8px);
    }

    .text {
      white-space: nowrap;
    }

    ${type("sm")} .item {
      align-items: center;
      padding: var(--spacing-paddingSM, 12px) var(--spacing-padding, 16px);
    }

    ${type("sm")} .text {
      color: var(--colorText, rgba(0, 0, 0, 0.88));
      ${font("SM/Strong")};
    }

    ${type("sm")} .icon {
      font-size: var(--Size-Base-sizeLG, 24px);
    }

    ${type("lg")} .item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: var(--Space-Padding-paddingMD, 20px) var(--spacing-padding, 16px);
    }

    ${type("lg")} .text {
      color: var(--color-text-base, #18181b);
      ${font("Base/Normal")};
    }

    ${type("lg")} .icon {
      font-size: var(--Size-Height-controlHeight, 40px);
    }
  `;
}
