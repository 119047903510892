import { font } from "@/util/fonts";
import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("fix-tag")
export class FixTag extends LitElement {
  @property()
  id!: string;

  @property()
  name!: string;

  @property()
  value!: string;

  render() {
    return html` <sl-tag size="medium" removable @sl-remove=${this.onRemove}>${this.renderText()}</sl-tag> `;
  }

  renderText() {
    return html`
      <span class="parameter">${this.name}:</span>
      <span class="value">${this.value}</span>
    `;
  }

  onRemove = () => {
    this.dispatchEvent(new CustomEvent("remove", { detail: { id: this.id } }));
  };

  static styles = css`
    .parameter {
      color: var(--Neutral-Text-colorText, #27272a);
      ${font("Base/Normal")};
    }

    .value {
      color: var(--Neutral-Text-colorText, #27272a);
      ${font("Base/Strong")};
    }

    .parameter + * {
      margin-left: 4px;
    }

    sl-tag {
      --sl-color-neutral-50: var(--Base-Gray-2, #f4f4f5);
      --sl-color-neutral-200: var(--Base-Gray-2, #f4f4f5);
      --sl-color-neutral-600: #27272a;
      --sl-input-border-radius-medium: var(--Border-Radius-borderRadiusSM, 4px);
      --sl-spacing-x-small: 4px;
      --sl-spacing-small: var(--Space-Padding-controlPaddingHorizontalSM, 8px);
    }

    sl-tag::part(base) {
      padding: 8px;
      height: 36px;
    }
  `;
}
