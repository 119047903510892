import { customElement, property } from "lit/decorators.js";
import { html, LitElement } from "lit";
import { ProductAreaStore } from "./product-area-store";

@customElement("sorter-element")
export class SorterElement extends LitElement {
  @property()
  store!: ProductAreaStore;

  render() {
    const sorters = this.store.filters.getOptions("sort");
    const value = this.store.filters.sort!;

    const options = sorters.map((item) => ({
      key: item.id,
      name: item.name,
      icon: "",
    }));

    return html` <fix-filter-dropdown .options=${options} .value=${value} @change=${this.onChange}></fix-filter-dropdown> `;
  }

  onChange = (event: CustomEvent<{ key: string }>) => {
    this.store.filters.selectSort(event.detail.key);
  };

  connectedCallback() {
    super.connectedCallback();
    this.store.events.addEventListener(this, "filters.updated", () => this.requestUpdate());
  }

  disconnectedCallback() {
    this.store.events.removeEventListeners(this);
  }
}
