import { CurrencyService } from "@/services/currency-service";

const intlcache: Record<string, Intl.NumberFormat> = {};
import { Money } from "@/generated/graphql/b2c";
import { CurrencyConverter } from "@/util/currency-converter";
import { LitElement } from "lit";
import { ContextProvider, createContext } from "@lit/context";
import { defaultCurrency } from "@/constants/currency";
import { defaultVatCoefficient } from "@/constants/vat";

const settings = {
  defaultCentsDelimiter: ".",
  defaultGroupsDelimiter: ",",
  money: {
    style1: {
      cents: true,
      groupsDelimiter: " ",
      centsDelimiter: ".",
    },
    style2: {
      cents: true,
      groupsDelimiter: ",",
      centsDelimiter: ".",
    },
    style3: {
      cents: true,
      groupsDelimiter: "",
      centsDelimiter: ".",
    },
    style4: {
      cents: false,
      groupsDelimiter: ",",
      centsDelimiter: "",
    },
  },
};

export class Formatter {
  provider: ContextProvider<{ __context__: Formatter }, LitElement>;

  currencyCode: string = defaultCurrency;
  vatCoefficient: number = defaultVatCoefficient;

  constructor(
    host: LitElement,
    protected currencyService: CurrencyService,
  ) {
    this.provider = new ContextProvider(host, { context: FormatterServiceContext, initialValue: this });
    this.provider.setValue(this, true);
  }

  setDefaultCurrency(currencyCode: string) {
    this.currencyCode = currencyCode;
    this.provider.setValue(this, true);
  }

  get defaultCurrency() {
    return this.currencyCode;
  }

  setDefaultVat(vat: number) {
    this.vatCoefficient = vat;
    this.provider.setValue(this, true);
  }

  price(amount: number, style = "style1" as "full" | "compact" | "compact2" | "style1" | "style2" | "style3" | "style4", isVatApplicable = true) {
    return this.formatMoney(this.getCustomerMoneyValue(amount, isVatApplicable), style, false);
  }

  priceRange(
    amountRange: [number, number],
    style = "style1" as "full" | "compact" | "compact2" | "style1" | "style2" | "style3" | "style4",
    isVatApplicable = true,
  ) {
    const [from, to] = amountRange.map((amount) => this.getCustomerMoneyValue(amount, isVatApplicable));

    if (from.amount === to.amount) {
      return this.formatMoney(from, style, false);
    }

    return `${this.formatMoney(from, style, false)} - ${this.formatMoney(to, style, true)}`;
  }

  private getCustomerMoneyValue(amount: number, isVatApplicable: boolean) {
    const rateEntry = this.currencyService.getRate(this.currencyCode);
    const moneyValue: Money = {
      amount: amount,
      currency: this.currencyCode,
      cents: "",
    };

    let customerValue = CurrencyConverter.convert(moneyValue, this.currencyCode, rateEntry);

    if (isVatApplicable) {
      customerValue = CurrencyConverter.applyVat(customerValue, this.vatCoefficient);
    }

    return customerValue;
  }

  private formatMoney(value: Money, style: "full" | "compact" | "compact2" | "style1" | "style2" | "style3" | "style4" = "style1", omitCurrency?: boolean) {
    try {
      const mode = this.getMode(style);
      const { cents, centsDelimiter, groupsDelimiter } = settings.money[mode];
      const { defaultCentsDelimiter, defaultGroupsDelimiter } = settings;

      const amount = this.getAmount(value);

      let formatted = this.moneyLine(value.currency, cents, amount, omitCurrency);

      if (centsDelimiter !== defaultCentsDelimiter) {
        formatted = formatted.replaceAll(defaultCentsDelimiter, centsDelimiter);
      }

      if (groupsDelimiter !== defaultGroupsDelimiter) {
        formatted = formatted.replaceAll(defaultGroupsDelimiter, groupsDelimiter);
      }

      return formatted;
    } catch (error) {
      console.error(error);
      return "--";
    }
  }

  private getMode(style: string) {
    switch (style) {
      case "style1":
      case "style2":
      case "style3":
      case "style4":
        return style as "style1" | "style2" | "style3" | "style4";
      default:
        return "style1" as const;
    }
  }

  private getAmount(value: Money) {
    const amount = value.amount === "" ? 0 : parseFloat(value.amount);
    if (!isNaN(amount)) {
      return amount;
    }
    const cents = parseInt(value.cents);
    if (!isNaN(cents)) {
      return cents / 100;
    }

    throw new Error(`incorrect money value: ${JSON.stringify(value)}`);
  }

  private moneyLine(currency: string, cents: boolean, floatval: number, omitCurrency?: boolean) {
    const key = `${currency}-${cents}-${omitCurrency}`;
    if (!intlcache[key]) {
      intlcache[key] = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        currencyDisplay: omitCurrency ? "code" : "narrowSymbol",
        maximumFractionDigits: cents ? undefined : 0,
      });
    }

    let formatted = intlcache[key].format(floatval);
    if (omitCurrency) {
      formatted = formatted.replace(`${currency}`, "").trim();
    }

    return formatted;
  }
}

export const FormatterServiceContext = createContext<Formatter>("formatter-service");
