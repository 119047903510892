export class Events {
  listeners: Array<{ host: object; type: string; listener: (event: Event) => void }> = [];

  constructor(protected host: HTMLElement) {}

  addEventListener(host: object, type: string, callback: Function) {
    const listener = (event: Event) => {
      if (event.type === type) {
        callback(event);
      }
    };
    this.host.addEventListener(type, listener);
    this.listeners.push({ host, type, listener });
  }

  removeEventListeners(host: object) {
    for (const { host: listenerHost, type, listener } of this.listeners) {
      if (listenerHost === host) {
        this.host.removeEventListener(type, listener);
      }
    }
    this.listeners = this.listeners.filter((item) => item.host !== host);
  }

  dispatch(type: string) {
    this.host.dispatchEvent(new CustomEvent(type));
  }
}
