import { type Services } from "@/services/services";
import { type B2cProductSearchPayload, type FilterInput, ProductsSearchDocument } from "@/generated/graphql/b2c";
import { type ProductAreaStore } from "./product-area-catalog/product-area-store";

export class ProductLoader {
  constructor(protected services: Services) { }

  async load(context: { category?: string; categories?: string[]; brand?: string; search?: string }, store: ProductAreaStore) {
    const pageSize = store.pager.pageSize;
    const page = store.state.page;
    const sortBy = store.state.sort!;
    const brands = store.state.brands;
    const categories = store.state.categories;
    const priceMin = store.state.prices.from;
    const priceMax = store.state.prices.to;
    const search = context.search ?? "";

    if (context.category && !categories.length) {
      categories.push(context.category);
    }

    if (context.brand && !brands.length) {
      brands.push(context.brand);
    }

    if (context.categories && !categories.length) {
      categories.push(...context.categories);
    }

    const price = [
      { op: "=>", prop: "price.min", value: priceMin },
      { op: "<=", prop: "price.max", value: priceMax },
    ]
      .filter((item) => item.value !== null)
      .map((item) => ({ ...item, value: (item.value! * 100)!.toString() })) as unknown as FilterInput[];

    const response = await this.services.api.client.query({
      query: ProductsSearchDocument,
      variables: {
        input: {
          pageSize: pageSize,
          page: page,
          query: search,
          sortBy: sortBy,
          filter: [{ op: "=", prop: "brand", value: [...brands] }, { op: "=", prop: "category", value: [...categories] }, ...price],
        },
      },
    });

    const products = (response.data.ProductsSearch.records as B2cProductSearchPayload[]).filter(product => product.categories.length > 0);

    store.pager.total = response.data.ProductsSearch.pagination.totalPages;
    store.pager.totalItems = response.data.ProductsSearch.pagination.totalCount;
    store.setupProducts(products);
    store.setupAggregated({
      categories: response.data.ProductsSearch.categories.map((category) => category.id),
    });

    for (const product of products) {
      this.services.routes.prepareForProduct(product);
    }
  }
}
