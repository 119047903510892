import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("fix-categories-button")
@localized()
export class FixCategoriesButton extends LitElement {
  render() {
    return html`<sl-button variant="primary" size="small" caret>${t("kgtqa204bgwxqctj.product-categories", "Product categories")}</sl-button>`;
  }

  static styles = css`
    :host {
      --sl-input-border-radius-small: 0px;
      --sl-spacing-x-small: 0px;
      --sl-spacing-small: 0px;
      --sl-input-border-width: 0px;
      --sl-input-height-small: 64px;
      --sl-color-primary-600: var(--Header-colorBGProductCategoriesButton, #3b82f6);
      --sl-color-primary-500: var(--Header-colorBGProductCategoriesButtonHover, #60a5fa);
      display: block;
    }

    ::part(prefix) {
      display: none;
    }

    ::part(base) {
      display: flex;
      align-items: center;
      gap: var(--Space-Padding-paddingXXS, 4px);
      padding: var(--Space-Padding-padding, 16px) var(--Space-Padding-paddingLG, 24px);
    }

    ::part(caret) {
      font-size: 12px;
      padding: 6px;
    }

    ::part(label) {
      /* LG/Normal */
      font-family: "SF Pro Text";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  `;
}
