import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";

@customElement("page-section-layout")
export class CatalogLayout extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "sm";

  @property({ attribute: true, type: Boolean, reflect: true })
  bg = true;

  @property({ attribute: true, type: Boolean, reflect: true })
  offsets = true;

  @property({ attribute: true, type: Boolean, reflect: true })
  smOffsets = false;

  @property({ attribute: true, type: String })
  sectionTitle: string | null = null;

  render() {
    return choose(this.type, [
      ["lg", () => this.renderDesktop()],
      ["sm", () => this.renderMobile()],
    ]);
  }

  renderDesktop() {
    return html`<div class="box ${this.offsets ? "offsets" : ""} ${this.bg ? "bg" : ""}">
      <div>${this.renderSectionTitle()} ${this.renderContent()}</div>
    </div>`;
  }

  renderSectionTitle() {
    if (!this.sectionTitle) {
      return;
    }

    return html`<div class="box-title">${this.sectionTitle}</div>`;
  }

  renderMobile() {
    return html`<div class="box ${this.offsets ? "offsets" : ""} ${this.bg ? "bg" : ""} ${this.smOffsets ? "sm-offsets" : ""}">
      <div>${this.renderSectionTitle()} ${this.renderContent()}</div>
    </div>`;
  }

  renderContent() {
    return html`<div class="box-content"><slot></slot></div>`;
  }

  static styles = css`
    .box {
      padding: var(--Space-Margin-marginXL, 32px) var(--Space-Margin-marginXL, 32px);
    }

    .box.bg {
      background: var(--colors-library-base-white, #ffffff);
    }

    ${type("sm")} .box {
      padding: 24px 8px;
    }

    .box-title {
      color: var(--color-text-heading, #111827);
      ${font("Heading/2")};
      margin-bottom: 24px;
    }

    ${type("sm")} .box-title {
      ${font("Heading/4")};
      margin-bottom: 16px;
    }

    .box.offsets {
      margin-bottom: 16px;
    }

    ${type("sm")} .box.sm-offsets {
      padding: 0 8px 8px;
    }
  `;
}
