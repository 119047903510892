export const defaultLang = "en";
export const supportedLanguages = [
  { code: "en", name: "English", icon: "gb", iconLibrary: "fix-icons-flags" },
  { code: "de", name: "Deutsch", icon: "de", iconLibrary: "fix-icons-flags" },
  { code: "es", name: "Español", icon: "es", iconLibrary: "fix-icons-flags" },
  { code: "fr", name: "Français", icon: "fr", iconLibrary: "fix-icons-flags" },
  { code: "lv", name: "Latviešu", icon: "lv", iconLibrary: "fix-icons-flags" },
  { code: "pl", name: "Polski", icon: "pl", iconLibrary: "fix-icons-flags" },
  { code: "ru", name: "Русский", icon: "ru", iconLibrary: "fix-icons-flags" },
  { code: "zh", name: "Chinese", icon: "cn", iconLibrary: "fix-icons-flags" }
];
export const localeLanguages = [
  { name: "English", id: "en-US" },
  { name: "Русский", id: "ru-RU" },
  { name: "Deutsch", id: "de-DE" },
  { name: "Français", id: "fr-FR" },
  { name: "Español", id: "es-ES" },
  { name: "Polski", id: "pl-PL" },
  { name: "Latviski", id: "lv-LV" },
  { name: "Chinese", id: "zh-CN" },
  { name: "Korean", id: "ko-KR" },
];
