import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { type } from "@/util/layout";
import { font } from "@/util/fonts";

@customElement("product-status")
@localized()
export class ProductStatus extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: String })
  status = "";

  get statusData() {
    switch (this.status) {
      case "in-stock":
        return { text: t("wwx593qvd0zl1lnj.in-stock", "In stock"), className: "success" };
      case "out-stock":
        return { text: t("ou8a50qhq8z03edd.available-for-order", "Available for order"), className: "out" };
      default:
        return { text: "--", className: "out" };
    }
  }

  render() {
    const { text, className } = this.statusData;
    const rootClass = classMap({
      ["status"]: true,
      [className]: true,
    });

    return html`<div class=${rootClass}>${text}</div>`;
  }

  static styles = css`
    .status {
      display: flex;
      align-items: center;
      gap: var(--gap, 8px);
      ${font("SM/Regular")};
    }

    .success {
      color: var(--color-succes, #16a34a);
    }

    .out {
      color: var(--colorsLibrary-Gray-600, #52525b);
    }

    .status::before {
      content: "";
      border-radius: 50%;
      background-color: currentColor;
      width: var(--Size-Base-sizeXS, 8px);
      height: var(--Size-Base-sizeXS, 8px);
    }

    ${type("sm")} {
      --gap: var(--Space-Padding-paddingXXS, 4px);
    }

    ${type("lg")} {
      gap: var(--Space-Padding-paddingXS, 8px);
    }
  `;
}
