import { font } from "@/util/fonts";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { type } from "@/util/layout";

const stylesSm = css`
  ${type("sm")} tr:nth-child(odd) {
    background-color: #f4f4f5;
  }

  ${type("sm")} th,
  ${type("sm")} td {
    ${font("SM/Normal")};
    padding: 8px;
  }

  ${type("sm")} .name {
    color: var(--Neutral-Text-colorText, #27272a);
  }

  ${type("sm")} .value {
    color: var(--Neutral-Text-colorText, #27272a);
  }
`;

const stylesLg = css`
  ${type("lg")} {
  }

  ${type("lg")} th,
  ${type("lg")} td {
    ${font("Base/Normal")};
    padding: 12px 8px;
  }

  ${type("lg")} .name {
    color: var(--Neutral-Text-colorTextSecondary, #737373);
  }

  ${type("lg")} .value {
    color: var(--Neutral-Text-colorText, #27272a);
  }

  ${type("lg")} tr + tr {
    border-top: 1px solid var(--Neutral-Border-colorBorderSecondary, #f0f0f0);
  }

  ${type("lg")} .valuecol {
    width: 142px;
  }
`;

@customElement("specifications-list")
export class SpecificationsList extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Array }) options = [] as Array<{ name: string; value: string; type: string }>;

  render() {
    const { options } = this;

    return html`
      <table class="container">
        <colgroup>
          <col />
          <col class="valuecol" />
        </colgroup>
        ${options.map(
          (item) => html`
            <tr class="row">
              <td class="name">${item.name}</td>
              <td class="value">${item.value} ${item.type}</td>
            </tr>
          `,
        )}
      </table>
    `;
  }

  static styles = css`
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th,
    td {
      border: none;
      text-align: left;
    }

    ${stylesSm};
    ${stylesLg};
  `;
}
