import { createContext } from "@lit/context";
import { Api } from "@/api";
import { ContextProvider } from "@lit/context";
import {
  CustomerChannelDocument,
  RegistrationDocument,
  UpdateAboutYourselfDocument,
  SendRecoveryPasswordMailDocument,
  B2cCustomer,
  SalesChannelOptions,
  RecoveryPasswordDocument,
} from "@/generated/graphql/b2c";
import { defaultCurrency } from "@/constants/currency";
import { defaultVatCoefficient } from "@/constants/vat";
import { LitElement } from "lit";
import { prepareFormErrors } from "@/util/graphql-errors-parser";

export class CustomerChannel {
  isLoaded = false;

  customer = null as B2cCustomer | null;
  channelOptions = null as SalesChannelOptions | null;
  allowedCurrencyCodes: string[] = [];
  isAllowedAllCurrencies = false;
  vatCoefficient: number = defaultVatCoefficient;
  availableCountries: { id: string; name: string; code: string; vatRate: number }[] = [];
  currentCountry: { id: string; name: string; code: string } | null = null;

  public currencyCode = defaultCurrency;

  private provider: ContextProvider<{ __context__: CustomerChannel }>;
  private api: Api;

  constructor(host: LitElement, api: Api) {
    this.api = api;
    this.provider = new ContextProvider(host, { context: customerChannelContext, initialValue: this });
  }

  private update = () => {
    this.provider.setValue(this.value);
  };

  private get value() {
    return {
      ...this,
    };
  }

  isCustomerAuth = () => {
    return !!this?.customer;
  };

  customerCurrencyCode() {
    if (this.isAllowedAllCurrencies) {
      return this.currencyCode;
    }

    if (!this.allowedCurrencyCodes.includes(this.currencyCode)) {
      return defaultCurrency;
    }

    return this.currencyCode;
  }

  fetchCustomerChannel = async () => {
    const response = await this.api.client.query({
      query: CustomerChannelDocument,
      variables: {},
    });
    const { CustomerChannel } = response.data;
    // @ts-ignore
    this.customer = CustomerChannel.customer;
    // @ts-ignore
    this.channelOptions = CustomerChannel.salesChannel;
    // @ts-ignore
    this.currencyCode = this.customer?.currency ?? this.channelOptions?.currency.code;
    // @ts-ignore
    this.allowedCurrencyCodes = this.channelOptions?.countries.map((country) => country.currencyCode);
    this.isAllowedAllCurrencies = this.allowedCurrencyCodes.length <= 0;

    this.vatCoefficient = CustomerChannel.vatCoefficient;
    this.availableCountries = CustomerChannel.availableCountries;
    this.currentCountry = CustomerChannel.currentCountry;
    this.isLoaded = true;

    this.update();
  };

  fetchIfNotLoaded = async () => {
    if (!this.isLoaded) {
      await this.fetchCustomerChannel();
    }
  }

  setDefaultVat(vat: number) {
    if (this.vatCoefficient === vat) {
      return;
    }
    
    this.vatCoefficient = vat;
    this.provider.setValue(this, true);
  }

  login = async (data: { email: string; password: string }) => {
    const url = `${this.api.base}/customer/auth`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      credentials: "include",
    });
    const success = response.status === 204;
    const result = success ? { errors: [] } : ((await response.json()) as { errors: string[] });
    return {
      success,
      errors: result.errors,
    };
  };

  logout = async () => {
    const url = `${this.api.base}/customer/logout`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
    });
    const success = response.status === 200;
    const result = success ? { errors: [] } : ((await response.json()) as { errors: string[] });

    this.customer = null;
    this.update();

    return {
      success,
      errors: result.errors,
    };
  };

  signup = async (input: { email: string; password: string; phone: string; newsletter: boolean }) => {
    const response = await this.api.client.query({
      query: RegistrationDocument,
      variables: { input },
    });

    if (response.errors) {
      return {
        success: false,
        errors: prepareFormErrors(response.errors),
      };
    }

    return {
      success: true,
      data: response.data!.Registration,
    };
  };

  requestRecoveryPassword = async (input: { email: string }) => {
    const response = await this.api.client.query({
      query: SendRecoveryPasswordMailDocument,
      variables: { input },
    });

    if (response.errors) {
      return {
        success: false,
        errors: response.errors,
      };
    }

    return {
      success: true,
      data: response.data!.SendRecoveryPasswordMail,
    };
  };

  resetPassword = async (input: { newPassword: string; confirmedPassword: string; resetPasswordToken: string }) => {
    const response = await this.api.client.query({
      query: RecoveryPasswordDocument,
      variables: { input },
    });

    if (response.errors) {
      return {
        success: false,
        errors: response.errors,
      };
    }

    return {
      success: true,
      data: response.data!.RecoveryPassword,
    };
  };

  updateAboutYourself = async (input: {
    countryId: string;
    currencyId: string;
    locale: string;
    isCompany: boolean;
    vatNumber: string;
    billingEmail: string;
  }) => {
    const response = await this.api.client.query({
      query: UpdateAboutYourselfDocument,
      variables: { input },
    });

    if (response.errors) {
      return {
        success: false,
        errors: prepareFormErrors(response.errors),
      };
    }

    return {
      success: true,
    };
  };
}

export const customerChannelContext = createContext<CustomerChannel>("customerChannel");

export type CustomerChannelContextType = typeof customerChannelContext;
