import { Api } from "@/api";
import { Url } from "@/helpers/url";
import { ContextProvider, createContext } from "@lit/context";
import { t } from "i18next";
import { LitElement } from "lit";

export type Breadcrumb = {
  url: string;
  label: string;
};

export class Breadcrumbs {
  private provider: ContextProvider<{ __context__: Breadcrumbs }, LitElement>;
  private api!: Api;

  breadcrumbs: Breadcrumb[] = [];

  constructor(host: LitElement, api: Api) {
    this.provider = new ContextProvider(host, { context: BreadcrumbsContext, initialValue: this });
    this.api = api;
  }

  update = () => {
    this.provider.setValue(this, true);
  };

  setBreadcrumb = (breadcrumb: Breadcrumb, forceOverride = false) => {
    if (forceOverride) {
      this.setBreadcrumbs([breadcrumb]);
      return;
    }
    const currentIndex = this.breadcrumbs.findIndex(({ label }) => label === breadcrumb.label);
    if (currentIndex !== -1) {
      this.breadcrumbs = this.breadcrumbs.slice(0, currentIndex);
      this.update();
      return;
    }

    this.breadcrumbs = [...this.breadcrumbs, breadcrumb];
    this.update();
  };

  setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    this.breadcrumbs = [this.firstBreadcrumb, ...breadcrumbs];
    this.update();
  };

  get firstBreadcrumb() {
    return {
      url: Url.to("home-page-with-lang"),
      label: t("j60wdc57kcnq57wt.home-page", "Home page"),
    };
  }

  clearBreadcrumb = () => {
    this.breadcrumbs = [];
  };
}

export const BreadcrumbsContext = createContext<Breadcrumbs>("Breadcrumbs");
