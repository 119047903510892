import { css, CSSResultGroup, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import "./item-button";
import "./layout-sm";
import { layoutFragment, layoutSmCss } from "./layout-sm";
import { MenuItem } from "@/services/categories";

@customElement("subcategories-screen")
export class SubcategoriesScreen extends LitElement {
  @property()
  items!: MenuItem[]

  render() {
    const content = html`
      ${layoutFragment((layout) =>
        layout.renderHeadStack({
          title: "Body",
          onBack: this.onBackClick,
        }),
      )}
      ${this.renderSubCategories()} ${layoutFragment((layout) => layout.renderDelimiter())}
    `;

    return html`<layout-sm-container .content=${content}> </layout-sm-container>`;
  }

  renderSubCategories = () => {
    const items = map(this.getItems(), (item) => {
      return html`<item-button .action=${'link'} .href=${item.url} .title=${item.name} @click=${() => this.onCategoryClick(item.key)}></item-button>`;
    });

    return layoutFragment((layout) => {
      return layout.renderSection({
        segment: "subcategories",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      });
    });
  };

  onCategoryClick = (key: string) => {
    this.dispatchEvent(new CustomEvent("toCategory", { detail: { key } }));
  };

  onBackClick = () => {
    this.dispatchEvent(new CustomEvent("back"));
  };

  getItems() {
    return this.items;
  }

  static styles = css`
    ${layoutSmCss}

    .head + * {
      margin-top: 16px;
    }

    .categories {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .settings {
      padding-top: 24px;
    }
  `;
}
