import { customElement, property } from "lit/decorators.js";
import { html, nothing } from "lit";
import cartSvg from "@/assets/cart.svg";
import doneCartSvg from "@/assets/done-cart.svg";
import { ProductButton } from "./product-button";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("add-to-cart")
@localized()
export class AddToCart extends ProductButton {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Boolean })
  disabled = false;

  @property({ type: Boolean })
  done = false;

  @property({ type: Boolean })
  noicon = false;

  render() {
    return html` <sl-button .disabled=${this.disabled}> ${this.getIcon()} ${this.getText()} </sl-button> `;
  }

  getIcon() {
    if (this.done) {
      return html`<sl-icon slot="prefix" src=${doneCartSvg}></sl-icon>`;
    }

    if (this.noicon) {
      return nothing;
    }

    return html`<sl-icon slot="prefix" src=${cartSvg}></sl-icon>`;
  }

  getText() {
    return this.done ? t("hr23xk0p7a7783h8.added-to-cart", "Added to cart") : t("mxa4s697394822iw.add-to-cart", "Add to cart");
  }
}
