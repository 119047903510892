import alipay from "@/assets/footer/logos/alipay.svg";
import master from "@/assets/footer/logos/master.svg";
import paypal from "@/assets/footer/logos/paypal.svg";
import visa from "@/assets/footer/logos/visa.svg";
import tnt from "@/assets/footer/logos/tnt.svg";
import fedex from "@/assets/footer/logos/fedex.svg";
import dhl from "@/assets/footer/logos/dhl.svg";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { type } from "@/util/layout";

const banks = [visa, master, alipay, fedex, dhl, paypal, tnt];

@customElement("partners-group")
export class PartnersGroup extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  list = [visa, master, alipay, fedex, dhl, paypal, tnt];

  render() {
    return banks.map((src) => this.bank(src));
  }

  bank(src: string) {
    return html`<sl-icon src=${src}></sl-icon>`;
  }

  static styles = css`
    :host {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    sl-icon {
      display: block;
      max-height: 32px;
      max-width: 88px;
      width: auto;
      height: 100%;
    }

    ${type("lg")} {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      justify-items: center;
    }

    ${type("sm")} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px 32px;
    }
  `;
}
