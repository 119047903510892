import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import store from "@/assets/footer/store.svg";
import fixpartsLogoOrange from "@/assets/fixparts-logo-orange.svg";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";

@customElement("fix-brand")
export class FixBrand extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  render() {
    return html` <sl-icon src=${fixpartsLogoOrange} class="icon"></sl-icon> `;
  }

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
    }

    .label {
      color: var(--Neutral-colorTextBase, #000);
      grid-area: label;
    }

    .icon {
      grid-area: icon;
    }

    ${type("sm")} {
      gap: 4.35px;
    }

    ${type("sm")} .label {
      ${font("Logo/2")};
    }

    ${type("sm")} .icon {
      width: 100px;
    }

    ${type("lg")} {
      gap: 8px;
    }

    ${type("lg")} .label {
      ${font("Logo/1")};
    }

    ${type("lg")} .icon {
      width: 100px;
    }
  `;
}
