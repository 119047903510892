import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

@customElement("fix-dropdown-head")
export class FixDropdownHead extends LitElement {
  @property({ type: String })
  label!: string;

  @property({ type: String })
  icon?: string;

  @property({ type: Boolean })
  state?: boolean;

  render() {
    const icon = this.iconElement;
    const label = this.label;
    const classname = classMap({
      icon: !!this.icon,
    });

    return html`<sl-button class=${classname} variant="text" size="small" caret>${icon} ${label} </sl-button>`;
  }

  get iconElement() {
    if (!this.icon) return nothing;
    if (this.icon === "custom") return html`<slot name=${this.icon} slot="prefix"></slot>`;
    return html`<sl-icon src=${this.icon} slot="prefix"></sl-icon>`;
  }

  static styles = css`
    :host {
      --sl-color-primary-600: var(--Components-Typography-Global-colorText, #27272a);
      --sl-color-primary-500: var(--Brand-Primary-colorPrimaryHover, #60a5fa);
      --sl-color-primary-700: var(--Brand-Primary-colorPrimaryTextActive, #2563eb);
      --sl-button-font-size-small: 14px;
      --sl-input-height-small: 22px;
      --sl-input-border-width: 0px;
      --sl-input-font-family: "SF Pro Text";
      --sl-font-weight-semibold: 400;
    }

    ::part(base) {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }

    ::part(label) {
      padding-inline-start: 0px;
      padding-inline-end: 4px;
    }

    ::part(caret) {
      font-size: 10px;
      padding-left: 3px;
      padding-right: 3px;
    }

    .icon::part(prefix) {
      margin-right: var(--icon-gap, 4px);
    }
  `;
}
