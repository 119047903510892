import { html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/product-grid";
import "@/elements/fix-tag";
import "@/elements/fix-filter-dropdown";

import { consume } from "@lit/context";
import { Url } from "@/helpers/url";
import { RouterLocation } from "@vaadin/router";
import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { CarBrandsListDocument, BrandsByCategoryDocument, B2cBrand } from "@/generated/graphql/b2c";
import { ServiceLocatorContext, Services } from "@/services/services";
import { provideLayoutStyles } from "../product-page/utils";
import { when } from "lit/directives/when.js";
import "./categories-navigator";
import "@/pages/home-page/extracted/featured-brands-list";
import "@/elements/fix-back-button";
import "@/elements/fix-pager-group";
import { choose } from "lit/directives/choose.js";
import { ProductAreaStore } from "./product-area-catalog/product-area-store";
import { ProductLoader } from "./product-loader";
import { PageElement, whenType } from "@/util/element";
import "./product-area-catalog/product-area-element";
import "@/elements/brands-list-images";
import "@/elements/brands-list-links";
import { t } from "i18next";
import { GlobalElement } from "./product-area-catalog/product-area-filter-popup";
import type { BeforeLeaveObserver, AfterEnterObserver, PreventCommands } from "@vaadin/router";
import "./loader-overlay";
import { ParametersValue } from "@/services/parameters";

@customElement("category-page")
export class CategoryPage extends PageElement implements BeforeLeaveObserver, AfterEnterObserver {
  declare type: "sm" | "lg";

  @property()
  location!: RouterLocation;

  @state()
  view: {
    categoryTabs?: { parentId: string; activeId: string | null } | null;
    categoryCards?: { parentId: string; activeId: string | null } | null;
    brands?: string;
  } = {};

  @state()
  category: { name?: string; id?: string } = {};

  @state()
  loading = true;

  @state()
  blank = true;

  @state()
  resultState: null | "empty" | "content" = null;

  @state()
  brands: B2cBrand[] = [];

  @consume({ context: BreadcrumbsContext, subscribe: true })
  public breadcrumbs!: Breadcrumbs;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  categoryId!: string;

  loader!: ProductLoader;

  store!: ProductAreaStore;

  loadedFor!: ParametersValue

  render() {
    if (this.blank) {
      return this.renderLoaderOverlay();
    }

    return html` ${this.renderContent()} ${when(this.loading, () => this.renderLoaderOverlay())} `;
  }

  renderContent() {
    return choose(this.type, [
      ["sm", this.renderContentSm],
      ["lg", this.renderContentLg],
    ]);
  }

  renderContentSm = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardSm()}
        <div>
          ${when(this.view.brands === "before-product-area", () => this.renderFeaturedBrands())} ${this.renderProductArea()}
          ${when(this.view.brands === "after-product-area", () => this.renderFeaturedBrands())}
        </div>
        ${this.renderPopularBrands()} ${this.renderAbout()}
      </catalog-layout>
    `;
  };

  renderContentLg = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardLg()}
        ${when(this.view.brands === "before-product-area", () => this.renderFeaturedBrands())} ${this.renderProductArea()} ${this.renderPopularBrands()}
        ${this.renderAbout()}
      </catalog-layout>
    `;
  };

  renderTopcardSm = () => {
    return html`
      <div class="section normargin card type-card-top">
        <div class="row navigation">
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="title">${this.category.name}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
        ${when(this.view.brands === "end-topcard", () => this.renderFeaturedBrands())}
      </div>
    `;
  }

  renderTopcardLg = () => {
    const back = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 2];

    return html`
      <div class="section normargin card type-card-top type-card-top-1">
        <div class="row navigation">
          ${when(back, () => html`<fix-back-button class="back" .url=${back.url} .mode=${'link'}></fix-back-button>`)}
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="titles">
            <div class="title">${this.category.name}</div>
            <div class="title-for"></div>
          </div>
          <div class="results">${t("j8cinnz091w154tp.results", "results")}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
      </div>
    `;
  }

  getFeatureBrandsPosition(type: "sm" | "lg", navigator: { top: boolean; sub: boolean }) {
    if (type === "lg") {
      return "before-product-area" as const;
    }

    if (type === "sm" && navigator.top && navigator.sub) {
      return "after-product-area" as const;
    }

    if (type === "sm" && navigator.sub) {
      return "before-product-area" as const;
    }

    if (type === "sm") {
      return "end-topcard" as const;
    }
  }

  renderLoaderOverlay() {
    return html` <loader-overlay></loader-overlay> `;
  }

  renderFeaturedBrands() {
    return html`
      <div class="section card type-3">
        <div class="section-title size-1">${t("wr3vc34d2y7w6nt9.featured-brands-title", "Featured Brands")}</div>
        <div class="section-box">
          <brands-list-images .type=${this.type} .brands=${this.brands}></brands-list-images>
        </div>
      </div>
    `;
  }

  renderPopularBrands() {
    return html`
      <div class="section card type-2">
        <div class="section-title">${t("22462yo1x6yur1ek.car-brands-title", "Buy car parts from the most popular car brands")}</div>
        <div class="section-box">
          <brands-list-links .type=${this.type} .brands=${this.brands}></brands-list-links>
        </div>
      </div>
    `;
  }

  renderAbout() {
    return html`
      <div class="section card type-1">
        <div class="section-title">${this.category.name}</div>
        <div class="section-box">
          <fix-text
            .type=${this.type}
            .text=${t("9us00h798g54t0wt.category-default-text", "<p>Your vehicle deserves the best, and so do you. Get the auto parts you need at prices that won't break the bank. Whether it's a simple replacement or an upgrade, find parts that fit perfectly and perform flawlessly.\nWith a wide selection of top-grade components, you're just a step away from smoother drives and peace of mind. Great products, great prices—because every journey should be a good one.</p><p>Keep your car running like new. Shop now and see the difference quality can make.</p>")}
          ></fix-text>
        </div>
      </div>
    `;
  }

  renderNavigator() {
    const root = this.view.categoryCards?.parentId;
    const items = this.services.categories.getChilds(root!);
    const categories = items.map((category) => ({
      name: category.name,
      key: category.id,
      id: category.id,
      image: category.image,
      url: category.url
    }));

    return html` <categories-preview-custom type=${this.type} .categories=${categories}></categories-preview-custom>`;
  }

  renderProductArea() {
    return html`<product-area-element
      .type=${this.type}
      .store=${this.store}
      .services=${this.services}
      .resultState=${this.resultState}
    ></product-area-element>`;
  }

  async navigate(params: { category: string; params?: URLSearchParams, replace?: boolean }) {
    const search = params.params ?? new URLSearchParams();

    const path = this.services.categories.getItemPath(params.category);
    const registryUrl = this.services.routes.convertToRoute({
      segments: path,
    });
    this.services.routes.setForUrl(registryUrl, { type: "category", id: params.category });

    this.overwriteLocation({ path, search });
    this.handleLocation({ path, search });
  }

  task: null | string = null;

  onAfterEnter(location: RouterLocation) {
    this.handleLocation({
      path: location.params.dynamicPath as string[],
      search: new URLSearchParams(location.search)
    });
    this.loadedFor = this.services.parameters.parameters;
  }

  async onBeforeLeave(location: RouterLocation, commands: PreventCommands) {
    const paramsEqual = this.services.parameters.equalsParameters(
      this.services.parameters.parameters,
      this.loadedFor
    );

    if (location.route?.name === 'dynamic-route' && paramsEqual) {
      const entity = await this.services.routes.resolveFromLocalPath(location.params.dynamicPath as string[]);
      if (entity?.type !== 'category') {
        return;
      }

      // locking navigation for component reload prevent
      // overwrtie location async, because commands.prevent will reset any url changes
      const params = {
        path: location.params.dynamicPath as string[],
        search: new URLSearchParams(location.search)
      };
      setTimeout(() => {
        this.overwriteLocation(params);
        this.handleLocation(params);
      });

      return commands.prevent();
    }
  }

  async overwriteLocation(params: { path: string[], search: URLSearchParams }) {
    const url = Url.to("dynamic-route", { dynamicPath: params.path }, params.search.toString());
    const currentPath = this.categoryId
      ? this.services.categories.getItemPathElements(this.categoryId)
      : [];
    const replace = currentPath.length > 0 && currentPath.length === params.path.length;
    const mode = replace ? "replaceState" : "pushState";

    window.history[mode](null, "", url);
  }

  async handleLocation(params: { path: string[], search: URLSearchParams }) {
    const { path, search } = params;
    const registryUrl = this.services.routes.convertToRoute({
      segments: path,
    });

    const route = await this.services.routes.resolve(registryUrl);
    const sameCategory = this.category?.id === route?.id;

    this.categoryId = route!.id;
    this.store.readParameters(search);

    if (this.blank) {
      this.updateState('blank');
    } else if (sameCategory) {
      this.updateState('selections');
    } else {
      this.updateState('category');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.loader = new ProductLoader(this.services);
    this.store = new ProductAreaStore();
    this.prepare();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.store.events.removeEventListeners(this);
    this.store.disconnect(this);
  }

  async prepare() {
    this.store.connect(this);
    this.addEventListener("productarea.update", () => this.emitStoreStateChanges());
    this.addEventListener("productarea.back", () => this.backFromSerch());
    this.loading = false;
    this.blank = false;
    this.requestUpdate();
  }

  async updateState(step: "blank" | "category" | "selections") {
    this.setLoading(true);

    if (step === "blank") {
      await this.initialFetch();
    }

    if (step === "blank" || step === "category") {
      await this.fetchCategoryRelated();
      await this.configureLayout();
      await this.updateBreadcrumbs();
      await this.configureViewMode();
      await this.fillStoreByFilterOptionsForCurrentCategory();
    }

    await this.fillStoreByUrl();
    await this.performProductsLoad();

    this.setLoading(false);
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  async initialFetch() {

  }

  async fetchCategoryRelated() {
    const { api } = this.services;

    const [brandsResponse] = await Promise.all([
      api.client.query({
        query: BrandsByCategoryDocument,
        variables: {
          input: {
            categoryId: this.getRootCategory(),
          }
        },
      }),
    ]);

    const ids = (brandsResponse.data.BrandsByCategory as B2cBrand[]).map(item => item.id);

    this.brands = this.services.brands.list.filter(item => ids.includes(item.id));
  }

  async fillStoreByUrl() {
    this.store.readParameters(new URLSearchParams(window.location.search));
  }

  async fillStoreByFilterOptionsForCurrentCategory() {
    const categoryId = this.getRootCategory();
    const categoryChild = this.services.categories.getChilds(categoryId);

    this.store.setupBrands(this.brands);
    this.store.setupCategories(categoryChild);
    this.store.fillForms();
  }

  async emitStoreStateChanges() {
    const params = this.store.serializeParameters()!;
    await this.navigate({
      category: this.getRootCategory(),
      params: params,
    });
  }

  async backFromSerch() {
    const categoryPath = this.services.categories.getItemPathElements(this.getRootCategory());
    const [parent, current] = categoryPath.slice(-2);
    await this.navigate({
      category: parent?.id ?? current?.id,
      params: new URLSearchParams(),
    });
  }

  async performProductsLoad() {
    await this.loader.load({ category: this.getRootCategory() }, this.store);
    this.resultState = this.store.products.length ? "content" : "empty";
  }

  updateBreadcrumbs() {
    const path = this.getCategoriesPath();
    const breadcrumbs = path.map((id) => {
      const item = this.services.categories.getItem(id);
      const url = Url.to("dynamic-route", { dynamicPath: this.services.categories.getItemPath(id) });
      return {
        label: item.name,
        url: url,
      };
    });

    this.breadcrumbs.setBreadcrumbs(breadcrumbs);
  }

  getCategoriesPath() {
    const root = this.getRootCategory();
    return this.services.categories.getParents(root).map((item) => item.id).concat(root);
  }

  getRootCategory() {
    return this.categoryId;
  }

  configureLayout() {
    const path = this.getCategoriesPath();
    const categoryId = path[path.length - 1];
    const category = this.services.categories.getItem(categoryId);
    this.category = {
      name: category.name,
      id: category.id,
    };
  }

  configureViewMode() {
    const path = this.getCategoriesPath();
    const depth = path.length;
    const active = path[path.length - 1] ?? null;
    const children = active ? this.services.categories.getChilds(active) : [];

    this.view.brands = "none";
    this.view.categoryTabs = null;
    this.view.categoryCards = null;

    if (depth <= 1) {
      this.view.categoryTabs = { parentId: "*", activeId: active ?? null };
    }

    if (active && children.length) {
      this.view.categoryCards = { parentId: active, activeId: null };
    }

    this.view.brands = this.getFeatureBrandsPosition(this.type, {
      top: Boolean(this.view.categoryTabs?.parentId),
      sub: Boolean(this.view.categoryCards?.parentId),
    });

    this.requestUpdate();
  }

  static stylesCommon = css``;

  static stylesSm = css`
    ${provideLayoutStyles("sm")};
  `;

  static stylesLg = css`
    ${provideLayoutStyles("lg")};
  `;

  static get styles() {
    return [this.stylesCommon, whenType("sm", this.stylesSm), whenType("lg", this.stylesLg)];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "category-page": CategoryPage;
  }
}