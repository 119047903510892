import { property, customElement } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { t } from "i18next";
import { font } from "@/util/fonts.ts";
import { localized } from "@/decorators/localized";
import {CartProductValidationMessage} from "@/generated/graphql/b2c.ts";

@customElement("cart-notifications")
@localized()
export class CartNotifications extends LitElement {
  @property()
  items: CartProductValidationMessage[] = [];

  render() {
    return html`
      <div class="content">
        ${this.items.map(
            (item) => html`
              <div class="notification">
                <span class="header">${item.title}</span>
                ${item.message}
              </div>
            `,
        )}
      </div>
    `;
  }
  static styles = css`
    .content {
      display: flex;
      gap: var(--space-margin-margin-xs, 8px);
      flex-direction: column;
    }
    
    .content .notification {
      padding: var(--space-margin-margin-lg, 24px);
      gap: var(--space-margin-margin-xs, 8px);
      border-radius: var(--border-radius-border-radius, 6px);
      background: var(--neutral-bg-color-bg-layout, #F8FAFC);
      //styleName: SM/Normal;
      ${font("SM/Normal")};
      color: var(--components-typography-global-color-text, #27272A);
    }

    .content .notification .header {
      display: block;
      ${font("LG/Strong")};
    }
  `;
}
