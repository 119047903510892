import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/mobile/store-header";

@customElement("cart-layout")
export class CartLayout extends LitElement {
  render() {
    return html`
      <store-header></cart-header>
      <div class="content">
        <slot></slot>
      </div>
    `;
  }

  @property({ type: Object }) location = {} as Location;

  @state()
  cart = {
    quantity: 0 as number,
  };

  get searchVal() {
    const urlParams = new URLSearchParams(this.location.search);
    return urlParams.get("q") || "";
  }

  static styles = css`
    :host {
      --layout-padding-h: 8px;
      --layout-padding-v: 4px;
    }

    :host > *:not(.selfpadding) {
      display: block;
      margin-left: var(--layout-padding-h);
      margin-right: var(--layout-padding-h);
    }

    .content {
      padding-top: 24px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "cart-layout": CartLayout;
  }
}
