import { Debouncer } from "@/util/schedule";
import { LitElement, ReactiveController } from "lit";

export class FilterForm implements ReactiveController {
  searchs: { [key: string]: string } = {};
  ranges: { [key: string]: { from: null | number; to: null | number } } = {};
  options: {
    [key: string]: { key: string; id: string; name: string; searchName: string }[];
  } = {};
  selections: Set<string> = new Set();
  sort: null | string = null;
  host: LitElement = null!;
  updating = false;

  constructor(protected params: { autoApply: boolean }) {

  }

  setOptions(key: string, list: { id: string; name: string }[]) {
    this.options[key] = list.map((item) => ({
      ...item,
      key: this.key(key, item.id),
      searchName: item.name.toLocaleLowerCase(),
    }));
    this.updated();
  }

  setSorters(list: { id: string; name: string }[]) {
    this.setOptions("sort", list);
  }

  getOptions(key: string, filtered = false) {
    const search = this.searchs[key]?.trim()?.toLocaleLowerCase() ?? "";
    const list = this.options[key] ?? [];

    return list
      .filter((item) => {
        if (filtered) {
          return search.length === 0 || item.searchName.includes(search);
        }
        return true;
      })
      .map((item) => {
        return {
          key: item.key,
          id: item.id,
          name: item.name,
          selected: this.selections.has(item.key),
        };
      });
  }

  toggleOption(key: string) {
    this.setOption(key, !this.selections.has(key));
    this.updated();
  }

  setOption(key: string, value: boolean) {
    if (value) {
      this.selections.add(key);
    } else {
      this.selections.delete(key);
    }
    this.updated();
  }

  selectRange(key: string, value: { from: null | number; to: null | number }) {
    this.ranges[key] = value;
    this.updated();
  }

  getRange(key: string) {
    return this.ranges[key] ?? { from: null, to: null };
  }

  selectSort(sort: string) {
    this.sort = sort;
    for (const item of this.getOptions("sort")) {
      if (item.id === sort) {
        this.selections.add(item.key);
      } else {
        this.selections.delete(item.key);
      }
    }
    this.updated();
  }

  setSearch(key: string, value: string) {
    this.searchs[key] = value;
    this.host?.requestUpdate();
  }

  getSearch(key: string) {
    return this.searchs[key] ?? "";
  }

  key(type: string, id: string) {
    return `${type}:${id}`;
  }

  isBlank() {
    const hasSelections = this.selections.size > 0;
    const hasRanges = Object.values(this.ranges).some((range) => range.from !== null || range.to !== null);

    return !(hasSelections || hasRanges);
  }

  debouncer = new Debouncer(450);

  public updated() {
    this.host?.requestUpdate();
    if (this.params.autoApply && !this.updating) {
      this.debouncer.call(() => {
        this.applied();
      });
    }
  }

  public immediateApply() {
    this.debouncer.release();
    this.applied();
  }

  public applied = () => {
    this.host?.dispatchEvent(new CustomEvent("filters.applied", { bubbles: true, composed: true, detail: { form: this } }));
  }

  public cleared = () => {
    this.host?.dispatchEvent(new CustomEvent("filters.clear", { bubbles: true, composed: true, detail: { form: this } }));
  }

  setHost(host: LitElement) {
    host.addController(this);
    this.host = host;
  }

  hostConnected() {

  }

  hostDisconnected() {

  }
}
