import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import menuSvg from "@/assets/menu.svg";
import menuCloseSvg from "@/assets/close.svg";

@customElement("fix-menu-button")
export class FixMenuButton extends LitElement {
  render() {
    return html` <sl-icon-button src=${menuSvg}> </sl-icon-button> `;
  }

  static styles = css`
    :host {
      --sl-color-neutral-600: #6b7280;
    }

    ::part(base) {
      width: 24px;
      height: 24px;
      font-size: 18px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
}

@customElement("fix-menu-close-button")
export class FixMenuCloseButton extends LitElement {
  render() {
    return html` <sl-icon-button src=${menuCloseSvg}> </sl-icon-button> `;
  }

  static styles = css`
    :host {
      --sl-color-neutral-600: #6b7280;
      display: contents;
    }

    ::part(base) {
      width: 24px;
      height: 24px;
      font-size: 24px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;
}
