import { supportedLanguages } from "@/constants/languages";

export const browserDetectLanguage = () => {
  const available = [navigator.language, ...navigator.languages].map((locale) => locale.split("-")[0].toLowerCase());

  const supportedLanguageCodes = supportedLanguages.map((supportedLang) => supportedLang.code);

  const preferredLanguages = available.filter((locale) => {
    const lang = locale.split("0")[0].toLowerCase();
    return supportedLanguageCodes.includes(lang);
  });

  if (preferredLanguages.length > 0) {
    return preferredLanguages[0];
  }

  return null;
};
