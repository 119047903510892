import { css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/product-element";
import { repeat } from "lit/directives/repeat.js";
import { Debouncer } from "@/util/schedule";
import { MultiTypeElement, whenType } from "@/util/element";

const lgCardWidth = 213;
const lgCardMaxWidth = lgCardWidth * 3;
const lgGap = 16;

const smCardWidth = 168;
const smCardMaxWidth = smCardWidth * 3;
const smGap = 8;

@customElement("product-grid-2")
export class ProductGrid2 extends MultiTypeElement {
  @property()
  type: "lg" | "sm" = "lg";

  @property()
  data!: Array<{
    id: string;
    name: string;
    mpn: string;
    brand: string;
    price: [number, number];
    discount: number;
    images: Array<{ path: string }>;
    url: string;
  }>;

  @state()
  classNames!: string;

  debouncer = new Debouncer(0);

  render() {
    return html` <div class=${this.classNames}>${this.renderList()}</div> `;
  }

  renderList() {
    return repeat(
      this.data,
      (item) => item.id,
      (_, index) => this.renderItem(index),
    );
  }

  renderItem(index: number) {
    const item = this.data[index];
    return html`
      <product-element
        .name=${item.name}
        .mpn=${item.mpn}
        .brand=${item.brand}
        .price=${item.price}
        .discount=${item.discount}
        .images=${item.images}
        .href=${item.url}
        .type=${this.type}
      ></product-element>
    `;
  }

  getSizeClassNames(): Record<string, boolean> {
    const cardWidth = this.type === "lg" ? lgCardWidth : smCardWidth;
    const cardMaxWidth = this.type === "lg" ? lgCardMaxWidth : smCardMaxWidth;
    const gap = this.type === "lg" ? lgGap : smGap;

    const size = this.getBoundingClientRect().width;

    if (size > this.calcSize(cardMaxWidth, gap, 8)) {
      return {
        ["col-8"]: true,
        ["limit"]: true,
      };
    }

    if (size > this.calcSize(cardWidth, gap, 8)) {
      return {
        ["col-8"]: true,
      };
    }

    if (size > this.calcSize(cardWidth, gap, 4)) {
      return {
        ["col-4"]: true,
      };
    }

    if (size > this.calcSize(cardWidth, gap, 2)) {
      return {
        ["col-2"]: true,
      };
    }

    return {
      ["col-1"]: true,
    };
  }

  calcSize(card: number, gap: number, count: number) {
    return card * count + gap * (count - 1);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.resizeMeasureSubribe(true);
    this.measure();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.resizeMeasureSubribe(false);
  }

  resizeMeasureSubribe(status: boolean) {
    if (status) {
      window.addEventListener("resize", this.measureDebounced);
    } else {
      window.removeEventListener("resize", this.measureDebounced);
    }
  }

  measureDebounced = () => {
    this.debouncer.call(this.measure);
  };

  measure = () => {
    const classList = ["container", ...Object.keys(this.getSizeClassNames())] as string[];

    this.classNames = classList.join(" ");
  };

  static stylesCommon = css`
    :host {
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(var(--cols), minmax(var(--min), var(--max)));
      justify-content: space-between;
      gap: var(--gap);
    }

    .limit {
      --max: 1fr;
    }

    .col-1 {
      --max: 100%;
      --cols: 1;
    }

    .col-2 {
      --cols: 2;
    }

    .col-4 {
      --cols: 4;
    }

    .col-8 {
      --cols: 8;
    }

    .col-8.limit {
      --max: ${lgCardMaxWidth}px;
    }
  `;

  static stylesSm = css`
    .container {
      --min: ${smCardWidth}px;
      --max: 1fr;
      --gap: ${smGap}px;
    }
  `;

  static stylesLg = css`
    .container {
      --min: ${lgCardWidth}px;
      --max: 1fr;
      --gap: ${lgGap}px;
    }
  `;

  static styles = [ProductGrid2.stylesCommon, whenType("sm", ProductGrid2.stylesSm), whenType("lg", ProductGrid2.stylesLg)];
}
