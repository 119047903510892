import { font } from "@/util/fonts";
import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import "@/elements/fix-button-default";
import { property } from "lit/decorators.js";
import { MultiTypeElement, whenType } from "@/util/element";
import { t } from "i18next";
import { consume } from "@lit/context";
import { Parameters, ParametersContext } from "@/services/parameters.ts";
import { CurrencyConverter } from "@/util/currency-converter.ts";

export abstract class FilterFormElement extends MultiTypeElement {
  @property()
  type!: "sm" | "lg";

  @consume({ context: ParametersContext, subscribe: true })
  public parameters!: Parameters;

  renderRange(params: {
    name?: string;
    type: "money";
    slider?: boolean;
    value: { from: null | number; to: null | number };
    onChange: (value: { from: null | number; to: null | number }) => void;
  }) {

    const value = (key: "from" | "to") => {
      return params.value[key]?.toString() ?? "";
    };
    const onChange = (key: "from" | "to") => {
      return (event: { target: HTMLInputElement }) => {
        const value = event.target.value;
        const numberValue = value ? Number(value) : null;
        params.onChange({ ...params.value, [key]: numberValue });
      };
    };

    const currencySymbol =  CurrencyConverter.getCurrencySymbol(this.parameters.getVisibleCurrency());
    return html`
      <div class="section range">
        ${when(params.name, () => this.renderTitle(params.name!))}
        <div class="inputs">
          <sl-input class="input" placeholder=${t("5jofq90us8x35pvo.min", "{{code}} min", {code: currencySymbol})} .value=${value("from")} @input=${onChange("from")}></sl-input>
          <div class="msg">${t("562j01i4db4j6mua.to", "to")}</div>
          <sl-input class="input" placeholder=${t("mqxa4h3tzcd8wz17.max", "{{code}} max", {code: currencySymbol})} .value=${value("to")} @input=${onChange("to")}></sl-input>
        </div>
      </div>
    `;
  }

  renderCheckboxes(params: {
    name?: string;
    search?: boolean;
    searchText: string;
    onChange: (key: string) => void;
    items: Array<{ key: string; name: string; selected: boolean }>;
    onSearch: (text: string) => void;
  }) {
    const items = repeat(
      params.items,
      (item) => item.key,
      (item) => html`<fix-checkbox .checked=${item.selected} @change=${() => params.onChange(item.key)}>${item.name}</fix-checkbox>`,
    );

    return html`
      <div class="section checkboxes">
        ${when(params.name, () => this.renderTitle(params.name!))}
        ${when(params.search, () => this.renderSearch({ value: params.searchText, onChange: params.onSearch }))}
        <div class="list">${items}</div>
      </div>
    `;
  }

  renderTitle(name: string) {
    return html`<div class="title">${name}</div>`;
  }

  renderSearch(params: { value: string; onChange: (value: string) => void }) {
    return html`<sl-input
      class="search"
      placeholder=${t("0x619y4k02smw9cs.search", "Search")}
      .value=${params.value}
      @input=${(event: { target: HTMLInputElement }) => params.onChange(event.target.value)}
    ></sl-input>`;
  }

  renderClear(onClear: () => void) {
    return html`<fix-button-default class="clear" @click=${onClear}>${t("38xtmz11ad14blsh.clear-all", "Clear All")}</fix-button-default>`;
  }

  renderGap(type: "x1" | "x2" | "x2line" | "x05") {
    const className = classMap({
      gap: true,
      [type]: true,
    });

    return html`<div class=${className}></div>`;
  }

  static stylesCommon = css`
    sl-input {
      --sl-input-border-radius-medium: 6px;
      --sl-input-border-color: var(--Components-Input-Global-colorBorder, #d9d9d9);
      --sl-input-border-color-hover: var(--Components-Input-Component-hoverBorderColor, #60a5fa);
      --sl-input-border-color-focus: var(--Components-Input-Component-activeBorderColor, #3b82f6);
      --sl-input-height-medium: 40px;
      width: var(--width, auto);
    }

    sl-input::part(input) {
      ${font("Base/Normal")};
      color: var(--Components-Input-Global-colorText, #27272a);
    }

    sl-input::part(input)::placeholder {
      ${font("Base/Normal")};
      color: var(--Neutral-Text-colorTextPlaceholder, #a3a3a3);
    }

    .gap.x1 {
      height: 32px;
    }

    .gap.x2 {
      height: 64px;
    }

    .gap.x2line {
      height: 64px;
      position: relative;
    }

    .gap.x2line::after {
      content: "";
      display: block;
      clear: both;
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      top: 50%;
      background: var(--Neutral-Border-colorBorderSecondary, #f0f0f0);
    }

    .title {
      ${font("Heading/4")};
      color: var(--color-text-heading, #111827);
    }

    .section > .title + .inputs {
      margin-top: 16px;
    }

    .section > .title + .search {
      margin-top: 12px;
    }

    .section > .title + .list {
      margin-top: 8px;
    }

    .section > .search + * {
      margin-top: 16px;
    }

    .section.range .inputs {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-start;
    }

    .section.range .inputs .input {
      --sl-input-border-radius-medium: 8px;
      --sl-input-height-medium: 48px;
      --width: 106px;
    }

    .section.range .msg {
      ${font("Base/Normal")};
      color: var(--Components-Typography-Global-colorText, #27272a);
    }

    .section.checkboxes .list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  `;

  static stylesSm = css`
    .title {
      ${font("Heading/5")};
    }

    .gap.x1 {
      height: 32px;
    }

    .gap.x2 {
      height: 64px;
    }

    .gap.x2line {
      height: 48px;
      position: relative;
    }

    .gap.x05 {
      height: 16px;
    }
  `;

  static stylesLg = css`
    .title {
      ${font("Heading/4")};
    }

    .gap.x1 {
      height: 32px;
    }

    .gap.x2 {
      height: 64px;
    }

    .gap.x2line {
      height: 64px;
      position: relative;
    }

    .gap.x05 {
      height: 16px;
    }
  `;

  static styles = [FilterFormElement.stylesCommon, whenType("sm", FilterFormElement.stylesSm), whenType("lg", FilterFormElement.stylesLg)];
}
