import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import down from "@/assets/down-outlined.svg";
import { font } from "@/util/fonts";

@customElement("fix-details")
export class FixDetails extends LitElement {
  @property({ type: String })
  summary!: string;

  render() {
    return html` <sl-details summary=${this.summary}>
      <sl-icon src=${down} slot="expand-icon"></sl-icon>
      <sl-icon src=${down} slot="collapse-icon"></sl-icon>
      <slot></slot>
    </sl-details>`;
  }

  static styles = css`
    ::part(base) {
      border: none;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--Footer-colorDevider, #d9d9d9);
      margin-top: 12px;
      border-radius: 0;
    }

    ::part(header) {
      padding: 0;
      gap: 16px;
      color: var(--Neutral-Text-colorTextSecondary, #737373);
      ${font("Base/Strong")};
    }

    ::part(summary-icon) {
      font-size: 16px;
    }

    ::part(content) {
      padding: 0px;
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    sl-details[open]::part(summary-icon) {
      /* Disable the expand/collapse animation */
      rotate: -90deg;
    }

    :host {
      margin-top: 20px;
    }
  `;
}
