import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { font } from "@/util/fonts.ts";

@customElement("dialog-mobile")
export class DialogMobile extends LitElement {
  @property()
  label!: string;

  render() {
    return html`
      <div class="dialog">
        <div class="header">
          <sl-button class="back-btn" variant="text" @click=${this.onClose}><sl-icon src=${arrowOutlined}></sl-icon> Back</sl-button>
          <div class="label">${this.label}</div>
        </div>
        <div class="container"><slot></slot></div>
      </div>
    `;
  }

  onClose = () => {
    this.dispatchEvent(new CustomEvent("dialogClose", {}));
  };

  static styles = css`
    .label {
      ${font("Heading/5")};
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    sl-button::part(label) {
      --sl-spacing-medium: 0;
    }
    .dialog {
      position: fixed;
      z-index: 99;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      padding: 16px 24px;
    }
  `;
}
