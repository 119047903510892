import { LitElement, PropertyValueMap, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { font } from "@/util/fonts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { layout } from "../product-page/cont";
import { createRef, ref } from "lit/directives/ref.js";
import { localized } from "@/decorators/localized";
import { VariantSwitcher } from "../product-page/switcher";
import { choose } from "lit/directives/choose.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/mobile/fix-input";
import "@/pages/product-page/layout/footer";
import "@/elements/mobile/store-header";
import { when } from "lit/directives/when.js";
import { cssLayout } from "@/util/layout";

@customElement("login-page")
@layout()
@localized()
export class LoginPage extends LitElement {
  layout!: string;

  switcher: VariantSwitcher<LoginPage> = new VariantSwitcher(this);

  @consume({ context: customerChannelContext, subscribe: true })
  @property({ attribute: false })
  public customerContext!: CustomerChannel;

  private ref = createRef<HTMLFormElement>();

  @state()
  private loginError = "";

  @state()
  private loading = false;

  render() {
    return choose(this.layout, [
      ["desktop", this.renderDesktop],
      ["mobile", this.renderMobile],
    ]);
  }

  renderMobile = () => {
    return html`<store-header type="sm"></store-header>${this.renderPage()}`;
  };

  renderDesktop = () => {
    return html` <store-header type="lg">
        <span slot="left-block">${this.renderContinueShoppingLink()}</span>
      </store-header>
      ${this.renderPage()}
      <footer-element></footer-element>`;
  };

  renderPage() {
    return html`
      <div class="login-page-container">
        ${when(this.layout === "mobile", () => html`<div class="header">${this.renderContinueShoppingLink()}</div>`)}
        <div class="login-container">
          <form class="login-form" name="login" @submit=${this.submit} ${ref(this.ref)}>
            <div><span class="login-heading">${t("7e19h22khwn365zm.sign-in", "Sign In")}</span></div>
            <div class="inputs-container">
              <fix-input
                id="login-input"
                name="login"
                type="email"
                placeholder="${t("stci3dd82scs5j9p.your-email", "* Your email")}"
                helpText="${t("x926694e5jfby7au.email-incorrect", "Please enter a valid email address")}"
                required
                clearable
                .customError="${this.loginError}"
              ></fix-input>
              <fix-input
                name="password"
                type="password"
                placeholder="${t("n6udbn26p3xrl83d.password", "* Password")}"
                helpText="${t("3t8k9e9jqwp1pn82.please-enter-password", "Please enter password")}"
                required
                clearable
              ></fix-input>
            </div>
            <fix-button @click=${this.submit} variant="primary" type="${this.elementsType}" class="login-btn" .loading=${this.loading}>
              ${t("8wzxb3kopj9y3659.login", "Login")}
            </fix-button>
          </form>
          <div class="divider"></div>
          <div class="forgot-password">
            <span>${t("s0hdh7oc8j01ydvf.forgot-password-text", "Forgot your password?")}</span>
            <fix-link class="forgot-password-link" href="${Url.to("forgot-password-page")}" type="sm">${t("44by1up0qxwa3clu.click-here", "Click here")}</fix-link>
          </div>
        </div>
      </div>
    `;
  }

  get elementsType() {
    return this.layout === "desktop" ? "md" : "sm";
  }

  renderContinueShoppingLink = () => {
    return html`
      <fix-link class="continue-shopping-link" href="${Url.to("home-page-with-lang")}" type=${this.elementsType}>
        <sl-icon src=${arrowOutlined}></sl-icon>
        ${t("k0q7l2w2q1856v4o.continue-shopping", "Continue shopping")}
      </fix-link>
    `;
  };

  submit = async () => {
    const formData = new FormData(this.ref.value!);
    // @ts-ignore
    const login = formData.get("login") as string;
    const password = formData.get("password") as string;

    const form = this.ref.value;

    if (!login || !password || !form?.checkValidity()) {
      return;
    }

    this.loginError = "";
    this.loading = true;

    const loginResult = await this.customerContext.login({ email: login, password });

    this.loading = false;

    if (!loginResult.success) {
      this.loginError = loginResult.errors?.[0] ?? t("jwr35j86u25g5ej6.default-error", "Incorrect username or password");
      return;
    }

    this.customerContext.fetchCustomerChannel();
    this.redirectToHomePage();
  };

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(_changedProperties);
    if (this.customerContext?.customer) {
      this.redirectToHomePage();
    }
  }

  private redirectToHomePage() {
    Router.go(Url.to("home-page-with-lang"));
  }

  static styles = css`
    .login-page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 16px;
      gap: var(--space-margin-margin-lg, 24px);
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
    }

    ${cssLayout("desktop")} .login-page-container {
      padding: 56px 0px;
    }

    .login-container {
      display: flex;
      padding: var(--space-padding-padding-xl, 32px) var(--space-padding-padding-md, 24px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: var(--border-radius-border-radius-lg, 8px);
      background: var(--neutral-bg-color-bg-container, #ffffff);
    }

    ${cssLayout("desktop")} .login-container {
      width: 410px;
      align-self: center;
      padding: var(--space-padding-padding-xl, 32px);
    }

    .header {
      display: flex;
      align-self: stretch;
      padding: 0px var(--header-margin-xs, 8px);
      margin-top: var(--header-margin-xs, 24px);
    }

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
      align-self: stretch;
    }

    ${cssLayout("mobile")} .login-heading {
      ${font("Heading/3")};
    }

    ${cssLayout("desktop")} .login-heading {
      ${font("Heading/2")}
    }

    .inputs-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      align-self: stretch;
    }

    .login-btn {
      align-self: stretch;
    }

    .divider {
      display: flex;
      height: 1px;
      width: 100%;
      background: var(--neutral-border-color-border-secondary, #f0f0f0);
    }

    .forgot-password {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .forgot-password > span {
      ${font("SM/Normal")}
      color: var(--components-typography-global-color-text, #27272a);
    }

    .forgot-password-link {
      ${font("SM/Normal")}
    }

    .continue-shopping-link sl-icon {
      margin-right: var(--Space-Padding-paddingXS, 8px);
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "login-page": LoginPage;
  }
}
