import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonCustomize, buttonFont } from "../mobile/fix-button";

@customElement("fix-button-default")
export class FixPagerNumber extends LitElement {
  render() {
    return html`<sl-button><slot></slot></sl-button>`;
  }

  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "Base/Normal")}

        sl-button {
      --border-radius: 6px;
      --width: 100%;
      --height: 40px;
      width: 100%;
    }

    sl-button:not(.active) {
      --color: var(--Components-Pagination-Global-colorText, #27272a);
      --color-bg: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-border: var(--Components-Button-Component-defaultBg, #f4f4f5);
      --color-hover: var(--Components-Button-Component-defaultHoverBorderColor, #60a5fa);
      --color-bg-hover: var(--Components-Button-Component-defaultHoverBg, #fff);
      --color-border-hover: var(--Components-Button-Component-defaultHoverBorderColor, #60a5fa);
      --color-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
      --color-bg-active: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
    }
  `;
}
