import { font } from "@/util/fonts";
import { css, unsafeCSS } from "lit";

const layoutOffset = `var(--Space-Margin-marginXXL, 48px)`;

export const provideLayoutVariables = (type: "sm" | "lg") => {
  switch (type) {
    case "sm":
      return unsafeCSS(`
                --Space-Margin-marginXXL: 8px;
            `);
    case "lg":
      return unsafeCSS(`
                --Space-Margin-marginXXL: 48px;
            `);
  }
};

export const provideLayoutStyles = (type: "sm" | "lg") => {
  switch (type) {
    case "sm":
      return provideLayoutStylesSm();
    case "lg":
      return provideLayoutStylesLg();
  }
};

export const provideLayoutStylesSm = () => css`
  :host {
    ${provideLayoutVariables("sm")};
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .section > .section-title {
    ${font("Heading/4")};
    color: var(--Neutral-Text-colorText, #27272a);
  }

  .section > .section-controls {
  }

  .section > .section-box {
  }

  .section.card {
    padding-top: 24px;
    padding-bottom: 24px;
    background: var(--Neutral-colorWhite, #fff);
    ${layoutOffsetPadding()};
  }

  .section.card.compact {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .card + * {
    margin-top: 8px;
    display: block;
  }

  .card:last-child {
    margin-bottom: 8px;
  }

  .card.type-card-top {
    gap: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .type-card-top .title {
    ${font("Heading/3")};
    color: var(--color-text-heading, #111827);
    margin-top: 8px;
    margin-bottom: 16px;
  }
`;

export const provideLayoutStylesLg = () => css`
  :host {
    ${provideLayoutVariables("lg")};
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .section > .section-title {
    ${font("Heading/2")};
    color: var(--Neutral-Text-colorTextHeading, #1f2937);
  }

  .section > .section-controls {
  }

  .section > .section-box {
  }

  .section.card {
    background: var(--Neutral-colorWhite, #fff);
  }

  .section.card:not(.normargin) {
    border-radius: var(--Border-Radius-borderRadius, 6px);
    ${layoutOffsetMargin()};
  }

  .section.card.normargin {
    ${layoutOffsetPadding()};
  }

  .section.card.type-1 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 8px;
  }

  .section.card.type-1 > .section-title {
    ${font("Heading/2")};
  }

  .section.card.type-2 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 24px;
  }

  .section.card.type-2 > .section-title {
    ${font("Heading/2")};
  }

  .section.card.type-3 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    gap: 16px;
  }

  .section.card.type-3 > .section-title {
    ${font("Heading/4")};
  }

  .section.card.type-card-top-1 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 48px;
    padding-right: 48px;
    gap: 16px;
  }

  .card + * {
    margin-top: 8px;
    display: block;
  }

  .card:last-child {
    margin-bottom: 32px;
  }

  .section.card.card-top {

  }

  .type-card-top {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .type-card-top .navigation {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  .type-card-top .title {
    ${font("Heading/2")};
    color: var(--color-text-heading, #111827);
  }

  .type-card-top .titles {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .type-card-top .titles:has(.title-for) .title {
    ${font("Heading/1")};
    color: var(--color-text-heading, #111827);
  }

  .type-card-top .title-for {
    ${font("Heading/1/Sub")};
    color: var(--Neutral-Text-colorText, #27272A);
  }

  .type-card-top .results {
    ${font("LG/Normal")};
    color: var(--Components-Typography-Global-colorTextDescription, #A3A3A3);
  }

  .type-card-top .row.primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const card = (params: { background?: string; boderRadius?: string }) =>
  unsafeCSS(`
    background: ${params.background ?? "transparent"};
    border-radius: ${params.boderRadius ?? "0px"};
`);

export const cardDefault = () =>
  card({
    background: "var(--Neutral-colorWhite, #fff)",
    boderRadius: "var(--Border-Radius-borderRadius, 6px)",
  });

export const marginH = (value: string) =>
  unsafeCSS(`
    margin-left: ${value};
    margin-right: ${value};
`);

export const paddingH = (value: string) =>
  unsafeCSS(`
    padding-left: ${value};
    padding-right: ${value};
`);

export const layoutOffsetMargin = () => marginH(layoutOffset);
export const layoutOffsetPadding = () => paddingH(layoutOffset);

export const columnsSidebarMain = (params: { mode: "solid" | "separate" }) =>
  unsafeCSS(`
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: ${params.mode === "solid" ? "calc(290px - 32px)" : "290px"} 1fr ;
    gap: ${params.mode === "solid" ? "32px" : "8px"};
`);

export const columnsMainSidebar = (params: { mode: "solid" | "separate" }) =>
  unsafeCSS(`
    display: grid;
    grid-template-areas: "main sidebar";
    grid-template-columns: 1fr ${params.mode === "solid" ? "calc(474px - 32px)" : "474px"};
    gap: ${params.mode === "solid" ? "32px" : "8px"};
`);
