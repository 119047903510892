import { LitElement, PropertyValueMap, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import arrowOutlined from "@/assets/arrow-outlined.svg";
import { font } from "@/util/fonts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { layout } from "../product-page/cont";
import { createRef, ref } from "lit/directives/ref.js";
import { localized } from "@/decorators/localized";
import { VariantSwitcher } from "../product-page/switcher";
import { choose } from "lit/directives/choose.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/mobile/fix-input";
import "@/elements/mobile/fix-select";
import "@/elements/mobile/fix-checkbox";
import "@/pages/product-page/layout/footer";
import "@/elements/mobile/store-header";
import { when } from "lit/directives/when.js";
import { cssLayout } from "@/util/layout";
import { CurrencyService, CurrencyServiceContext } from "@/services/currency-service";
import { localeLanguages } from "@/constants/languages";

@customElement("about-yourself-page")
@layout()
@localized()
export class AboutYourselfPage extends LitElement {
  layout!: string;

  switcher: VariantSwitcher<AboutYourselfPage> = new VariantSwitcher(this);

  @consume({ context: customerChannelContext, subscribe: true })
  @property({ attribute: false })
  private customerContext!: CustomerChannel;

  @consume({ context: CurrencyServiceContext, subscribe: true })
  private currencyService!: CurrencyService;

  private ref = createRef<HTMLFormElement>();

  @state()
  private loading = false;

  @state()
  private formData = {
    countryId: "",
    currencyId: "",
    locale: "",
    vatNumber: "",
    billingEmail: "",
    isCompany: false,
  };

  @state()
  private formErrors = {
    countryId: null,
    currencyId: null,
    locale: null,
    vatNumber: null,
    billingEmail: null,
  };

  render() {
    return choose(this.layout, [
      ["desktop", this.renderDesktop],
      ["mobile", this.renderMobile],
    ]);
  }

  renderMobile = () => {
    return html`<store-header type="sm"></store-header>${this.renderPage()}`;
  };

  renderDesktop = () => {
    return html` <store-header type="lg">
        <span slot="left-block">${this.renderContinueShoppingLink()}</span>
      </store-header>
      ${this.renderPage()}
      <footer-element></footer-element>`;
  };

  renderPage() {
    return html`
      <div class="about-yourself-page-container">
        ${when(this.layout === "mobile", () => html`<div class="header">${this.renderContinueShoppingLink()}</div>`)}
        <div class="about-yourself-container">
          <form class="about-yourself-form" name="about-yourself" @submit=${this.submit} ${ref(this.ref)}>
            <div><span class="about-yourself-heading">${t("u7d37u9iwn9i1m39.tell-us-about-yourself", "Tell us about yourself")}</span></div>
            <div class="inputs-container">
              <fix-select
                id="country-input"
                name="countryId"
                placeholder="${t("nhuz8dp4nq7nrm9y.select-country", "Select your country")}"
                .customError=${this.formErrors.countryId}
                .options=${this.countriesOptions}
              ></fix-select>
              <fix-select
                id="language-input"
                name="locale"
                placeholder="${t("2z5w2khfh21knzuu.select-language", "Select your language")}"
                .customError=${this.formErrors.locale}
                .options=${this.localeOptions}
              ></fix-select>
              <fix-select
                id="currency-input"
                name="currencyId"
                placeholder="${t("26eiec2enyejp9pg.select-currency", "Currency")}"
                .customError=${this.formErrors.currencyId}
                .options=${this.currenciesOptions}
                clearable
              ></fix-select>
              <fix-checkbox class="business-checkbox" name="business" .checked=${this.formData.isCompany} @change=${this.toggleIsBusiness}>
                ${t("m341v9ze77b9i70i.it-is-business", "It's business")}
              </fix-checkbox>
              <fix-input
                name="vatNumber"
                type="text"
                placeholder="${t("ck63lp68yjro87x1.choose-your-vat", "Choose your VAT")}"
                .customError=${this.formErrors.vatNumber}
                clearable
              ></fix-input>
              <fix-input
                name="billingEmail"
                type="email"
                placeholder="${t("p5i7s4o042yezs8r.enter-billing-email", "Enter your billing email")}"
                .customError=${this.formErrors.billingEmail}
                clearable
              ></fix-input>
            </div>
            <div class="btns-container">
              <fix-button variant="default" type="${this.elementsType}" class="fill-later-btn" href="${Url.to("home-page-with-lang")}">
                ${t("1e7uepvw541v0i5s.fill-later", "Fill it later")}
              </fix-button>
              <fix-button @click=${this.submit} variant="primary" type="${this.elementsType}" class="signup-btn" .loading=${this.loading}>
                ${t("8fuhpq74oh37x30i.save", "Save")}
              </fix-button>
            </div>
          </form>
        </div>
      </div>
    `;
  }

  get countriesOptions() {
    const countries = this.customerContext.availableCountries;

    return countries.map(({ id, name }) => ({ id, name }));
  }

  get currenciesOptions() {
    return this.currencyService.currencies?.map(({ id, code }) => ({ id, name: code }));
  }

  get localeOptions() {
    return localeLanguages;
  }

  get elementsType() {
    return this.layout === "desktop" ? "md" : "sm";
  }

  renderContinueShoppingLink = () => {
    return html`
      <fix-link class="continue-shopping-link" href="${Url.to("home-page-with-lang")}" type=${this.elementsType}>
        <sl-icon src=${arrowOutlined}></sl-icon>
        ${t("k0q7l2w2q1856v4o.continue-shopping", "Continue shopping")}
      </fix-link>
    `;
  };

  toggleIsBusiness = () => {
    this.formData = {
      ...this.formData,
      isCompany: !this.formData.isCompany,
    };
  };

  submit = async () => {
    this.resetFormErrors();
    this.requestUpdate();

    const formData = new FormData(this.ref.value!);
    // @ts-ignore
    const form = this.ref.value;

    const countryId = formData.get("countryId") as string;
    const currencyId = formData.get("currencyId") as string;
    const locale = formData.get("locale") as string;
    const vatNumber = formData.get("vatNumber") as string;
    const billingEmail = formData.get("billingEmail") as string;
    const isCompany = formData.get("business") === "true";

    if (!form?.checkValidity()) {
      return;
    }

    this.loading = true;

    const updateResult = await this.customerContext.updateAboutYourself({
      countryId,
      currencyId,
      locale,
      vatNumber,
      billingEmail,
      isCompany,
    });

    this.loading = false;

    if (!updateResult.success) {
      // @ts-ignore
      this.formErrors = { ...updateResult?.errors };
      this.requestUpdate();
    }

    this.redirectToHomePage();
  };

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.updated(_changedProperties);
    if (this.customerContext.isLoaded && !this.customerContext.isCustomerAuth()) {
      this.redirectToHomePage();
    }
  }

  private resetFormErrors() {
    this.formErrors = {
      countryId: null,
      currencyId: null,
      locale: null,
      vatNumber: null,
      billingEmail: null,
    };
  }

  private redirectToHomePage() {
    Router.go(Url.to("home-page-with-lang"));
  }

  static styles = css`
    .about-yourself-page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 16px;
      gap: var(--space-margin-margin-lg, 24px);
      background: var(--Neutral-Bg-colorBgPages, #f1f5f9);
    }

    ${cssLayout("desktop")} .about-yourself-page-container {
      padding: 56px 0px;
    }

    .about-yourself-container {
      display: flex;
      padding: var(--space-padding-padding-xl, 32px) var(--space-padding-padding-md, 24px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      border-radius: var(--border-radius-border-radius-lg, 8px);
      background: var(--neutral-bg-color-bg-container, #ffffff);
    }

    ${cssLayout("desktop")} .about-yourself-container {
      width: 410px;
      align-self: center;
      padding: var(--space-padding-padding-xl, 32px);
    }

    .header {
      display: flex;
      align-self: stretch;
      padding: 0px var(--header-margin-xs, 8px);
      margin-top: var(--header-margin-xs, 24px);
    }

    .about-yourself-form {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
      align-self: stretch;
    }

    ${cssLayout("mobile")} .about-yourself-heading {
      ${font("Heading/3")};
    }

    ${cssLayout("desktop")} .about-yourself-heading {
      ${font("Heading/2")}
    }

    .inputs-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      align-self: stretch;
    }

    .save-btn {
      align-self: stretch;
    }

    .divider {
      display: flex;
      height: 1px;
      width: 100%;
      background: var(--neutral-border-color-border-secondary, #f0f0f0);
    }

    .continue-shopping-link sl-icon {
      margin-right: var(--Space-Padding-paddingXS, 8px);
    }

    .business-checkbox {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .btns-container {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }

    .btns-container fix-button {
      flex: 1;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "about-yourself-page": AboutYourselfPage;
  }
}
