import { customElement, property } from "lit/decorators.js";
import { css, html, LitElement } from "lit";
import { t } from "i18next";
import { Url } from "@/helpers/url.ts";
import cart from "@/assets/shopping-cart.svg";
import { Router } from "@vaadin/router";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { whenType } from "@/util/element";
import { choose } from "lit/directives/choose.js";
import "@/elements/mobile/dialog";

@customElement("customer-actions")
export class CustomerActions extends LitElement {
  @property()
  type!: "sm" | "lg";

  @consume({ context: customerChannelContext, subscribe: true })
  CustomerChannel!: CustomerChannel;

  renderSm = () => {
    return html`${this.renderCustomerButtons()}`;
  };

  renderLg = () => {
    return html`
      <div class="heading">${t("1ystg2f5ui8t2310.my-account", "My account")}</div>
      ${this.renderCustomerButtons()}
    `;
  };

  renderCustomerButtons() {
    const ordersLink = location.origin.replace("www.", "") + "/business-v2/orders";

    return html`
      <div class="buttons-container">
        <item-button .href=${ordersLink} .action=${"link-router-ignore"} .title=${"Orders"} .schema=${"column"} .icon=${cart}></item-button>
        <sl-button size="large" class="sign-out-btn" @click=${this.signOutAction}> Sign out </sl-button>
      </div>
    `;
  }

  async signOutAction() {
    await this.CustomerChannel.logout();
    Router.go(Url.to("home-page-with-lang"));
  }

  render() {
    return choose(this.type, [
      ["sm", this.renderSm],
      ["lg", this.renderLg],
    ]);
  }

  static stylesSm = css``;
  static stylesLg = css``;
  static stylesCommon = css`
    :host {
      --sl-panel-background-color: #fff;
      font-family: "SF Pro Text";
      --sl-shadow-x-large: 0 4px 12px 0 #00000026;
      --sl-border-radius-medium: 6px;
      --sl-spacing-large: 20px;
    }
    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      align-self: stretch;
      margin-top: 24px;
    }
    .sign-out-btn {
      --sl-input-border-width: 0px;
      --sl-spacing-medium: 0px;
      --sl-input-border-radius-large: 8px;
      --sl-color-neutral-0: #f4f4f5;
      --sl-color-neutral-700: #000;
      margin-top: 24px;
      min-width: 100%;
    }
    .customer-actions-divider {
      margin: 0;
    }
  `;

  static styles = [CustomerActions.stylesCommon, whenType("sm", CustomerActions.stylesSm), whenType("lg", CustomerActions.stylesLg)];
}
