import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { t } from "i18next";
import { localized } from "@/decorators/localized";
import { font } from "@/util/fonts.ts";

@customElement("product-mpn")
@localized()
export class ProductMpn extends LitElement {
  @property({ type: String })
  code = "";

  render() {
    return html`
      <div class="label">${t("zdfw6dq1f34n1u02.item-number", "MPN")}:</div>
      <div class="code">${this.code}</div>
    `;
  }

  static styles = css`
    :host {
      ${font("SM/Regular")};
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--colorText, rgba(0, 0, 0, 0.88));
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "product-mpn": ProductMpn;
  }
}
