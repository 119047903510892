import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "../fix-brand";
import { when } from "lit/directives/when.js";
import "./item-button";
import "../../../../elements/fix-language";
import "../../../../elements/fix-country";
import "../../../../elements/fix-currency";
import car from "@/assets/car.svg";
import account from "@/assets/account.svg";

import { map } from "lit/directives/map.js";
import { getMenuIconUrl, getTopMenuItems } from "./util";
import "./subcategories-screen";
import { layoutFragment, LayoutSmContainer, layoutSmCss } from "./layout-sm";
import "./layout-sm";
import { features } from "@/constants/features.ts";
import { MenuItem } from "@/services/categories";
import "@/pages/product-page/extracted/menu/customer-actions.ts";
import { consume } from "@lit/context";
import { CustomerChannel, customerChannelContext } from "@/services/customer-channel";
import { t } from "i18next";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url.ts";

@customElement("main-screen")
export class MainScreen extends LitElement {
  @property()
  items!: MenuItem[];

  @state()
  dialogAccount = false;

  @consume({ context: customerChannelContext, subscribe: true })
  customerChannel!: CustomerChannel;

  get logged() {
    return true;
  }

  get label() {
    return this.customerChannel?.isCustomerAuth() ? this.customerChannel.customer?.email : t("7e19h22khwn365zm.sign-in", "Sign in");
  }

  onAccountClick = () => {
    this.customerChannel?.isCustomerAuth() ? (this.dialogAccount = true) : Router.go(Url.to("login-page"));
  };

  onCategoryClick = (key: string) => {
    this.dispatchEvent(new CustomEvent("toCategory", { detail: { key } }));
  };

  render = () => {
    const content = html`
      ${layoutFragment((layout) => layout.renderHeadMain())} ${when(this.logged, this.renderAccount)} ${layoutFragment((layout) => layout.renderDelimiter())}
      ${when(true, this.renderCategories)} ${layoutFragment((layout) => layout.renderDelimiter())} ${when(true, this.renderSettings)}
    `;

    return html`<layout-sm-container .content=${content}> </layout-sm-container>`;
  };

  renderAccount = () => {
    const items = html`
      ${when(
        this.dialogAccount,
        () => html`
          <dialog-mobile .label=${"My account"} @dialogClose=${() => (this.dialogAccount = false)}>
            <customer-actions .type=${"sm"}></customer-actions>
          </dialog-mobile>
        `,
      )}
      <item-button @click=${this.onAccountClick} .title=${"My account"} .sub=${this.label} .schema=${"column"} .icon=${account}></item-button>
      ${when(
        features.garage,
        () => html`<item-button .title=${"My garage"} .sub=${"2015 Ford Focus"} .count=${1} .schema=${"column"} .icon=${car}></item-button>`,
      )}
    `;

    return layoutFragment((layout) => {
      return layout.renderSection({
        segment: "account",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      });
    });
  };

  renderCategories = () => {
    const items = map(this.items, (item) => {
      return html`<item-button .title=${item.name} .icon=${item.icon} @click=${() => this.onCategoryClick(item.id)}></item-button>`;
    });

    return layoutFragment((layout) => {
      return layout.renderSection({
        segment: "categories",
        title: "Categories",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      });
    });
  };

  renderSettings = () => {
    const items = html`
      <fix-country></fix-country>
      <fix-currency></fix-currency>
      <fix-language></fix-language>
    `;

    return layoutFragment((layout) => {
      return layout.renderSection({
        segment: "settings",
        title: "Settings",
        content: () => {
          return html`<div class="items">${items}</div>`;
        },
      });
    });
  };

  static styles = css`
    ${layoutSmCss}

    .head + * {
      margin-top: 4px;
    }

    .categories {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .settings {
      padding-top: 24px;
    }
  `;
}
