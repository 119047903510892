import { customElement, state } from "lit/decorators.js";
import { html, LitElement } from "lit";
import { supportedLanguages } from "@/constants/languages.ts";
import { LayoutParameters, LayoutParametersContext } from "@/pages/product-page/cont.ts";
import { consume } from "@lit/context";
import "./fix-dropdown/fix-dropdown";
import { when } from "lit/directives/when.js";
import "@/elements/mobile/dialog";
import { Parameters, ParametersContext } from "@/services/parameters";

@customElement("fix-language")
export class FixLanguage extends LitElement {
  @consume({ context: LayoutParametersContext, subscribe: true })
  layoutParameters!: LayoutParameters;

  @consume({ context: ParametersContext, subscribe: true })
  public parameters!: Parameters;

  @state()
  dialogOpen = false;

  get options() {
    return supportedLanguages.map((item) => ({
      label: item.name,
      value: item.code,
      icon: html`<sl-icon library="${item.iconLibrary}" name="${item.icon}"></sl-icon>`,
      selected: item.code === this.selectedLanguage,
    }));
  }

  get currentLanguage() {
    return supportedLanguages.find((item) => item.code === this.selectedLanguage);
  }

  get selectedLanguage() {
    return this.parameters.getVisibleLanguage();
  }

  onLanguageChange = (lang: string) => {
    this.parameters.changeParameter("language", lang);
    this.parameters.applyParameters();
  };

  get defaultLayout() {
    return html`<fix-dropdown .isSearchable=${false} .items=${this.options} .onSelect=${(value: string) => this.onLanguageChange(value)} /></fix-dropdown>`;
  }

  get mobileLayout() {
    return html`
      <item-button
        .title=${"Language"}
        .sub=${this.currentLanguage?.code.toUpperCase()}
        .schema=${"line"}
        @click=${() => (this.dialogOpen = true)}
      ></item-button>
      ${when(
      this.dialogOpen,
      () => html`
          <dialog-mobile .label=${"Language"} @dialogClose=${() => (this.dialogOpen = false)}>
            <fix-dialog-selector
              .items=${this.options ?? []}
              .onSelect=${(value: string) => {
          this.onLanguageChange(value);
          this.dialogOpen = false;
        }}
            ></fix-dialog-selector>
          </dialog-mobile>
        `,
    )}
    `;
  }

  render() {
    return this.layoutParameters.mode === "mobile" ? this.mobileLayout : this.defaultLayout;
  }
}
