import { t } from "i18next";
import { LitElement, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import searchSvg from "@/assets/search.svg";
import { Router } from "@vaadin/router";
import { Url } from "@/helpers/url";
import { localized } from "@/decorators/localized";

@customElement("fix-search")
@localized()
export class FixSearch extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @state()
  searchValue = "";

  constructor() {
    super();
    this.searchValue = Url.getQueryParam("q", window.location.search) ?? "";
  }

  render() {
    return html`
      <sl-input
        class="search"
        name="mpn-search"
        placeholder="${t("kmaqihq7zxarr34z.search-placeholder", "Search for part number.")}"
        size="medium"
        value=${this.searchValue}
        @sl-change=${this.handleSearchChange}
        autocomplete="off"
      >
        <sl-icon class="icon" slot=${this.iconSlot} src=${searchSvg}></sl-icon>
      </sl-input>
    `;
  }

  handleSearchChange(event: CustomEvent) {
    const searchKey = (event.target as HTMLInputElement).value;
    Router.go(
      Url.to("search-page", {},`?q=${encodeURIComponent(searchKey)}` )
    );
  }

  get iconSlot() {
    switch (this.type) {
      case "lg":
        return "suffix";
      case "sm":
        return "prefix";
    }
  }

  static styles = css`
    :host {
      --sl-input-height-medium: 46px;
      --sl-input-border-radius-medium: 8px;
      --sl-input-border-color: var(--Brand-Primary-colorPrimary, #3b82f6);
      --sl-input-color: var(--Neutral-Text-colorText, #27272a);
      --sl-input-placeholder-color: var(--Components-Input-Global-colorTextPlaceholder, #a3a3a3);
      --sl-input-spacing-medium: 15px;
      --sl-input-icon-color: var(--Neutral-Text-colorText, #27272a);
    }

    ::part(input),
    ::part(input)::placeholder {
      font-family: "SF Pro Text";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    ::part(prefix),
    ::part(suffix) {
      font-size: 24px;
    }
  `;
}
