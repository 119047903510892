import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { repeat } from "lit/directives/repeat.js";
import "@/elements/fix-pager-arrow";
import "@/elements/fix-pager-number";

@customElement("fix-pager")
export class FixPager extends LitElement {
  @property()
  current!: number;

  @property()
  total!: number;

  visible = 5;

  render() {
    const pages = this.calcPager(this.current, this.total, this.visible);
    const prevDisabled = this.current <= 1;
    const nextDisabled = this.current >= this.total;

    return html`
      <div class="container">
        <fix-pager-arrow class="step" .type=${"prev"} .disabled=${prevDisabled} @click=${this.onPrev}></fix-pager-arrow>
        <div class="numbers">
          ${repeat(
            pages,
            (item) => item,
            (item) => this.renderPageNumber(item),
          )}
        </div>
        <fix-pager-arrow class="step" .type=${"next"} .disabled=${nextDisabled} @click=${this.onNext}></fix-pager-arrow>
      </div>
    `;
  }

  renderPageNumber(number: number) {
    const classNames = classMap({
      ["number"]: true,
      ["active"]: number === this.current,
    });

    return html` <fix-pager-number class=${classNames} .active=${number === this.current} @click=${() => this.onPage(number)}> ${number} </fix-pager-number> `;
  }

  calcPager(current: number, total: number, size: number) {
    const { left, right } = this.calcLeftRight(current, total, size);
    const from = current - left;
    const to = current + right;
    const result = [];

    for (let i = from; i <= to; i++) {
      result.push(i);
    }
    return result;
  }

  calcLeftRight(current: number, total: number, size: number) {
    const before = Math.max(current - 1, 0);
    const after = Math.min(total - current, total);
    const all = size - 1;
    const half = Math.floor(all / 2);
    let left, right: number;
    left = Math.min(before, half);
    right = Math.min(after, all - left);
    left = Math.min(left, all - right);

    return { left, right };
  }

  onPrev = () => {
    if (this.current > 1) {
      this.onPage(this.current - 1);
    }
  };

  onNext = () => {
    if (this.current < this.total) {
      this.onPage(this.current + 1);
    }
  };

  onPage = (page: number) => {
    this.dispatchEvent(new CustomEvent("pager.page", { detail: { page } }));
  };

  static styles = css`
    .container,
    .numbers {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  `;
}
