import { LitElement, css, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import cart from "@/assets/shopping-cart.svg";
import { classMap } from "lit/directives/class-map.js";

@customElement("fix-icon-indicator")
export class FixIconIndicator extends LitElement {
  @property({ type: Number })
  count = 0;

  render() {
    const indicator = this.indicatorVisible ? this.indicator : nothing;

    return html` ${this.icon}${indicator}`;
  }

  get icon() {
    return html`<sl-icon class="icon" src=${cart}></sl-icon>`;
  }

  get indicator() {
    return html`<div class=${classMap({ indicator: true, [this.indicatorSize]: true })}>${this.count}</div>`;
  }

  get indicatorVisible() {
    return typeof this.count === "number";
  }

  get indicatorSize() {
    const numbers = (this.count ?? 0).toString().split("").length;
    switch (numbers) {
      case 0:
      case 1:
        return "size1";
      case 2:
        return "size2";
      case 3:
      default:
        return "size3";
    }
  }

  static styles = css`
    :host {
      display: flex;
    }

    .icon,
    .indicator {
      width: var(--Size-Base-sizeMD, 20px);
      height: var(--Size-Base-sizeMD, 20px);
    }

    .indicator {
      background: var(--Brand-Primary-colorPrimary, #3b82f6);
      color: var(--Neutral-colorWhite, #fff);
      font-family: "SF Pro Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      user-select: none;
      margin-left: -5.5px;
    }

    .indicator.size1 {
      font-size: 12px;
    }

    .indicator.size2 {
      font-size: 10px;
    }

    .indicator.size3 {
      font-size: 7px;
    }
  `;
}
