import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { font } from "@/util/fonts";

@customElement("fix-link")
export class FixLink extends LitElement {
  @property()
  href = "";

  @property({ attribute: true })
  type!: "sm" | "md";

  render() {
    return html`<a href=${this.href}>
      <span class="label"><slot></slot></span>
    </a>`;
  }

  static styles = css`
    :host([type="sm"]) a {
      color: var(--components-typography-global-color-link, #3b82f6);
      text-decoration: none;
    }

    :host([type="md"]) a {
      color: var(--components-typography-global-color-text, #27272a);
    }

    .label {
      display: flex;
      align-items: center;
      align-self: stretch;
    }
    a:hover,
    a:visited,
    a:link,
    a:active {
      text-decoration: none;
    }

    :host([type="md"]) .label {
      ${font("Base/Normal")};
    }

    :host([type="sm"]) .label {
      ${font("SM/Normal")};
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-link": FixLink;
  }
}
