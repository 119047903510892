import type { Api } from "@/api";
import { type B2cBrand, CarBrandsListDocument } from "@/generated/graphql/b2c";
import { LitElement } from "lit";

export class Brands {
    brands = new Map<string, B2cBrand>();

    constructor(protected host: LitElement, protected api: Api) {

    }

    async load() {
        const categoriesResponse = await this.api.client.query({
            query: CarBrandsListDocument,
            variables: {},
        });

        this.brands.clear();
        for (const brand of categoriesResponse.data.CarBrandsList) {
            this.brands.set(brand.id, brand as B2cBrand);
        }
    }
    
    clear() {
        this.brands.clear();
    }

    get list() {
        return Array.from(this.brands.values());
    }
}