import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import starSvg from "@/assets/star.svg";

@customElement("fix-rating")
export class FixRating extends LitElement {
  @property({ type: Number })
  value = 0;

  max = 5;

  getSymbolIcon = () => {
    return `<sl-icon src="${starSvg}"></sl-icon>`;
  };

  render() {
    return html` <sl-rating value=${this.value} max=${this.max} .getSymbol=${this.getSymbolIcon}></sl-rating> `;
  }

  static styles = css`
    sl-rating {
      --symbol-spacing: 0px;
      --symbol-color-active: #fb923c;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-rating": FixRating;
  }
}
