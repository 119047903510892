import { customElement, property } from "lit/decorators.js";
import { html } from "lit";
import { ProductButton } from "../add-to-cart/product-button";
import { localized } from "@/decorators/localized";
import { t } from "i18next";

@customElement("view-details")
@localized()
export class ViewDetails extends ProductButton {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  @property({ type: Boolean })
  disabled = false;

  render() {
    return html` <sl-button .disabled=${this.disabled}> ${this.getText()} </sl-button> `;
  }

  getText() {
    return t(`z1fgc55qbh3jc0fr.view-details`, "View details");
  }
}
