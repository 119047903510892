import { adoptStyles, css, CSSResult, LitElement, PropertyValues, ReactiveElement, unsafeCSS } from "lit";
import { state } from "lit/decorators.js";
import { Debouncer } from "./schedule";

export const typeregexp = /@when\[type=\"([a-zA-Z]+)\"\]/;
export const findCssResultsForType = (results: CSSResult[], type: string) => {
  return results.filter((item) => {
    const text = item.cssText;
    const matched = text.match(typeregexp);

    return !matched || matched[1] === type;
  });
};

export const whenType = (type: string, styles?: CSSResult) => {
  const annotation = unsafeCSS(`/** @when[type="${type}"] */`);

  return styles
    ? css`
        ${annotation} ${styles}
      `
    : annotation;
};

export abstract class MultiTypeElement extends LitElement {
  abstract type: string;

  protected createRenderRoot() {
    const renderRoot = this.shadowRoot ?? this.attachShadow((this.constructor as typeof ReactiveElement).shadowRootOptions);

    this.adoptStyles();

    return renderRoot;
  }

  protected findStylesForType(type: string) {
    const constructor = this.constructor as typeof ReactiveElement;
    const styles = constructor.elementStyles as CSSResult[];

    return findCssResultsForType(styles, type);
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // don't adopt styles if previous type is undefined, because it will be adopted in the createRenderRoot
    if (changedProperties.get("type")) {
      this.adoptStyles();
    }
  }

  protected adoptStyles() {
    adoptStyles(this.shadowRoot!, this.findStylesForType(this.type));
  }
}

export abstract class PageElement extends MultiTypeElement {
  debouncer = new Debouncer(25, true);

  @state()
  type!: string;

  constructor() {
    super();
    this.updateLayoutType();
  }

  connectedCallback(): void {
    super.connectedCallback();
    window.addEventListener("resize", this.updateLayoutType);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener("resize", this.updateLayoutType);
  }

  updateLayoutType = () => {
    this.debouncer.call(() => {
      const width = window.innerWidth;

      this.type = this.calcLayoutType(width);
    });
  };

  calcLayoutType = (width: number) => {
    return width >= 1024 ? "lg" : "sm";
  };
}
