import { ContextProvider, consume, createContext } from "@lit/context";
import { LitElement, css } from "lit";
import { property, state } from "lit/decorators.js";

export const desktop = css`
  :host([layout="desktop"]);
`;
export const mobile = css`
  :host([layout="mobile"]);
`;

export const layout = function () {
  return function <T extends typeof LitElement>(Target: T) {
    //@ts-ignore
    class Wrapped extends Target {
      @consume({ context: LayoutParametersContext, subscribe: true })
      layoutParameters!: LayoutParameters;

      @property({ type: String, reflect: true })
      @state()
      layout?: string;

      willUpdate() {
        this.layout = this.layoutParameters.mode;
      }
    }

    return Wrapped as T;
  };
};

export const LayoutParametersContext = createContext<LayoutParameters>("LayoutParameters");

export class LayoutParameters {
  provider: ContextProvider<{ __context__: LayoutParameters }>;
  width!: number;

  constructor(protected host: LitElement) {
    this.provider = new ContextProvider(host, { context: LayoutParametersContext, initialValue: this });
    this.host.addController(this);
  }

  hostConnected() {
    window.addEventListener("resize", this.measure);
    this.measure();
  }

  hostDisconnected() {
    window.removeEventListener("resize", this.measure);
    this.provider.clearCallbacks();
  }

  protected measure = () => {
    this.width = window.innerWidth;
    this.provider.setValue(this, true);
  };

  get mode() {
    return this.width <= 768 ? ("mobile" as const) : ("desktop" as const);
  }
}
