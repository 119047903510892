export class PagerForm {
  page = 1;
  total = 1;
  pageSize = 20;
  totalItems = 0;

  constructor(protected host: HTMLElement) {}

  isBlank(field: string) {
    switch (field) {
      case "page":
        return this.page === 1;
      default:
        return false;
    }
  }

  selectPage(page: number) {
    this.page = page;
    this.updated();
  }

  selectPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.updated();
  }

  next() {
    this.page++;
    this.updated();
  }

  prev() {
    if (this.page > 1) {
      this.page--;
      this.updated();
    }
  }

  updated() {
    this.host.dispatchEvent(new CustomEvent("pager.updated"));
  }
}
