import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import menuSvg from "@/assets/menu.svg";
import store from "@/assets/footer/store.svg";
import { t } from "i18next";
import { type } from "@/util/layout";
import { font } from "@/util/fonts";
import { when } from "lit/directives/when.js";

@customElement("store-header")
export class StoreHeader extends LitElement {
  @property({ type: String, reflect: true })
  type: "sm" | "lg" = "sm";

  render() {
    return html`
      <div class="container">
        ${when(this.isSm, () => html`<sl-icon-button class="menu" src=${menuSvg}> </sl-icon-button>`)}
        <slot name="left-block"></slot>
        <div class="title">
          <fix-brand type="lg"></fix-brand>
        </div>
        ${when(this.isSm, () => html`<div class="empty"></div>`)}
      </div>
    `;
  }

  get isSm() {
    return this.type === "sm";
  }

  static styles = css`
    .container {
      display: flex;
      height: var(--header-control-height-lg, 48px);
      padding: 0px var(--header-margin-xs, 8px);
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-bottom: 1px solid var(--neutral-border-color-border, #d9d9d9);
      background: var(--header-color-bg-container, #ffffff);
    }
    ${type("lg")} .container {
      padding: var(--space-padding-padding, 16px) var(--space-margin-margin-xxl, 48px);
      justify-content: center;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 4.351px;
      display: flex;
      padding: 4px 3px 5px 3px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      text-align: center;
    }
    .label {
      color: var(--Neutral-colorTextBase, #000);
    }

    ${type("sm")} .label {
      ${font("Logo/2")}
    }

    ${type("lg")} .label {
      ${font("Logo/1")}
    }

    ${type("lg")} .store-icon {
      width: 32px;
      height: 32px;
    }

    .empty {
      display: flex;
      width: var(--size-base-size-lg, 24px);
      height: var(--size-base-size-lg, 24px);
      padding: 4px 3px 5px 3px;
      justify-content: center;
      align-items: center;
    }

    ::slotted([slot="left-block"]) {
      position: absolute;
      left: var(--space-margin-margin-xxl, 48px);
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "cart-header": StoreHeader;
  }
}
