import { customElement, property, state } from "lit/decorators.js";
import { PageElement, whenType } from "@/util/element.ts";
import { css, html } from "lit";
import { when } from "lit/directives/when.js";
import { StaticPages, StaticPagesContext, StaticPagesType } from "@/context/static-pages.ts";
import { consume } from "@lit/context";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { choose } from "lit/directives/choose.js";
import { font } from "@/util/fonts.ts";
import { cardDefault, layoutOffsetPadding } from "@/pages/product-page/utils.ts";
import { classMap } from "lit/directives/class-map.js";
import { RouterLocation } from "@vaadin/router";
import { Url } from '@/helpers/url';

@customElement("help-page")
export class HelpPage extends PageElement {
  declare type: "sm" | "lg";

  @property()
  location!: RouterLocation;

  @state()
  loading = true;

  @state()
  activeSection = 0;

  @consume({ context: StaticPagesContext, subscribe: true })
  @property({ attribute: true })
  public staticPages!: StaticPages;

  sections: HTMLCollectionOf<Element> | undefined;

  render() {
    return html` ${this.renderContent()} ${when(this.loading, () => this.renderLoaderOverlay())} `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.load();
  }

  async load() {
    await this.staticPages.fetchStaticPage(StaticPagesType.help);
    this.loading = false;
  }

  renderContent() {
    const parser = new DOMParser();
    const dom = parser.parseFromString(this.staticPages.getStaticPage(StaticPagesType.help), "text/html");
    if (this.location.hash) {
      const currentTab = this.location.hash.replace("#", "");
      this.activeSection = this.prepareMenuLabels(dom).findIndex((item) => {
        return this.trimSpecialChars(item!) === currentTab;
      });
    }

    this.prepareSections(dom);

    return choose(this.type, [
      ["sm", () => this.renderContentSm(dom)],
      ["lg", () => this.renderContentLg(dom)],
    ]);
  }

  prepareSections = (dom: Document) => {
    this.sections = dom.getElementsByClassName("static-page-option");
  };

  renderContentSm = (dom: Document) => {
    return html`
      <catalog-layout type=${this.type}>
        <div class="content">${this.renderSectionMenu(dom)} ${this.sections?.[this.activeSection]}</div>
      </catalog-layout>
    `;
  };

  renderContentLg = (dom: Document) => {
    return html`
      <catalog-layout type=${this.type}>
        <div class="topcard">
          <div class="row-navigation">
            <fix-breadcrumb></fix-breadcrumb>
          </div>
          <div class="row-title">
            <div class="title">Help</div>
          </div>
        </div>
        <div class="content">${this.renderSectionMenu(dom)} ${this.sections?.[this.activeSection]}</div>
      </catalog-layout>
    `;
  };

  prepareMenuLabels(dom: Document) {
    return Array.from(dom.getElementsByClassName("static-page-header")).map((element) => element.textContent);
  }

  trimSpecialChars(str: string) {
    return str.trim().toLowerCase().replaceAll(" ", "-");
  }

  prepareMenuUrl(label: string | undefined | null) {
    if (!label) {
      return "#";
    }

    return Url.to("help-page-with-section", { section: this.trimSpecialChars(label) });
  }

  renderSectionMenu = (dom: Document) => {
    const menuSections = html`${this.prepareMenuLabels(dom).map((label, key) => {
      if (!label) {
        return html``;
      }

      const classNames = classMap({
        "menu-section": true,
        active: key === this.activeSection,
      });

      return html`<div class=${classNames}>
        <a href="${this.prepareMenuUrl(label)}"><div class="menu-section-label">${label}</div></a>
      </div>`;
    })}`;

    return html` <div class="menu">${menuSections}</div> `;
  };

  renderLoader() {
    return html`<fix-loader></fix-loader>`;
  }

  renderLoaderOverlay() {
    return html` <div class="overlay">${this.renderLoader()}</div> `;
  }

  static stylesCommon = css`
    .static-page-header {
      ${font("Heading/2")}
    }

    .menu-section {
      width: 100%;
      padding: 8px 0;
      cursor: pointer;
    }

    .menu-section a {
      color: #000;
      text-decoration: unset;
    }

    .menu-section.active .menu-section-label {
      background: #f3f4f6;
      border-radius: 8px;
    }

    .menu-section:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }

    .content {
      background: #fff;
    }

    .section {
      ${font("Base/Normal")}
      margin-top: 32px;
    }

    .ul-dashed {
      margin: 0;
      padding: 0;
    }
  `;

  static stylesSm = css`
    .section {
      margin-top: 24px;
    }
    .content {
      padding: 8px 0 8px;
    }
    .static-page-header {
      margin-bottom: 16px;
    }

    .static-page-header {
      margin-top: 32px;
    }

    .menu-section {
      padding: 4px 0;
    }

    .menu {
      padding: 4px 8px;
      margin: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
    }

    .menu-section-label {
      padding: 4px 8px;
    }

    .menu {
      ${font("SM/Strong")}
    }

    .static-page-option {
      padding: 0 8px;
    }
    .block-with-icon img {
      padding: 16px;
      border: 1px #d9d9d9 solid;
      border-radius: 16px;
      margin-right: 16px;
      height: 24px;
    }

    .block-with-icon .block-title {
      ${font("Heading/5")};
    }
    .block-with-icon {
      display: flex;
      margin-bottom: 32px;
      align-items: flex-start;
    }

    .block-with-icon.icon-top {
      align-items: center;
    }

    .ul-dashed li::before {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: #60a5fa;
      position: absolute;
      left: 0;
      top: 5px;
    }
    .ul-dashed span:not(.li-title),
    p {
      display: block;
      margin: 4px 0 0;
    }

    .ul-dashed li {
      list-style-type: none;
      position: relative;
      padding-left: 28px;
      margin-top: 16px;
    }
    .section-title-lg {
      ${font("Heading/3")}
      margin-bottom: 16px;
    }
    .section-title {
      ${font("Heading/5")}
      margin-bottom: 16px;
      margin-top: 16px;
    }
  `;

  static stylesLg = css`
    .section {
      margin-top: 32px;
    }

    .menu {
      ${font("LG/Strong")}
    }

    .block-with-icon {
      display: flex;
      margin-bottom: 32px;
      align-items: flex-start;
    }

    .block-with-icon.icon-top {
      align-items: center;
    }

    .section-title {
      margin-top: 32px;
      margin-bottom: 18px;
    }

    .menu-section {
      padding: 8px 0;
    }

    .block-with-icon img {
      padding: 16px;
      border: 1px #d9d9d9 solid;
      border-radius: 16px;
      margin-right: 16px;
      height: 24px;
    }

    .block-with-icon .block-title {
      ${font("Heading/5")};
    }

    .topcard .title {
      ${font("Heading/2")};
    }

    .static-page-header {
      margin-bottom: 20px;
    }

    .menu {
      margin-right: 48px;
    }

    .menu-section-label {
      padding: 8px 16px;
      width: max-content;
    }

    .section-title-lg {
      ${font("Heading/3")}
      margin-bottom: 16px;
    }

    .ul-dashed li {
      list-style-type: none;
      position: relative;
      padding-left: 28px;
      margin-top: 16px;
    }

    .ul-dashed li::before {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: #60a5fa;
      position: absolute;
      left: 0;
      top: 5px;
    }

    .section-title {
      ${font("Heading/4")}
    }
    .li-title {
      ${font("Heading/5")}
      display: inline-block;
    }
    .topcard {
      ${cardDefault()};
      ${layoutOffsetPadding()};
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .content {
      margin: 32px 48px;
      padding: 32px;
      border-radius: 6px;
      display: flex;
    }

    .section-divider {
      background: #f0f0f0;
      height: 1px;
      margin: 32px 0px;
    }

    .ul-dashed span:not(.li-title),
    p {
      display: block;
      margin: 4px 0 0;
    }
  `;

  static get styles() {
    return [this.stylesCommon, whenType("sm", this.stylesSm), whenType("lg", this.stylesLg)];
  }
}
