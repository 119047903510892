import { HttpBackendOptions } from "i18next-http-backend";
import { InitOptions } from "i18next/typescript/options";

interface CustomInitOptions extends InitOptions {
  backend?: HttpBackendOptions;
}

let base = import.meta.env.BASE_URL ?? "";
if (!base.endsWith("/")) {
  base += "/";
}
const path = "translations/{{lng}}.json";

const i18nOptions: CustomInitOptions = {
  backend: {
    loadPath: `${base}${path}`,
  },
  debug: import.meta.env.DEV,
  lng: "en",
  fallbackLng: "en",
};

export default i18nOptions;
