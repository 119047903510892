import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("fix-heading")
export class FixHeading extends LitElement {
  @property({ type: String })
  text = "";

  render() {
    return html` <div>${this.text}</div> `;
  }

  static styles = css`
    :host {
      --color-text-heading: #111827;

      color: var(--color-text-heading, #111827);
      text-align: left;

      font-family: "SF Pro Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    "fix-heading": FixHeading;
  }
}
