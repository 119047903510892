import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonCustomize, buttonFont } from "../mobile/fix-button";

@customElement("fix-button-primary")
export class FixButtonPrimary extends LitElement {
  render() {
    return html`<sl-button><slot></slot></sl-button>`;
  }

  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "Base/Normal")}

        sl-button {
      --border-radius: 6px;
      --width: 100%;
      --height: 40px;
      width: 100%;
    }

    sl-button:not(.active) {
      --color: var(--Components-Button-Component-primaryColor, #FFF);
      --color-bg: var(--Components-Button-Global-colorPrimary, #3B82F6);
      --color-border: var(--Components-Button-Global-colorPrimary, #3B82F6);
      --color-hover: var(--Components-Button-Component-primaryColor, #FFF);
      --color-bg-hover: var(--Components-Button-Global-colorPrimaryHover, #60A5FA);
      --color-border-hover: var(--Components-Button-Global-colorPrimaryHover, #60A5FA);
      --color-active: var(--Components-Button-Component-defaultActiveBorderColor, #2563eb);
      --color-bg-active: var(--Components-Button-Global-colorPrimaryActive, #2563EB);;
      --color-border-active: var(--Components-Button-Global-colorPrimaryActive, #2563EB);;
    }
  `;
}
