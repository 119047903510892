import { html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import "@/elements/mobile/fix-heading";
import "@/elements/mobile/fix-loader";
import "@/elements/product-grid";
import "@/elements/fix-tag";
import "@/elements/fix-filter-dropdown";

import { consume } from "@lit/context";
import { Url } from "@/helpers/url";
import { Router, RouterLocation } from "@vaadin/router";
import { Breadcrumbs, BreadcrumbsContext } from "@/services/breadcrumbs";
import { CarBrandsListDocument, B2cBrand, BrandDocument, CategoriesByBrandDocument, B2cCategory } from "@/generated/graphql/b2c";
import { ServiceLocatorContext, Services } from "@/services/services";
import { provideLayoutStyles } from "../product-page/utils";
import { when } from "lit/directives/when.js";
import "../category-page/categories-navigator";
import "@/pages/home-page/extracted/featured-brands-list";
import "@/elements/fix-back-button";
import "@/elements/fix-pager-group";
import { choose } from "lit/directives/choose.js";
import { ProductAreaStore } from "../category-page/product-area-catalog/product-area-store";
import { ProductLoader } from "../category-page/product-loader";
import { PageElement, whenType } from "@/util/element";
import "../category-page/product-area-catalog/product-area-element";
import "@/elements/brands-list-images";
import "@/elements/brands-list-links";
import { t } from "i18next";
import { GlobalElement } from "../category-page/product-area-catalog/product-area-filter-popup";
import type { BeforeLeaveObserver, AfterEnterObserver, PreventCommands } from "@vaadin/router";
import "../category-page/loader-overlay";
import { ParametersValue } from "@/services/parameters";

@customElement("search-page")
export class SearchPage extends PageElement implements BeforeLeaveObserver, AfterEnterObserver {
  declare type: "sm" | "lg";

  @property()
  location!: RouterLocation;

  @state()
  view: {

  } = {};

  @state()
  category: { name?: string; id?: string } = {};

  @state()
  loading = true;

  @state()
  blank = true;

  @state()
  resultState: null | "empty" | "content" = null;

  @consume({ context: BreadcrumbsContext, subscribe: true })
  public breadcrumbs!: Breadcrumbs;

  @consume({ context: ServiceLocatorContext, subscribe: true })
  public services!: Services;

  brandId!: string;

  loader!: ProductLoader;

  store!: ProductAreaStore;

  @state()
  brand!: B2cBrand

  @state()
  categories!: B2cCategory[]

  @state()
  brands: B2cBrand[] = []

  @state()
  serchQuery: string = ""

  loadedFor!: ParametersValue

  render() {
    if (this.blank) {
      return this.renderLoaderOverlay();
    }

    return html` ${this.renderContent()} ${when(this.loading, () => this.renderLoaderOverlay())} `;
  }

  renderContent() {
    return choose(this.type, [
      ["sm", this.renderContentSm],
      ["lg", this.renderContentLg],
    ]);
  }

  renderContentSm = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardSm()}
        ${this.renderProductArea()}
      </catalog-layout>
    `;
  };

  renderContentLg = () => {
    return html`
      <catalog-layout type=${this.type}>
        ${this.renderTopcardLg()}
        ${this.renderProductArea()}
      </catalog-layout>
    `;
  };

  renderTopcardSm = () => {
    return html`
      <div class="section normargin card type-card-top">
        <div class="row navigation">
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="title">${this.category.name}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
      </div>
    `;
  }

  renderTopcardLg = () => {
    const back = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 2];

    return html`
      <div class="section normargin card type-card-top type-card-top-1">
        <div class="row navigation">
          ${when(back, () => html`<fix-back-button class="back" .url=${back.url} .mode=${'link'}></fix-back-button>`)}
          <fix-breadcrumb class="breadcrumbs"></fix-breadcrumb>
        </div>
        <div class="row primary">
          <div class="titles">
            <div class="title">${t("j8cinnz091w154tp.search-results", "Search results")} </div>
            <div class="title-for"> ${t("43ook0siipp3ufhv.for-name", "for {{name}}", {name: this.serchQuery})}</div>
          </div>
          <div class="results">${t("j8cinnz091w154tp.results", "results")}</div>
        </div>
        <div>
          ${this.renderNavigator()}
        </div>
      </div>
    `;
  }

  renderLoaderOverlay() {
    return html` <loader-overlay></loader-overlay> `;
  }

  renderNavigator() {
    return null;
    // return html`
    //   <categories-navigator
    //     .type=${this.type}
    //     .tabsRoot=${this.view.categoryTabs?.parentId}
    //     .tabsActive=${this.view.categoryTabs?.activeId}
    //     .cardsRoot=${this.view.categoryCards?.parentId}
    //   ></categories-navigator>
    // `;
  }

  renderProductArea() {
    return html`<product-area-element
      .type=${this.type}
      .store=${this.store}
      .services=${this.services}
      .resultState=${this.resultState}
    ></product-area-element>`;
  }

  async navigate(params: { params?: URLSearchParams, replace?: boolean }) {
    const search = params.params ?? new URLSearchParams();

    const path = [] as string[];
    this.overwriteLocation({ path, search });
    this.handleLocation({ path, search });
  }

  task: null | string = null;

  onAfterEnter(location: RouterLocation) {
    this.handleLocation({
      path: location.params.dynamicPath as string[],
      search: new URLSearchParams(location.search)
    });
    this.loadedFor = this.services.parameters.parameters;
  }

  onBeforeLeave(location: RouterLocation, commands: PreventCommands) {
    const paramsEqual = this.services.parameters.equalsParameters(
      this.services.parameters.parameters,
      this.loadedFor
    );

    if (location.route?.name === 'search-page' && paramsEqual) {
      // locking navigation for component reload prevent
      // overwrtie location async, because commands.prevent will reset any url changes
      const params = {
        path: location.params.dynamicPath as string[],
        search: new URLSearchParams(location.search)
      };
      setTimeout(() => {
        this.overwriteLocation(params);
        this.handleLocation(params);
      });

      return commands.prevent();
    }
  }

  async overwriteLocation(params: { path: string[], search: URLSearchParams }) {
    const url = Url.to("search-page", {}, params.search.toString());
    const replace = true;
    const mode = replace ? "replaceState" : "pushState";

    window.history[mode](null, "", url);
  }

  async handleLocation(params: { path: string[], search: URLSearchParams }) {
    const { path, search } = params;

    this.serchQuery = search.get("q") ?? "";
    this.store.readParameters(search);

    if (this.blank) {
      this.updateState('blank');
    } else if (true) {
      this.updateState('selections');
    } else {
      this.updateState('brand');
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.loader = new ProductLoader(this.services);
    this.store = new ProductAreaStore();
    this.prepare();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.store.events.removeEventListeners(this);
    this.store.disconnect(this);
  }

  async prepare() {
    this.store.connect(this);
    this.addEventListener("productarea.update", () => this.emitStoreStateChanges());
    this.addEventListener("productarea.back", () => this.backFromSerch());
    this.requestUpdate();
  }

  async updateState(step: "blank" | "brand" | "selections") {
    this.setLoading(true);

    if (step === "blank") {
      await this.initialFetch();
      this.blank = false;
    }

    if (step === "blank" || step === "brand") {
      await this.configureLayout();
      await this.updateBreadcrumbs();
      await this.configureViewMode();
      await this.fillStoreByFilterOptionsForCurrentCategory();
    }

    await this.fillStoreByUrl();
    await this.performProductsLoad();
    this.setLoading(false);
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  async initialFetch() {
    const { api } = this.services;
    const [] = await Promise.all([

    ]);
  }

  async fillStoreByUrl() {
    this.store.readParameters(new URLSearchParams(window.location.search));
  }

  async fillStoreByFilterOptionsForCurrentCategory() {
    const categoryId = this.getRootCategory();
    const categoryChild = this.services.categories.getChilds(categoryId);

    this.store.setupBrands(this.brands);
    this.store.setupCategories(categoryChild);
    this.store.fillForms();
  }

  async emitStoreStateChanges() {
    const params = this.store.serializeParameters()!;
    await this.navigate({
      params: params,
    });
  }

  async backFromSerch() {
    Router.go(Url.to("home-page-with-lang"));
    // temporary disable cleck
    // if (!this.store.state.isFiltersBlank()) {
    //   this.store.state.clear();
    // } else {
    //   Router.go(Url.to("home-page-with-lang"));
    // }
  }

  async performProductsLoad() {
    await this.loader.load({
        // categories: this.services.categories.findTopLevelCategories().map(item => item.id),
        search: this.serchQuery,
      },
      this.store,
    );
    this.resultState = this.store.products.length ? "content" : "empty";
  }

  updateBreadcrumbs() {
    this.breadcrumbs.setBreadcrumbs([
      {
        label: t("0x619y4k02smw9cs.search", "Search"),
        url: Url.to("search-page", {}, new URLSearchParams([["q", this.serchQuery]]).toString()),
      },
    ]);
  }

  getCategoriesPath() {
    const root = this.getRootCategory();
    return this.services.categories.getParents(root).map((item) => item.id).concat(root);
  }

  getRootCategory() {
    return this.brandId;
  }

  configureLayout() {
    // const path = this.getCategoriesPath();
    // const categoryId = path[path.length - 1];
    // const category = this.services.categories.getItem(categoryId);
    // this.category = {
    //   name: category.name,
    //   id: category.id,
    // };
  }

  configureViewMode() {

    this.requestUpdate();
  }

  static stylesCommon = css``;

  static stylesSm = css`
    ${provideLayoutStyles("sm")};
  `;

  static stylesLg = css`
    ${provideLayoutStyles("lg")};
  `;

  static get styles() {
    return [this.stylesCommon, whenType("sm", this.stylesSm), whenType("lg", this.stylesLg)];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "search-page": SearchPage;
  }
}