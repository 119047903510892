import { unsafeCSS } from "lit";

const Heading1 = `
/* Heading/1 */
font-family: "SF Pro Text";
font-size: 38px;
font-style: normal;
font-weight: 600;
line-height: 46px; /* 121.053% */
`;

const Heading1Sub = `
/* Heading/1/Sub */
font-family: "SF Pro Text";
font-size: 38px;
font-style: normal;
font-weight: 400;
line-height: 46px; /* 121.053% */
`;

const BaseNormal = `
/* Base/Normal */
font-family: "SF Pro Text";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
`;

const BaseStrong = `
/* Base/Strong */
font-family: "SF Pro Text";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 157.143% */
`;

const BaseDelete = `
/* Base/Delete */
font-family: "SF Pro Text";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
text-decoration-line: line-through;
`;

const Heading2 = `
/* Heading/Heading 2 */
font-family: "SF Pro Text";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
`;

const Heading3 = `
/* Heading/3 */
font-family: "SF Pro Text";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
`;

const Heading4 = `
/* Heading/4 */
font-family: "SF Pro Text";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 140% */
`;

const Heading5 = `
/* Heading/5 */
font-family: "SF Pro Text";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
`;

const LgNormal = `
/* LG/Normal */
font-family: "SF Pro Text";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
`;

const LgStrong = `
/* LG/Strong */
font-family: "SF Pro Text";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
`;

const XlNormal = `
/* XL/Normal */
font-family: "SF Pro Text";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 140% */
`;

const SmRegular = `
/* SM/Regular */
font-family: "SF Pro Text";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
`;

const SmNormal = `
/* SM/Normal */
font-family: "SF Pro Text";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
`;

const SmStrong = `
/* SM/Strong */
font-family: "SF Pro Text";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */
`;

const Logo1 = `
font-family: "SF Pro Text";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 22px; /* 100% */
`;

const Logo2 = `
font-family: "SF Pro Text";
font-size: 15.59px;
font-style: normal;
font-weight: 700;
line-height: 15.59px; /* 100% */
`;

const FontEntries = [
  ["Base/Normal", BaseNormal],
  ["Base/Strong", BaseStrong],
  ["Base/Delete", BaseDelete],
  ["Heading/1", Heading1],
  ["Heading/2", Heading2],
  ["Heading/3", Heading3],
  ["Heading/4", Heading4],
  ["Heading/5", Heading5],
  ["Heading/1/Sub", Heading1Sub],
  ["SM/Regular", SmRegular],
  ["SM/Normal", SmNormal],
  ["SM/Strong", SmStrong],
  ["LG/Normal", LgNormal],
  ["LG/Strong", LgStrong],
  ["XL/Normal", XlNormal],
  ["Logo/1", Logo1],
  ["Logo/2", Logo2],
] as const;

type FontKeys = (typeof FontEntries)[number][0];

const FontMap = new Map(FontEntries) as Map<FontKeys, string>;

export const font = (type: FontKeys) => {
  return unsafeCSS(FontMap.get(type) ?? "");
};
