export class UrlState {
  encodeParam(param: object) {
    return btoa(JSON.stringify(param));
  }

  decodeParam(value: string) {
    try {
      return JSON.parse(atob(value));
    } catch (e) {
      console.error("Failed to parse URL parameter", e);
      return null;
    }
  }

  readParam(params: URLSearchParams, key: string) {
    const value = params.get(key);
    return value ? this.decodeParam(value) : null;
  }

  writeParam(params: URLSearchParams, key: string, value: object) {
    params.set(key, this.encodeParam(value));
  }

  readIntegerParam(params: URLSearchParams, key: string) {
    const value = params.get(key);
    const parsed = parseInt(value ?? "");

    if (typeof parsed === "number" && !isNaN(parsed)) {
      return parsed;
    }

    return null;
  }

  writeIntegerParam(params: URLSearchParams, key: string, value: number) {
    params.set(key, value.toString());
  }

  readStringParam(params: URLSearchParams, key: string) {
    return params.get(key);
  }

  writeStringParam(params: URLSearchParams, key: string, value: string) {
    params.set(key, value);
  }
}
