import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonCustomize, buttonFont } from "../mobile/fix-button";

@customElement("fix-pager-arrow")
class FixPagerArrow extends LitElement {
  @property()
  type!: "prev" | "next";

  render() {
    return html`<sl-button>${this.renderIcon()}</sl-button>`;
  }

  renderIcon() {
    return html`<sl-icon name=${this.icon} label=${this.type}></sl-icon>`;
  }

  get icon() {
    switch (this.type) {
      case "prev":
        return "chevron-left";
      case "next":
        return "chevron-right";
    }
  }

  static styles = css`
    ${buttonCustomize()}
    ${buttonFont("", "Base/Normal")}
        ${buttonFont(".active", "Base/Strong")}

        sl-button {
      --border-radius: 6px;
      --width: 40px;
      --height: 40px;
    }

    sl-button {
      --color: var(--Components-Pagination-Global-colorText, #27272a);
      --color-bg: transparent;
      --color-border: transparent;
      --color-hover: var(--Components-Pagination-Global-colorText, #27272a);
      --color-bg-hover: var(--Components-Pagination-Global-colorBgTextHover, rgba(0, 0, 0, 0.06));
      --color-border-hover: var(--Components-Pagination-Global-colorBgTextHover, rgba(0, 0, 0, 0.06));
      --color-active: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
      --color-bg-active: var(--Components-Pagination-Component-itemActiveBg, #fff);
      --color-border-active: var(--Components-Pagination-Global-colorPrimary, #3b82f6);
    }
  `;
}
