import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import auto from "@/assets/auto.svg";

@customElement("fix-garage-button")
export class FixGarageButton extends LitElement {
  protected render(): unknown {
    return html`<sl-button>
      <sl-icon src=${auto} slot="prefix"></sl-icon>
      <div class="label">Empty garage</div>
      <div class="delimiter"></div>
      <div class="action">Add car</div>
    </sl-button>`;
  }

  static styles = css`
    :host {
      --sl-input-border-radius-medium: 8px;
    }

    ::part(base) {
      background-color: var(--Base-Cool-Gray-2, #f3f4f6);
      border-color: var(--Base-Cool-Gray-2, #f3f4f6);
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      min-height: 48px;
      display: flex;
      align-items: center;
    }

    ::part(base):hover {
      background-color: transparent;
      border-color: var(--sl-color-primary-300);
    }

    ::part(base):active {
      background-color: var(--Base-Cool-Gray-3, #e5e7eb);
      border-color: var(--Base-Cool-Gray-3, #e5e7eb);
    }

    ::part(base),
    ::part(base):hover,
    ::part(base):active {
      color: var(--Neutral-Text-colorText, #27272a);
      box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.04);
    }

    ::part(prefix) {
      font-size: 24px;
    }

    .action {
      color: var(--Components-Typography-Global-colorLink, #3b82f6);
    }

    .delimiter {
      width: 1px;
      background-color: var(--Base-Gray-4, #d4d4d8);
      height: 100;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    ::part(label) {
      display: flex;
      gap: 12px;
      padding-inline-start: 8px;
      padding-inline-end: 0px;
    }

    sl-button {
      display: flex;
    }
  `;
}
